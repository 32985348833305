import * as React from 'react';
import {
    DateField,
    DeleteButton,
    Edit,
    Labeled,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput, TopToolbar,
    usePermissions,
} from 'react-admin';
import { CircularProgress } from '@mui/material';
import { IodToolbar } from '../../../components/ui/layout/IodToolbar';
import { DOCUMENT_ITEM_TITLE_MAX_LENGTH } from '../types/document';
import { DocumentEditModeSelectInput } from './DocumentEditModeSelectInput';
import {BackButton} from '../../../components/ui/button/BackButton';

export const DocumentEdit = () => {
    const { isLoading, permissions } = usePermissions();
    if (isLoading) {
        return <CircularProgress />;
    }
    // 権限がある場合のみ各ボタンを有効化する
    const hasSavePolicy = permissions.documents.includes('update');
    const hasDeletePolicy = permissions.documents.includes('delete');

    return (
        <Edit
            actions={
                <TopToolbar sx={{justifyContent: 'flex-start'}}>
                    <BackButton />
                    {/*<PrevNextButtons />*/}
                </TopToolbar>
            }
        >
            <SimpleForm
                toolbar={
                    <IodToolbar>
                        <SaveButton disabled={!hasSavePolicy} />
                        <DeleteButton disabled={!hasDeletePolicy} mutationMode="pessimistic" />
                    </IodToolbar>
                }
            >
                <Labeled label="ドキュメントID">
                    <TextField source="id" />
                </Labeled>
                <NumberInput source="position" name="position" label="表示順（0始まり）"  />
                <TextInput
                    source="title"
                    name="title"
                    label="タイトル"
                    placeholder="タイトルを入力"
                    fullWidth
                    required
                    inputProps={{
                        maxLength: DOCUMENT_ITEM_TITLE_MAX_LENGTH,
                    }}
                />
                <DocumentEditModeSelectInput source="edit_mode" label="編集モード" sx={{minWidth: '20%'}}/>
                <Labeled label="作成日時">
                    <DateField source="created_at" showTime={true} />
                </Labeled>
                <Labeled label="更新日時">
                    <DateField source="updated_at" showTime={true} />
                </Labeled>
            </SimpleForm>
        </Edit>
    );
};
