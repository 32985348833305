import * as React from 'react';
import { ReactElement } from 'react';
import { NumberListInput } from '../../../components/ui/inputs/NumberListInput';
import TooltipTextInput from '../../../components/ui/inputs/TooltipTextInput';
import PhaseSelectInput from '../../contents/components/PhaseSelectInput';
import { DocumentsFilter } from './DocumentsFilter';
import { FavoriteListFilter } from './FavoriteListFilter';
import { FilterClearButton } from './FilterClearButton';
import { HierarchicalOrgDialog } from './HierarchicalOrgDialog';

export class ParagraphFilterBuilder {
    private filters: ReactElement[] = [];

    addDocumentsFilter(): ParagraphFilterBuilder {
        const source = 'document_id';
        const alwaysOn = true;
        this.filters.push(<DocumentsFilter source={source} alwaysOn={alwaysOn} />);
        return this;
    }

    addTooltipTextInput(): ParagraphFilterBuilder {
        const title = 'スペース区切りで複数キーワード検索ができます';
        const source = 'q';
        const label = '検索';
        const alwaysOn = true;
        this.filters.push(<TooltipTextInput title={title} source={source} label={label} alwaysOn={alwaysOn} />);
        return this;
    }

    addNoteTextInput(): ParagraphFilterBuilder {
        const title = 'スペース区切りで複数キーワード検索ができます';
        const source = 'note';
        const label = '備考';
        const alwaysOn = true;
        this.filters.push(<TooltipTextInput title={title} source={source} label={label} alwaysOn={alwaysOn} />);
        return this;
    }

    addAffiliationTextInput(): ParagraphFilterBuilder {
        const title = '編集した人の所属で前方一致検索できます';
        const source = 'affiliation';
        const label = '編集者の所属';
        const alwaysOn = true;
        this.filters.push(<TooltipTextInput title={title} source={source} label={label} alwaysOn={alwaysOn} />);
        return this;
    }

    addNumberListInput(): ParagraphFilterBuilder {
        const source = 'sequences';
        const title = 'カンマ区切りで複数No検索ができます';
        const label = 'No';
        const alwaysOn = true;
        this.filters.push(<NumberListInput source={source} title={title} label={label} alwaysOn={alwaysOn} />);
        return this;
    }

    addPhaseSelectInput(): ParagraphFilterBuilder {
        const source = 'phase';
        const sx = { minWidth: '110px' };
        const alwaysOn = true;
        this.filters.push(<PhaseSelectInput source={source} sx={sx} alwaysOn={alwaysOn} />);
        return this;
    }

    addHierarchicalOrgDialogs(): ParagraphFilterBuilder {
        const sources = ['responsible_organization_id', 'co_responsible_organization_id'];
        const labels = ['主管組織', '共管組織'];
        const alwaysOn = true;
        sources.forEach((source, index) => {
            this.filters.push(<HierarchicalOrgDialog source={source} label={labels[index]} alwaysOn={alwaysOn} />);
        });
        return this;
    }

    addFavoriteListFilter(): ParagraphFilterBuilder {
        const source = 'favorite_list_id';
        const alwaysOn = true;
        this.filters.push(<FavoriteListFilter source={source} alwaysOn={alwaysOn} />);
        return this;
    }

    build(): ReactElement[] {
        // 最後に必ずクリアボタンを追加
        this.filters.push(<FilterClearButton source="clear" alwaysOn={true} />);
        return this.filters;
    }
}
