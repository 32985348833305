import React from 'react';
import { Logout, UserMenu, useGetIdentity, useUserMenu } from 'react-admin';
import { Link } from 'react-router-dom';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { ManageAccounts } from '@mui/icons-material';
import { UserProfileIcon } from '../elements/UserProfileIcon';


const ConfigurationMenu = React.forwardRef((props, ref: any) => {
    const { onClose } = useUserMenu();
    return (
        <MenuItem
            onClick={onClose}
            ref={ref}
            component={Link}
            // It's important to pass the props to allow MUI to manage the keyboard navigation
            {...props}
            //http://localhost:3000/#/usersettingsにアクセスされる
            to="/usersettings"
        >
            <ListItemIcon>
                <ManageAccounts fontSize="small" />
            </ListItemIcon>
            <ListItemText>ユーザ設定</ListItemText>
        </MenuItem>
    );
});

export const IodUserMenu = (props: any) => {
    const { data: userData } = useGetIdentity();
    return (
        <UserMenu
            icon={<UserProfileIcon userId={userData?.id} />}
        >
            <ConfigurationMenu />
            <Logout />
        </UserMenu>
    );
};
