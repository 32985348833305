import { RaThemeOptions, defaultTheme } from 'react-admin';
import { PaletteColorOptions } from '@mui/material';
import { PaletteColor } from '@mui/material/styles/createPalette';


/**
 * PaletteおよびPaletteOptionsインタフェースを上書きする。
 *
 * https://mui.com/material-ui/customization/palette/#custom-colors
 */
declare module '@mui/material/styles' {
    interface Palette {
        edit_secondary: PaletteColor;
    }

    interface PaletteOptions {
        edit_secondary?: PaletteColorOptions;
    }
}


export interface IodThemeOptions extends RaThemeOptions {
    sidebar: {
        width: number;
        closedWidth: number;
    };
}

export const iodThemeOptions: IodThemeOptions = {
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        // defaultTheme.paletteで設定されているテーマを上書きによりカスタマイズ
        primary: {
            //light: '#757ce8',
            //main: '#002063',
            main: '#399c4a',
            //    dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            //light: '#ff7961',
            main: '#002063',
            //main: '#399c4a',
            //dark: '#ba000d',
            contrastText: '#fff',
        },
        edit_secondary: {
            // light: '',
            // dark: '',
            main: '#fcf3e9',
            contrastText: '#de8423',
        }
    },
    sidebar: {
        // width: 240, // The default value is 240
        // closedWidth: 55, // The default value is 55
        width: 53, // for solarLayout
        closedWidth: 53, // for solarLayout
    },
    components: {
        ...defaultTheme.components,
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    // borderLeft: '3px solid #000',
                    '&.RaMenuItemLink-active': {
                        // border: '10px solid #399c4a',
                        // color: '#399c4a',
                        fontWeight: 'bold',
                        background: '#ccdcff',
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "0.8rem", // ツールチップのデフォルト文字サイズを少し大き目に変更
                }
            }
        }
    },
};
