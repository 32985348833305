export class ParagraphBodyHighlight {
    static normalizeKeyword(keyword: string): string[] {
        if (!keyword) {
            return [];
        }

        // 正規化したのち、キーワードをリストにして返す
        const normalizedKeyword = keyword.replace(/　/g, ' ');
        const keywords = normalizedKeyword.trim().split(' ');
        return keywords;
    }

    static highlightBody(body: string, keywords: string[]): string {
        // see: api: /ddd/infrastructure/mysql/content/paragraph/paragraph_query_service.py # __highlight_body

        const KEYWORD_NO_MATCH = -1;

        if (keywords.length === 0) {
            return body;
        }

        // キーワードの重複を削除
        const keywordSet = new Set(keywords);
        const uniqueKeywords = Array.from(keywordSet);

        // 本文における各キーワードの出現位置を特定する
        let indices: [number, number][] = [];
        uniqueKeywords.forEach((keyword) => {
            let start = 0;
            while (start < body.length) {
                const startIdx = body.indexOf(keyword, start);
                if (startIdx === KEYWORD_NO_MATCH) {
                    break; // 本文に一致箇所がない場合は処理を抜ける
                }

                const endIdx = startIdx + keyword.length;
                // indices.push([startIdx, endIdx]);

                /*
                 * FIXME: 暫定処置
                 * HTML画像リンクが崩れないようにする暫定処置
                 * 将来的にHTML画像リンクが無くなる場合は不要となる
                 * alt属性付近の場合は、HTML画像リンクとみなしてインデックスから除外する
                 */
                const forwardLength = 30; // 前方文字数
                const altCheckStartIdx = Math.max(0, startIdx - forwardLength);
                const altCheckRange = body.slice(altCheckStartIdx, startIdx);
                const hasAltAttribute = altCheckRange.includes('alt=');
                if (!hasAltAttribute) {
                    indices.push([startIdx, endIdx]);
                }

                start = endIdx; // 次の開始位置を更新
            }
        });

        // 本文に一致箇所がない ※画像中の文言にヒットした場合に起こり得る
        if (indices.length === 0) {
            return body;
        }

        // インデックスをキーワードの開始位置でソート
        indices.sort((a, b) => a[0] - b[0]);

        // 複数のキーワードが近接して出現する場合、それらをまとめてハイライトして視覚体験を向上するひと手間
        let mergedIndices: [number, number][] = [];
        let [prevStart, prevEnd] = indices[0];
        for (let i = 1; i < indices.length; i++) {
            const [start, end] = indices[i];
            if (start <= prevEnd) {
                // 範囲が連続または重複している場合
                prevEnd = Math.max(prevEnd, end);
            } else {
                mergedIndices.push([prevStart, prevEnd]);
                [prevStart, prevEnd] = [start, end];
            }
        }
        mergedIndices.push([prevStart, prevEnd]);

        // 本文に対してハイライト加工を施す
        let highlightedBody = '';
        let lastEnd = 0;
        mergedIndices.forEach(([start, end]) => {
            // 無加工テキストを反映
            highlightedBody += body.slice(lastEnd, start);

            // ハイライト加工後のテキストを反映
            highlightedBody += `<span class="highlighter">${body.slice(start, end)}</span>`;

            lastEnd = end; // ハイライト終了位置を更新
        });
        // 最後のハイライト後のテキストを反映
        highlightedBody += body.slice(lastEnd);

        return highlightedBody;
    }
}
