import React, { FC } from 'react';
import { useDelete } from 'react-admin';
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogProps } from '@mui/material';
import { FavoriteItem } from '../types/favoriteItem';

export interface FavoriteListDeleteDialogProps extends DialogProps {
    list_id: number;
    onSuccess?: () => void;
}

export const FavoriteListDeleteDialog: FC<FavoriteListDeleteDialogProps> = ({ list_id, onSuccess, ...props }) => {
    const [deleteOne, { isLoading, error }] = useDelete<FavoriteItem>(undefined, undefined, {
        onError: (error: any) => {
            return error;
        },
        onSuccess: (res: any) => {
            onSuccess && onSuccess();
        },
    });

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        return <p>ERROR</p>;
    }

    const handleDeleteClick = async () => {
        await deleteOne('favorites', {
            id: list_id,
        });
    };

    const handleCancelClick = (e: any) => {
        props.onClose && props.onClose(e, 'backdropClick');
    };

    return (
        <Dialog {...props}>
            <DialogContent>このお気に入りリストを削除してもよろしいですか？</DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleCancelClick}>
                    キャンセル
                </Button>
                <Button color="error" variant="contained" onClick={handleDeleteClick}>
                    削除
                </Button>
            </DialogActions>
        </Dialog>
    );
};
