import { Labeled } from 'react-admin';
import { Chip, List } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Organization } from '../../organizations/types/organization';

interface DiffOrganizationsProps {
    oldOrganizations: Array<Organization>;
    newOrganizations: Array<Organization>;
    organizationType: OrganizationType;
    labelName: string;
}

type OrganizationType = 'responsible' | 'co_responsible';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export const DiffOrganizations = (props: DiffOrganizationsProps) => {
    const { oldOrganizations, newOrganizations, organizationType, labelName } = props;

    // 組織IDの集合を作成
    const oldIds = new Set(oldOrganizations.map((org: Organization) => org.id));
    const newIds = new Set(newOrganizations.map((org: Organization) => org.id));

    // 削除されたIDと追加されたIDを区別しておき、後続で装飾する
    const removedIds = new Set([...oldIds].filter((id) => !newIds.has(id)));
    const addedIds = new Set([...newIds].filter((id) => !oldIds.has(id)));

    return (
        <>
            <Labeled label={labelName} fullWidth sx={{ mb: 2 }}>
                <List sx={{ display: 'flex', flexDirection: 'row', p: 0.5 }}>
                    {oldOrganizations.map((organization: Organization) => (
                        <ListItem sx={{ p: 0.5, m: 0 }} key={organization.id}>
                            <Chip
                                label={organization.name}
                                variant={organizationType === 'responsible' ? 'filled' : 'outlined'}
                                sx={{
                                    color: removedIds.has(organization.id) ? 'red' : 'default',
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Labeled>
            <Labeled label={labelName} fullWidth sx={{ mb: 2 }}>
                <List sx={{ display: 'flex', flexDirection: 'row', p: 0.5 }}>
                    {newOrganizations.map((organization: Organization) => (
                        <ListItem sx={{ p: 0.5, m: 0 }} key={organization.id}>
                            <Chip
                                label={organization.name}
                                variant={organizationType === 'responsible' ? 'filled' : 'outlined'}
                                sx={{
                                    color: addedIds.has(organization.id) ? 'green' : 'default',
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Labeled>
        </>
    );
};
