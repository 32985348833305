import * as React from 'react';
import { Identifier, RaRecord } from 'react-admin';
import { DocumentListComponent } from './DocumentListComponent';

/**
 * 目次（現行版）のドキュメント選択画面
 */
export const LatestDocumentList = () => {
    const handleRowClick = (id: Identifier, resource: string, record: RaRecord) => {
        return `/${resource}/${id}/contents_view`;
    };

    return <DocumentListComponent canEdit={false} rowClick={handleRowClick} />;
};
