import React from 'react';
import { Title, TopToolbar, useGetList, useGetOne, usePermissions, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { BackButton } from '../../components/ui/button/BackButton';
import { Snapshot } from '../snapshots/types/snapshot';
import { ComparisonTableExportCard } from './ComparisonTableExportCard';

export const ComparisonTableExportPage = () => {
    const translate = useTranslate();
    const { documentId } = useParams();
    const { permissions, isLoading: isLoadingPermissions } = usePermissions();
    const { data: document, isLoading: isLoadingDoc } = useGetOne('documents', {
        id: documentId
    }, {
        enabled: !!documentId
    });
    const { data: snapshots, isLoading: isLoadingSnapshots } = useGetList<Snapshot>(
        `documents/${documentId}/snapshots`,
        {
            // pagination: { page: startPage, perPage },
            sort: {
                field: 'id',
                order: 'DESC',
            },
        },
        { enabled: !!documentId },
    );

    if (isLoadingPermissions || isLoadingDoc || isLoadingSnapshots || !snapshots || !documentId) {
        return <CircularProgress />;
    }

    return (
        <>
            <TopToolbar sx={{ justifyContent: 'flex-start' }}>
                <BackButton />
            </TopToolbar>

            <Title title={`${document.title} / 新旧対照表出力`} />

            <ComparisonTableExportCard documentId={Number(documentId)} snapshots={snapshots} />
        </>
    );
};
