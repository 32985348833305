export const API_URL = process.env.REACT_APP_API_URL as string;

export const cognitoConstants = {
    /**
     * Cognitoの設定情報
     *
     * 色々オプションのプロパティが存在するため、詳細はAWSの公式ドキュメント参照
     * https://docs.amplify.aws/lib/auth/getting-started/q/platform/js/#set-up-backend-resources
     */

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
};
