import * as React from 'react';
import { memo } from 'react';
import { FunctionField } from 'react-admin';
import { FunctionFieldProps } from 'ra-ui-materialui/src/field/FunctionField';
import { UserInfo } from '../../../features/users/types/userInfo';


interface UserFieldProps extends Omit<FunctionFieldProps, 'render'> {}

export const UserField = memo((props: UserFieldProps) => {
    const { source: userSource, ...restProps } = props;

    if (!userSource) {
        return null;
    }

    return (
        <FunctionField
            source={userSource}
            render={(record: any) => {
                const user: UserInfo = record[userSource] as UserInfo;
                if (!user) {
                    return null;
                }
                // 単体のときはチップ表示さずにそのまま表示する方向で
                // return <UserChip key={user.id} user={user} />;
                return `【${user.affiliation}】${user.name}`;
            }}
            {...restProps}
        />
    );
});
