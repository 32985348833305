import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import {
    DateField,
    EditBase,
    Labeled,
    NumberField,
    SimpleForm,
    TextField,
    usePermissions,
    useShowContext,
} from 'react-admin';
import { NodeEditActions, ShowNode } from '@react-admin/ra-tree';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Chip, CircularProgress, Grid, Stack, Tab, Tabs } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IodMarkdownField from '../../../components/ui/fields/IodMarkdownField';
import { UserField } from '../../../components/ui/fields/UserField';
import { useContentPermissions } from '../../../hooks/useContentPermissions';
import { PhaseField } from '../../paragraphs/components/PhaseField';
import { CommitAndDiffContainer } from '../../workflow/components/CommitsAndDiffContainer';
import { ContentNode } from '../types/contentNode';
import { OrganizationArrayField } from './OrganizationArrayField';

interface Props {
    onRecordChange: (record: ContentNode) => void;
}

const ContentShowForm: FC<Props> = (props) => {
    const { onRecordChange } = props;

    // タブ制御関連
    const [activeTabPosition, setActiveTabIndex] = useState(0);

    // データ関連
    const { record } = useShowContext<ContentNode>();

    const { isLoading, permissions } = usePermissions();

    useEffect(() => {
        if (record) {
            setActiveTabIndex(1);
            // デフォルト表示タブを出し分ける場合
            // setActiveTabIndex(isEditingHeading ? 0 : 1);

            // 親側にrecordの変更を通知（見出し/パラグラフの判断に必要）
            onRecordChange(record);
        }
    }, [record, onRecordChange]);

    if (!record) {
        return <CircularProgress />;
    }

    // 権限
    if (isLoading || !record) {
        return <CircularProgress />;
    }
    const hasUpdateHeadingPolicy = permissions.headings.includes('update');

    // 見出し判定
    const isEditingHeading = record?.content_type === 'heading';

    const handleTabChange = (event: React.ChangeEvent<{}>, selectedTabIndex: number) => {
        setActiveTabIndex(selectedTabIndex);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Tabs value={activeTabPosition} onChange={handleTabChange}>
                    <Tab label="編集履歴" disabled={isEditingHeading} />
                    <Tab label="最新修正案" />
                </Tabs>

                {!hasUpdateHeadingPolicy && isEditingHeading ? (
                    <></> // 編集者で見出しの場合は表示しない
                ) : (
                    <ContentEditButton documentId={record.document_id} contentPath={record.content_path} />
                )}
            </Box>

            {/* タブ index:0 */}
            <CustomTabPanel activeTabPosition={activeTabPosition} tabPosition={0}>
                <CommitAndDiffContainer
                    documentId={record.document_id}
                    contentPath={record.content_path}
                    canHistoryOperation={true}
                />
            </CustomTabPanel>

            {/* タブ index:1 */}
            <CustomTabPanel activeTabPosition={activeTabPosition} tabPosition={1}>
                <EditBase>
                    <SimpleForm toolbar={false}>
                        <NumberField source="id" label="コンテンツノードID" sx={{ display: 'none' }} />
                        <NumberField source="document_id" label="ドキュメントID" sx={{ display: 'none' }} />
                        <NumberField source="content_id" label="コンテンツID" sx={{ display: 'none' }} />

                        <Grid container spacing={2} sx={{ mb: 2.5 }}>
                            <Grid item xs={12} md={record.updated_by ? 3 : 4}>
                                <Labeled label="コンテンツタイプ" sx={{ mb: 2 }}>
                                    <Chip
                                        label={isEditingHeading ? '見出し' : 'パラグラフ'}
                                        variant="outlined"
                                        sx={{ mt: 0.5 }}
                                    />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12} md={record.updated_by ? 3 : 8}>
                                <Labeled label="最終更新日時">
                                    <DateField source="updated_at" showTime={true} />
                                </Labeled>
                            </Grid>
                            {record.updated_by && (
                                <Grid item xs={12} md={6}>
                                    <Labeled label="最終更新者">
                                        <UserField source="updated_by" sx={{ mt: 0.5 }} />
                                    </Labeled>
                                </Grid>
                            )}
                        </Grid>

                        {isEditingHeading ? (
                            <Labeled fullWidth sx={{ mb: 2 }}>
                                <TextField name="body" source="body" label="内容" />
                            </Labeled>
                        ) : (
                            <>
                                <Labeled label="内容" fullWidth sx={{ mb: 2 }}>
                                    <IodMarkdownField
                                        source="body"
                                        label="" // 空で渡さないと二重でラベル表示される
                                        className="font-size-changeable"
                                    />
                                </Labeled>

                                <Labeled fullWidth sx={{ mb: 2 }}>
                                    <PhaseField />
                                </Labeled>

                                <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                                    <OrganizationArrayField type="responsible_organization" />
                                    <OrganizationArrayField type="co_responsible_organization" />
                                </Stack>

                                <Labeled label="画像中の文言" fullWidth sx={{ mb: 2 }}>
                                    <TextField name="embedded_text" source="embedded_text" label="画像中の文言" />
                                </Labeled>
                            </>
                        )}

                        <Labeled label="備考" fullWidth sx={{ mb: 2 }}>
                            <TextField name="note" source="note" label="備考" />
                        </Labeled>
                    </SimpleForm>
                </EditBase>
            </CustomTabPanel>
        </Box>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    tabPosition: number;
    activeTabPosition: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, activeTabPosition, tabPosition } = props;

    return (
        <div
            role="tabpanel"
            hidden={activeTabPosition !== tabPosition}
            id={`simple-tabpanel-${tabPosition}`}
            aria-labelledby={`simple-tab-${tabPosition}`}
        >
            {/* アクティブなタブに対応するコンテンツだけを表示する */}
            {activeTabPosition === tabPosition && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
};

export const ContentEditShow: FC = () => {
    const [contentNode, setContentNode] = useState<ContentNode | null>(null);
    const { isLoading, permissions } = usePermissions();
    const handleRecordChange = useCallback((record: ContentNode) => {
        setContentNode(record);
    }, []);

    if (isLoading) {
        return <CircularProgress />;
    }

    const isEditingHeading = !!contentNode && contentNode.content_type === 'heading';
    const hasCreatePolicy = permissions.headings.includes('create') && permissions.paragraphs.includes('create');

    return (
        <ShowNode
            sx={{ minWidth: '500px' }}
            // mutationMode="pessimistic" // 更新時にすぐAPIをコールし、結果を反映する。ツリーのlazyモードでは指定が必須
            actions={hasCreatePolicy && isEditingHeading ? <NodeEditActions /> : false} // add child button
            title="目次（最新修正案）" // デフォルトだとsuffixにIDが付与されるため指定
        >
            <ContentShowForm onRecordChange={handleRecordChange}></ContentShowForm>
        </ShowNode>
    );
};

interface ContentEditButtonProps {
    documentId: number;
    contentPath: string;
}

const ContentEditButton = (props: ContentEditButtonProps) => {
    const { documentId, contentPath } = props;

    const { updatable } = useContentPermissions({ documentId: documentId, contentPath: contentPath });
    const navigate = useNavigate();

    const handleEdit = () => {
        // 編集画面に遷移
        navigate(`/edit/documents/${documentId}/edit/contents/${encodeURIComponent(contentPath)}`);
    };

    return (
        <Button
            variant="text"
            color="primary"
            onClick={handleEdit}
            sx={{ pl: 2, pr: 2, columnGap: 0.5 }}
            disabled={!updatable}
        >
            <EditIcon fontSize="small" />
            編集
        </Button>
    );
};
