import React, { useState } from 'react';
import {
    Labeled,
    Show,
    SimpleForm,
    Toolbar,
    useCreate,
    useDelete,
    useEditContext,
    useGetList,
    useNotify,
} from 'react-admin';
import { Alert, Button, CardHeader, CircularProgress } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Identifier } from 'ra-core';
import { ErrorResponse } from '../../../types/errorResponse';
import { UserInfo } from '../../users/types/userInfo';
import { ReviewsField } from '../pages/diffs/components/ReviewsField';
import { MergeRequestOperationButtons } from '../pages/merge-requests/components/MergeRequestOperationButtons';
import { MergeRequestInfo } from '../types/mergeRequest';
import { Reviewer, ReviewerAdd } from '../types/reviewer';
import { ReviewerDialog } from './ReviewerDialog';

export const MergeRequestReviewCard = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    const { record, isLoading, refetch } = useEditContext<MergeRequestInfo>();
    const notify = useNotify();
    const { data: allReviewers, isLoading: isLoadingReviewers } = useGetList<UserInfo>('users/me/reviewers');
    const [create] = useCreate<ReviewerAdd | { comment: string }>();
    const [deleteOne, { data, isLoading: isLoadingDelete, error }] = useDelete();

    if (isLoading || !record) {
        return <CircularProgress />;
    }

    if (isLoadingReviewers || !allReviewers) {
        return <CircularProgress />;
    }

    const handleAddReviewer = async (user: UserInfo) => {
        await create(
            `merge_requests/${record.id}/reviewers`,
            {
                data: {
                    reviewer_id: user.id,
                },
            },
            {
                onError: (error: ErrorResponse | any) => {
                    // if (error.body?.code === 'RESOURCE_CONFLICT') {
                    //     notify(<Alert severity="error">既に指定の確認者は追加されています。</Alert>, {
                    //         autoHideDuration: 10000,
                    //     });
                    // } else {
                    // 想定外のエラー
                    notify(
                        <Alert severity="error">
                            確認者の追加に失敗しました
                            <br />
                            {error.status && error.message}
                        </Alert>,
                        {
                            autoHideDuration: 10000,
                        },
                    );
                    // }
                    // return error;
                },
                onSuccess: (res: Reviewer) => {
                    notify(<Alert severity="info">確認者を追加しました</Alert>, { autoHideDuration: 10000 });
                    refetch();
                },
            },
        );
    };

    const handleDeleteReviewer = async (userId: Identifier) => {
        await deleteOne(
            `merge_requests/${record.id}/reviewers`,
            {
                id: userId,
            },
            {
                onSuccess: () => {
                    notify(<Alert severity="info">確認者を削除しました</Alert>, { autoHideDuration: 10000 });
                    refetch();
                },
                onError: (error: any) => {
                    // if (error && error.status === 403) {
                    //     // TODO
                    //     notify(<Alert severity="error">確認者を削除する権限がありません</Alert>);
                    // } else {
                    notify(
                        <Alert severity="error">
                            確認者の削除に失敗しました
                            <br />
                            {error.status && error.message}
                        </Alert>,
                        { autoHideDuration: 10000 },
                    );
                    // }
                },
            },
        );
    };

    const handleReassignReviewer = async (userId: Identifier) => {
        await create(
            `merge_requests/${record.id}/reviewers/${userId}/reassign`,
            {
                data: {
                    comment: '',
                },
            },
            {
                onError: (error: ErrorResponse | any) => {
                    // if (error.body?.code === 'RESOURCE_CONFLICT') {
                    //     notify(<Alert severity="error">確認者に再確認依頼を行いました。</Alert>);
                    // } else {
                    // 想定外のエラー
                    notify(
                        <Alert severity="error">
                            確認者の再確認依頼に失敗しました
                            <br />
                            {error.status && error.message}
                        </Alert>,
                        {
                            autoHideDuration: 10000,
                        },
                    );
                    // }
                    // return error;
                },
                onSuccess: (res: Reviewer) => {
                    notify(<Alert severity="info">確認者に再確認依頼を行いました</Alert>, {
                        autoHideDuration: 10000,
                    });
                    refetch();
                },
            },
        );
    };

    const canChangeReviewers =
        record.review_stats.status !== 'fixed' && record.review_stats.status !== 'secondary_approved';

    return (
        <Show title={<></>} resource="merge_requests" id={record.id} sx={{ mb: 2, mt: 0 }} actions={<></>}>
            <CardHeader title="確認・承認担当者" action={<></>} />

            <SimpleForm
                toolbar={
                    <Toolbar>
                        <MergeRequestOperationButtons allowedOptions={['confirm', 'approve', 'reject']} />
                        {canChangeReviewers ? (
                            <Button
                                variant="text"
                                onClick={handleOpenDialog}
                                sx={{ m: 1 }}
                                startIcon={<SettingsIcon />}
                            >
                                確認者の追加・削除
                            </Button>
                        ) : (
                            <></>
                        )}
                    </Toolbar>
                }
            >
                {/*<Labeled label="ステータス" sx={{ mb: 2.5 }}>*/}
                {/*    <MergeStatusField source="status" />*/}
                {/*</Labeled>*/}
                <Labeled label="確認・承認担当者" sx={{ mb: 2.5 }}>
                    <ReviewsField canReassign={canChangeReviewers} onReassignReviewer={handleReassignReviewer} />
                </Labeled>

            </SimpleForm>

            <ReviewerDialog
                open={openDialog}
                onClose={handleCloseDialog}
                availableReviewers={allReviewers}
                selectedReviewers={record.reviews}
                onAddReviewer={handleAddReviewer}
                onDeleteReviewer={handleDeleteReviewer}
            />
        </Show>
    );
};
