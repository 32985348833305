// import * as React from 'react';
import { DateField, EditButton, List, RowClickFunction, TextField, usePermissions } from 'react-admin';
import { CircularProgress } from '@mui/material';
import { DocumentDatagridComponent } from './DocumentDatagridComponent';

interface DocumentListComponentProps {
    canEdit: boolean;
    rowClick: string | RowClickFunction | false;
    children?: React.ReactNode;
}

export const DocumentListComponent = (props: DocumentListComponentProps) => {
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return <CircularProgress />;
    }

    // 権限がある場合のみ各ボタンを有効化する
    const hasCreatePolicy = permissions.documents.includes('create');
    const hasEditPolicy = permissions.documents.includes('update');

    return (
        <List
            exporter={false}
            pagination={false}
            hasCreate={props.canEdit && hasCreatePolicy}
            sort={{ field: 'position', order: 'ASC' }}
        >
            <DocumentDatagridComponent canEdit={props.canEdit} rowClick={props.rowClick}>
                <TextField source="id" label="ドキュメントID" sortable={false} />
                <TextField source="position" label="表示順" sortable={false} />
                <TextField source="title" label="タイトル" sortable={false} />
                <DateField source="created_at" label="作成日時" sortable={false} showTime={true} />
                <DateField source="updated_at" label="更新日時" sortable={false} showTime={true} />
                {props.canEdit && hasEditPolicy && <EditButton />}
                {props.children}
            </DocumentDatagridComponent>
        </List>
    );
};
