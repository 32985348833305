import * as React from 'react';
import {
    BooleanInput,
    Create,
    Labeled,
    NumberInput, SaveButton,
    SimpleForm,
    TextInput, TopToolbar,
    useGetOne,
    usePermissions,
    useTranslate
} from 'react-admin';
import {useParams} from 'react-router-dom';
import {Alert, Box, CircularProgress} from '@mui/material';
import {IodToolbar} from '../../../components/ui/layout/IodToolbar';
import {BackButton} from '../../../components/ui/button/BackButton';

export const SnapshotCreate = () => {
    const translate = useTranslate();
    const { documentId, snapshotId } = useParams();
    const { isLoading, permissions } = usePermissions();
    const { data, error, isLoading: isLoadingDoc } = useGetOne('documents', { id: documentId });
    if (isLoading || isLoadingDoc || !data) {
        return <CircularProgress />;
    }

    const hasCreatePolicy = permissions.snapshots.includes('create');

    return (
        <Create
            resource={`admin/documents/${documentId}/snapshots`}
            title={`${data.title} / ${translate('routes.documents.snapshots.titles.create')}`}
            actions={
                <TopToolbar sx={{ justifyContent: 'flex-start' }}>
                    <BackButton />
                </TopToolbar>
            }
        >
            <SimpleForm
                toolbar={
                    <IodToolbar>
                        <SaveButton
                            disabled={!hasCreatePolicy}
                            label="版確定"
                        />
                    </IodToolbar>
                }
            >
                <Box sx={{mb: 2, width: '100%'}}>
                    <Alert severity="info">版の確定には時間がかかります。</Alert>
                </Box>

                <TextInput
                    source="name"
                    name="name"
                    label={translate('routes.documents.snapshots.fields.name')}
                    placeholder="タイトルを入力"
                    fullWidth
                    inputProps={{
                        maxLength: 50,
                    }}
                    required
                />
                <TextInput
                    source="description"
                    name="description"
                    label={translate('routes.documents.snapshots.fields.description')}
                    placeholder="説明を入力"
                    fullWidth
                    inputProps={{
                        maxLength: 500,
                    }}
                    multiline
                    maxRows={5}
                />
                {/* TODO あとで変える可能性大だが一旦実装 */}
                <BooleanInput
                    source="is_public"
                    name="is_public"
                    label={translate('routes.documents.snapshots.fields.is_public')}
                />
            </SimpleForm>
        </Create>
    );
};
