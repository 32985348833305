import { useMemo } from 'react';
import { useSidebarState } from 'react-admin';
import { useTheme } from '@mui/material/styles';
import { IodThemeOptions } from '../components/functional/themes';

/**
 * サイドバーの現在の幅を取得するカスタムフック
 *
 * 利用例：
 * const sidebarWidth = useSidebarWidth();
 * console.log(sidebarWidth);
 */
export const useSidebarWidth = () => {
    const [isOpen] = useSidebarState();
    const theme = useTheme<IodThemeOptions>();

    // Return the appropriate width based on the sidebar state
    return useMemo(() => {
        return isOpen ? theme.sidebar.width : theme.sidebar.closedWidth;
    }, [isOpen, theme.sidebar.width, theme.sidebar.closedWidth]);
};
