import { Labeled } from 'react-admin';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { DiffText } from '../../../components/ui/elements/DiffText';
import { formatDate } from '../../../utils/dateUtil';
import { ContentPhaseType } from '../../contents/types/contentPhaseType';
import { ContentMetadata } from '../types/diffInfo';
import { DiffFiles } from './DiffFiles';
import { DiffOrganizations } from './DiffOrganizations';

interface IodMetadataDiffViewerProps {
    newMetadata: ContentMetadata | null;
    oldMetadata: ContentMetadata | null;
    oldUpdatedAt: string;
    newUpdatedAt: string;
    oldSnapshotName: string;
    newSnapshotName: string;
}

export const IodMetadataDiffViewer = (props: IodMetadataDiffViewerProps) => {
    const { newMetadata, oldMetadata, oldUpdatedAt, newUpdatedAt, oldSnapshotName, newSnapshotName } = props;

    return (
        <Stack sx={{ width: '100%' }}>
            <Box sx={{ p: 1 }}>
                {/* 編集前・編集後の帯：差分表示コンポーネント本体と同様のデザイン */}
                {/* see: /src/features/workflow/components/IodDiffViewer.tsx */}
                <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
                    <Box sx={{ mb: '0', width: '50%', backgroundColor: '#FEE8E9' }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography
                                variant="h6"
                                color="red"
                                sx={{
                                    fontWeight: 'bold',
                                    pl: 1,
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                }}
                            >
                                編集前　
                                <Typography>
                                    {oldSnapshotName ? oldSnapshotName : oldUpdatedAt && formatDate(oldUpdatedAt)}
                                </Typography>
                            </Typography>
                        </Stack>
                    </Box>
                    <Box sx={{ mb: '0', width: '50%', backgroundColor: '#DDFFDD' }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography
                                variant="h6"
                                color="green"
                                sx={{
                                    fontWeight: 'bold',
                                    pl: 1,
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                }}
                            >
                                編集後　
                                <Typography>
                                    {newSnapshotName ? newSnapshotName : newUpdatedAt && formatDate(newUpdatedAt)}
                                </Typography>
                            </Typography>
                        </Stack>
                    </Box>
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Labeled label="フェーズ" fullWidth sx={{ mb: 2 }}>
                        <TextField
                            value={oldMetadata ? getPhaseLabel(oldMetadata.phase) : ''}
                            size="small"
                            variant="standard"
                            inputProps={{ readOnly: true }}
                            sx={{ ml: 1, width: '20%' }}
                        />
                    </Labeled>
                    <Labeled label="フェーズ" fullWidth sx={{ mb: 2 }}>
                        <TextField
                            value={newMetadata ? getPhaseLabel(newMetadata.phase) : ''}
                            size="small"
                            variant="standard"
                            inputProps={{ readOnly: true }}
                            sx={{ ml: 1, width: '20%' }}
                        />
                    </Labeled>
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <DiffOrganizations
                        oldOrganizations={oldMetadata ? oldMetadata.responsible_organizations : []}
                        newOrganizations={newMetadata ? newMetadata.responsible_organizations : []}
                        organizationType={'responsible'}
                        labelName="主管組織"
                    />
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <DiffOrganizations
                        oldOrganizations={oldMetadata ? oldMetadata.co_responsible_organizations : []}
                        newOrganizations={newMetadata ? newMetadata.co_responsible_organizations : []}
                        organizationType={'co_responsible'}
                        labelName="共管組織"
                    />
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <DiffText
                        oldText={oldMetadata ? oldMetadata.embedded_text : ''}
                        newText={newMetadata ? newMetadata.embedded_text : ''}
                        removedTextBackgroundColor="#FFB6BA"
                        addedTextBackgroundColor="#97F295"
                        labelName="画像中の文言"
                        paperSx={{ p: 1.5, ml: 1, mt: 1, width: '80%' }}
                    />
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <DiffText
                        oldText={(oldMetadata ? oldMetadata.note : '') || ''}
                        newText={(newMetadata ? newMetadata.note : '') || ''}
                        removedTextBackgroundColor="#FFB6BA"
                        addedTextBackgroundColor="#97F295"
                        labelName="備考"
                        paperSx={{ p: 1.5, ml: 1, mt: 1, width: '80%' }}
                    />
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <DiffFiles
                        oldFiles={oldMetadata ? oldMetadata.files : []}
                        newFiles={newMetadata ? newMetadata.files : []}
                        labelName="関連ファイル"
                    />
                </Box>
            </Box>
        </Stack>
    );
};

/**
 * フェーズの論理名を返す関数
 *
 * FIXME: フェーズの論理名を提供する処理は散見されるため共通化を検討
 */
const getPhaseLabel = (phase: ContentPhaseType | null) => {
    switch (phase) {
        case 'yobou':
            return '予防';
        case 'oukyu':
            return '応急';
        case 'fukkyu':
            return '復旧';
        default:
            return '';
    }
};
