import * as React from 'react';
import { FC, useState } from 'react';
import {
    Alert,
    AlertColor,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton, Snackbar,
    TextField, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IodMarkdownInput from '../../../components/ui/inputs/IodMarkdownInput';
import {useLocation} from 'react-router-dom';
import {httpClient} from '../../../components/functional/dataProvider';
import {API_URL} from '../../../config';

/**
 * パラグラフ入力用のマークダウンインプット
 */
export const ParagraphMarkdownInput: FC = () => {
    // データ関連
    const [ocrText, setOcrText] = useState<string>('');
    const [resultOcrOpen, setResultOcrOpen] = useState<boolean>(false);
    // OCR実行確認ダイアログの表示状態
    const [confirmOcrOpen, setConfirmOcrOpen] = useState(false);

    const [currentBlob, setCurrentBlob] = useState<Blob | File | null>(null);
    const [currentCallback, setCurrentCallback] = useState<any>(null);

    const handleResultOcrClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setResultOcrOpen(false);
    };

    const location = useLocation();

    /*
        スナックバーの表示制御
    */
    type SnackbarType = 'NOTICE' | 'SUCCESS' | 'ALERT';
    //  プレビューの表示方法

    interface SnackbarState {
        type: SnackbarType;
        open: boolean;
    }

    const initialSnackbarState: SnackbarState = {
        type: 'NOTICE',
        open: false,
    };

    const snackbarMessages: { [key in SnackbarType]: string } = {
        NOTICE: '画像をアップロードしています。',
        SUCCESS: '画像のアップロードが完了しました。',
        ALERT: '画像のアップロードに失敗しました。',
    };

    const snackbarSeverities: { [key in SnackbarType]: AlertColor } = {
        NOTICE: 'info',
        SUCCESS: 'success',
        ALERT: 'error',
    };

    const [snackbarState, setSnackbarState] = useState<SnackbarState>(initialSnackbarState);
    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        // 関係ないところをクリックして消えないようにする
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarState((prevState) => ({ ...prevState, open: false }));
    };

    const handleImageBlob = async (blob: Blob | File, callback: any) => {
        setCurrentBlob(blob);
        setCurrentCallback(() => callback);
        setConfirmOcrOpen(true);
    };

    const handleConfirm = async (blob: Blob | File, callback: any, enableOCR: boolean) => {
        setConfirmOcrOpen(false); // ダイアログを閉じる

        try {
            // 処理開始通知
            setSnackbarState((prevState) => ({ ...prevState, open: false }));
            setSnackbarState({ type: 'NOTICE', open: true });

            // ファイルのデータを作成してAPIを叩く
            const fileName = blob instanceof File ? blob.name : 'image.png';

            const requestBody = new FormData();
            requestBody.append('file', blob, fileName);
            requestBody.append('name', fileName);

            let resource = location.pathname
            if (resource.startsWith('/edit')) {
                // 最初に見つかった "edit/" のみを置換
                // URLから `/edit/` 部分を削除し、必要な形式に変換する正規表現を使用
                const regex = /\/edit(\/documents\/\d+)\/edit\/(contents\/.+)/;
                const replacement = '$1/$2';
                // URL内の不要な `/edit/` 部分を削除し、新しい形式に置き換える
                const newUrl = resource.replace(regex, replacement);

                // `/edit/` を削除した新しいURLを返す
                resource = newUrl.replace(/\/edit/g, '');
            }

            const { json } = await httpClient(`${API_URL}${resource}/media?enable_ocr=${enableOCR}`, {
                method: 'POST',
                body: requestBody,
            });

            // 返ってきたURLを画像のURLとしてセット
            callback(json.url, fileName);

            if (enableOCR) {
                setOcrText(json.ocr_text);
                setResultOcrOpen(true);
            }

            // 成功通知
            setSnackbarState((prevState) => ({ ...prevState, open: false }));
            setSnackbarState({ type: 'SUCCESS', open: true });
        } catch (ex) {
            console.error(ex);
            // 失敗通知
            setSnackbarState((prevState) => ({ ...prevState, open: false }));
            setSnackbarState({ type: 'ALERT', open: true });
        }
    }


    return (
        <>
            <IodMarkdownInput
                name="body"
                source="body"
                label="編集エリア"
                hideModeSwitch={true} // 202401 第3回ユーザテスト向けに、markdownのみに修正
                hooks={{
                    addImageBlobHook: handleImageBlob
                }}
            />
            {/* 通知コンポーネント */}
            <Snackbar
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={3000}
            >
                <Alert severity={snackbarSeverities[snackbarState.type]} onClose={handleSnackbarClose}>
                    {snackbarMessages[snackbarState.type]}
                </Alert>
            </Snackbar>
            <Dialog
                open={confirmOcrOpen}
                onClose={() => setConfirmOcrOpen(false)}
            >
                <DialogTitle>文言抽出の確認</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="body1" sx={{mb: 2}}>画像中の文言抽出処理を実行してよろしいですか？</Typography>
                        <Alert severity="warning">時間がかかる場合があります。</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={async () => {
                        setConfirmOcrOpen(false);
                        if (currentBlob && currentCallback) {
                            await handleConfirm(currentBlob, currentCallback, true);
                        } else {
                            throw new Error('');
                        }
                    }} color="primary" autoFocus>
                        はい
                    </Button>
                    <Button onClick={async () => {
                        setConfirmOcrOpen(false);
                        if (currentBlob && currentCallback) {
                            await handleConfirm(currentBlob, currentCallback, false);
                        } else {
                            throw new Error('');
                        }
                    }} color="primary">
                        いいえ
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={resultOcrOpen} onClose={handleResultOcrClose} fullWidth maxWidth="md">
                <DialogTitle>
                    画像中の文言抽出（OCR）結果
                    <IconButton
                        aria-label="close"
                        onClick={handleResultOcrClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {ocrText ? (
                    <>
                        <DialogContent>
                            <DialogContentText>
                                <Typography variant="body1">
                                    必要に応じてOCR結果をコピーし、「画像中の文言」に追記してください。
                                </Typography>
                            </DialogContentText>
                            <TextField
                                multiline
                                fullWidth
                                rows={10}
                                value={ocrText}
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => navigator.clipboard.writeText(ocrText)}
                                color="primary"
                                startIcon={<ContentCopyIcon />}
                            >
                                コピー
                            </Button>
                        </DialogActions>
                    </>
                ) : (
                    <DialogContent>
                        <DialogContentText>
                        文言を抽出できませんでした。
                        </DialogContentText>
                    </DialogContent>
                )}

            </Dialog>

        </>
    );
};
