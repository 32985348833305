import { FC } from 'react';
import { FunctionField, FunctionFieldProps } from 'react-admin';
import { ReviewStatusChip } from '../../../components/ReviewStatusChip';
import { EditInfo } from '../../../types/editInfo';

interface EditStatusFieldProps extends Omit<FunctionFieldProps, 'render'> {}

export const EditStatusField: FC<EditStatusFieldProps> = (props) => {
    return (
        <FunctionField
            {...props}
            render={(record: EditInfo) => (
                <ReviewStatusChip isMergeRequested={record.is_requested} reviewStats={record.review_stats} />
            )}
        />
    );
};
