import React, { FC } from 'react';
import { SimpleForm, useCreate, useNotify, usePermissions, useRefresh } from 'react-admin';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { Alert, Box, Typography } from '@mui/material';
import { CancelButton } from '../../../components/ui/button/CancelButton';
import { SaveButton } from '../../../components/ui/button/SaveButton';
import IodMarkdownInput from '../../../components/ui/inputs/IodMarkdownInput';
import { IodToolbar } from '../../../components/ui/layout/IodToolbar';
import { FormInformation } from '../types/information';

interface Props {
    onSave: () => void;
    onCancel: () => void;
}

export const InformationEditForm: FC<Props> = (props) => {
    const { isLoading, permissions } = usePermissions();
    const reflesh = useRefresh();
    const notify = useNotify();
    const [create] = useCreate<FormInformation>(undefined, undefined, {
        onError: (error: any) => {
            console.error(error);
            notify(<Alert severity="error">お知らせの更新に失敗しました。</Alert>);
        },
        onSuccess: () => {
            notify(<Alert severity="info">お知らせを更新しました。</Alert>);
            props.onSave();
            reflesh();
        },
    });

    if (isLoading || isLoading) {
        return null;
        // return <CircularProgress/>;
    }

    const postSave = async (data: FieldValues) => {
        try {
            await create('information', { data: { body: data.body || '' } });
        } catch (e) {
            console.error(e);
            notify(<Alert severity="error">お知らせの更新に失敗しました。</Alert>);
        }
    };

    const handleCancel = (e: any) => {
        console.log(e);
        e.preventDefault(); // 本来のEditButtonの機能を止める。
        props.onCancel();
    };

    return (
        <SimpleForm
            onSubmit={postSave}
            toolbar={
                <IodToolbar>
                    <SaveButton />
                    <CancelButton onClick={handleCancel} />
                </IodToolbar>
            }
        >
            <IodMarkdownInput
                name="body" label="お知らせの内容" source="body" />
            <Box
                sx={{
                    width: '100%',
                    textAlign: 'right',
                }}
            >
                <Typography variant="body2">
                    {/*{translate('resources.information.note')}*/}※
                    お知らせの内容は全ユーザのダッシュボードに表示されます。
                </Typography>
            </Box>
        </SimpleForm>
    );
};
