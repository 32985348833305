import { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Chip } from '@mui/material';
import { Check, DoneAll, Edit, Verified } from '@mui/icons-material';
import ReplyIcon from '@mui/icons-material/Reply';
import { ReviewStatsInfo } from '../types/reviewStatsInfo';
import { ReviewStatusCode, determineReviewStatus } from './determineReviewStatus';

export interface ReviewStatusChipProps {
    isMergeRequested: boolean;
    reviewStats?: ReviewStatsInfo;
}

export const ReviewStatusChip: FC<ReviewStatusChipProps> = ({ isMergeRequested: isRequested, reviewStats }) => {
    const translate = useTranslate();

    // ステータスの状態
    const [statusCode, setStatusCode] = useState<ReviewStatusCode>(ReviewStatusCode.editing);

    useEffect(() => {
        const reviewStatusCode: ReviewStatusCode = determineReviewStatus(isRequested, reviewStats);
        setStatusCode(reviewStatusCode);
    }, [isRequested, reviewStats]);

    switch (statusCode) {
        case ReviewStatusCode.editing:
            return <Editing label={translate('resources.merge_requests.statusesName.editing')} />;
        case ReviewStatusCode.confirming:
            return (
                <Confirming
                    label={translate('resources.merge_requests.statusesName.confirming')}
                    reviewStats={reviewStats}
                />
            );
        case ReviewStatusCode.approving:
            return (
                <Approving
                    label={translate('resources.merge_requests.statusesName.approving')}
                    reviewStats={reviewStats}
                />
            );
        case ReviewStatusCode.fixed:
            return <Fixed label={translate('resources.merge_requests.statusesName.fixed')} />;
        case ReviewStatusCode.rejected:
            return <Rejected label={translate('resources.merge_requests.statusesName.rejected')} />;
        case ReviewStatusCode.withdrawn:
            return <Withdrawn label={translate('resources.merge_requests.statusesName.withdrawn')} />;
        default:
            return <></>;
    }
};

interface StatusChipProps {
    icon: ReactElement;
    label: string;
    color: 'primary' | 'secondary' | 'warning' | 'success' | 'error' | 'info';
    variant?: 'outlined' | 'filled';
}

// ツールチップを提供する共通の関数
const StatusChip: FC<StatusChipProps> = ({ icon, label, color, variant = 'filled' }) => {
    return (
        <Chip
            sx={{ pl: 0.5, '& .MuiChip-label': { pl: 1 } }}
            icon={icon}
            label={label}
            variant={variant}
            color={color}
        />
    );
};

const Editing = ({ label }: { label: string }) => {
    return <StatusChip icon={<Edit fontSize="small" />} label={`${label}`} variant="outlined" color="info" />;
};

interface ConfirmingProps {
    label: string;
    reviewStats?: ReviewStatsInfo;
}
const Confirming = (props: ConfirmingProps) => {
    const { label, reviewStats } = props;
    return (
        <StatusChip
            icon={<Check fontSize="small" />}
            label={`${label} ${
                reviewStats ? `${reviewStats.confirmer_approve_count}/${reviewStats.confirmer_total_count}` : ''
            }`}
            variant="outlined"
            color="secondary"
        />
    );
};

interface ApprovingProps {
    label: string;
    reviewStats?: ReviewStatsInfo;
}
const Approving = (props: ApprovingProps) => {
    const { label, reviewStats } = props;
    return (
        <StatusChip
            icon={<DoneAll fontSize="small" />}
            label={`${label} ${
                reviewStats ? `${reviewStats.approver_approve_count}/${reviewStats.approver_total_count}` : ''
            }`}
            variant="outlined"
            color="success"
        />
    );
};

const Fixed = ({ label }: { label: string }) => {
    return <StatusChip icon={<Verified fontSize="small" />} label={`${label}`} variant="outlined" color="info" />;
};

const Rejected = ({ label }: { label: string }) => {
    return <StatusChip icon={<ReplyIcon fontSize="small" />} label={`${label}`} variant="outlined" color="warning" />;
};

const Withdrawn = ({ label }: { label: string }) => {
    return <StatusChip icon={<ReplyIcon fontSize="small" />} label={`${label}`} variant="outlined" color="warning" />;
};
