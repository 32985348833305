import { I18nProvider } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import japaneseMessages from './jp';

export const i18nProvider: I18nProvider = polyglotI18nProvider((locale) => {
    // if (locale === 'jp') {
    //     return import('./i18n/jp').then(messages => messages.default);
    // }

    // Always fallback on japanese
    return japaneseMessages;
}, 'jp');
