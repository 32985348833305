import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { FieldProps, LabeledProps, useRecordContext } from 'react-admin';
import { MarkdownField } from '@react-admin/ra-markdown';
import { ViewerProps } from '@react-admin/ra-markdown/src/toast-ui';
import { Dialog, DialogContent, DialogTitle, IconButton, SxProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './IodMarkdownField.scss';

export interface IodMarkdownFieldProps
    extends Omit<ViewerProps, 'initialValue'>,
        Omit<FieldProps, 'label'>,
        Pick<LabeledProps, 'label'> {
    sx?: SxProps;
}

interface ImageModalState {
    open: boolean;
    src: string;
    alt?: string;
}

const initialModalState: ImageModalState = {
    open: false,
    src: '',
};

const IodMarkdownField: FC<IodMarkdownFieldProps> = (props) => {
    const record = useRecordContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalState, setModalState] = useState<ImageModalState>(initialModalState);

    useEffect(() => {
        // if (props.id && id !== props.id) {
        setIsLoading(true);
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1);

        return () => {
            clearTimeout(timer);
        };
        // }
    }, [record]);

    const handleModalClose = () => {
        setModalState(initialModalState);
    };

    // const record = useRecordContext<Invoice>();
    return (
        <>
            {!isLoading && (
                <>
                    <MarkdownField
                        {...props}
                        onLoad={(param) => {
                            // フィールドに含まれるimgタグをすべて取得
                            const images = param.preview.previewContent?.querySelectorAll('img');
                            // imgタグをクリックしたときにモーダルを開くようにする
                            images?.forEach((image: HTMLImageElement) => {
                                image.onclick = (e) => {
                                    e.preventDefault();
                                    setModalState({
                                        open: true,
                                        src: image.src,
                                        alt: image.alt,
                                    });
                                };
                            });
                        }}
                    />
                    <Dialog open={modalState.open} onClose={handleModalClose} maxWidth="xl">
                        <DialogTitle
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <IconButton aria-label="close" onClick={handleModalClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent
                            sx={{
                                width: '100%',
                                maxHeight: '90vh',
                                padding: 8,
                            }}
                        >
                            <img
                                src={modalState.src}
                                style={{
                                    display: 'block',
                                    maxWidth: '100%',
                                    height: 'auto',
                                    marginInline: 'auto',
                                }}
                                alt={modalState.alt}
                            />
                        </DialogContent>
                    </Dialog>
                </>
            )}
        </>
    );
};

export default IodMarkdownField;
