import React, { memo, useState } from 'react';
import { Button, CardActions, CardContent, CardHeader, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MoreVert } from '@mui/icons-material';
import { UserProfileIcon } from '../../../../components/ui/elements/UserProfileIcon';
import { formatDate } from '../../../../utils/dateUtil';
import { Comment } from '../../types/comment';
import { LineDiscussionUpdate } from '../../types/discussion/content-discussion/lineDiscussion';
import { DiscussionForm, DiscussionFormValue } from './DiscussionForm';
import {DiscussionUpdate} from '../../types/discussion/discussion';

interface Props {
    comment: Comment;
    onEditDiscussion: (discussionUpdate: LineDiscussionUpdate) => void;
    onDeleteDiscussion: () => void;
    onEditing?: (isEditing: boolean) => void; // 編集状態の変更を伝えるハンドラ
}

export const DiscussionCommentContent = memo((props: Props) => {
    const { comment,  ...restProps } = props;

    const [formValue, setFormValue] = useState<DiscussionFormValue>({
        comment: comment.body,
    });

    const handleFormChange = (formValue: DiscussionFormValue) => {
        setFormValue(formValue);
    };

    const isFormValid = () => {
        return !!formValue.comment;
    };

    const [editMode, setEditMode] = useState(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();

    const switchMode = (editMode: boolean) => {
        setEditMode(editMode);
        // 高さを検知できるまで若干のタイムラグがあるため100ms待つ
        setTimeout(() => {
            if (restProps.onEditing) {
                restProps.onEditing(editMode);
            }
        }, 100);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        // メニューの表示位置を適切に制御するためにanchorElを利用する
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handleClose();
        switchMode(true);
    };

    const handleSubmit = () => {
        const sendingData: DiscussionUpdate = {
            comment: formValue.comment,
        };
        restProps.onEditDiscussion(sendingData);
        switchMode(false);
    };

    const handleDelete = () => {
        if (window.confirm('削除してよろしいですか？（元に戻せません）')) {
            handleClose();
            restProps.onDeleteDiscussion();
        }
        handleClose();
        switchMode(false);
    };

    const when = formatDate(comment.created_at);

    return (
        <>
            <CardHeader
                // avatar={<UserProfileIcon userId={comment?.author?.id} />}
                title={
                    <>
                        <Typography
                            sx={{ display: 'inline'}}
                            component="span"
                            variant="body1"
                            color="text.primary"
                        >
                            {`【${comment.author.affiliation}】${comment.author.name}`}
                        </Typography>
                        <Typography
                            sx={{ display: 'inline', ml: 0.5, mr: 0.5}}
                            component="span"
                            variant="body1"
                            color="text.secondary"
                        >
                            は
                        </Typography>
                        <Typography
                            sx={{ display: 'inline'}}
                            component="span"
                            variant="body1"
                            color="text.primary"
                        >
                            コメント
                        </Typography>
                        <Typography
                            sx={{ display: 'inline', ml: 0.5, mr: 0.5}}
                            component="span"
                            variant="body1"
                            color="text.secondary"
                        >
                            しました
                            {' — ' + when}
                        </Typography>
                    </>

                }
                // subheader={formatDate(comment.created_at)}
                action={
                    <>
                        <IconButton aria-label="settings" onClick={handleClick}>
                            <MoreVert />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                            <MenuItem onClick={handleEdit}>編集</MenuItem>
                            <MenuItem onClick={handleDelete} sx={{ color: theme.palette.error.main }}>
                                削除
                            </MenuItem>
                        </Menu>
                    </>
                }
                sx={{
                    py: 0.5,
                    backgroundColor: '#F5F5F5'
                }}
            />
            <CardContent
                sx={{
                    // py: 1,
                }}
            >
                {editMode ? (
                    <DiscussionForm formValue={formValue} handleChange={handleFormChange} />
                ) : (
                    <>
                        <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                            {comment.body}
                        </Typography>
                    </>
                )}
            </CardContent>
            {editMode && (
                <CardActions>
                    {/*<SaveButton*/}
                    {/*    onClick={(e) => {*/}
                    {/*        // 正確に制御するにはuseUpdateを使って更新のAPIコールのレスポンスを待って制御するべきだが必要十分と判断*/}
                    {/*        setTimeout(() => {*/}
                    {/*            setEditMode(false);*/}
                    {/*        }, 500);*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <Button variant="outlined" onClick={() => setEditMode(false)}>
                        キャンセル
                    </Button>
                    {/*<CancelButton onClick={() => setEditMode(false)}/>*/}
                    <Button variant="contained" disabled={!isFormValid()} onClick={handleSubmit}>
                        保存
                    </Button>
                </CardActions>
            )}
        </>
    );
});
