import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useGetOne, useNotify, useUpdate } from 'react-admin';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    TextField,
    Tooltip,
} from '@mui/material';
import { FAVORITE_ITEM_NAME_MAX_LENGTH, FavoriteItem } from '../types/favoriteItem';

export interface FavoriteListEditDialogProps extends DialogProps {
    list_id: number;
    onSuccess?: (item: FavoriteItem) => void;
}

interface FavoriteListEditData {
    name: string;
}

const initialEditData: FavoriteListEditData = {
    name: '',
};

export const FavoriteListEditDialog: FC<FavoriteListEditDialogProps> = ({ list_id, onSuccess, ...props }) => {
    const [editData, setEditData] = useState<FavoriteListEditData>(initialEditData);
    const [unchangedData, setUnchangedData] = useState<FavoriteListEditData>(initialEditData);
    const notify = useNotify();

    const { data, isLoading, error } = useGetOne<FavoriteItem>('favorites', { id: list_id });

    const [update, { isLoading: isLoadingOnUpdate, error: errorOnUpdate }] = useUpdate<FavoriteItem>(
        undefined,
        undefined,
        {
            onError: (error: any) => {
                console.log(error);
                if (error.status === 409) {
                    notify(<Alert severity="error">既に同名のお気に入りリストが存在します</Alert>);
                } else {
                    notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>);
                }
            },
            onSuccess: (res: FavoriteItem) => {
                setEditData(initialEditData);
                onSuccess && onSuccess(res);
            },
        },
    );

    useEffect(() => {
        setEditData(initialEditData);
        setUnchangedData(initialEditData);
    }, [list_id]);

    useEffect(() => {
        if (list_id && data) {
            const editData = {
                name: data.name,
            };
            setEditData(editData);
            setUnchangedData(editData);
        }
    }, [data]);

    const validate = (): boolean => {
        return editData.name !== '';
    };
    const isChanged = (): boolean => {
        return editData.name !== unchangedData.name;
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;
        setEditData({
            ...editData,
            [name]: value,
        });
    };

    const handleSaveClick = async () => {
        const res = await update('favorites', {
            id: list_id,
            data: editData,
            previousData: data,
        });
    };

    const handleCancelClick = (e: any) => {
        props.onClose && props.onClose(e, 'backdropClick');
    };

    return (
        <Dialog {...props}>
            <DialogTitle>お気に入りリストを編集</DialogTitle>
            <DialogContent>
                <Tooltip title={`${FAVORITE_ITEM_NAME_MAX_LENGTH}文字以内で入力してください`} placement="top">
                    <TextField
                        required
                        label="リスト名"
                        name="name"
                        value={editData.name}
                        inputProps={{
                            maxLength: FAVORITE_ITEM_NAME_MAX_LENGTH,
                        }}
                        onChange={handleChange}
                        error={editData.name === ''}
                        helperText={editData.name === '' && 'このフィールドは空にできません。'}
                        sx={{
                            minWidth: 300,
                        }}
                        // 編集時かつ読み込み中はdisabled
                        disabled={isLoading || isLoadingOnUpdate}
                    />
                </Tooltip>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelClick} color="secondary" disabled={isLoading}>
                    キャンセル
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSaveClick}
                    disabled={!validate() || !isChanged() || isLoading}
                >
                    保存
                </Button>
            </DialogActions>
        </Dialog>
    );
};
