import React, { useState } from 'react';
import {
    Autocomplete,
    Dialog,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    TextField,
} from '@mui/material';
import { Identifier } from 'ra-core';
import { UserProfileIcon } from '../../../components/ui/elements/UserProfileIcon';
import { UserInfo } from '../../users/types/userInfo';
import { ReviewInfo } from '../types/reviewInfo';

// API呼び出しのシミュレーション
interface ReviewerDialogProps {
    open: boolean;
    onClose: () => void;
    availableReviewers: UserInfo[];
    selectedReviewers: ReviewInfo[];
    onAddReviewer: (user: UserInfo) => void;
    onDeleteReviewer: (userId: Identifier) => void;
}

export const ReviewerDialog: React.FC<ReviewerDialogProps> = ({
    open,
    onClose,
    availableReviewers,
    selectedReviewers,
    onAddReviewer,
    onDeleteReviewer,
}) => {
    // レビュワーを追加する非同期関数
    const selectedConfirmors = selectedReviewers.filter((review) => review.reviewer_id !== null);

    const unselectedReviewers = availableReviewers?.filter((item) => {
        return !selectedConfirmors.find((review) => review.reviewer_id === item.id);
    });
    const handleAddReviewer = (user: UserInfo) => {
        if (window.confirm('追加してもよろしいですか？')) {
            onClose();
            onAddReviewer(user); // 親コンポーネントに選択されたレビュワーを通知
        }
    };

    const handleDeleteReviewer = (userId: Identifier) => {
        if (window.confirm('削除してもよろしいですか？')) {
            onClose();
            onDeleteReviewer(userId); // 親コンポーネントに選択されたレビュワーを通知
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>追加する確認者を選択</DialogTitle>
            <DialogContent>
                <Autocomplete
                    options={unselectedReviewers}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="ユーザーを検索" />}
                    onChange={(event, newValue) => {
                        if (!newValue) {
                            return;
                        }
                        return handleAddReviewer(newValue);
                    }}
                    clearOnEscape
                />
            </DialogContent>

            <DialogTitle>削除する確認者を選択</DialogTitle>
            <DialogContent>
                <List sx={{ pt: 0, width: '100%' }}>
                    {selectedConfirmors.map((review) => (
                        <ListItem disableGutters key={review.reviewer_id}>
                            <ListItemButton onClick={() => handleDeleteReviewer(review.reviewer_id as Identifier)}>
                                <ListItemAvatar sx={{ minWidth: '32px' }}>
                                    <UserProfileIcon userId={review.reviewer_id} />
                                </ListItemAvatar>
                                <ListItemText primary={review.reviewer_name} />
                                {/*{review.reviewer_id && (*/}
                                {/*    <ListItemSecondaryAction>*/}
                                {/*            <IconButton edge="end">*/}
                                {/*                <DeleteIcon />*/}
                                {/*            </IconButton>*/}
                                {/*    </ListItemSecondaryAction>*/}
                                {/*)}*/}
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
};
