import { CompositionEvent, FC, FocusEvent, FormEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import { TextField, TextFieldProps, Tooltip } from '@mui/material';

/**
 * 文字列を正規化する
 */
const normalizeValue = (value: string) => {
    // 全角数字を半角数字に変換する
    let normalized = value.replace(/[０-９]/g, (s) => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    // 全角、を半角,に変換する
    normalized = normalized.replace(/[、，]/g, ',');
    // 全角ーを半角-に変換する
    normalized = normalized.replace(/[ー－―‐～]/g, '-');
    // 不要な文字を削除する
    normalized = normalized.replace(/[^\d,-]/g, '');

    return normalized;
};

/**
 * 数値の配列を範囲（ハイフンで連結された文字列）に変換する
 */
const convertToRanges = (sortedArr: number[]): string[] => {
    if (sortedArr.length === 0) return [];

    const ranges: string[] = [];
    let start = sortedArr[0];
    let end = sortedArr[0];

    for (let i = 1; i < sortedArr.length; i++) {
        if (sortedArr[i] === end + 1) {
            end = sortedArr[i];
        } else {
            ranges.push(start === end ? start.toString() : `${start}-${end}`);
            start = end = sortedArr[i];
        }
    }

    ranges.push(start === end ? start.toString() : `${start}-${end}`);

    return ranges;
};

/**
 * 範囲（ハイフンで連結された文字列）を数値の配列に変換する
 */
const convertRangesToNumberList = (ranges: string[]): number[] => {
    const result: number[] = [];

    for (let range of ranges) {
        if (range.includes('-')) {
            const [start, end] = range.split('-').map((num) => parseInt(num, 10));
            for (let i = start; i <= end; i++) {
                result.push(i);
            }
        } else if (range === '') {
            // 空文字列は無視する
        } else {
            result.push(parseInt(range, 10));
        }
    }

    return result;
};

/**
 * 文字列を数値の配列に変換する
 */
const parseToNumberList = (value: string) => {
    const ranges = normalizeValue(value).split(',');
    const result = convertRangesToNumberList(ranges);

    return result;
};

/**
 * 数値の配列を文字列に変換する
 */
const parseToString = (value: number[]) => {
    const sorted = value.sort((a, b) => a - b);
    const result = convertToRanges(sorted).join(',');

    return result;
};

interface NumberListInputProps extends Omit<TextFieldProps, ''> {
    source: string;
    alwaysOn?: boolean;
    title: string;
}

/**
 * 数値の配列を入力するためのテキストフィールド
 */
export const NumberListInput: FC<NumberListInputProps> = ({
    source,
    alwaysOn: _,
    title,
    onBlur,
    onInput,
    onKeyDown,
    onCompositionEnd,
    ...props
}) => {
    const { filterValues, setFilters, displayedFilters } = useListContext();
    const [value, setValue] = useState<string>(parseToString(filterValues[source] ?? []));

    const setFilter = (newValue: number[]) => {
        if (filterValues[source] !== newValue) {
            setFilters(
                {
                    ...filterValues,
                    [source]: newValue,
                },
                displayedFilters,
            );
        }
    };

    // イベントハンドラの設定
    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        setFilter(parseToNumberList(value));
        onBlur && onBlur(e);
    };

    const handleInput = (e: FormEvent<HTMLInputElement>) => {
        const value = (e.target as HTMLInputElement).value;
        if ((e.nativeEvent as InputEvent).isComposing) {
            // 全角での入力時はそのまま表示する（変換中に値が消えるのを防ぐため）
            setValue(value);
        } else {
            // 半角での入力時は正規化して表示する
            setValue(normalizeValue(value));
        }
        onInput && onInput(e);
    };

    // IMEの変換終了時に正規化する
    const handleCompositionEnd = (e: CompositionEvent<HTMLInputElement>) => {
        const value = (e.target as HTMLInputElement).value;
        const normalizedValue = normalizeValue(value);
        setValue(normalizedValue);
        onCompositionEnd && onCompositionEnd(e);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (document.activeElement instanceof HTMLInputElement) {
                // フォーカスを外す際にブラーイベントが発生するので、検索される
                document.activeElement.blur();
            }
        }
        onKeyDown && onKeyDown(e);
    };

    // クリアボタンなどでフィルターの値が変わった場合に対応
    useEffect(() => {
        const value = parseToString(filterValues[source] ?? []);
        setValue(value);
    }, [source, filterValues]);

    return (
        <Tooltip title={title}>
            <TextField
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onInput={handleInput}
                onCompositionEnd={handleCompositionEnd}
                value={value}
                {...props}
            />
        </Tooltip>
    );
};
