import { ComponentType, MouseEvent, ReactElement, isValidElement, useCallback, useMemo } from 'react';
import {
    Button,
    ComponentPropType,
    CreateButton,
    CreateButtonProps,
    Title,
    useCreatePath,
    useNotify,
    usePermissions,
    useResourceContext,
} from 'react-admin';
import {
    Tree,
    TreeContextProvider,
    TreeProps,
    TreeRecord,
    UNSAVED_NEW_NODE,
    useTreeController,
} from '@react-admin/ra-tree';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import Split from 'react-split';
import { Card, CardContent, SxProps, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { EventDataNode } from 'rc-tree/lib/interface';

// esm以下がbuild時に使えないためコメントアウトで対応 [https://trello.com/c/vcYk2fdG]
// import DefaultNodeActions, { NodeActionsProps } from "@react-admin/ra-tree/esm/src/NodeActions";

export const IodTreeWithDetails = ({
    allowMultipleRoots = false,
    className,
    create: Create,
    edit: Edit,
    hideRootNodes,
    // lazy = false, // 遅延ロード
    linkTo = 'edit',
    nodeActions,
    show: Show,
    showLine,
    sx,
    title,
    titleField = 'title',
    addRootButton = <CreateButton label="ra-tree.action.add_root" sx={{ marginTop: '.75rem' }} />,
    meta,
    ...props
}: IodTreeWithDetailsProps): ReactElement => {
    const resource = useResourceContext();
    const navigate = useNavigate();
    const createPath = useCreatePath();
    // find id in location to set it as selected
    // location can be /posts, /posts/123, or /posts/123/show, or /posts/create
    const matchCreate = useMatch(`/${resource}/create`);
    const matchEdit = useMatch(`/${resource}/:id`);
    const matchShow = useMatch(`/${resource}/:id/show`);

    const defaultSelectedKeys = useMemo(() => {
        if (matchCreate) {
            return [UNSAVED_NEW_NODE];
        }

        if (matchEdit) {
            return [matchEdit.params.id];
        }
        if (matchShow) {
            return [matchShow.params.id];
        }
    }, [matchCreate, matchEdit, matchShow]);

    const { permissions } = usePermissions();

    const controllerProps = useTreeController({
        hideRootNodes,
        lazy: true,
        resource,
        titleField,
        meta,
    });
    const notify = useNotify(); // エラー表示用

    // ツリー状態再現バグについての改修検討 [https://trello.com/c/OQ7iUww9]
    // useEffect(() => {
    //   return () => {
    //     handleExpand([]);
    //   };
    // }, [])

    const {
        defaultTitle,
        expandedKeys,
        handleExpand,
        isLoading,
        loadChildNodes,
        tree,
        // handleDrop, // ノード並び替え関連
        error,
    } = controllerProps;

    // 特定の場合のみ画面にエラー表示する
    if (error && error.status === 500) {
        notify(error.message, { type: 'error' });
    }

    const handleClick = useCallback(
        (event: MouseEvent, treeNode: EventDataNode<TreeRecord>): void => {
            navigate(createPath({ resource, id: treeNode.id, type: linkTo }));
        },
        [createPath, linkTo, navigate, resource],
    );

    const handleLoadData = useCallback(
        async (node: any): Promise<void> => {
            // console.log(node);
            return loadChildNodes(node.id);
        },
        [loadChildNodes],
    );

    if (isLoading) {
        return <></>;
    }

    const handleCollapseAllClick = () => {
        // すべて閉じる
        console.log('collapse all button clicked');
        handleExpand([]);
    };

    const canCreateNode = !tree || tree.length === 0 || allowMultipleRoots;

    return (
        <TreeContextProvider value={controllerProps}>
            <Root className={className} sx={sx}>
                <Split
                    style={{
                        width: '100%',
                        height: 'calc(100vh - 70px)',
                        display: 'flex',
                    }}
                    gutter={() => {
                        const iodTreeGutter = document.createElement('div');
                        iodTreeGutter.className = 'iod-tree-gutter';
                        return iodTreeGutter;
                    }}
                    // デフォルトのガター幅を無効にするために指定
                    gutterStyle={() => ({})}
                    direction="horizontal"
                    sizes={[40, 60]}
                    minSize={[100, 256]}
                >
                    <div className={TreeWithDetailsClasses.cardTree}>
                        <Card>
                            <CardContent className={TreeWithDetailsClasses.cardContentTree}>
                                {expandedKeys.length !== 0 && (
                                    <Button onClick={handleCollapseAllClick} label="すべて閉じる" />
                                )}

                                <Tree
                                    treeData={tree}
                                    // onDrop={handleDrop} // ノード並び替え関連
                                    onExpand={handleExpand}
                                    onClick={handleClick}
                                    expandedKeys={tree && Array.isArray(expandedKeys) ? expandedKeys : []}
                                    selectedKeys={defaultSelectedKeys}
                                    showLine={showLine}
                                    nodeActions={nodeActions}
                                    loadData={handleLoadData}
                                    // loadedKeys={undefined} // 遅延ロード関連
                                    // expandAction={false} // ノード選択時の挙動
                                    {...sanitizeRestProps(props)}
                                ></Tree>

                                {canCreateNode && addRootButton}
                                {/* 表示判定 */}
                            </CardContent>
                        </Card>
                    </div>
                    <div className={TreeWithDetailsClasses.actionCard}>
                        <Routes>
                            {Create ? (
                                <Route path="create/*" element={isValidElement(Create) ? Create : <Create />} />
                            ) : null}
                            {Show ? <Route path=":id/show/*" element={isValidElement(Show) ? Show : <Show />} /> : null}
                            {Edit ? <Route path=":id/*" element={isValidElement(Edit) ? Edit : <Edit />} /> : null}
                            {title !== false ? (
                                <Route path="*" element={<Title title={title} defaultTitle={defaultTitle} />} />
                            ) : null}
                        </Routes>
                    </div>
                </Split>
            </Root>
        </TreeContextProvider>
    );
};

export interface IodTreeWithDetailsProps extends TreeProps {
    allowMultipleRoots?: boolean;
    className?: string;
    create?: ComponentType;
    edit?: ComponentType;
    hideRootNodes?: boolean;
    // lazy?: boolean; // 遅延ロード関連
    linkTo?: string;
    // nodeActions?: ReactElement<NodeActionsProps>;
    resource?: string;
    show?: ComponentType;
    showLine?: boolean;
    sx?: SxProps;
    title?: string | ReactElement | false;
    titleField?: string;
    addRootButton?: ReactElement<CreateButtonProps> | false;
    meta?: any;
}

const sanitizeRestProps = ({
    basePath,
    history,
    location,
    match,
    options,
    hasList,
    hasEdit,
    hasShow,
    hasCreate,
    syncWithLocation,
    ...rest
}: any): any => rest;

IodTreeWithDetails.propTypes = {
    edit: ComponentPropType,
    create: ComponentPropType,
    resource: PropTypes.string,
};

const PREFIX = 'RaTreeWithDetails';
export const TreeWithDetailsClasses = {
    container: `${PREFIX}-container`,
    cardTree: `${PREFIX}-cardTree`,
    cardContentTree: `${PREFIX}-cardContentTree`,
    actionCard: `${PREFIX}-actionCard`,
};

const Root = styled('div', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})({
    display: 'flex',
    [`& .${TreeWithDetailsClasses.actionCard}`]: {
        width: '100%',
    },
    [`& .${TreeWithDetailsClasses.cardTree}`]: {
        // minWidth: '300px',
        marginTop: '1em',
        // marginRight: '1em',
    },
    [`& .${TreeWithDetailsClasses.cardContentTree}`]: {},
    [`& .${TreeWithDetailsClasses.container}`]: {
        display: 'flex',
    },
});
