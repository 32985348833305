import React, { useEffect, useState } from 'react';
import {
    Button,
    ButtonProps,
    Labeled,
    RecordContextProvider,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    maxLength,
    required,
    useGetIdentity,
    useGetOne,
    useNotify,
    useRecordContext,
    useRedirect,
    useUpdate,
} from 'react-admin';
import { Card, CardHeader, Tooltip } from '@mui/material';
//import "./styles.css"
import { IodToolbar } from '../../components/ui/layout/IodToolbar';

interface CancelButtonProps extends ButtonProps {
    onClick: () => void;
}

const CancelButton: React.FC<CancelButtonProps> = ({ onClick, ...rest }) => {
    return <Button label="キャンセル" onClick={onClick} {...rest} />;
};

interface ToolbarProps {
    handleUpdate: () => void;
    handleCancel: () => void;
    disabled: boolean;
}

const CustomToolbar = (props: ToolbarProps) => {
    return (
        <IodToolbar>
            <SaveButton disabled={props.disabled} label="保存" onClick={props.handleUpdate} />
            <CancelButton onClick={props.handleCancel} />
        </IodToolbar>
    );
};

const Settings = () => {
    const { isLoading: isLoadingId, refetch } = useGetIdentity();
    const { data, error, isLoading } = useGetOne('users', { id: 'me' });

    //console.log(`usegetone`);
    const [updatedName, setUpdatedName] = useState<string>(data?.name || '');
    const [updatedAffiliation, setUpdatedAffiliation] = useState<string>(data?.affiliation || '');
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [update] = useUpdate();
    const notify = useNotify();
    const redirect = useRedirect();
    const record = useRecordContext();
    //const { refetch: refetch } = useGetIdentity();

    useEffect(() => {
        // dataの変化を検知したら更新する

        if (isLoading || error) {
            return;
        }
        setUpdatedName(data.name);
        setUpdatedAffiliation(data.affiliation);
    }, [data]);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedName(event.target.value);
    };
    const handleAffiliationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedAffiliation(event.target.value);
    };

    if (error || isLoading || isLoadingId) {
        return null;
    }

    const handleUpdate = async () => {
        setButtonDisabled(true);
        //PUT /v1/users/meで更新する
        if (!updatedName || !updatedAffiliation) {
            // TODO 保存ボタンを押すまでバリデーションが機能しない
            notify(`入力必須項目が未入力です。`);
            return;
        }

        console.log(updatedName);
        console.log(updatedAffiliation);
        try {
            const res = await update('users', {
                id: 'me',
                data: { affiliation: updatedAffiliation, name: updatedName },
                previousData: record,
            });

            setTimeout(async () => {
                // refetchは一定時間経過後でないと古い情報を取得してしまう可能性がある
                if (refetch) {
                    await refetch();
                    await setButtonDisabled(false);
                } else {
                    console.log('refetch is not defined');
                    setButtonDisabled(false);
                }
            }, 1000);
        } catch (e) {
            notify(`Error: ${error}`);
            setButtonDisabled(false);
        }
    };

    const handleCancel = () => {
        redirect('/'); // Redirect to dashboard after cancel
    };

    return (
        <RecordContextProvider value={data}>
            <Card sx={{ mb: 2 }}>
                <CardHeader title="ユーザ情報" />

                <SimpleForm
                    toolbar={
                        <CustomToolbar
                            handleUpdate={handleUpdate}
                            handleCancel={handleCancel}
                            disabled={isButtonDisabled}
                        />
                    }
                >
                    <TextInput
                        label="氏名"
                        source="name"
                        name="name"
                        // value={updatedName}
                        isRequired={true}
                        onChange={handleNameChange}
                        validate={[required(), maxLength(64)]}
                        fullWidth
                    />
                    <Tooltip
                        title={
                            <>
                                <p>東京都例：総務局総合防災部防災計画課</p>
                                <p>関係機関例：〇〇株式会社△△部</p>
                            </>
                        }
                        placement="bottom-start"
                    >
                        {/* Mui Tooltipはchildrenに直接refを渡すことが期待されているため、それが可能なコンポーネントでラップ*/}
                        <span style={{ width: '100%' }}>
                            <TextInput
                                label="所属"
                                source="affiliation"
                                name="affiliation"
                                // value={updatedAffiliation}
                                isRequired={true}
                                onChange={handleAffiliationChange}
                                validate={[required(), maxLength(64)]}
                                fullWidth
                            />
                        </span>
                    </Tooltip>

                    <Labeled label="メールアドレス">
                        <TextField
                            name="email"
                            source="email"
                            // variant="body1"
                        />
                    </Labeled>
                </SimpleForm>
            </Card>
        </RecordContextProvider>
    );
};

export default Settings;
