import { Labeled } from 'react-admin';
import { Box, List, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import FileIcon from '@mui/icons-material/NoteOutlined';
import { ParagraphFile } from '../types/diffInfo';

interface DiffFilesProps {
    oldFiles: Array<ParagraphFile>;
    newFiles: Array<ParagraphFile>;
    labelName: string;
}

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export const DiffFiles = (props: DiffFilesProps) => {
    const { oldFiles, newFiles, labelName } = props;

    // ファイルsrcの集合を作成
    const oldSrcList = new Set(oldFiles.map((file: ParagraphFile) => file.src));
    const newSrcList = new Set(newFiles.map((file: ParagraphFile) => file.src));

    // 削除されたファイルと追加されたファイルを区別しておき、後続で装飾する
    const removedSrcList = new Set([...oldSrcList].filter((src) => !newSrcList.has(src)));
    const addedSrcList = new Set([...newSrcList].filter((src) => !oldSrcList.has(src)));

    return (
        <>
            <Labeled label={labelName} fullWidth sx={{ mb: 2 }}>
                <List sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                    {oldFiles.map((file: ParagraphFile) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FileIcon sx={{ color: removedSrcList.has(file.src) ? 'red' : 'default' }} />
                            <ListItem sx={{ p: 0.5, m: 0 }} key={file.src}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: removedSrcList.has(file.src) ? 'red' : 'default',
                                        ml: 1,
                                    }}
                                >
                                    {file.title}
                                </Typography>
                            </ListItem>
                        </Box>
                    ))}
                </List>
            </Labeled>
            <Labeled label={labelName} fullWidth sx={{ mb: 2 }}>
                <List sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                    {newFiles.map((file: ParagraphFile) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FileIcon sx={{ color: addedSrcList.has(file.src) ? 'green' : 'default' }} />
                            <ListItem sx={{ p: 0.5, m: 0 }} key={file.src}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: addedSrcList.has(file.src) ? 'green' : 'default',
                                        ml: 1,
                                    }}
                                >
                                    {file.title}
                                </Typography>
                            </ListItem>
                        </Box>
                    ))}
                </List>
            </Labeled>
        </>
    );
};
