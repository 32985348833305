import { StringMap, TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

interface IodTranslationMessages extends TranslationMessages {
    resources: StringMap | string | undefined;
    'ra-tree': StringMap | string | undefined;
    routes: StringMap | string | undefined;
}

const customJapaneseMessages: IodTranslationMessages = {
    ...englishMessages,
    ra: {
        action: {
            add_filter: '検索条件',
            add: '追加',
            back: '戻る',
            bulk_actions: '%{smart_count}件選択',
            cancel: 'キャンセル',
            clear_array_input: 'すべての項目を削除',
            clear_input_value: '空にする',
            clone: '複製',
            confirm: '確認',
            create: '作成',
            create_item: '%{item}を作成',
            delete: '削除',
            edit: '編集',
            export: 'エクスポート',
            list: '一覧',
            refresh: '更新',
            remove_filter: '検索条件を削除',
            remove_all_filters: '現在の検索条件をクリア',
            remove: '削除',
            save: '保存',
            search: '検索',
            select_all: 'すべて選択',
            select_row: 'この行を選択',
            show: '詳細',
            sort: '並び替え',
            undo: '元に戻す',
            unselect: '選択解除',
            expand: '開く',
            close: '閉じる',
            open_menu: '開く',
            close_menu: '閉じる',
            update: '更新',
            move_up: '上へ移動',
            move_down: '下へ移動',
            open: '開く',
            toggle_theme: 'ダークモード切替',
            select_columns: '列を編集',
            update_application: 'アプリケーションを更新',
        },
        boolean: {
            true: 'はい',
            false: 'いいえ',
            null: '未選択',
        },
        page: {
            create: '%{name}を作成',
            dashboard: 'ダッシュボード',
            edit: '%{name} #%{id}',
            error: '問題が発生しました',
            list: '%{name}',
            loading: '読み込み中',
            not_found: '見つかりませんでした',
            show: '%{name} #%{id}',
            empty: '%{name}はありません',
            invite: '作成しますか？',
        },
        input: {
            file: {
                upload_several: 'アップロードするファイルをドロップ、または選択してください',
                upload_single: 'アップロードするファイルをドロップ、または選択してください',
            },
            image: {
                upload_several: 'アップロードする画像をドロップ、または選択してください',
                upload_single: 'アップロードする画像をドロップ、または選択してください',
            },
            references: {
                all_missing: 'データは利用できなくなりました',
                many_missing: '選択したデータは利用できなくなりました',
                single_missing: '選択したデータは利用できなくなりました',
            },
            password: {
                toggle_visible: '非表示',
                toggle_hidden: '表示',
            },
        },
        message: {
            about: '詳細',
            are_you_sure: '本当によろしいでしょうか？',
            auth_error: '認証エラーです',
            bulk_delete_content: '%{name}を削除しますか？ |||| 選択した %{smart_count}件のアイテムを削除しますか？',
            bulk_delete_title: '%{name}を削除 |||| %{name} %{smart_count}件を削除',
            bulk_update_content: '%{name}を更新しますか？ |||| 選択した %{smart_count}件のアイテムを更新しますか？',
            bulk_update_title: '%{name}を更新 |||| %{name} %{smart_count}件を更新',
            clear_array_input: '全ての項目を消去しますか？',
            delete_content: '削除しますか？',
            delete_title: '%{name} #%{id} を削除',
            details: '詳細',
            error: 'クライアントエラーが発生し、処理を完了できませんでした',
            invalid_form: '入力値に誤りがあります。エラーメッセージを確認してください',
            loading: '読み込み中です。しばらくお待ちください',
            no: 'いいえ',
            not_found: '間違ったURLを入力したか、古いリンクを開いた可能性があります',
            yes: 'はい',
            unsaved_changes: '行った変更が保存されていません。このページから移動しますか？',
        },
        navigation: {
            no_results: '結果が見つかりませんでした',
            no_more_results: '%{page}ページは最大のページ数を超えています。前のページに戻ってください',
            page_out_of_boundaries: '%{page}ページは最大のページ数を超えています',
            page_out_from_end: '最大のページより後に移動できません',
            page_out_from_begin: '1ページより前に移動できません',
            page_range_info: '%{offsetBegin} - %{offsetEnd} / %{total}',
            partial_page_range_info: '%{offsetBegin} - %{offsetEnd}',
            current_page: '%{page}ページ目',
            page: '%{page}ページに移動',
            first: '最初のページに移動',
            last: '最後のページに移動',
            next: '次のページに移動',
            previous: '前のページに移動',
            page_rows_per_page: '表示件数:',
            skip_nav: 'コンテンツにスキップ',
        },
        auth: {
            auth_check_error: '認証に失敗しました。再度ログインしてください',
            user_menu: 'プロフィール',
            username: 'ユーザー名',
            password: 'パスワード',
            sign_in: 'ログイン',
            sign_in_error: '認証に失敗しました。入力を確認してください',
            logout: 'ログアウト',
        },
        sort: {
            sort_by: '%{field}を%{order}で並び替え中',
            ASC: '昇順',
            DESC: '降順',
        },
        notification: {
            updated: '更新しました |||| %{smart_count}件更新しました',
            created: '作成しました',
            deleted: '削除しました |||| %{smart_count}件削除しました',
            bad_item: 'データが不正です',
            item_doesnt_exist: 'データが存在しませんでした',
            http_error: '通信エラーが発生しました',
            data_provider_error: 'dataProviderエラー。詳細はコンソールを確認してください',
            i18n_error: '翻訳ファイルが読み込めませんでした',
            canceled: '元に戻しました',
            logged_out: '認証に失敗しました。再度ログインしてください',
            not_authorized: 'このページにアクセスする権限がありません',
            application_update_available: 'アプリケーションの更新が利用可能です',
        },
        validation: {
            required: '必須',
            minLength: '%{min}文字以上である必要があります',
            maxLength: '%{max}文字以下である必要があります',
            minValue: '%{min}以上である必要があります',
            maxValue: '%{max}以下である必要があります',
            number: '数字である必要があります',
            email: 'メールアドレスである必要があります',
            oneOf: '次のいずれかである必要があります: %{options}',
            regex: '次の正規表現形式にする必要があります: %{pattern}',
        },
        saved_queries: {
            label: '保存した検索条件',
            query_name: '検索条件名',
            new_label: '現在の検索条件を保存',
            new_dialog_title: '検索条件を保存',
            remove_label: '検索条件を削除',
            remove_label_with_name: '保存済みの検索条件 %{name} を削除',
            remove_dialog_title: '検索条件を削除',
            remove_message: '選択した保存検索条件を削除しますか？',
            help: '検索条件を保存して、あとから同じ条件で検索できます',
        },
        configurable: {
            customize: 'カスタマイズ',
            configureMode: 'このページをカスタマイズする',
            inspector: {
                title: 'カスタマイズ',
                content: 'UI要素にマウスオーバーするとカスタマイズできます',
                reset: '設定をリセット',
                hideAll: 'すべて非表示',
                showAll: 'すべて表示',
            },
            Datagrid: {
                title: 'データグリッド',
                unlabeled: '列名未設定 #%{column}',
            },
            SimpleForm: {
                title: 'フォーム',
                unlabeled: '項目名未設定 #%{input}',
            },
            SimpleList: {
                title: 'リスト',
                primaryText: '1行目',
                secondaryText: '2行目',
                tertiaryText: '3行目',
            },
        },
    },

    'ra-tree': {
        action: {
            add_root: 'ルート要素追加',
            add_child: '子要素追加',
        },
        new_node: 'new...',
        item_moved: '要素を移動しました',
    },

    resources: {
        organizations: {
            name: '組織 |||| 組織一覧',
            fields: {
                name: '名称',
                name_kana: '組織名（カナ）',
                position: '表示順（0始まり）',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: '今日',
                this_week: '今週',
                last_week: '先週',
                this_month: '今月',
                last_month: '先月',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                address: 'Adresse',
                stats: 'Statistiques',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                edit: '編集',
                delete: '削除',
            },
            errors: {
                password_mismatch: 'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        paragraphs: {
            name: '検索（現行版）',
            detail: 'パラグラフ詳細',
            fields: {
                document_id: 'ドキュメント',
                sequence: 'No.',
                headings: '標題',
                body: '本文',
                phase: 'フェーズ',
                responsible_organizations: '主管組織',
                co_responsible_organizations: '共管組織',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: '今日',
                this_week: '今週',
                last_week: '先週',
                this_month: '今月',
                last_month: '先月',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                address: 'Adresse',
                stats: 'Statistiques',
                history: 'Historique',
                password: 'パスワード',
                change_password: 'パスワードを変更',
            },
            page: {
                edit: '編集',
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch: 'La confirmation du mot de passe est différent du mot de passe.',
            },
            action: {
                export: '検索結果エクスポート（CSV）',
            },
            custom: {
                success_to_file_upload: 'ファイルのアップロードに成功しました。',
                failed_to_file_upload: 'ファイルのアップロードに失敗しました。',
                failed_to_file_download: 'ファイルのダウンロードに失敗しました。',
            },
        },
        users: {
            name: 'ユーザ |||| ユーザ一覧',
            fields: {
                name: '名前',
                email: 'メール',
                phone: '電話',
                website: 'ウェブサイト',
                company: {
                    name: '会社名',
                },
                // address: 'Rue',
                // birthday: 'Anniversaire',
                // city: 'Ville',
                // stateAbbr: 'Etat',
                // commands: 'Commandes',
                // first_name: 'Prénom',
                // first_seen: 'Première visite',
                // groups: 'Segments',
                // has_newsletter: 'Abonné à la newsletter',
                // has_ordered: 'A commandé',
                // last_name: 'Nom',
                // last_seen: 'Vu le',
                // last_seen_gte: 'Vu depuis',
                // latest_purchase: 'Dernier achat',
                // total_spent: 'Dépenses',
                // zipcode: 'Code postal',
                // password: 'Mot de passe',
                // confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: '今週',
                last_week: '先週',
                this_month: '今月',
                last_month: '先月',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                address: 'Adresse',
                stats: 'Statistiques',
                history: '履歴',
                password: 'パスワード',
                change_password: 'パスワードを変更',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch: 'パスワードが違います',
            },
        },
        documents: {
            name: 'ドキュメント |||| 目次（現行版）',
        },
        'edit/paragraphs': {
            name: '検索（最新修正案）',
            detail: 'パラグラフ詳細',
            fields: {
                document_id: 'ドキュメント',
                sequence: 'No.',
                headings: '標題',
                body: '本文',
                phase: 'フェーズ',
                responsible_organizations: '主管組織',
                co_responsible_organizations: '共管組織',
            },
        },
        edits: {
            fields: {
                document_title: 'ドキュメント',
                heading_path: '標題',
                body: '本文',
                updated_by: '更新者',
                last_updated_by: '最終更新者',
                updated_at: '最終更新日時',
                user_last_modified_at: 'あなたの最終更新日時',
                request_title: '依頼タイトル',
            },
        },
        merge_requests: {
            fields: {
                id: '依頼ID',
                title: '依頼タイトル',
                updated_by: '更新者',
                created_by: '依頼者',
                created_at: '依頼日時',
                merge_status: 'ステータス',
                content: {
                    document_title: 'ドキュメント',
                    heading_path: '標題',
                    body: '本文',
                },
            },
            activities: {
                types: {
                    content_create: 'コンテンツを作成',
                    content_update: 'コンテンツを編集',
                    content_delete: 'コンテンツを削除',
                    merge_request_create: '依頼を作成',
                    merge_request_update: '依頼を変更',
                    merge_request_confirm: '確認',
                    merge_request_primary_approve: '一次承認',
                    merge_request_secondary_approve: '二次承認',
                    snapshot_fix: '版確定',
                    merge_request_reject: '差戻',
                    merge_request_withdraw: '引戻',
                    merge_request_reopen: '再依頼',
                    merge_request_discussion_create: 'ディスカッションを作成',
                    reviewer_add: '確認者を追加',
                    reviewer_remove: '確認者を削除',
                    reviewer_reassign: '再確認依頼',
                    commit_discard: '編集履歴を取消',
                },
            },
            statusesName: {
                editing: '編集中',
                confirming: '確認',
                approving: '承認',
                fixed: '確定済',
                rejected: '差戻',
                withdrawn: '引戻',
            },
        },
        'admin/documents': {
            name: 'ドキュメント一覧',
            fields: {
                id: 'ドキュメントID',
                title: 'タイトル',
                updated_by: '更新者',
                created_by: '作成者',
                created_at: '作成日時',
                updated_at: '更新日時',
                edit_mode: '編集モード',
                // content: {
                //     title: 'ドキュメント',
                // },
            },
        },
    },
    routes: {
        documents: {
            snapshots: {
                name: '版',
                titles: {
                    list: '版一覧',
                    show: '版詳細',
                    edit: '版編集',
                    create: '版作成',
                },
                fields: {
                    id: '版ID',
                    name: 'タイトル',
                    description: '説明',
                    is_public: '素案 / 確定版',
                    updated_by: '更新者',
                    created_by: '作成者',
                    created_at: '作成日時',
                    updated_at: '更新日時',
                    export: '版エクスポート（PDF）',
                    // content: {
                    //     title: 'ドキュメント',
                    // },
                },
            },
        },
    },
};

export default customJapaneseMessages;
