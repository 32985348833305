import * as React from 'react';
import { FC } from 'react';
import { useListContext, useRecordContext } from 'react-admin';
import { Chip, List, ListItem } from '@mui/material';
import { Organization } from '../../organizations/types/organization';
import { OrganizationComponentType } from '../../organizations/types/organizationComponentType';
import { Paragraph } from '../types/paragraph';

type Props = {
    type: OrganizationComponentType;
};

export const OrganizationsSearchResultsField: FC<Props> = (props) => {
    const record = useRecordContext<Paragraph>();
    const { filterValues } = useListContext(); // フィルタの状態を取得

    if (!record) {
        return null;
    }

    const organizations =
        props.type === 'responsible_organization'
            ? record.responsible_organizations
            : record.co_responsible_organizations;
    if (!organizations) {
        return null; // 組織が存在しない場合は何も表示しない
    }

    const maxToShow = 3; // 最大件数を定義
    const etcText = '...'; // 最大件数を超えた場合に省略を表すテキスト

    // 最大件数の超過判定用
    const etcShow = organizations.length > maxToShow;

    // フィルタ指定されている組織を取得する
    const filterOrgId = filterValues.responsible_organization_id;
    const filterCoOrgId = filterValues.co_responsible_organization_id;
    const filterId = props.type === 'responsible_organization' ? filterOrgId : filterCoOrgId;

    // フィルタ指定されている組織は先頭ソートする
    const sortedOrganizations = [...organizations];
    const matchedIndex = sortedOrganizations.findIndex((org) => org.id === filterId);
    if (matchedIndex !== -1) {
        const matchedOrg = sortedOrganizations.splice(matchedIndex, 1); // 先頭ソート対象組織を切り出す
        sortedOrganizations.unshift(matchedOrg[0]); // 先頭に対象組織を追加する
    }

    // 表示する組織を削って最大件数までに調整する
    const orgsToShow = sortedOrganizations.slice(0, maxToShow);

    return (
        <List>
            {orgsToShow.map((organization) => (
                <OrganizationItem
                    key={organization.id}
                    organization={organization}
                    variant={props.type === 'responsible_organization' ? 'filled' : 'outlined'}
                    color={organization.id === filterId ? 'success' : 'default'}
                />
            ))}
            {etcShow && <span>{etcText}</span>}
        </List>
    );
};

interface OrganizationItemProps {
    organization: Organization;
    variant: 'filled' | 'outlined';
    color: 'success' | 'default';
}

const OrganizationItem: FC<OrganizationItemProps> = React.memo(({ organization, variant, color }) => {
    return (
        <ListItem sx={{ p: 0.25, m: 0 }}>
            <Chip
                key={organization.id}
                label={organization.name}
                clickable={false}
                variant={variant} // チップのデザイン指定
                color={color} // 先頭ソートした組織のみ色を変更
            />
        </ListItem>
    );
});
