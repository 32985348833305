import React, { FC } from 'react';
import { RecordContextProvider, useRecordContext } from 'react-admin';
import { MarkdownField } from '@react-admin/ra-markdown';
import { List, ListItem } from '@mui/material';
import { Heading, Paragraph } from '../types/paragraph';

interface HeadingItemProps {
    heading: Heading;
}

// itemに依存してメモ化、内部でcontextやstateを使っていないのがポイント
const HeadingItem: FC<HeadingItemProps> = React.memo(({ heading }) => (
    <RecordContextProvider value={heading} key={heading.content_node_id}>
        <ListItem alignItems="flex-start" disablePadding>
            <MarkdownField
                className="heading-field-item"
                sx={{
                    '&.heading-field-item p': {
                        margin: '0',
                        lineHeight: '1',
                    },
                }}
                source="body"
            />
        </ListItem>
    </RecordContextProvider>
));

// 呼び出し側
export const InnerHeadingsField: FC = () => {
    const record = useRecordContext<Paragraph>();
    return (
        <List
            component="nav"
            aria-label="list-item headings"
            sx={{
                //標題は見切れないようにmaxHeightとoverflowを削除
                // maxHeight: "80px",
                // overflow: "hidden",
                padding: '0',
            }}
        >
            {record.headings.map((heading) => (
                <HeadingItem heading={heading} key={heading.content_node_id} />
            ))}
        </List>
    );
};
