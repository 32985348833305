import React, { useEffect, useState } from 'react';
import { RecordContextProvider, useDataProvider, usePermissions } from 'react-admin';
import { FormInformation } from '../types/information';
import { InformationEditForm } from './InformationEditForm';
import { InformationShowForm } from './InformationShowForm';

export const InformationComponent = () => {
    const { isLoading: isLoadingPermissions, permissions } = usePermissions();
    const [information, setInformation] = useState<FormInformation>({
        body: '',
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    // const [error, setError] = useState();
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider
            .getOne('information', { id: 'latest' })
            .then(({ data }) => {
                // console.log(data);
                setInformation({ body: data.body });
                setIsLoading(false);
            })
            .catch((error) => {
                // お知らせデータが存在しないときもここにくる
                console.log(error);
                // setError(error);
                setIsLoading(false);

                // 特定の場合のみ画面にエラー表示する
                if (error.status === 500) {
                    return Promise.reject(error);
                }
            });
    }, [dataProvider, isEditMode]);

    if (isLoadingPermissions || isLoading) {
        return null;
        // return <CircularProgress/>;
    }

    // 権限がある場合のみ各ボタンを有効化する
    const hasSavePolicy = permissions.information.includes('update');

    return (
        <RecordContextProvider value={information}>
            {hasSavePolicy && isEditMode ? (
                <InformationEditForm
                    onSave={() => {
                        setIsEditMode(false);
                    }}
                    onCancel={() => {
                        setIsEditMode(false);
                    }}
                />
            ) : (
                <InformationShowForm
                    onClickEdit={() => {
                        setIsEditMode(true);
                    }}
                />
            )}
        </RecordContextProvider>
    );
};
