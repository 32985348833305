import React, { FC, useCallback, useEffect, useState } from 'react';
import { useCreate, useDelete, useNotify, useUpdate } from 'react-admin';
import { Alert } from '@mui/material';
import { Identifier } from 'ra-core';
import { httpClient } from '../../../components/functional/dataProvider';
import { API_URL } from '../../../config';
import { CommentAdd, CommentUpdate } from '../types/comment';
import { DiffInfo } from '../types/diffInfo';
import {
    LineDiscussion,
    LineDiscussionAdd,
    LineDiscussionUpdate,
} from '../types/discussion/content-discussion/lineDiscussion';
import { LineComment } from './DiffViewer';
import { IodDiffViewer } from './IodDiffViewer';

type TabStateType = 'viewer' | 'preview';

export interface IodDiffContainerProps {
    diff: DiffInfo;
    showHistory?: boolean;
    showMetadataDiff?: boolean;
    // onComment?: (commentdata: CommentData) => void;
    // onReply?: (commentdata: CommentData) => void;
    // onDelete?: (commentdata: CommentData) => void;
    canComment?: boolean;
}

/**
 * システムで共通的に利用している差分表示コンポーネント。
 */
export const IodDiffContainer: FC<IodDiffContainerProps> = ({
    diff,
    showHistory,
    showMetadataDiff,
    // onComment,
    // onReply,
    // onDelete,
    canComment,
    ...props
}) => {
    const newContentPath = diff.new_path || diff.old_path || '';
    const newCommitId = diff.new_commit_id;
    const document_id = diff.document_id;
    const originalContentId = diff.original_content_id;

    const [discussions, setDiscussions] = useState<LineDiscussion[]>([]);

    const fetchDiscussions = useCallback(() => {
        const apiPath = `documents/${document_id}/${encodeURIComponent(
            newContentPath,
        )}/commits/${newCommitId}/discussions`;

        // フィルターで使用されている組織idがundefinedでない場合、組織名データをapiから取得
        httpClient(`${API_URL}/${apiPath}`).then(({ json }): void => {
            setDiscussions(json);
        });
    }, [document_id, newContentPath, newCommitId, setDiscussions]);

    useEffect(() => {
        fetchDiscussions();
    }, [document_id, newContentPath, newCommitId, fetchDiscussions]);

    const [create, { isLoading }] = useCreate<LineDiscussionAdd | any>();
    const [update] = useUpdate();
    const [deleteOne, { data, isLoading: isLoadingDelete, error }] = useDelete();
    const notify = useNotify();

    const onComment = useCallback(
        (lineComment: LineComment) => {
            const apiPath = `documents/${document_id}/${encodeURIComponent(
                newContentPath,
            )}/commits/${newCommitId}/discussions`;
            create(
                apiPath,
                {
                    data: {
                        original_content_id: originalContentId,
                        line_no: lineComment.lineNo,
                        classification: lineComment.classification,
                        reason: lineComment.reason,
                        comment: lineComment.comment,
                    },
                },
                {
                    onError: (error: any) => {
                        if (error.body?.code === 'RESOURCE_CONFLICT') {
                            notify(<Alert severity="error">この行には既に修正理由等があります。</Alert>);
                        } else {
                            // 想定外のエラー
                            notify(<Alert severity="error">修正理由等の作成に失敗しました。</Alert>);
                        }
                        // return error;
                    },
                    onSuccess: (res: LineDiscussion) => {
                        notify(<Alert severity="info">{res.line_no}行目に修正理由等を追加しました。</Alert>);
                        fetchDiscussions();
                    },
                },
            );
        },
        [document_id, newContentPath, newCommitId, create, originalContentId, notify, fetchDiscussions],
    );

    const onReply = useCallback(
        (commentAdd: CommentAdd) => {
            // const apiPath = `discussions/${commentAdd.discussion_id}/comments`;
            const apiPath = `documents/${document_id}/${encodeURIComponent(
                newContentPath,
            )}/commits/${newCommitId}/discussions/${commentAdd.discussion_id}/comments`;
            create(
                apiPath,
                {
                    data: {
                        body: commentAdd.body,
                    },
                },
                {
                    onError: (error: any) => {
                        notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>);
                        return error;
                    },
                    onSuccess: (res: any) => {
                        notify(<Alert severity="info">返信しました。</Alert>);
                        fetchDiscussions();
                    },
                },
            );
        },
        [document_id, newContentPath, newCommitId, originalContentId, fetchDiscussions],
    );

    const onEditDiscussion = (discussionId: Identifier, discussionUpdate: LineDiscussionUpdate) => {
        const apiPath = `documents/${document_id}/${encodeURIComponent(
            newContentPath,
        )}/commits/${newCommitId}/discussions`;
        // 編集処理
        update(
            apiPath,
            {
                id: discussionId,
                data: discussionUpdate,
            },
            {
                onSuccess: () => {
                    notify(<Alert severity="info">更新しました</Alert>);
                    fetchDiscussions();
                },
                onError: (error: any) => {
                    if (error && error.status === 403) {
                        // notify(error.message, { type: 'error' });
                        notify(<Alert severity="error">本人以外は更新できません</Alert>);
                    } else {
                        notify(<Alert severity="error">更新に失敗しました</Alert>);
                    }
                    // notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>)
                },
            },
        );
    };

    const onDeleteDiscussion = (discussionId: Identifier) => {
        deleteOne(
            'discussions',
            {
                id: discussionId,
            },
            {
                onSuccess: () => {
                    notify(<Alert severity="info">削除しました</Alert>);
                    fetchDiscussions();
                },
                onError: (error: any) => {
                    if (error && error.status === 403) {
                        notify(<Alert severity="error">本人以外やリプライがある場合は削除できません</Alert>);
                    } else {
                        notify(<Alert severity="error">削除に失敗しました</Alert>);
                    }
                    // notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>)
                },
            },
        );
    };

    const onEditReply = (commendId: Identifier, commentUpdate: CommentUpdate) => {
        update(
            'comments',
            {
                id: commendId,
                data: commentUpdate,
            },
            {
                onSuccess: () => {
                    notify(<Alert severity="info">更新しました</Alert>);
                    fetchDiscussions();
                },
                onError: (error: any) => {
                    if (error && error.status === 403) {
                        notify(<Alert severity="error">本人以外は更新できません</Alert>);
                    } else {
                        notify(<Alert severity="error">更新に失敗しました</Alert>);
                    }
                    // notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>)
                },
            },
        );
    };

    const onDeleteReply = (commentId: Identifier) => {
        deleteOne(
            'comments',
            {
                id: commentId,
            },
            {
                onSuccess: () => {
                    notify(<Alert severity="info">削除しました</Alert>);
                    fetchDiscussions();
                },
                onError: (error: any) => {
                    if (error && error.status === 403) {
                        notify(<Alert severity="error">本人以外は削除できません</Alert>);
                    } else {
                        notify(<Alert severity="error">削除に失敗しました</Alert>);
                    }
                    // notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>)
                },
            },
        );
    };

    return (
        <IodDiffViewer
            newContent={diff.new_body ?? ''}
            oldContent={diff.old_body ?? ''}
            newPath={diff.new_path ?? ''}
            oldPath={diff.old_path ?? ''}
            newMetadata={diff.new_metadata}
            oldMetadata={diff.old_metadata}
            oldUpdatedAt={diff.old_updated_at}
            newUpdatedAt={diff.new_updated_at}
            oldSnapshotName={diff.old_snapshot_name}
            newSnapshotName={diff.new_snapshot_name}
            document_id={document_id}
            onComment={onComment}
            canComment={canComment}
            discussions={discussions}
            showHistory={showHistory}
            showMetadataDiff={showMetadataDiff}
            tools={[]}
            // tools={[
            //     showHistory && (
            //         <Button
            //             onClick={handleHistoryDialogOpen(
            //                 true,
            //                 diff.document_id,
            //                 diff.new_path,
            //             )}
            //             sx={{ columnGap: 0.5, pl: 1.25 }}
            //         >
            //             <HistoryIcon />
            //             編集履歴を表示
            //         </Button>
            //     ),
            // ]}
            onReply={onReply}
            onEditReply={onEditReply}
            onDeleteReply={onDeleteReply}
            onEditDiscussion={onEditDiscussion}
            onDeleteDiscussion={onDeleteDiscussion}
        />
    );
};
