export interface FavoriteItem {
    id: number;
    name: string;
    created_at: Date;
    updated_at: Date;
    content_ids: number[];
}

export const FAVORITE_ITEM_NAME_MAX_LENGTH: number = 16;
export const FAVORITE_ITEM_LIMIT: number = 10;
