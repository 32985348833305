import { Exporter, downloadCSV } from 'react-admin';
import { ContentPhaseType } from '../../contents/types/contentPhaseType';
import { Organization } from '../../organizations/types/organization';
import { Paragraph } from '../types/paragraph';
import IodDownloadCSV from './IodDownloadCSV';
import { generateNowJstDate } from '../../../utils/dateUtil';

export const paragraphCsvExporter: Exporter = (data: Paragraph[]) => {
    // 出力したい項目をヘッダーに定義する
    const csvHeaders = [
        // 'id',
        '標題',
        '本文',
        'フェーズ',
        '主管組織',
        '共管組織',
    ].join(',');

    // データをCSV形式の文字列に変換する
    const csvData = data.map((record: Paragraph) => {
        // 標題を整形
        const headings = record.headings ? record.headings.map((heading: any) => heading.body).join('/') : '';
        // 本文を整形
        const bodyPlainText = record.plain_body
            .replace(/<[^>]*>/g, '') // HTMLタグを削除
            .replace(/\n/g, ''); // 改行コード削除
        // .replace(/\\"/g, '""');  // エスケープ「"」#対応予定: trello.com/c/2EloZ5lE
        // フェーズを整形
        const phase = phaseToText(record.phase);
        // 組織を整形
        const responsibleOrgs = record.responsible_organizations
            ? record.responsible_organizations.map((org: Organization) => org.name).join('、')
            : '';
        const responsibleOCoOrgs = record.co_responsible_organizations
            ? record.co_responsible_organizations.map((org: Organization) => org.name).join('、')
            : '';

        return [
            // record.id,
            headings,
            bodyPlainText,
            phase,
            responsibleOrgs,
            responsibleOCoOrgs,
        ]
            .map((value) => (typeof value === 'string' ? JSON.stringify(value) : value))
            .join(',');
    });
    const csvString = `${csvHeaders}\n${csvData.join('\n')}`;

    // ファイル名を定義する
    const fileName = `paragraph_search_list_${generateNowJstDate()}`;

    // CSVファイルをダウンロード
    IodDownloadCSV(csvString, fileName);
};

const phaseToText = (phase: ContentPhaseType | undefined): string => {
    switch (phase) {
        case 'yobou':
            return '予防';
        case 'oukyu':
            return '応急';
        case 'fukkyu':
            return '復旧';
        default:
            return '';
    }
};