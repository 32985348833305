import { FC } from 'react';
import {SelectArrayInput, SelectArrayInputProps} from 'react-admin';
import { MergeRequestStatus } from '../types/mergeRequestStatus';


type Props = {} & Omit<SelectArrayInputProps, 'choices'>;
const StatusSelectInput: FC<Props> = (props) => {
    const statuses: Array<{ id: MergeRequestStatus; name: string }> = [
        { id: 'requested', name: '確認中' },
        { id: 'primary_approved', name: '防災部担当者承認済' },
        { id: 'secondary_approved', name: '防災部管理職承認済' },
        { id: 'fixed', name: '確定済' },
        { id: 'rejected', name: '差戻' },
        { id: 'withdrawn', name: '引戻' },
    ];

    return (
        <SelectArrayInput
            //{...props}
            // 以下上手く機能しないのでパスで指定でよさそう
            // defaultValue={['requested', 'primary_approved', 'secondary_approved']}
            choices={statuses}
            {...props}
        />
    );
};

export default StatusSelectInput;
