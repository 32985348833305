import * as React from 'react';
import {
    BooleanInput,
    Datagrid,
    DateField,
    TextField,
    Title,
    useGetIdentity,
    useListContext,
    useListController,
    usePermissions,
} from 'react-admin';
import { CircularProgress } from '@mui/material';
import { UserField } from '../../../../../components/ui/fields/UserField';
import TooltipTextInput from '../../../../../components/ui/inputs/TooltipTextInput';
import { IodList } from '../../../../../components/ui/layout/IodList';
import { FilterClearButton } from '../../../../paragraphs/components/FilterClearButton';
import { ComparisonTableDownloadButton } from '../../../components/ComparisonTableDownloadButton';
import { EditInfo } from '../../../types/editInfo';
import { MergeRequestInfo } from '../../../types/mergeRequest';
import { MergeStatusField } from './MergeStatusField';
import StatusSelectInput from './StatusSelectInput';

const CustomDownloadButton = () => {
    // チェックされているID
    const { data: mergeRequests, isLoading, selectedIds } = useListContext<MergeRequestInfo>();
    if (isLoading || !mergeRequests || mergeRequests.length === 0 || !selectedIds || selectedIds.length === 0) {
        return null;
    }

    const selectedRequests = mergeRequests.filter((mergeRequest) => {
        return selectedIds.includes(mergeRequest.id);
    });

    const selectedOriginalContentIds = selectedRequests.map((selectedRequest) => {
        return selectedRequest.content?.original_content_id || 0;
    });

    return <ComparisonTableDownloadButton originalContentIds={selectedOriginalContentIds} />;
};

export const MergeRequestList = () => {
    // 権限の取得
    const { isLoading: isLoadingPermissions, permissions } = usePermissions();
    const { filterValues, isLoading } = useListController();
    const { isLoading: isLoadingId, data: userData } = useGetIdentity();
    // const unselectAll = useUnselectAll('merge_requests');

    if (isLoading || isLoadingPermissions || isLoadingId) {
        return <CircularProgress />;
    }

    const filters = [
        <TooltipTextInput
            title="依頼者の所属名、依頼者名、依頼タイトル、補足事項で検索ができます"
            source="q" // filtersのreactElementにはsourceの指定は必須（ループ時のkeyに使われているっぽい）
            label="検索"
            alwaysOn
        />,
        <StatusSelectInput label="ステータス" source="statuses" sx={{ minWidth: '130px' }} alwaysOn />,
    ];

    let titleSuffix = null;
    if (filterValues.editor === userData?.id) {
        titleSuffix = '（自分が編集に関わった依頼）';
        filters.push(<FilterClearButton source="clear" defaultFilters={{ editor: filterValues.editor }} alwaysOn />);
    } else if (filterValues.reviewer === userData?.id) {
        titleSuffix = '（自分宛の依頼）';
        filters.push(
            <BooleanInput
                sx={{ mb: 2 }}
                label="確認が必要な依頼に絞る"
                name="review_required"
                source="review_required"
                alwaysOn
            />,
        );
        filters.push(
            <FilterClearButton source="clear" defaultFilters={{ reviewer: filterValues.reviewer }} alwaysOn />,
        );
    } else {
        filters.push(<FilterClearButton source="clear" alwaysOn />);
    }

    // // 権限がある場合のみ各ボタンを有効化する
    // TODO 参照先がpermissions.documentsではなくなるかも
    const hasCreatePolicy = permissions.paragraphs.includes('create');
    const hasEditPolicy = permissions.paragraphs.includes('update');

    return (
        <IodList
            exporter={false} // 一覧そのものを出力
            filters={filters}
            sort={{ field: 'id', order: 'DESC' }} // デフォルトソート順
            // actions={hasEditPolicy ? <CustomDownloadButton /> : false} // 出力ボタン
        >
            {titleSuffix && <Title title={titleSuffix} />}

            <Datagrid
                // まとめて承認はいったん塞ぐ
                bulkActionButtons={<CustomDownloadButton />}
                // bulkActionButtons={false}
                isRowSelectable={(record: MergeRequestInfo) => {
                    return record.review_stats.status !== 'fixed';
                }} // 確定済みの場合はチェック不可
                rowClick="edit"
                sx={{
                    '.id': {
                        width: '6%',
                        minWidth: 70,
                    },
                    '.created_by': {
                        width: '15%',
                    },
                    '.title': {
                        width: '15%',
                    },
                    '.merge_status': {
                        width: '8%',
                    },
                    '.created_at': {
                        width: '10%',
                    },
                    '.document_title': {
                        width: '12%',
                    },
                    '.heading_path': {
                        width: '34%',
                    },
                    // '.body': {
                    //     width: '22%',
                    // },
                    '& .MuiTableCell-paddingCheckbox': {
                        pointerEvents: 'none',
                        '& .RaDatagrid-checkbox': {
                            pointerEvents: 'auto',
                        },
                        '& .select-all': {
                            pointerEvents: 'auto',
                        },
                    },
                }}
            >
                <TextField source="id" headerClassName="id" sortable />
                <UserField headerClassName="created_by" source="created_by" sortable={false} />
                <TextField source="title" headerClassName="title" sortable={false} />
                <DateField source="created_at" headerClassName="created_at" sortable showTime={true} />
                <MergeStatusField source="merge_status" headerClassName="merge_status" sortable={false} />
                <TextField source="content.document_title" headerClassName="document_title" sortable={false} />
                <TextField source="content.heading_path" headerClassName="heading_path" sortable={false} />
            </Datagrid>
        </IodList>
    );
};
