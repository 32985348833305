import React, { FC } from 'react';
import { Box, BoxProps, List, ListItem } from '@mui/material';
import { DiffInfo } from '../types/diffInfo';
import { IodDiffContainer } from './IodDiffContainer';



export interface IodMultiDiffContainerProps extends Omit<BoxProps, 'children'> {
    diffList: DiffInfo[];
    showHistory?: boolean;
    // onComment?: (commentdata: CommentData) => void;
    // onReply?: (commentdata: CommentData) => void;
    // onDelete?: (commentdata: CommentData) => void;
    canComment?: boolean;
}

/**
 * システムで共通的に利用している差分表示コンポーネント。
 */
export const IodMultiDiffContainer: FC<IodMultiDiffContainerProps> = ({
                                                                     diffList,
                                                                     showHistory,
                                                                     // onComment,
                                                                     // onReply,
                                                                     // onDelete,
                                                                     canComment,
                                                                     ...props
                                                                 }) => {
    return (
        <Box {...props}>
            <List>
                {diffList.map((diff) => (
                    <ListItem key={diff.id} sx={{ padding: 0, flexDirection: 'column', alignItems: 'flex-start' }}>
                        <IodDiffContainer diff={diff} canComment={canComment} showHistory={showHistory} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};
