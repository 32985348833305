import { FC } from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';
import { ContentPhaseType } from '../types/contentPhaseType';

type Props = {} & Omit<SelectInputProps, 'choices' | 'label'>;
const PhaseSelectInput: FC<Props> = (props) => {
    const phases: Array<{ id: ContentPhaseType; name: string }> = [
        { id: 'yobou', name: '予防' },
        { id: 'oukyu', name: '応急' },
        { id: 'fukkyu', name: '復旧' },
    ];

    return (
        <SelectInput
            {...props}
            // source="phase"
            choices={phases}
            label="フェーズ"
        />
    );
};

export default PhaseSelectInput;
