import React, { ReactElement, cloneElement } from 'react';
import {
    ListActions as DefaultActions,
    Pagination as DefaultPagination,
    Empty,
    Error,
    ListBase,
    ListClasses,
    ListProps,
    ListToolbar,
    ListViewProps,
    RaRecord,
    Title,
    useListContext,
} from 'react-admin';
import { Card, LinearProgress, styled } from '@mui/material';
import clsx from 'clsx';

const defaultActions = <DefaultActions />;
const defaultPagination = <DefaultPagination />;
const defaultEmpty = <Empty />;
const DefaultComponent = Card;

/**
 * IoDプロジェクト向けにカスタマイズしたリストコンポーネント
 * ページング部品を、上部フィルターの下、最下部の二か所に出力している
 */
export const IodList = <RecordType extends RaRecord = any>({
    debounce,
    disableAuthentication,
    disableSyncWithLocation,
    exporter,
    filter,
    filterDefaultValues,
    perPage,
    queryOptions,
    resource,
    sort,
    storeKey,
    ...rest
}: ListProps<RecordType>): ReactElement => (
    <ListBase<RecordType>
        debounce={debounce}
        disableAuthentication={disableAuthentication}
        disableSyncWithLocation={disableSyncWithLocation}
        exporter={exporter}
        filter={filter}
        filterDefaultValues={filterDefaultValues}
        perPage={perPage}
        queryOptions={queryOptions}
        resource={resource}
        sort={sort}
        storeKey={storeKey}
    >
        <IodListView<RecordType> {...rest} />
    </ListBase>
);

/** カスタマイズリストの心臓部分 */
const IodListView = <RecordType extends RaRecord = any>(props: ListViewProps) => {
    const {
        actions = defaultActions,
        aside,
        filters,
        emptyWhileLoading,
        hasCreate,
        pagination = defaultPagination,
        children,
        className,
        component: Content = DefaultComponent,
        title,
        empty = defaultEmpty,
        ...rest
    } = props;
    const { defaultTitle, data, error, isLoading, isFetching, filterValues, resource } =
        useListContext<RecordType>(props);

    if (!children || (!data && isLoading && emptyWhileLoading)) {
        return null;
    }

    const renderList = () => (
        <div className={ListClasses.main}>
            {(filters || actions) && (
                <ListToolbar
                    className={ListClasses.actions}
                    filters={filters}
                    actions={actions}
                    hasCreate={hasCreate}
                />
            )}

            {/* ローディング中の表示 */}
            {isFetching && <LinearProgress color="secondary" />}

            {/* 上部ページネーション */}
            {!error && pagination !== false && pagination}
            <Content className={ListClasses.content}>
                {children}
            </Content>
            {/* 最下部ページネーション */}
            {error ? <Error error={error} resetErrorBoundary={null!} /> : pagination !== false && pagination}
        </div>
    );

    const renderEmpty = () => empty !== false && cloneElement(empty, { className: ListClasses.noResults, hasCreate });

    const shouldRenderEmptyPage =
        !isLoading && data?.length === 0 && !Object.keys(filterValues).length && empty !== false;

    return (
        <Root className={clsx('list-page', className)} {...rest}>
            <Title title={title} defaultTitle={defaultTitle} preferenceKey={`${resource}.list.title`} />
            {shouldRenderEmptyPage ? renderEmpty() : renderList()}
            {aside}
        </Root>
    );
};

const PREFIX = 'IodList';

const Root = styled('div', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    display: 'flex',

    [`& .${ListClasses.main}`]: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
    },

    [`& .${ListClasses.content}`]: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            boxShadow: 'none',
        },
        overflow: 'inherit',
    },

    [`& .${ListClasses.actions}`]: {},

    [`& .${ListClasses.noResults}`]: {},
}));
