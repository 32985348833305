import * as JsDiff from 'diff';
import {
    AlignmentType,
    BorderStyle,
    Paragraph as DocxParagraph,
    Table as DocxTable,
    IRunOptions,
    ImageRun,
    TableCell,
    TableRow,
    TextRun,
    UnderlineType,
    WidthType,
} from 'docx';
import { DocxCell, DocxInfo, DocxRow, ReasonAndClassification } from './diffToDocxRowsConverter';
import { RE_MD_IMAGE, RE_MD_IMAGE_URI, RE_MD_LINK, RE_MD_LINK_NAME } from './utils/regex';
import {
    COLUMN_WIDTH_BEFORE_AFTER,
    COLUMN_WIDTH_REASON,
    FONT_SIZE,
    TABLE_ROW_MARGIN,
    TOTAL_TABLE_WIDTH,
} from './utils/size';

// メタデータ
export const ADDED_METADATA = '（追加）';
export const DELETED_METADATA = '（削除）';

interface DocxXmlFactoryParam {
    docxInfoList: DocxInfo[];
}

interface MakeTableRowDataBodyParam {
    docxInfo: DocxInfo;
}

interface MakeDocxParagraphOrTableParam {
    docxRow: DocxRow;
    position: 'before' | 'after';
}

interface MakeDocxParagraphCharsParam {
    docxRow: DocxRow;
    position: 'before' | 'after';
}

interface SizeParam {
    width: number;
    height: number;
}

/**
 * テキストの比較を行った際、文字単位の差分を保持する中間オブジェクト
 */
interface DiffCharInfo {
    beforeChars: DiffChar[];
    afterChars: DiffChar[];
}
interface DiffChar {
    order: number;
    type: 'diff' | 'context' | null; // 差分属性 diff:差分 context:差分以外
    value: string;
}

class DocxXmlFactory {
    // constructor( property: type, ) {} // 必要ならコンストラクタを定義

    public convertDocxXml = async (param: DocxXmlFactoryParam): Promise<DocxTable[]> => {
        const { docxInfoList } = param;

        // docxライブラリを用いてWord出力用の形式（XML）に変換する
        // ※画像エンコード時に非同期処理が必須
        const docxThreeColumnTables: DocxTable[] = await this.makeThreeColumnTables(docxInfoList);
        return docxThreeColumnTables;
    };

    /**
     * 全体像：パラグラフ差分を三列テーブル［新｜旧｜修正理由］で表現
     *
     * 三列テーブルとパラグラフ差分は1:1
     *
     * see: https://docx.js.org/#/usage/tables
     */
    private makeThreeColumnTables = async (docxInfoList: DocxInfo[]): Promise<DocxTable[]> => {
        // パラグラフ差分の単位に処理する
        const makeTables: Promise<DocxTable>[] = docxInfoList.map(async (docxInfo: DocxInfo) => {
            // 段落のオプション
            const createWidthOption = (size: number) => {
                return {
                    size: size,
                    type: WidthType.DXA,
                };
            };
            const bordersOption = {
                top: { style: BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
                bottom: { style: BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
            };

            // テーブルのヘッダー行を定義する
            const tableRowHeader = new TableRow({
                children: [
                    new TableCell({
                        // 1列目
                        children: [this.createHeaderDocxParagraph('編集前')],
                    }),
                    new TableCell({
                        // 2列目
                        children: [this.createHeaderDocxParagraph('編集後')],
                    }),
                    new TableCell({
                        // 3列目
                        children: [this.createHeaderDocxParagraph('修正理由')],
                    }),
                ],
            });

            // テーブルのデータ行（見出し）を定義する
            const tableRowDataHeading = new TableRow({
                children: [
                    new TableCell({
                        // 1列目
                        width: createWidthOption(COLUMN_WIDTH_BEFORE_AFTER),
                        children: [this.createDataHeadingDocxParagraph(docxInfo.beforeHeading)],
                        borders: bordersOption,
                    }),
                    new TableCell({
                        // 2列目
                        width: createWidthOption(COLUMN_WIDTH_BEFORE_AFTER),
                        children: [this.createDataHeadingDocxParagraph(docxInfo.afterHeading)],
                        borders: bordersOption,
                    }),
                    new TableCell({
                        // 3列目
                        width: createWidthOption(COLUMN_WIDTH_REASON),
                        children: [], // 修正理由なので見出しは無い
                        borders: bordersOption,
                    }),
                ],
            });

            // テーブルのデータ行（本文）を定義する ※複数行
            const tableRowDataBody: TableRow[] = await this.makeTableRowDataBody({
                docxInfo: docxInfo,
            });

            // テーブル全体を定義する
            const docxThreeColumnTable = new DocxTable({
                width: {
                    size: TOTAL_TABLE_WIDTH,
                    type: WidthType.DXA,
                },
                rows: [
                    tableRowHeader, // ヘッダー行
                    tableRowDataHeading, // データ行（見出し）
                    ...tableRowDataBody, // データ行（本文）
                ],
                margins: {
                    top: TABLE_ROW_MARGIN,
                    // bottom:
                    // right: // 全体的にAlignmentType.LEFTなので指定不要
                    left: TABLE_ROW_MARGIN,
                },
            });
            return docxThreeColumnTable;
        });

        // 全ての非同期処理を並列で実行する
        return await Promise.all(makeTables);
    };

    /**
     * 三列テーブルのヘッダー行を作成する関数
     */
    private createHeaderDocxParagraph = (text: string): DocxParagraph => {
        return new DocxParagraph({
            children: [
                new TextRun({
                    text: text,
                    size: FONT_SIZE,
                }),
            ],
            alignment: AlignmentType.CENTER,
        });
    };

    /**
     * 三列テーブルのデータ行（見出し）を作成する関数
     */
    private createDataHeadingDocxParagraph = (text: string): DocxParagraph => {
        return new DocxParagraph({
            children: [
                new TextRun({
                    text: text,
                    size: FONT_SIZE,
                    bold: true,
                }),
            ],
            alignment: AlignmentType.LEFT,
        });
    };

    private makeTableRowDataBody = async (param: MakeTableRowDataBodyParam): Promise<TableRow[]> => {
        const { docxInfo } = param;

        // Word上の行番号の単位に処理する
        const createdTableRowDataBody: Promise<TableRow>[] = docxInfo.docxRows.map(
            async (docxRow: DocxRow, index, maxLine) => {
                // 最後の行であるかを判断する材料（最後の行以外は上下の罫線を透明にする）
                const isLastLine = index === maxLine.length - 1;

                // 文字列の場合は段落、テーブル行の場合はdocxテーブルを生成する
                const beforeParagraphOrTable: DocxParagraph | DocxTable = await this.makeDocxParagraphOrTable({
                    docxRow: docxRow,
                    position: 'before',
                });
                const afterParagraphOrTable: DocxParagraph | DocxTable = await this.makeDocxParagraphOrTable({
                    docxRow: docxRow,
                    position: 'after',
                });

                // 修正理由、及び分類
                let classAndReasonList: string[] = [];
                if (docxRow.reasons) {
                    classAndReasonList = docxRow.reasons.map(
                        (reason: ReasonAndClassification) => `【${reason.classification}】${reason.reason}`,
                    );
                }

                // テーブルのデータ行（本文）を定義する
                const tableRowDataBody = new TableRow({
                    children: [
                        new TableCell({
                            // 1列目
                            width: {
                                size: COLUMN_WIDTH_BEFORE_AFTER,
                                type: WidthType.DXA,
                            },
                            children: [beforeParagraphOrTable],
                            borders: {
                                top: { style: BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
                                bottom: { style: BorderStyle.SINGLE, size: 0, color: isLastLine ? '000000' : 'FFFFFF' },
                            },
                        }),
                        new TableCell({
                            // 2列目
                            width: {
                                size: COLUMN_WIDTH_BEFORE_AFTER,
                                type: WidthType.DXA,
                            },
                            children: [afterParagraphOrTable],
                            borders: {
                                top: { style: BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
                                bottom: { style: BorderStyle.SINGLE, size: 0, color: isLastLine ? '000000' : 'FFFFFF' },
                            },
                        }),
                        new TableCell({
                            // 3列目
                            width: {
                                size: COLUMN_WIDTH_REASON,
                                type: WidthType.DXA,
                            },
                            // 範囲に紐づく修正理由すべての段落を生成する
                            children: classAndReasonList.map(
                                (classAndReason: string) =>
                                    new DocxParagraph({
                                        children: [
                                            new TextRun({
                                                text: classAndReason,
                                                size: FONT_SIZE,
                                            }),
                                        ],
                                        alignment: AlignmentType.LEFT,
                                    }),
                            ),
                            borders: {
                                top: { style: BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
                                bottom: { style: BorderStyle.SINGLE, size: 0, color: isLastLine ? '000000' : 'FFFFFF' },
                            },
                        }),
                    ],
                });

                return tableRowDataBody;
            },
        );

        // 全ての非同期処理を並列で実行する
        return await Promise.all(createdTableRowDataBody);
    };

    private makeDocxParagraphOrTable = async (
        param: MakeDocxParagraphOrTableParam,
    ): Promise<DocxParagraph | DocxTable> => {
        const { docxRow, position } = param;

        const cell: DocxCell = position === 'before' ? docxRow.beforeBody : docxRow.afterBody;

        const bodyType = cell.bodyType;

        // 文字列の属性ごとに加工を施す

        // マークダウン表
        if (bodyType === 'MdTable') {
            if (cell.docxTable) {
                // 変換済みのテーブルがある場合は描画する
                return cell.docxTable;
            } else {
                // 前提としてテーブルがある場合は変換済みなので入らない想定
                return new DocxParagraph({
                    children: [
                        new TextRun({
                            text: '【表】（描画に失敗しました。）',
                            size: FONT_SIZE,
                        }),
                    ],
                    alignment: AlignmentType.LEFT,
                });
            }
        }

        // マークダウン表ではないので、一行分の文字列である
        // diffToDocxRowsConverter.ts # mapToDiffDocxRows
        const body = cell.bodies[0].body;

        // 画像・リンク・プレーンテキスト
        if (RE_MD_IMAGE.test(body)) {
            // マークダウンの画像

            const match = body.match(RE_MD_IMAGE_URI);
            const url = match ? match[1] : '';
            // console.log(`IMAGE URI: ${url}`);

            // エンコード
            const base64data = await this.fetchImage(url);
            // console.log(base64data);
            if (base64data) {
                // 元々のサイズ
                const { width, height } = await this.getImageSize(base64data);

                // レイアウトが崩れない様に、アスペクト比を維持したまま画像サイズを調整する
                const maxWidthPixel = this.twipToPixel(COLUMN_WIDTH_BEFORE_AFTER); // 最大幅をpixel変換
                const adjustedSize: SizeParam = this.calculateOptimal({ width, height }, maxWidthPixel);

                const imageRun = new ImageRun({
                    data: base64data,
                    transformation: {
                        width: adjustedSize.width, // 画像の幅
                        height: adjustedSize.height, // 画像の高さ
                    },
                });

                return new DocxParagraph({
                    children: [imageRun],
                    alignment: AlignmentType.LEFT,
                });
            } else {
                return new DocxParagraph({
                    children: [
                        new TextRun({
                            text: '【画像】（描画に失敗しました。）',
                        }),
                    ],
                    alignment: AlignmentType.LEFT,
                });
            }
        } else if (RE_MD_LINK.test(body)) {
            // マークダウンの内部リンク

            const match = body.match(RE_MD_LINK_NAME);
            const name = match ? match[1] : '';
            // console.log(`URL INNER LINK NAME: ${name}`);

            // FIXME: 草案：文字列＋リンクが含まれる行へ対処するなら
            // 現在は行まるごとリンク名に置き換えられてしまう
            // 文字列の内、マークダウンの内部リンク部分だけをリンク名に置き換える
            // const RE_MD_LINK_STR = /\[(.*?)\]\(.*?\)/g;
            // let adjustedBody = '';
            // let lastIndex = 0;
            // const match = RE_MD_LINK_STR.exec(body)
            // while ((match) !== null) {
            //     adjustedBody += body.substring(lastIndex, match.index);
            //     const linkNameMatch = match[0].match(RE_MD_LINK_NAME);
            //     const linkName = linkNameMatch ? linkNameMatch[1] : "";
            //     adjustedBody += linkName;
            //     lastIndex = match.index + match[0].length;
            // }
            // adjustedBody += body.substring(lastIndex);

            return new DocxParagraph({
                children: [
                    new TextRun({
                        text: name,
                        size: FONT_SIZE,
                    }),
                ],
                alignment: AlignmentType.LEFT,
            });
        } else {
            // プレーンテキストの段落を作成する
            const plainTextDocxParagraph = this.makeDocxParagraphPlainText({ docxRow, position });
            return plainTextDocxParagraph;
        }

        // TODO: 322 - 420付近のコードが以下のようになるイメージ
        // Strategyを使用する場合のメモ
        // ※現在行っている分岐をcreateStrategyの中ですべて行う、分岐ごとにxxStrategyをインスタンス化
        // const strategy: DocxParagraphStrategy = this.createStrategy(docxRow, position);
        // const docxParagraph: DocxParagraph = strategy.generateDocxParagraph(anotherParam)
        // return docxParagraph
    };

    /**
     * プレーンテキストの段落を作成する
     *
     * 段落を作成する過程で、文字の差分を表現する装飾を含める
     */
    private makeDocxParagraphPlainText = async (param: MakeDocxParagraphCharsParam): Promise<DocxParagraph> => {
        const { docxRow, position } = param;

        // 段落の装飾オプション
        const redUnderlineOptions: IRunOptions = {
            size: FONT_SIZE,
            color: '#FF0000', //red
            underline: {
                type: UnderlineType.SINGLE,
                color: '#FF0000',
            },
        };

        // プレーンテキストは行範囲ではなく一行の想定
        const beforeBodies = docxRow.beforeBody.bodies[0];
        const afterBodies = docxRow.afterBody.bodies[0];

        /*
         * 追加削除のメタデータを表現する段落を作成して返す
         */
        // 判定条件を準備
        // Diff2Html.parseした際に「""」「" "」が含まれる場合がある（MDエディタ依存の可能性）
        const isBeforeEmpty = !docxRow.beforeBody || beforeBodies.body === '' || beforeBodies.body === ' ';
        const isAfterEmpty = !docxRow.afterBody || afterBodies.body === '' || afterBodies.body === ' ';

        if (isBeforeEmpty && isAfterEmpty) {
            // 空白行
            return new DocxParagraph({
                children: [this.createTextRun('', redUnderlineOptions)],
                alignment: AlignmentType.LEFT,
            });
        } else if (position === 'before' && isBeforeEmpty) {
            // 新側に文言が追加された場合
            const text = ADDED_METADATA;
            return new DocxParagraph({
                children: [this.createTextRun(text, redUnderlineOptions)],
                alignment: AlignmentType.LEFT,
            });
        } else if (position === 'after' && isAfterEmpty) {
            // 旧側の文言が削除された場合
            const text = DELETED_METADATA;
            return new DocxParagraph({
                children: [this.createTextRun(text, redUnderlineOptions)],
                alignment: AlignmentType.LEFT,
            });
        }

        // FIXME: 以下は近似している処理、理想は共通化したい
        // see:/src/features/workflow/pages/edits/components/docxTableConverter.ts # convertMdTableToDocxTable

        let changes: JsDiff.Change[] = [];
        if (position === 'before') {
            changes = beforeBodies.changes;
        } else {
            changes = afterBodies.changes;
        }

        /*
         * 行の差分が無い場合は、元のテキストを表示する段落を作成して返す
         */
        if (changes.length === 0) {
            if (position === 'before') {
                return new DocxParagraph({
                    children: [this.createTextRun(beforeBodies.body, redUnderlineOptions)],
                    alignment: AlignmentType.LEFT,
                });
            } else if (position === 'after') {
                return new DocxParagraph({
                    children: [this.createTextRun(afterBodies.body, redUnderlineOptions)],
                    alignment: AlignmentType.LEFT,
                });
            }
        }

        /*
         * 行の差分がある場合は、差分を装飾した段落を作成して返す
         *
         * 文字単位の差分を描画するために、差分を元に中間オブジェクトを作成する
         * FIXME: 後々考えると中間オブジェクトを作らなくても処理可能だった、理想は最適化したい
         */
        const mapChangesToObj = (changes: JsDiff.Change[]): DiffCharInfo => {
            let order = 0;
            let beforeChars: DiffChar[] = [];
            let afterChars: DiffChar[] = [];

            changes.forEach((change: JsDiff.Change) => {
                // デフォルト
                let char: DiffChar = {
                    order: order++,
                    type: null,
                    value: change.value,
                };

                // 差分属性を設定
                if (change.added) {
                    char.type = 'diff'; // より厳密に扱う場合はdiff -> added,removedに細分化してもよい
                    afterChars.push(char);
                } else if (change.removed) {
                    char.type = 'diff';
                    beforeChars.push(char);
                } else {
                    char.type = 'context';
                    beforeChars.push(char);
                    afterChars.push(char);
                }
            });

            return {
                beforeChars: beforeChars,
                afterChars: afterChars,
            };
        };
        const diffCharInfo: DiffCharInfo = mapChangesToObj(changes);
        // console.log(diffCharInfo);

        let chars: DiffChar[] = [];
        if (position === 'before') {
            chars = diffCharInfo.beforeChars;
        } else if (position === 'after') {
            chars = diffCharInfo.afterChars;
        }

        // 文字単位の差分を表現する段落を作成する
        const textRuns: TextRun[] = chars.map((char: DiffChar) => {
            if (char.type === 'diff') {
                if (position === 'before' && isAfterEmpty) {
                    // 変更 or 削除された文字
                    return this.createTextRun(char.value, { size: FONT_SIZE });
                } else if (position === 'after' && isBeforeEmpty) {
                    // 変更 or 追加された文字
                    return this.createTextRun(char.value, redUnderlineOptions);
                }

                return this.createTextRun(char.value, redUnderlineOptions);
            } else {
                // 変更が無い文字
                return this.createTextRun(char.value, { size: FONT_SIZE });
            }
        });
        return new DocxParagraph({
            children: textRuns,
            alignment: AlignmentType.LEFT,
        });
    };

    // 段落を作成する関数
    private createTextRun = (text: string, options: IRunOptions): TextRun => {
        return new TextRun({
            text: text,
            ...options,
        });
    };

    // 画像をダウンロードしてBase64エンコードされたデータを返す非同期関数
    private fetchImage = async (imageUri: string) => {
        try {
            // see: https://github.com/aiit-iod/iod-mng-front/pull/536/files
            const response = await fetch(imageUri, {
                mode: 'cors',
                headers: { Accept: 'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8' },
            });
            // console.log(`mode cors fetch ${response.body}`);

            // ダウンロード失敗しても後続処理が続くようにここでエラーを判定する（CORSにひっかかるとbody=nullとなる）
            if (!response.body) {
                return null;
            }
            const blob = await response.blob();
            // console.log(`size ${blob.size}`);

            return new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    // console.log("load success");

                    // 読み込みが終わったらエンコードされたデータを取得
                    const result = reader.result as string;
                    // console.log(`results is ${result}`);
                    resolve(result);
                };
                reader.onerror = () => {
                    console.error(`画像エンコードでエラーが発生: ${imageUri}`);
                    reject(reader.result);
                };
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error(`画像エンコードでエラーが発生: ${error}`, error);
        }
    };

    // Base64エンコード後の画像からサイズ情報を取得する関数
    private getImageSize = (base64data: string): Promise<SizeParam> => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = base64data;
            img.onload = () => {
                const result = { width: Number(img.width), height: Number(img.height) };
                resolve(result);
            };
            // エラーハンドリング
            // img.onerror = (error) => {
            //     reject(`画像サイズ情報の取得でエラーが発生: ${error}`);
            // };
        });
    };

    // サイズ単位変換 twip -> pixel
    public twipToPixel = (twip: number, dpi: number = 96): number => {
        // ピクセルへの変換には、情報としてDPIが必要
        // デフォルトDPI：96（WindowsPCの標準解像度）

        const inches = twip / 1440; // twipをインチに変換
        return inches * dpi; // インチをピクセルに変換
    };

    // アスペクト比を維持したままサイズを調整する関数
    // より厳密には四角い領域と最大幅を指定すると、適切な四角い領域を計算して返す関数
    public calculateOptimal = (param: SizeParam, maxWidthPixel: number): SizeParam => {
        const { width, height } = param;

        // 小数点丸め
        const fixedMaxWidth = Math.round(maxWidthPixel);

        // アスペクト比を計算
        const aspectRatio = width / height;

        let adjustedWidth = width;
        let adjustedHeight = height;

        // 幅が最大幅を超える場合、幅と高さを調整する
        if (width > fixedMaxWidth) {
            adjustedWidth = fixedMaxWidth;
            adjustedHeight = fixedMaxWidth / aspectRatio;
        }

        // 小数点丸め
        adjustedWidth = Math.round(adjustedWidth);
        adjustedHeight = Math.round(adjustedHeight);

        // 調整後の幅と高さを返却
        return { width: adjustedWidth, height: adjustedHeight };
    };
}
export { DocxXmlFactory };
