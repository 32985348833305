import { ReactNode, createContext, useContext, useState } from 'react';
import { Box, makeStyles } from '@mui/material';

// フォントサイズのコンテキストの作成
export const FontSizeContext = createContext<{ fontSize: number; setFontSize: (size: number) => void }>({
    fontSize: 1,
    setFontSize: () => {},
});

// フォントサイズのプロバイダーコンポーネント
export const FontSizeProvider = ({ children }: { children: ReactNode }) => {
    const [fontSize, setFontSize] = useState(1);

    return <FontSizeContext.Provider value={{ fontSize, setFontSize }}>{children}</FontSizeContext.Provider>;
};

// フォントサイズを適用するコンポーネント
export const ApplyFontSize = ({ children }: { children: ReactNode }) => {
    const { fontSize: baseFontSize } = useContext(FontSizeContext);

    const smallFontSize = (baseFontSize - 0.2).toFixed(1);
    const largeFontSize = (baseFontSize + 0.3).toFixed(1);

    return (
        <Box
            component="div"
            sx={{
                '& .font-size-changeable:not(.large-font):not(.small-font) *': {
                    fontSize: `${baseFontSize}rem`,
                },
                '& .font-size-changeable.large-font *': {
                    fontSize: `${largeFontSize}rem`,
                },
                '& .font-size-changeable.small-font *': {
                    fontSize: `${smallFontSize}rem`,
                },
                '& .font-size-changeable .MuiFormLabel-root': {
                    // 追加: Muiラベル用のフォントサイズ
                    fontSize: `0.9rem!important`,
                },
            }}
        >
            {children}
        </Box>
    );
};

//old version

// export const ApplyFontSize = ({ children }: { children: ReactNode }) => {
//     const { fontSize } = useContext(FontSizeContext);

//     const style = {
//         '& .toastui-editor-contents': {
//         fontSize: `${fontSize}rem`
//         }
//     };

//     return <div style={style}>{children}</div>;
// };
