import * as JsDiff from 'diff';
import * as Diff2Html from 'diff2html';
import { DiffBlock as DiffBlock2Html, DiffFile as DiffFile2Html, DiffLine as DiffLine2Html } from 'diff2html/lib/types';
import { DiffInfo as ResourceDiffInfo } from '../../types/diffInfo';
import { RE_DELETE_PREFIX, RE_INSERT_PREFIX, RE_MD_TABLE, RE_PIPE } from './utils/regex';

export const ADDED_PLACEHOLDER = '\\added';
export const DELETED_PLACEHOLDER = '\\deleted';

interface PreprocessedObj {
    diff: ResourceDiffInfo;
    diffFile2Html: DiffFile2Html[];
}

/**
 * パラグラフ単体の差分を行番号ごとに対応付けしたオブジェクト
 *
 * 1つのオブジェクトでパラグラフ単体の情報を持つ
 */
export interface ParagraphDiff {
    original_content_id: number;
    beforeHeading: string;
    afterHeading: string;
    diffLines: ParagraphDiffLine[];
}

export interface ParagraphDiffLine {
    line_number: number;
    beforeBody: CellItem;
    afterBody: CellItem;

    // 行ごとに比較した差分
    changes?: JsDiff.Change[];
}

export type BodyType = 'MdTableLineStart' | 'MdTableLine' | 'MdTableLineEnd';

export interface CellItem {
    body: string;
    lineType: 'context' | 'delete' | 'insert' | string; // see:/node_modules/diff2html/lib/types.d.ts
    oldNumber?: number;
    newNumber?: number;

    // 内容を示すメタ情報
    bodyType?: BodyType | string;
}

class ParagraphDiffProcessor {
    // constructor( property: type, ) {} // 必要ならコンストラクタを定義

    public compare = (diffs: ResourceDiffInfo[]): ParagraphDiff[] => {
        // 前処理（ライブラリを用いて行単位の差分の検出だけ行う）
        const preprocessedObjs: PreprocessedObj[] = ParagraphDiffProcessor.preprocess(diffs);

        // パラグラフ単体の差分を、行番号ごとのオブジェクトに対応付けする
        const paragraphDiffList: ParagraphDiff[] = preprocessedObjs.map((preprocessedObj: PreprocessedObj) => {
            const { diff, diffFile2Html } = preprocessedObj;

            // 前提としてこの時点でパラグラフを1つずつ処理しているので index:0 固定で問題ない
            // file: パラグラフ単体の差分を検出した結果オブジェクト
            // block: 複数パラグラフをparseした場合は複数含まれる場合もあるが、等処理では単体
            // lines: パラグラフ単体における、行の集合データ
            const file: DiffFile2Html = diffFile2Html[0];
            const block: DiffBlock2Html = file.blocks[0];
            const lines: DiffLine2Html[] = block.lines;

            // 差分情報を作り変えて、行番号ごとの差分オブジェクトに対応付けする
            // ※contextに着目して行ズレを修正しながら構築
            const editedParagraphDiffLines: ParagraphDiffLine[] = this.mapToDiffLines(lines);
            // console.log(editedParagraphDiffLines);

            const paragraphDiff: ParagraphDiff = {
                original_content_id: diff.original_content_id,
                beforeHeading: diff.old_heading ?? '',
                afterHeading: diff.new_heading ?? '',
                diffLines: editedParagraphDiffLines, // パラグラフ単体に対する行ごとの情報
            };

            return paragraphDiff;
        });
        // console.log(paragraphDiffList);

        // 行単位の差分を検出してプロパティに持たせる
        // ※編集前の文字列を基準とした差分であることを注意すること
        // ※副作用ナシ
        const diffCharsAddedList: ParagraphDiff[] = this.addDiffCharsToDiff(paragraphDiffList);
        // console.log(diffCharsAddedList);

        // 差分にマークダウン表を意識させる
        // 表を示すプロパティを追加した新たなオブジェクトを作成する
        // マークダウン表の行には表であることを示す情報持たせる（開始行/中間行/終了行）
        // ※副作用ナシ
        const lineTypeAddedList: ParagraphDiff[] = this.addLineTypeToDiff(diffCharsAddedList);
        // console.log(addedLineTypeList);

        return lineTypeAddedList;
    };

    /**
     * 差分の検出
     *
     * ライブラリを用いて実施する
     * 1. 差分文字列をUnified Diff形式に変換する{JsDiff.createTwoFilesPatch}
     * 2. Unified Diffをparseする{Diff2Html.parse}
     */
    private static preprocess(diffs: ResourceDiffInfo[]): PreprocessedObj[] {
        return diffs
            .map((diff: ResourceDiffInfo) => {
                // 単体のパラグラフを、Unified Diff形式に変換する
                // see: https://github.com/kpdecker/jsdiff/tree/master?tab=readme-ov-file#api
                const unifiedDiff = JsDiff.createTwoFilesPatch(
                    diff.old_path ?? '',
                    diff.new_path ?? '',
                    diff.old_body ?? '',
                    diff.new_body ?? '',
                    diff.old_heading ?? '',
                    diff.new_heading ?? '',
                    // オプション設定は画面側に極力合わせる
                    // see: /app/src/features/workflow/components/DiffViewer.tsx
                    {
                        // context: 変更があった行の周囲何行を差分に含むか
                        // 0 及び小さい場合はデータ落ちが発生する可能性があるので注意
                        // UNIX diff -Uオプションと同等の役割
                        // see: https://www.ibm.com/docs/ja/aix/7.3?topic=d-diff-command#diff__row-d3e29764
                        context: 10000,

                        ignoreWhitespace: true, // 行頭と末尾の空白を無視するか
                        ignoreCase: false, // 大文字と小文字の違いを無視するか
                        newlineIsToken: false, // false: 改行を無視しない true: 改行を無視して比較
                    },
                );
                // console.log(unifiedDiff);

                // 単体のパラグラフを、parseして解析する
                // see: https://github.com/rtfpessoa/diff2html?tab=readme-ov-file#diff2html-npm--nodejs-library
                // MEMO: オプションは`Diff2Html.html`でのみ有効

                // Unified Diff形式は複数の差分を含むことが可能なのでparseの戻り{DiffFile2Html}は配列である
                const diffFiles: DiffFile2Html[] = Diff2Html.parse(unifiedDiff);

                // DiffFile2HTML[]＋diffを含んだ一時的なオブジェクト
                return {
                    diff: diff,
                    diffFile2Html: diffFiles,
                };
            })
            .filter((preprocessedObj) => {
                // 差分がないパラグラフを除外する

                const { diff, diffFile2Html } = preprocessedObj;
                const file: DiffFile2Html = diffFile2Html[0];
                return file.blocks.length > 0;
            });
    }

    /**
     * 差分の情報を作り変えて、独自の差分オブジェクトに対応付けする
     *
     * ※contextに着目して行ズレを修正しながら構築する
     * # TODO DiffFile2Html[]を引数にした方が情報落ちがないし、仕様変更に強くなる
     * */
    public mapToDiffLines = (parsedLines: DiffLine2Html[]): ParagraphDiffLine[] => {
        const editedParagraphDiffLines: ParagraphDiffLine[] = [];

        // 編集前/編集後のカラムを、ズレを修正しながら構築するための空リスト
        const beforeColumns: CellItem[] = [];
        const afterColumns: CellItem[] = [];

        let offset: number = 0; // 行ズレ

        // line: 行単位
        parsedLines.forEach((line: DiffLine2Html) => {
            if (line.type === 'context') {
                const currentOffset = line.newNumber - line.oldNumber; // 現在のズレ行数
                const absoluteOffset = Math.abs(offset - currentOffset); // 絶対的なズレの行数
                // console.log(`offset: ${offset}|currentOffset: ${currentOffset}|absoluteOffset: ${absoluteOffset}`);

                if (offset === currentOffset) {
                    // 行ズレがない状態
                    const beforeColumn: CellItem = this.createCellItem(line, true);
                    beforeColumns.push(beforeColumn);
                    const afterColumn: CellItem = this.createCellItem(line, false);
                    afterColumns.push(afterColumn);
                } else if (offset > currentOffset) {
                    // 編集前(old)に行が増えている状態
                    // 編集後(new)に空行を足して行ズレを要調整

                    // FIXME: 行ズレの調整処理 近似した処理があるので、理想は共通化したい
                    let blankCell;
                    if (RE_MD_TABLE.test(line.content)) {
                        // マークダウン表の場合

                        if (afterColumns.length > 0) {
                            // 前の行が存在する
                            const previousLineAfterBody = afterColumns[afterColumns.length - 1].body;

                            if (RE_MD_TABLE.test(previousLineAfterBody)) {
                                // 前の行が表である、つまり表の途中なのでズレ調整にはテーブル行を足す

                                // 前の行と同じ列数のテーブル行を生成する
                                const blankMdTableLine = this.generateMdTableRow(
                                    previousLineAfterBody,
                                    DELETED_PLACEHOLDER,
                                );

                                for (let i = 0; i < absoluteOffset; i++) {
                                    blankCell = { body: blankMdTableLine, lineType: line.type };
                                    afterColumns.push(blankCell);
                                }
                            } else {
                                // 前の行が表ではない、つまり現行が表の始まりなのでズレ調整には空白を足す
                                for (let i = 0; i < absoluteOffset; i++) {
                                    blankCell = { body: '', lineType: line.type };
                                    afterColumns.push(blankCell);
                                }
                            }
                        } else {
                            // 前の行が存在しない場合
                            // おのずと現行が表の始まりなのでズレ調整には空白を足す
                            for (let i = 0; i < absoluteOffset; i++) {
                                blankCell = { body: '', lineType: line.type };
                                afterColumns.push(blankCell);
                            }
                        }
                    } else {
                        // 行ズレを調整する（複数行に対応）
                        for (let i = 0; i < absoluteOffset; i++) {
                            blankCell = { body: '', lineType: line.type };
                            afterColumns.push(blankCell);
                        }
                    }

                    // 編集前/編集後リストの両方にデータを反映
                    const beforeColumn: CellItem = this.createCellItem(line, true);
                    beforeColumns.push(beforeColumn);
                    const afterColumn: CellItem = this.createCellItem(line, false);
                    afterColumns.push(afterColumn);

                    offset = currentOffset;
                } else if (offset < currentOffset) {
                    // 編集後(new)に行が増えている状態
                    // 編集前(old)に空行を足して行ズレを要調整

                    // FIXME: 行ズレの調整処理 近似した処理があるので、理想は共通化したい
                    let blankCell;
                    if (RE_MD_TABLE.test(line.content)) {
                        // マークダウン表の場合

                        if (beforeColumns.length > 0) {
                            // 前の行が存在する
                            const previousLineBeforeBody = beforeColumns[beforeColumns.length - 1].body;

                            if (RE_MD_TABLE.test(previousLineBeforeBody)) {
                                // 前の行が表である、つまり表の途中なのでズレ調整にはテーブル行を足す

                                // 前の行と同じ列数のテーブル行を生成する
                                const blankMdTableLine = this.generateMdTableRow(
                                    previousLineBeforeBody,
                                    ADDED_PLACEHOLDER,
                                );

                                for (let i = 0; i < absoluteOffset; i++) {
                                    blankCell = { body: blankMdTableLine, lineType: line.type };
                                    beforeColumns.push(blankCell);
                                }
                            } else {
                                // 前の行が表ではない、つまり現行が表の始まりなのでズレ調整には空白を足す
                                for (let i = 0; i < absoluteOffset; i++) {
                                    blankCell = { body: '', lineType: line.type };
                                    beforeColumns.push(blankCell);
                                }
                            }
                        } else {
                            // 前の行が存在しない場合
                            // おのずと現行が表の始まりなのでズレ調整には空白を足す
                            for (let i = 0; i < absoluteOffset; i++) {
                                blankCell = { body: '', lineType: line.type };
                                beforeColumns.push(blankCell);
                            }
                        }
                    } else {
                        // 行ズレを調整する（複数行に対応）
                        for (let i = 0; i < absoluteOffset; i++) {
                            blankCell = { body: '', lineType: line.type };
                            beforeColumns.push(blankCell);
                        }
                    }

                    // 編集前/編集後リストの両方にデータを反映
                    const beforeColumn: CellItem = this.createCellItem(line, true);
                    beforeColumns.push(beforeColumn);
                    const afterColumn: CellItem = this.createCellItem(line, false);
                    afterColumns.push(afterColumn);

                    offset = currentOffset;
                }
            } else if (line.type === 'delete') {
                // 編集前リストに反映
                const beforeColumn: CellItem = this.createCellItem(line, true);
                beforeColumns.push(beforeColumn);
            } else if (line.type === 'insert') {
                // 編集後リストに反映
                const afterColumn: CellItem = this.createCellItem(line, false);
                afterColumns.push(afterColumn);
            }
        });

        // 編集前/編集後の列ごとの対応付けが終了
        // 長い方の列を基準に、最終的なオブジェクトを構築する
        const blankCell: CellItem = { body: '', lineType: 'context' };
        for (let i = 0; i < Math.max(beforeColumns.length, afterColumns.length); i++) {
            let diffLine: ParagraphDiffLine = {
                // 行番号は、シンプルに順次採番してリストに反映する
                // ※inputデータのoldNumber,newNumberに行番号としての役割は無い、ズレを検出するためだけに用いる
                line_number: i + 1,

                // 列ごとにデータを反映、行が少ない方の列は空行で埋める
                beforeBody: beforeColumns[i] || blankCell,
                afterBody: afterColumns[i] || blankCell,
            };
            editedParagraphDiffLines.push(diffLine);
        }

        // console.log(editedParagraphDiffLines);
        return editedParagraphDiffLines;
    };

    /**
     * セル単位のオブジェクトを作成する共通的な関数
     */
    private createCellItem = (line: DiffLine2Html, isBefore: boolean): CellItem => {
        const cellItem = {
            body: this.removePrefix(line.content, isBefore),
            lineType: line.type,

            // 元の行番号が存在している場合のみプロパティに追加する
            ...(line.oldNumber && { oldNumber: line.oldNumber }),
            ...(line.newNumber && { newNumber: line.newNumber }),
        };
        return cellItem;
    };

    /**
     * 差分のプレフィックス[+][-]を除去する関数
     */
    private removePrefix(lineContent: string, isBefore: boolean) {
        if (isBefore) {
            return lineContent.replace(RE_DELETE_PREFIX, '') || '';
        } else {
            return lineContent.replace(RE_INSERT_PREFIX, '') || '';
        }
    }

    /**
     * 受け取ったマークダウンの表と同じ列数の行を返す関数
     */
    public generateMdTableRow = (previousLine: string, placeholder?: string): string => {
        const pipeCount = (previousLine.match(RE_PIPE) || []).length;

        // 同じパイプ数（列数）の行を生成する
        // その際、プレースホルダを受け取っていれば設定する
        let row = '';
        for (let i = 0; i < pipeCount; i++) {
            row += '|';
            if (i < pipeCount - 1) {
                // 最後のパイプ到達前
                row = placeholder ? row + placeholder : row;
            }
        }

        return row;
    };

    /**
     * 行単位の差分を検出してプロパティに持たせる
     *
     * 行ごとに比較した差分を追加した新たなオブジェクトを作成する
     * ※編集前の文字列を基準とした差分であることに注意すること
     *
     * 差分検出ライブラリ{JsDiff.diffChars()}
     */
    private addDiffCharsToDiff = (paragraphDiffList: ParagraphDiff[]): ParagraphDiff[] => {
        const diffCharsAddedList = paragraphDiffList.map((paragraphDiff: ParagraphDiff) => {
            // 行単位
            const processedDiffLines: ParagraphDiffLine[] = paragraphDiff.diffLines.map(
                (diffLine: ParagraphDiffLine) => {
                    // 行ごとに、文字単位の差分を検出
                    const diffChars = JsDiff.diffChars(diffLine.beforeBody.body, diffLine.afterBody.body);
                    return {
                        ...diffLine,

                        // プロパティを反映
                        changes: diffChars,
                    };
                },
            );

            // 元のオブジェクトを直接変更せずに追加プロパティを反映する
            let paragraphDiffCopy: ParagraphDiff = { ...paragraphDiff };
            paragraphDiffCopy.diffLines = processedDiffLines;
            return paragraphDiffCopy;
        });
        return diffCharsAddedList;
    };

    /**
     * 表であることを示すメタ情報をプロパティに持たせる
     * 値がマークダウン表の行（厳密にはセル）のみ
     *
     * 開始行/中間行/終了行の情報を持たせて、後続処理で使用する
     */
    private addLineTypeToDiff = (paragraphDiffList: ParagraphDiff[]): ParagraphDiff[] => {
        // パラグラフ単位の差分
        const lineTypeAddedList: ParagraphDiff[] = paragraphDiffList.map((paragraphDiff) => {
            let inTableBefore = false;
            let inTableAfter = false;

            // 行単位
            const processedDiffLines: ParagraphDiffLine[] = paragraphDiff.diffLines.map((originalDiffLine, index) => {
                const diffLine: ParagraphDiffLine = JSON.parse(JSON.stringify(originalDiffLine)); // DeepCopyを作成して副作用が及ばない様にする

                // マークダウン表の場合はメタ情報をプロパティに追加する
                // 編集前のデータ
                if (diffLine.beforeBody) {
                    const beforeBody: CellItem = diffLine.beforeBody;

                    if (RE_MD_TABLE.test(beforeBody.body)) {
                        if (!inTableBefore) {
                            inTableBefore = true;
                            beforeBody.bodyType = 'MdTableLineStart'; // 開始行
                        } else {
                            beforeBody.bodyType = 'MdTableLine'; // 中間行

                            // 次の行が存在しない、または次の行がテーブルではない場合は現在の行をテーブルの終了行と見なす
                            const nextLineBeforeBody = paragraphDiff.diffLines[index + 1]?.beforeBody?.body;
                            if (!nextLineBeforeBody || !RE_MD_TABLE.test(nextLineBeforeBody)) {
                                beforeBody.bodyType = 'MdTableLineEnd'; // 終了行
                            }
                        }
                    } else {
                        inTableBefore = false;
                    }

                    // プロパティを反映
                    diffLine.beforeBody = beforeBody;
                }

                // 編集後のデータ
                if (diffLine.afterBody) {
                    const afterBody: CellItem = diffLine.afterBody;

                    if (RE_MD_TABLE.test(afterBody.body)) {
                        if (!inTableAfter) {
                            inTableAfter = true;
                            afterBody.bodyType = 'MdTableLineStart'; // 開始行
                        } else {
                            afterBody.bodyType = 'MdTableLine'; // 中間行

                            const nextLineAfterBody = paragraphDiff.diffLines[index + 1]?.afterBody?.body;
                            if (!nextLineAfterBody || !RE_MD_TABLE.test(nextLineAfterBody)) {
                                afterBody.bodyType = 'MdTableLineEnd'; // 終了行
                            }
                        }
                    } else {
                        inTableAfter = false;
                    }

                    // プロパティを反映
                    diffLine.afterBody = afterBody;
                }

                return diffLine;
            });

            // 元のオブジェクトを直接変更せずに追加プロパティを反映する
            let paragraphDiffCopy: ParagraphDiff = { ...paragraphDiff };
            paragraphDiffCopy.diffLines = processedDiffLines;
            return paragraphDiffCopy;
        });

        return lineTypeAddedList;
    };
}
export { ParagraphDiffProcessor };
