import React, { FC } from 'react';
import { SaveButton as RaSaveButton, SaveButtonProps as RaSaveButtonProps } from 'react-admin';

export interface SaveButtonProps extends Omit<RaSaveButtonProps, 'label'> {
    // Omitで指定したプロパティ以外を継承
}

export const SaveButton: FC<SaveButtonProps> = (props) => {
    return <RaSaveButton label="保存" {...props} />;
};
