import * as React from 'react';
import {
    BooleanInput,
    DateField,
    Edit,
    Labeled,
    NumberField,
    SaveButton,
    SimpleForm,
    TextInput,
    TopToolbar,
    useGetOne,
    usePermissions,
    useTranslate,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { BackButton } from '../../../components/ui/button/BackButton';
import { IodToolbar } from '../../../components/ui/layout/IodToolbar';

export const SnapshotEdit = () => {
    const translate = useTranslate();
    const { documentId, snapshotId } = useParams();
    const { isLoading, permissions } = usePermissions();
    const { data, error, isLoading: isLoadingDoc } = useGetOne('documents', { id: documentId });
    if (isLoading || isLoadingDoc || !data) {
        return <CircularProgress />;
    }

    // 権限がある場合のみ各ボタンを有効化する
    const hasSavePolicy = permissions.snapshots.includes('update');
    const hasDeletePolicy = permissions.snapshots.includes('delete');

    return (
        <Edit
            resource={`admin/documents/${documentId}/snapshots`}
            id={snapshotId}
            title={`${data.title} / ${translate('routes.documents.snapshots.titles.edit')}`}
            actions={
                <TopToolbar sx={{ justifyContent: 'flex-start' }}>
                    <BackButton />
                </TopToolbar>
            }
        >
            <SimpleForm
                toolbar={
                    <IodToolbar>
                        <SaveButton disabled={!hasSavePolicy} />
                        {/*<DeleteButton disabled={!hasDeletePolicy} />*/}
                    </IodToolbar>
                }
            >
                <Labeled label={translate('routes.documents.snapshots.fields.id')}>
                    <NumberField source="id" />
                </Labeled>

                <TextInput
                    source="name"
                    name="name"
                    label={translate('routes.documents.snapshots.fields.name')}
                    placeholder="タイトルを入力"
                    fullWidth
                    inputProps={{
                        maxLength: 50,
                    }}
                    required
                />
                <TextInput
                    source="description"
                    name="description"
                    label={translate('routes.documents.snapshots.fields.description')}
                    placeholder="説明を入力"
                    fullWidth
                    inputProps={{
                        maxLength: 500,
                    }}
                    multiline
                    maxRows={5}
                />
                {/* TODO あとで変える可能性大だが一旦実装 */}
                <BooleanInput
                    source="is_public"
                    name="is_public"
                    label={translate('routes.documents.snapshots.fields.is_public')}
                />

                <Labeled label="作成日時">
                    <DateField source="created_at" showTime={true} />
                </Labeled>
                <Labeled label="更新日時">
                    <DateField source="updated_at" showTime={true} />
                </Labeled>
            </SimpleForm>
        </Edit>
    );
};
