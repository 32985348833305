import * as React from 'react';
import {
    AutocompleteInput,
    DateField,
    Edit,
    FunctionField,
    Labeled,
    ReferenceInput,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    usePermissions,
} from 'react-admin';
import { CircularProgress } from '@mui/material';
import { Check } from '@mui/icons-material';
import { IodToolbar } from '../../../components/ui/layout/IodToolbar';

// AutocompleteInput プルダウン入力時のフィルタリングのため必要
const matchSuggestion = (filter: string, choice: any) => {
    return choice.name.toLowerCase().includes(filter.toLowerCase());
};

export const UserEdit = () => {
    const { isLoading, permissions } = usePermissions();
    if (isLoading) {
        return <CircularProgress />;
    }
    // 権限がある場合のみ各ボタンを有効化する
    const hasSavePolicy = permissions.users.includes('update');
    // const hasDeletePolicy = permissions.users.includes('delete');

    return (
        <Edit>
            <SimpleForm
                toolbar={
                    // <CustomToolbar
                    <IodToolbar>
                        <SaveButton disabled={!hasSavePolicy} />
                    </IodToolbar>
                }
            >
                <Labeled label="ID" fullWidth>
                    <TextField source="id" />
                </Labeled>
                <Labeled label="メールアドレス" fullWidth>
                    <TextField source="email" />
                </Labeled>
                <Labeled label="所属" fullWidth>
                    <TextInput source="affiliation" label="所属を入力" />
                </Labeled>
                <Labeled label="氏名" fullWidth>
                    <TextInput source="name" label="氏名を入力" />
                </Labeled>
                <Labeled label="ステータス">
                    <FunctionField render={renderStatus} label="ステータス" />
                </Labeled>
                <Labeled label="ロール" fullWidth>
                    <ReferenceInput source="role_id" reference="roles">
                        <AutocompleteInput optionText="name" label="ロールを選択" matchSuggestion={matchSuggestion} />
                    </ReferenceInput>
                </Labeled>
                <Labeled label="作成日時" fullWidth>
                    <DateField source="created_at" showTime={true} />
                </Labeled>
                <Labeled label="更新日時" fullWidth>
                    <DateField source="updated_at" showTime={true} />
                </Labeled>
            </SimpleForm>
        </Edit>
    );
};

// const renderStatus = (record: UserInfo) => { // npm update後、型エラーになるようになったため一旦anyで逃げる
const renderStatus = (record: any) => {
    return record.status === 'CONFIRMED' ? <Check /> : '';
};
