import React, { useCallback } from 'react';
import { useCreate, useDelete, useNotify, useRefresh, useTranslate, useUpdate } from 'react-admin';
import { Alert, Box, ListItemText, Typography } from '@mui/material';
import { Identifier } from 'ra-core';
import { formatDate } from '../../../../../utils/dateUtil';
import { DiscussionCard } from '../../../components/discussion/DiscussionCard';
import { CommentAdd, CommentUpdate } from '../../../types/comment';
import { MergeRequestDiscussion } from '../../../types/discussion/content-discussion/mergeRequestDiscussion';
import { DiscussionUpdate } from '../../../types/discussion/discussion';
import { ActivityType, MergeRequestActivity } from '../types/mergeRequestActivity';

interface Props {
    merge_request_id: Identifier;
    activity: MergeRequestActivity;
}

/**
 * activity_typeによって追加の情報を表示するためのコンポーネント
 */
export const MergeRequestActivityListItemContent = (props: Props) => {
    const { merge_request_id, activity } = props;
    const translate = useTranslate();

    const activityType: ActivityType = activity.type;

    const action_text = translate(`resources.merge_requests.activities.types.${activityType}`);
    const who = `【${activity.created_by.affiliation}】${activity.created_by.name}`;
    const when = formatDate(activity.created_at);

    let additionalContent = null;
    if (activity.detail && activity.detail.type === 'discussion') {
        const discussion: MergeRequestDiscussion = activity.detail;

        additionalContent = <MergeRequestDiscussionActivityDetail
            merge_request_id={merge_request_id} discussion={discussion}
        />;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {!['merge_request_discussion_create'].includes(activityType) && (
                <ListItemText
                    primary={
                        <>
                            {who}
                            <Typography
                                sx={{ display: 'inline', ml: 0.5, mr: 0.5 }}
                                component="span"
                                variant="body2"
                                color="text.secondary"
                            >
                                は
                            </Typography>
                            {action_text}
                            <Typography
                                sx={{ display: 'inline', ml: 0.5, mr: 0.5 }}
                                component="span"
                                variant="body1"
                                color="text.secondary"
                            >
                                しました
                                {' — ' + when}
                            </Typography>
                        </>
                    }
                />
            )}
            {additionalContent}
        </Box>
    );
};

interface MergeRequestDiscussionActivityDetailProps {
    merge_request_id: Identifier;
    discussion: MergeRequestDiscussion;
}

const MergeRequestDiscussionActivityDetail = (props: MergeRequestDiscussionActivityDetailProps) => {
    const { merge_request_id, discussion } = props;

    const [create, { isLoading }] = useCreate<CommentAdd>();
    const [update] = useUpdate();
    const [deleteOne, { data, isLoading: isLoadingDelete, error }] = useDelete();
    const notify = useNotify();
    const refresh = useRefresh();

    const refreshDiscussions = useCallback(() => {
        refresh();
    }, [refresh]);

    const onReply = useCallback(
        (commentAdd: CommentAdd) => {
            const apiPath = `discussions/${commentAdd.discussion_id}/comments`;
            create(
                apiPath,
                {
                    data: {
                        body: commentAdd.body,
                    },
                },
                {
                    onError: (error: any) => {
                        notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>);
                        return error;
                    },
                    onSuccess: (res: any) => {
                        notify(<Alert severity="info">返信しました。</Alert>);
                        setTimeout(() => {
                            // 直後に再読み込みしてもうまくいかないことがあるため
                            refreshDiscussions();
                        }, 100);
                    },
                },
            );
        },
        [create, notify, refreshDiscussions],
    );

    const onEditDiscussion = useCallback(
        (discussionId: Identifier, discussionUpdate: DiscussionUpdate) => {
            const apiPath = `merge_requests/${merge_request_id}/discussions`;
            // 編集処理
            update(
                apiPath,
                {
                    id: discussionId,
                    data: discussionUpdate,
                },
                {
                    onSuccess: () => {
                        notify(<Alert severity="info">更新しました</Alert>);
                        setTimeout(() => {
                            // 直後に再読み込みしてもうまくいかないことがあるため
                            refreshDiscussions();
                        }, 100);
                    },
                    onError: (error: any) => {
                        if (error && error.status === 403) {
                            // notify(error.message, { type: 'error' });
                            notify(<Alert severity="error">本人以外は更新できません</Alert>);
                        } else {
                            notify(<Alert severity="error">更新に失敗しました</Alert>);
                        }
                        // notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>)
                    },
                },
            );
        },
        [update, notify, refreshDiscussions, merge_request_id],
    );

    const onDeleteDiscussion = useCallback(
        (discussionId: Identifier) => {
            deleteOne(
                'discussions',
                {
                    id: discussionId,
                },
                {
                    onSuccess: () => {
                        notify(<Alert severity="info">削除しました</Alert>);
                        setTimeout(() => {
                            // 直後に再読み込みしてもうまくいかないことがあるため
                            refreshDiscussions();
                        }, 100);
                    },
                    onError: (error: any) => {
                        if (error && error.status === 403) {
                            notify(<Alert severity="error">本人以外やリプライがある場合は削除できません</Alert>);
                        } else {
                            notify(<Alert severity="error">削除に失敗しました</Alert>);
                        }
                        // notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>)
                    },
                },
            );
        },
        [deleteOne, notify, refreshDiscussions],
    );

    const onEditReply = useCallback(
        (commendId: Identifier, commentUpdate: CommentUpdate) => {
            update(
                'comments',
                {
                    id: commendId,
                    data: commentUpdate,
                },
                {
                    onSuccess: () => {
                        notify(<Alert severity="info">更新しました</Alert>);
                        setTimeout(() => {
                            // 直後に再読み込みしてもうまくいかないことがあるため
                            refreshDiscussions();
                        }, 100);
                    },
                    onError: (error: any) => {
                        if (error && error.status === 403) {
                            notify(<Alert severity="error">本人以外は更新できません</Alert>);
                        } else {
                            notify(<Alert severity="error">更新に失敗しました</Alert>);
                        }
                        // notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>)
                    },
                },
            );
        },
        [update, notify, refreshDiscussions],
    );

    const onDeleteReply = useCallback(
        (commentId: Identifier) => {
            deleteOne(
                'comments',
                {
                    id: commentId,
                },
                {
                    onSuccess: () => {
                        notify(<Alert severity="info">削除しました</Alert>);
                        setTimeout(() => {
                            // 直後に再読み込みしてもうまくいかないことがあるため
                            refreshDiscussions();
                        }, 100);
                    },
                    onError: (error: any) => {
                        if (error && error.status === 403) {
                            notify(<Alert severity="error">本人以外は削除できません</Alert>);
                        } else {
                            notify(<Alert severity="error">削除に失敗しました</Alert>);
                        }
                        // notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>)
                    },
                },
            );
        },
        [deleteOne, notify, refreshDiscussions],
    );

    if (!discussion.comments || !discussion.comments[0] || !discussion.comments[0].body) {
        return null;
    }

    return (
        <DiscussionCard
            discussionId={discussion.id}
            comment={discussion.comments[0]}
            replies={discussion.comments.slice(1)}
            onReply={onReply}
            onEditReply={onEditReply}
            onDeleteReply={onDeleteReply}
            onEditDiscussion={onEditDiscussion}
            onDeleteDiscussion={onDeleteDiscussion}
            canReply={true}
            // onHeightChange={() => adjustHeight()}
            sx={{
                // position: 'absolute',
                borderRadius: '4px 4px 4px 4px',
                overflow: 'visible',
                left: '.75rem',
                right: '.75rem',
                ':hover': {
                    zIndex: 999,
                },
            }}
        />
    );
};
