import { Auth } from 'aws-amplify';
import { AuthProvider } from 'ra-core/dist/cjs/types';
import { API_URL } from '../../config';
import { httpClient } from './dataProvider';

export const cognitoAuthProvider: AuthProvider = {
    /**
     * 認証の仕組みとしてAWS Cognito、React側をaws-amplifyやamplify-uiを使ったReact-Admin用のAuthProvider。
     *
     * React-AdminのAuthProviderやAdminコンポーネントにAmplify-uiを組み込むのに試行錯誤が必要だったが結果としてシンプルになった。
     * 主に以下の公式ドキュメントを参考に作り上げたので改修時は以下を参考に。
     *
     * React-Adminのドキュメント:
     * https://marmelab.com/react-admin/Authentication.html#using-external-authentication-providers
     * amplify UIのAuthenticator:
     * https://ui.docs.amplify.aws/react/connected-components/authenticator
     *
     */

    login: async () => {
        /* Nothing to do here, this function will never be called */
    },
    // called when the user clicks on the logout button
    logout: async () => {
        // console.log('logout');
        await Auth.signOut();
        window.location.href = '/'; // ログアウト時のhrefを指定するオプションがどこかにあるかもしれないが一旦ここに
        return;
    },
    // called when the API returns an error
    checkError: async ({ status, body, ...params }: any) => {
        if (status === 401) {
            await Auth.signOut();
            window.location.href = '/';
        }
        return;
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async (params) => {
        // console.log('Check auth', params);
        const userData = await Auth.currentAuthenticatedUser();
        // console.log(userData);
        if (userData) {
            return;
        }

        // // デバッグ用
        // Auth.currentSession().then((data) => {
        //     console.log(`token: ${data.getIdToken().getJwtToken()}`);
        // });
    },

    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: async (params) => {
        // console.log(params);
        const response = await httpClient(`${API_URL}/users/me/permissions`);
        const data = response.json;

        // API仕様変更につき、仕様変更前後どちらでも動作するように（最新版を優先）
        return data?.permissions || data;
    },

    getIdentity: async () => {
        // console.log('get_identity');
        const userData = await Auth.currentAuthenticatedUser({ bypassCache: true });
        // console.log(userData)

        // fullNameキープロパティの値がヘッダ右の名前表示となる
        return {
            id: userData?.username,
            fullName: userData?.attributes?.name,
            affiliation: userData?.attributes['custom:affiliation'],
        };
    },
};
