import { FC } from 'react';
import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin';
import { OrganizationComponentType } from '../../organizations/types/organizationComponentType';

type Props = {
    type?: OrganizationComponentType;
    disabled?: boolean;
};

const OrganizationArrayInput: FC<Props> = (props) => {
    // const filterToQuery = (q: string) => {
    //     // APIコールのクエリをカスタマイズする場合はここを修正
    //     console.log(q);
    //     return {
    //         q: q
    //     };
    // };

    return (
        <ReferenceArrayInput
            source={props.type ? `${props.type}_ids` : 'parent_ids'}
            reference="organizations"
            perPage={200}
        >
            <AutocompleteArrayInput
                label={
                    props.type == 'responsible_organization'
                        ? '主管組織'
                        : props.type == 'co_responsible_organization'
                        ? '共管組織'
                        : '親組織'
                }
                optionText="name"
                fullWidth
                // filterToQuery={filterToQuery}
                debounce={10} // 最後のonChange発生からdebounceミリ秒後にAPIコール
                disabled={props.disabled ? props.disabled : false}
            />
        </ReferenceArrayInput>
    );
};

export default OrganizationArrayInput;
