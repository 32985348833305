import React, { FC, useEffect, useState } from 'react';
import { useGetOne, useRecordContext } from 'react-admin';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ExclamationIcon from '@mui/icons-material/Error';
import ReplayIcon from '@mui/icons-material/Replay';
import { Identifier } from 'ra-core';
import { formatDate } from '../../../../../utils/dateUtil';
import { EditInfo } from '../../../types/editInfo';
import { MergeRequestInfo } from '../../../types/mergeRequest';
// import { MergeRequestContentInfo } from '../../../types/mergeRequest';
import { ReviewInfo } from '../../../types/reviewInfo';

interface Props {
    onReassignReviewer: (userId: Identifier) => void;
    canReassign?: boolean;
}

export const ReviewsField: FC<Props> = (props) => {
    const record = useRecordContext<MergeRequestInfo>();
    const [isReassigning, setIsReassigning] = useState<boolean>(false);

    const { data: editInfo } = useGetOne<EditInfo>(
        `edits`,
        {
            id: record.content?.original_content_id || '',
        },
        { enabled: !!record.content }, // Truthyな場合のみ実行
    );

    if (!record) {
        return null; // レコードがまだ利用できない場合は何も表示しない
    }

    const reviews: ReviewInfo[] = record.reviews;

    const canReassign = props.canReassign ?? true;

    const handleReassign = async (review: ReviewInfo) => {
        setIsReassigning(true);
        if (window.confirm('再依頼してよろしいですか？確認・承認状況がリセットされます')) {
            await props.onReassignReviewer(review.reviewer_id as Identifier);
        }
        setIsReassigning(false);
    };

    const shouldShowTooltip = (review: ReviewInfo) => {
        // 承認者
        const isReviewer = review.is_approval_required;
        // 承認の後にパラグラフが編集されているか
        const isEditedAfterReview = editInfo && new Date(review.reviewed_at) < new Date(editInfo.created_at);

        return isReviewer && isEditedAfterReview;
    };

    return (
        <TableContainer>
            <Table sx={{ minWidth: '100px' }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>区分</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>氏名</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }} colSpan={2}>
                            確認・承認状況
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }} align="center">
                            再依頼
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reviews &&
                        reviews.map((review, index: number) => (
                            <TableRow key={index}>
                                <TableCell>{review.reviewer_role_name}</TableCell>
                                <TableCell>{review.reviewer_name}</TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    {/*{review.status === 'Unreviewed' && <UnpublishedOutlinedIcon />}*/}
                                    {review.status === 'Approved' && (
                                        <Tooltip title="確認・承認" placement="right" color="primary">
                                            <CheckIcon />
                                        </Tooltip>
                                    )}
                                    {review.status === 'ChangesRequested' && (
                                        <Tooltip title="差戻" placement="right" color="error">
                                            <ReplayIcon />
                                        </Tooltip>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {review.reviewed_at && (
                                        <Box display="flex" alignItems="center">
                                            {formatDate(review.reviewed_at)}

                                            {/* 特定の条件下でツールチップを表示する */}
                                            {shouldShowTooltip(review) && (
                                                <Tooltip
                                                    title={
                                                        <>
                                                            最新の版に対する承認ではありません。
                                                            <br />
                                                            承認後に編集が行われています。
                                                            <br />
                                                            必要に応じて再度承認をしてください。
                                                        </>
                                                    }
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                fontSize: '1em',
                                                                whiteSpace: 'nowrap',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <ExclamationIcon
                                                        sx={{
                                                            fontSize: 20,
                                                            color: 'orange',
                                                            marginLeft: '8px',
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                        </Box>
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {canReassign && review.status !== 'Unreviewed' && review.reviewer_id && (
                                        <Button
                                            variant="text"
                                            size="small"
                                            onClick={() => {
                                                handleReassign(review);
                                            }}
                                            disabled={isReassigning}
                                        >
                                            再依頼
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
