import React, {createContext, FC, ReactNode, useContext, useState} from 'react';

type Menu = 'dashboard' | 'search' | 'edit' | 'admin' | 'other';

interface SelectedMenuContextProps {
    selectedMenu: Menu;
    setSelectedMenu: React.Dispatch<React.SetStateAction<Menu>>;
}

export const SelectedMenuContext = createContext<SelectedMenuContextProps | undefined>(undefined);

interface SelectedMenuProviderProps {
    children: ReactNode;
}
export const SelectedMenuProvider: FC<SelectedMenuProviderProps> = ({ children }) => {
    const [selectedMenu, setSelectedMenu] = useState<Menu>('dashboard');

    return (
        <SelectedMenuContext.Provider value={{ selectedMenu, setSelectedMenu }}>
            {children}
        </SelectedMenuContext.Provider>
    );
};
