import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { Box, Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { Help } from '@mui/icons-material';
import { createTwoFilesPatch } from 'diff';
import 'diff2html/bundles/css/diff2html.min.css';
import { ColorSchemeType, DiffStyleType, OutputFormatType } from 'diff2html/lib/types';
import { Diff2HtmlUI } from 'diff2html/lib/ui/js/diff2html-ui-slim.js';
import { Identifier } from 'ra-core';
import { formatDate } from '../../../utils/dateUtil';
import { CommentAdd, CommentUpdate } from '../types/comment';
import { LineDiscussion, LineDiscussionUpdate } from '../types/discussion/content-discussion/lineDiscussion';
import './DiffViewer.css';
import { LineDiscussionCard } from './discussion/line-discussion/LineDiscussionCard';
import { LineDiscussionCreateDialog } from './discussion/line-discussion/LineDiscussionCreateDialog';
import { LineDiscussionFormValue } from './discussion/line-discussion/LineDiscussionForm';

export interface DiffViewerProps {
    // 差分を作成するデータ
    oldTitle?: string;
    newTitle?: string;
    oldHeader?: string;
    newHeader?: string;
    oldContent: string;
    newContent: string;
    oldUpdatedAt?: string;
    newUpdatedAt?: string;
    oldSnapshotName?: string;
    newSnapshotName?: string;
    /**
     * 何行前後を表示するか
     */
    context?: number;
    ignoreWhitespace?: boolean;
    ignoreCase?: boolean;
    newlineIsToken?: boolean;
    // 差分表示に関する設定
    outputFormat?: OutputFormatType;
    diffStyle?: DiffStyleType;
    diffMaxChanges?: number;
    diffMaxLineLength?: number;
    renderNothingWhenEmpty?: boolean;
    diffTooBigMessage?: (fileIndex: number) => string;
    colorScheme?: ColorSchemeType;
    fileContentToggle?: boolean;
    synchronisedScroll?: boolean;
    sx?: SxProps<Theme>;
    // コメント表示に関する設定
    discussions: LineDiscussion[];
    showDiscussion?: boolean;
    onComment: (discussionAdd: LineComment) => void;
    onEditDiscussion?: (discussionId: Identifier, discussionUpdate: LineDiscussionUpdate) => void;
    onDeleteDiscussion?: (discussionId: Identifier) => void;
    onReply: (commentAdd: CommentAdd) => void;
    onEditReply?: (commentId: Identifier, commentUpdate: CommentUpdate) => void;
    onDeleteReply?: (commentId: Identifier) => void;
    canComment?: boolean;
    canReply?: boolean;
}

export interface LineComment {
    lineNo: number;
    classification: string;
    reason: string;
    comment?: string;
}

interface CommentRefs {
    [key: number]: HTMLDivElement | null;
}

interface CommentDialogState {
    open: boolean;
    lineNum: number;
}

const initialCommentDialogState: CommentDialogState = {
    open: false,
    lineNum: 0,
};

export const DiffViewer: FC<DiffViewerProps> = memo((props) => {
    const outerRef = useRef(null);
    const targetRef = useRef<HTMLDivElement>(null);
    const commentRefs = useRef<CommentRefs>({});
    const [refsSnapshot, setRefsSnapshot] = useState<CommentRefs>({});
    const [commentDialogState, setCommentDialogState] = useState<CommentDialogState>(initialCommentDialogState);

    const [outerHeight, setOuterHeight] = useState<number>(0);
    useEffect(() => {
        setRefsSnapshot({ ...commentRefs.current });
    }, [commentRefs.current]);

    useEffect(() => {
        const observers: ResizeObserver[] = [];

        Object.values(refsSnapshot).forEach((ref) => {
            if (ref) {
                const observer = new ResizeObserver(() => {
                    adjustHeight();
                });

                observer.observe(ref);
                observers.push(observer);
            }
        });

        return () => {
            observers.forEach((observer) => observer.disconnect());
        };
    }, [refsSnapshot]); // 依存配列に commentRefs を追加することもできますが、その場合、ref の変更を検知するために追加のロジックが必要です。

    /**
     * ダイアログを閉じる処理
     */
    const handleDialogClose = () => {
        setCommentDialogState({
            ...commentDialogState,
            open: false,
        });
    };

    const onReplyFunc = (commentAdd: CommentAdd) => {
        props.onReply(commentAdd);

        // リプライ後高さが足りなくなる可能性があるので高さ調整
        // setTimeout(() => {
        //     adjustHeight();
        // }, 1000)
    };

    const onEditDiscussion = (discussionId: Identifier, discussionUpdate: LineDiscussionUpdate) => {
        if (props.onEditDiscussion) {
            props.onEditDiscussion(discussionId, discussionUpdate);
        }
    };

    const onDeleteDiscussion = (discussionId: Identifier) => {
        if (props.onDeleteDiscussion) {
            props.onDeleteDiscussion(discussionId);
        }
    };

    const onEditReply = (commentId: Identifier, commentUpdate: CommentUpdate) => {
        if (props.onEditReply) {
            props.onEditReply(commentId, commentUpdate);
        }
    };

    const onDeleteReply = (commentId: Identifier) => {
        if (props.onDeleteReply) {
            props.onDeleteReply(commentId);
        }
    };

    // 追加ボタン押下時処理
    const handleDialogAdd = (commentEvent: LineDiscussionFormValue) => {
        const commentData: LineComment = {
            lineNo: commentDialogState.lineNum,
            reason: commentEvent.reason,
            classification: commentEvent.classification,
            comment: commentEvent.comment,
        };
        if (props.onComment) {
            props.onComment(commentData);
        }
        handleDialogClose();
    };

    // キャンセルボタン押下時処理
    const handleDialogCancel = () => {
        handleDialogClose();
    };

    const adjustHeight = () => {
        if (!targetRef.current) {
            return;
        }
        const top = targetRef.current.getBoundingClientRect().top;

        let maxBottom = 0;
        // 子要素（LineDiscussionCard）の高さを計算
        for (const key in commentRefs.current) {
            if (commentRefs.current.hasOwnProperty(key)) {
                const el = commentRefs.current[key];
                if (el) {
                    maxBottom = Math.max(maxBottom, el.getBoundingClientRect().bottom);
                }
            }
        }

        const height = maxBottom - top;

        // 計算した高さを設定
        setOuterHeight(height);
    };

    /*
     * 差分テーブル自体の描画
     */
    useEffect(() => {
        if (!targetRef.current) {
            return;
        }

        // 差分の検出
        const diff = createTwoFilesPatch(
            props.oldTitle ?? '',
            props.newTitle ?? '',
            props.oldContent ?? '',
            props.newContent ?? '',
            props.oldHeader ?? '',
            props.newHeader ?? '',
            {
                context: props.context ?? 0, // 何行前後を表示するか
                ignoreWhitespace: props.ignoreWhitespace ?? true, // 空白を無視するか
                ignoreCase: props.ignoreCase ?? false, // 大文字小文字を無視するか
                newlineIsToken: props.newlineIsToken ?? false, // 改行をトークンとして扱うか。trueの場合、差分が一行で表示される。
            },
        );

        // 差分情報を画面表示
        const diff2htmlUi = new Diff2HtmlUI(targetRef.current, diff, {
            // https://github.com/rtfpessoa/diff2html#diff2html-configuration

            // ******** 差分表示関連 ********
            // 差分の表示形式
            // line-by-line: 変更前と変更後を表示, side-by-side: 差分のみを表示
            outputFormat: props.outputFormat ?? 'side-by-side',
            // 各行の差分表示レベル
            // char: 文字単位, word: 差分が含まれる単語単位
            diffStyle: props.diffStyle ?? 'char',
            // 一度に表示する差分の最大行数
            diffMaxChanges: props.diffMaxChanges ?? 3000,
            // 一度に表示する差分の一行の最大文字数
            diffMaxLineLength: props.diffMaxLineLength ?? 500,
            // diffが比較で変更を示さない場合、何もレンダリングしない。diffが比較で変更を示さない場合、何もレンダリングしない。
            renderNothingWhenEmpty: props.renderNothingWhenEmpty ?? false,
            // 差分が多すぎる場合のメッセージ
            diffTooBigMessage: props.diffTooBigMessage ?? ((fileIndex) => '差分が非常に多いので表示できませんでした'),
            // カラーモード
            // ColorSchemeType.LIGHT: ライトモード, ColorSchemeType.DARK: ダークモード, ColorSchemeType.AUTO: ブラウザの設定に従う
            colorScheme: props.colorScheme ?? ColorSchemeType.LIGHT,
            // 差分内容をトグルできるようにするか
            fileContentToggle: props.fileContentToggle ?? false,
            // 表示形式がside-by-sideの場合、スクロールを左右で同期させるか
            synchronisedScroll: props.synchronisedScroll ?? true,

            // ******** ファイルリスト表示関連 ********
            // ファイルの一覧を表示するか
            // trueにすると「File Changed」というヘッダーも表示される
            drawFileList: false,
            // ファイルリスト表示時、ファイルリストをトグルできるようにするか
            fileListToggle: true,
            // ファイルリスト表示時、トグルをオープンした状態で開始するか
            fileListStartVisible: true,

            // ******** 表示のカスタマイズ ********
            // Hogan.jsを使用したカスタマイズ
            compiledTemplates: {},
            // HTMLを使用したカスタマイズ
            rawTemplates: {},
        });
        diff2htmlUi.draw();
    }, [
        targetRef,
        props.oldTitle,
        props.newTitle,
        props.oldContent,
        props.newContent,
        props.oldHeader,
        props.newHeader,
        props.context,
        props.ignoreWhitespace,
        props.ignoreCase,
        props.newlineIsToken,
        props.outputFormat,
        props.diffStyle,
        props.diffMaxChanges,
        props.diffMaxLineLength,
        props.renderNothingWhenEmpty,
        props.diffTooBigMessage,
        props.colorScheme,
        props.fileContentToggle,
        props.synchronisedScroll,
        // props.discussions,
    ]);

    /*
     * 差分テーブルの横の行コメントの描画
     */
    useEffect(() => {
        if (!targetRef.current) {
            return;
        }

        // コメントコンポーネントの位置調整
        const diffTable = targetRef.current.querySelector('.d2h-file-wrapper');
        const diffColumns = diffTable?.querySelectorAll('.d2h-file-side-diff');
        [
            // 'old', // 右側のみ対象
            'new',
        ].forEach((target, index) => {
            const column = diffColumns?.[1]; // 右側のみ対象
            // const column = diffColumns?.[index];
            const rowNums = column?.querySelectorAll(
                '.d2h-code-side-linenumber.d2h-ins,' + // 追加行
                    '.d2h-code-side-linenumber.d2h-del,' + // 削除行
                    '.d2h-code-side-linenumber.d2h-cntx', // 変更のない行
            );

            // console.log(rowNums);

            rowNums?.forEach((row) => {
                if (row.textContent?.trim() !== '') {
                    // コメント表示に関する処理
                    const lineNum = parseInt(row.textContent?.trim() ?? '0');
                    const comment = props.discussions.find((discussion) => {
                        // TODO 旧側にコメントを表示するのはいったんなし
                        return discussion.line_no === lineNum && (discussion.target === target || target === 'new');
                    });
                    if (comment) {
                        const commentRef = commentRefs.current[comment.id as number];
                        if (commentRef) {
                            // 行番号の相対位置を取得
                            const diffTableRect = diffTable!.getBoundingClientRect();
                            const lineNumRect = row.getBoundingClientRect();
                            const relativeTop = lineNumRect.top - diffTableRect?.top;

                            commentRef.style.top = `${relativeTop}px`;
                        }
                    }

                    // 新規コメント追加に関する処理
                    const parentTr = row.closest('tr');
                    if (parentTr && props.canComment) {
                        parentTr.style.position = 'relative';
                        const addCommentButton = document.createElement('button');
                        addCommentButton.type = 'button'; // formの内側にあった場合formの内容がsubmitされてしまうのを防ぐ
                        addCommentButton.textContent = '+';
                        addCommentButton.className = 'add-comment-button';

                        addCommentButton.addEventListener('click', (e) => {
                            e.stopPropagation();
                            setCommentDialogState({
                                open: true,
                                lineNum: lineNum,
                            });
                        });

                        parentTr.appendChild(addCommentButton);
                    }
                }
            });
        });

        // コメントのpositionがabsoluteなので、カード内にすべて表示できるように外側の要素の高さを調整する
        adjustHeight();
    }, [targetRef, props.discussions, props.canComment, props.outputFormat]);

    const isShowDiscussion = (props.showDiscussion ?? true) && props.outputFormat === 'side-by-side';

    return (
        <>
            <Box sx={props.sx}>
                {props.outputFormat === 'side-by-side' && (
                    <Box
                        sx={{
                            width: isShowDiscussion ? '70%' : '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: '0',
                        }}
                    >
                        <Box sx={{ flexBasis: '50%', mb: '0', backgroundColor: '#FEE8E9' }}>
                            <Typography
                                variant="h6"
                                color="red"
                                sx={{
                                    pl: 1,
                                    fontWeight: 'bold',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                }}
                            >
                                編集前　
                                <Typography>
                                    {props.oldSnapshotName
                                        ? props.oldSnapshotName
                                        : props.oldUpdatedAt && formatDate(props.oldUpdatedAt)}
                                </Typography>
                            </Typography>
                        </Box>
                        <Box sx={{ flexBasis: '50%', mb: '0', backgroundColor: '#DDFFDD' }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography
                                    variant="h6"
                                    color="green"
                                    sx={{
                                        pl: 1,
                                        fontWeight: 'bold',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    編集後　
                                    <Typography>
                                        {props.newSnapshotName
                                            ? props.newSnapshotName
                                            : props.newUpdatedAt && formatDate(props.newUpdatedAt)}
                                    </Typography>
                                </Typography>
                                {props.canComment && (
                                    <Tooltip
                                        placement="top"
                                        title="行番号の左側をクリックすることで修正理由等を追加できます"
                                    >
                                        <Help />
                                    </Tooltip>
                                )}
                            </Stack>
                        </Box>
                    </Box>
                )}
                <Stack
                    ref={outerRef}
                    direction="row"
                    sx={{
                        width: '100%',
                        minHeight: outerHeight,
                    }}
                >
                    <Box ref={targetRef} sx={{ width: isShowDiscussion ? '70%' : '100%' }} />
                    {isShowDiscussion && (
                        // ここを高さをカードから取得するようにコンポーネント課
                        <Box sx={{ p: 2, position: 'relative', flexGrow: 1 }}>
                            {props.discussions.map((discussion, index) => (
                                <LineDiscussionCard
                                    discussionId={discussion.id}
                                    classification={discussion.classification}
                                    reason={discussion.reason}
                                    comment={discussion.comments[0]}
                                    replies={discussion.comments.slice(1)}
                                    onReply={onReplyFunc}
                                    onEditReply={onEditReply}
                                    onDeleteReply={onDeleteReply}
                                    onEditDiscussion={onEditDiscussion}
                                    onDeleteDiscussion={onDeleteDiscussion}
                                    onEditing={() => adjustHeight()}
                                    canReply={props.canReply}
                                    // onHeightChange={() => adjustHeight()}
                                    ref={(el) => {
                                        commentRefs.current[discussion.id as number] = el;
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        borderRadius: '0px 4px 4px 4px', // 左上は吹き出しをつけるので丸めない
                                        overflow: 'visible',
                                        left: '.75rem',
                                        right: '.75rem',
                                        ':hover': {
                                            zIndex: 999,
                                        },
                                        '&:before': {
                                            content: '""', // 擬似要素にはcontentプロパティが必要
                                            position: 'absolute',
                                            top: '2px',
                                            left: '-8px',
                                            width: '14px',
                                            height: '14px',
                                            backgroundColor: '#fff',
                                            borderRight: 'rgba(0, 0, 0, 0.12) 1px solid',
                                            borderBottom: 'rgba(0, 0, 0, 0.12) 1px solid',
                                            transform: 'rotate(-225deg)',
                                        },
                                    }}
                                    key={discussion.id}
                                />
                            ))}
                        </Box>
                    )}
                </Stack>
            </Box>

            <LineDiscussionCreateDialog
                open={commentDialogState.open}
                dialogTitle={`${commentDialogState.lineNum}行目に修正理由等を追加`}
                handleDialogClose={handleDialogClose}
                handleDialogCancel={handleDialogCancel}
                handleDialogAdd={handleDialogAdd}
            />
        </>
    );
});
