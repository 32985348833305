import { DataProvider, GetOneParams, HttpError, fetchUtils, withLifecycleCallbacks } from 'react-admin';
import { GetChildNodesParams, TreeDataProvider, addTreeMethodsBasedOnChildren } from '@react-admin/ra-tree';
import { Auth } from 'aws-amplify';
import { GetListParams } from 'ra-core/dist/cjs/types';
import simpleRestProvider from 'ra-data-simple-rest';
import { API_URL } from '../../config';


// export const dataProvider: DataProvider = jsonServerProvider(API_URL);

const apiUrl = API_URL;
// ユーザ識別のためCognito発行のJWTトークンをリクエストヘッダに付与
export const httpClient = async (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers();
    }

    options.headers.set('Accept', 'application/json');
    const session = await Auth.currentSession();
    const jwtToken = session.getAccessToken().getJwtToken();
    options.headers.set('Authorization', `Bearer ${jwtToken}`);

    try {
        const result = await fetchUtils.fetchJson(url, options);
        return result;
    } catch (error) {
        if (error instanceof HttpError && error.status === 500) {
            throw new HttpError('サーバー側でエラーが発生しました。システム管理者にお問い合わせください', error.status);
        }
        throw error;
    }
};

const dataProviderWithLifecycleCallbacks: DataProvider = withLifecycleCallbacks(simpleRestProvider(apiUrl, httpClient), [
    // DataProviderへの処理の直前か直後に任意の処理を入れることができる
    // {
    //     resource: 'documents',
    //     beforeSave: async (params: any, dataProvider: DataProvider) => {
    //
    //         if (params.data.files && params.data.files.rawFile instanceof File) {
    //             // const fileUrl = await s3Upload(params.data.files.rawFile);
    //             // params.data.files = fileUrl;
    //             console.log('pasted!!!!')
    //         }
    //         return params;
    //     }
    // }
]);

// 初期表示時に各ノードごとに子要素を取得してしまう（おそらく枝葉の判定も兼ねているのだろう）
// export const dataProviderWithTree: DataProvider & TreeDataProvider = addTreeMethodsBasedOnParentAndPosition(
//     dataProvider,
//     'parent_id',
//     'position',
//     false,
// );

const dataProviderWithTree: DataProvider & TreeDataProvider = {
    ...addTreeMethodsBasedOnChildren(dataProviderWithLifecycleCallbacks, 'children', 'is_root', true),
    // 今後カスタマイズを想定して残しておく
    getChildNodes: async (resource: string, params: GetChildNodesParams) => {
        // console.log(resource);
        // console.log(params);

        const url = `${apiUrl}/${resource}`;
        const options = {
            method: 'GET',
        };

        // デフォルトだと、クエリパラメータが必須なため、rootと同じ呼び出し方法で良い
        return httpClient(url, options).then(({ headers, json }) => {
            return {
                data: json,
                // @ts-ignore
                total: parseInt(headers.get('content-range').split('/').pop(), 10),
            };
        });
    },
    // addRootNode: async (
    //     resource,
    //     { data, meta }
    // ) => {
    //     console.log(resource);
    //     return dataProvider.create(resource, {
    //         data: {
    //             ...data,
    //             content_path: null,
    //             // [isRootField]: true,
    //             // [childrenField]: [],
    //         },
    //         meta,
    //     });
    // },
    addChildNode: async (resource, { parentId, data, meta }) => {
        // get Parent
        // const { data: parent } = await dataProvider.getOne(
        //     resource,
        //     {
        //         id: parentId,
        //         meta,
        //     }
        // );

        // create child
        const endpoint = parentId ? `${resource}/${encodeURIComponent(parentId)}` : resource;
        // console.log(endpoint);
        const newChild = await dataProvider.create(endpoint, {
            data: {
                ...data,
                // parent_path: parentId,
                // [isRootField]: false,
                // [childrenField]: [],
            },
            meta,
        });
        // update parent children
        // await dataProvider.update(resource, {
        //     id: parentId,
        //     data: {
        //         ...parent,
        //         children: parent["children"].concat(newChild.data.id),
        //     },
        //     previousData: parent,
        //     meta,
        // });

        return newChild;
    },
};

// @ts-ignore
const customDataProviderWithTree: DataProvider & TreeDataProvider = {
    ...dataProviderWithTree,
    getList: (resource: string, params: GetListParams) => {
        /**
         * 一覧取得用のメソッド。
         */
        const postResources = ['paragraphs', 'organizations', 'edits', 'merge_requests'];
        const isPost = postResources.includes(resource);

        if (!isPost) {
            return dataProviderWithTree.getList(resource, params);
        }

        /*
         *  一覧取得APIのうち検索系のAPIは、xxx/searchをエンドポイントにし、POSTにする。
         *
         *  GETだと、一般にクエリパラメータに複雑な条件を入れる必要があり、以下のようなデメリットがあるため。
         *   - 複雑な検索条件を表現しづらいこと
         *   - サーバー側の複雑性が増す
         *   - URLは一般に2083文字が上限であること
         *  また、GETでもリクエストボディに検索条件を渡すという手もあるが各ライブラリやインフラ側が対応していないことが多いため。
         *  （少なくともOpenAPI v3は仕様的に対応していない）
         */

        // filterの構築、toプロパティに指定されたfilterを優先で受け入れる
        // see: https://marmelab.com/react-admin/SolarLayout.html#solarmenuitem
        // console.log(`meta: ${JSON.stringify(params.meta)} filter: ${JSON.stringify(params.filter)}`);
        const filter = { ...params.meta, ...params.filter };

        const url = `${apiUrl}/${resource}/search`;
        const options = {
            method: 'POST',
            body: JSON.stringify({
                filter: filter,
                range: {
                    page: params.pagination.page, // 1始まり
                    per_page: params.pagination.perPage, // 1ページあたりの最大データ数
                },
                sort: params.sort,
            }),
        };
        return httpClient(url, options).then(({ headers, json }) => {
            return {
                data: json,
                // @ts-ignore
                total: parseInt(headers.get('content-range').split('/').pop(), 10),
            };
        });
    },

    getOne: (resource: string, params: GetOneParams) => {
        /**
         * 単体のリソース取得用メソッド。
         *
         * metaパラメータを与えることでクエリパラメータを付与する。
         */

        if (!params.meta) {
            // meta指定なしのときはデフォルトのままの挙動
            return dataProviderWithTree.getOne(resource, params);
        }

        const meta: { [key: string]: any } = params.meta;

        const query = Object.entries(meta)
            .filter(([, value]) => value != null) // nullまたはundefinedの値を除外
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');

        const url = `${apiUrl}/${resource}/${params.id}?${query}`;
        const options = {
            method: 'GET',
        };
        return httpClient(url, options).then(({ headers, json }) => {
            return {
                data: json,
            };
        });
    },
};

export const dataProvider = new Proxy(customDataProviderWithTree, {
    get: (target, name) => {
        return (resource: string, params: any) => {
            if (typeof name === 'symbol' || name === 'then') {
                return;
            }

            // prefixが 'edit/' のページはすべてのAPIエンドポイントのprefixに 'edit/' がつかないように除去する
            if (resource.startsWith('edit/')) {
                // 最初に見つかった "edit/" のみを置換
                resource = resource.replace('edit/', '');
            } else if (resource.startsWith('admin/')) {
                resource = resource.replace('admin/', '');
            }

            // TODO getRootNodes, getChildNodesをきちんと実装した方が良さそう
            // 2023-03-01 IoD合宿モブプロ成果
            // see: https://marmelab.com/react-admin/DataProviders.html#combining-data-providers
            const regex = /^documents\/(\d+)\/contents/;
            const match = resource.match(regex);
            if (match) {
                // console.log(params, name);
                let path_param = match[1]; // document_id
                if (params.parentId && name !== 'addChildNode') {
                    // addChildNodeのときだけ documents/{document_id}/contents/{path} の形式のため
                    path_param = `${path_param}/${params.parentId}`;
                }

                // ページによって見出しのみを取得する場合、見出しパラグラフ両方を取得する場合がある
                const api_prefix = resource.includes('contents_view') ? 'headings' : 'contents';
                return customDataProviderWithTree[name](`documents/${path_param}/${api_prefix}`, params);
            } else {
                return customDataProviderWithTree[name](resource, params);
            }
        };
    },
});
