import { FC, useCallback } from 'react';
import { useGetOne, useRecordContext } from 'react-admin';
import { EditNode, ShowNode } from '@react-admin/ra-tree';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ApplyFontSize, FontSizeProvider } from '../../../components/functional/FontSizeProvider';
import { FontSizeSelector } from '../../../components/ui/inputs/FontSizeSelector';
import { useSidebarWidth } from '../../../hooks/useSidebarWidth';
import { ContentNode } from '../types/contentNode';
import { ParagraphsShow } from './ParagraphsShow';

// a Create view for a tree uses <CreateNode> instead of the standard <Create>

const ContentShowForm: FC = (props: any) => {
    const location = useLocation();
    // const record = useRecordContext<ContentNode>();
    // console.log(record)

    const match = matchPath('/documents/:document_id/contents_view/*', location.pathname);

    const document_id = match?.params.document_id;
    const content_path = match?.params['*'];
    console.log(document_id, content_path);

    const { data: detail_content, error } = useGetOne(`documents/${document_id}/contents`, {
        id: content_path,
    });

    if (error || !detail_content || !detail_content.first_paragraph_in_preorder) {
        return null;
    }

    return (
        <ParagraphsShow
            document_id={detail_content.document_id}
            paragraph_content_node_id={detail_content.first_paragraph_in_preorder}
        />
    );
};

// an Edit view for a tree uses <EditNode> instead of the standard <Edit>
export const ContentShow: FC = () => {
    return (
        <ShowNode
            actions={false}
            sx={{
                minWidth: '500px',
            }}
            title="目次（現行版）" // デフォルトだとsuffixにIDが付与されるため
            // mutationMode="optimistic"
        >
            <ApplyFontSize>
                <ContentShowForm />
            </ApplyFontSize>
        </ShowNode>
    );
};
