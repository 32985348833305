import { useMemo, useState } from 'react';
import { useLogoutIfAccessDenied } from 'react-admin';
import { UseQueryOptions, useQuery } from 'react-query';
import { httpClient } from '../components/functional/dataProvider';
import { API_URL } from '../config';


interface Param {
    documentId: number;
    contentPath: string;
}

export const useContentPermissions = <Permissions = any, Error = any>(
    params: Param,
    queryParams: UseQueryOptions<Permissions, Error> = {
        staleTime: 5 * 60 * 1000,
    }
) => {
    const logoutIfAccessDenied = useLogoutIfAccessDenied();
    const [creatable, setCreatable] = useState<boolean>(false);
    const [readable, setReadable] = useState<boolean>(false);
    const [updatable, setUpdatable] = useState<boolean>(false);
    const [deletable, setDeletable] = useState<boolean>(false);

    const result = useQuery(
        ['getContePermissions', params],
        async () => {
            const contentPermissionsEndpoint = `${API_URL}/documents/${params.documentId}/contents/${encodeURIComponent(params.contentPath)}/permissions`;
            const response = await httpClient(contentPermissionsEndpoint);
            const data = response.json;

            const contentPermissions = data.permissions;

            let permission: string[] = []
            if ('paragraphs' in contentPermissions) {
                permission = contentPermissions.paragraphs;
            } else if ('headings' in contentPermissions) {
                permission = contentPermissions.headings;
            } else {
                throw Error(`Invalid content permissions: ${contentPermissions}`);
            }

            setCreatable(permission.includes('create'))
            setReadable(permission.includes('read'))
            setUpdatable(permission.includes('update'))
            setDeletable(permission.includes('delete'))

            return data.permissions;
        },
        {
            onError: (error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error(error);
                }
                logoutIfAccessDenied(error);
            },
            // ...queryParams,
        }
    );

    return useMemo(
        () => ({
            creatable,
            readable,
            updatable,
            deletable,
            permissions: result.data,
            isLoading: result.isLoading,
            error: result.error,
            refetch: result.refetch,
        }),
        [creatable, readable, updatable, deletable, result]
    );
};
