import {FunctionField, Labeled, useRecordContext} from 'react-admin';


import { Chip } from '@mui/material';
import { UserInfo } from '../../../features/users/types/userInfo';

interface UserFieldProps {
    user: UserInfo;
}

export const UserChip = ({ user }: UserFieldProps) => {
    return (
        <Chip
            // key={user.id}
            label={`【${user.affiliation}】${user.name}`}
            variant="outlined"
            sx={{ mr: 1 }}
        />
    );
};
