import { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

export const NowLoading: FC = () => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
        </Box>
    );
};
