import * as React from 'react';
import { Create, SimpleForm, TextInput, TopToolbar } from 'react-admin';
import { BackButton } from '../../../components/ui/button/BackButton';
import { DOCUMENT_ITEM_TITLE_MAX_LENGTH } from '../types/document';

export const DocumentCreate = () => (
    <Create
        redirect="list"
        actions={
            <TopToolbar sx={{ justifyContent: 'flex-start' }}>
                <BackButton />
            </TopToolbar>
        }
    >
        <SimpleForm>
            <TextInput
                source="title"
                label="タイトル"
                placeholder="タイトルを入力"
                fullWidth
                required
                inputProps={{
                    maxLength: DOCUMENT_ITEM_TITLE_MAX_LENGTH,
                }}
            />
        </SimpleForm>
    </Create>
);
