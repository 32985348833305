import * as React from 'react';
import { useRef } from 'react';
import { RecordContextProvider, useGetList, useRedirect, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, IconButton, List, ListItem, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IodMarkdownField from '../../../components/ui/fields/IodMarkdownField';
import { FavoriteButton } from '../../favorites/components/FavoriteButton';
import { Paragraph } from '../types/paragraph';
import { ListHeadingsField } from './ListHeadingsField';
import OrganizationsField from './OrganizationsField';
import { PhaseField } from './PhaseField';
import {ParagraphEditButton} from './ParagraphEditButton';


// import Basket from '../orders/Basket';
// import { Customer, Invoice } from '../types';

interface Props {
    paragraph: Paragraph;
    version?: string;
}

export const ParagraphListAfter = (props: Props) => {
    const sequence = props?.paragraph?.sequence;
    const perPage = 10;

    const { data, total, isLoading, error } = useGetList(
        'paragraphs',
        {
            pagination: { page: 1, perPage },
            sort: {
                field: 'sequence',
                order: 'ASC',
            },
            filter: {
                document_id: props.paragraph.document_id,
                sequence_from: sequence + 1,
                version: props.version ? props.version : undefined
            },
        },
        { enabled: !!sequence },
    );

    const navigate = useNavigate();
    const observerElem = useRef(null);

    const translate = useTranslate();
    const redirect = useRedirect();

    if (!sequence) {
        return null;
    }
    if (isLoading || !data) {
        return <CircularProgress />;
        // return <Loading/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }


    return (
        <
            // sx={{
            // height: '300px',
            // overflowY: 'auto',
            // }}
        >
            <List
                sx={
                    {
                        // margin: '1em',
                        // width: '98%'
                        // flexFlow: 'column',
                    }
                }
                dense
            >
                {data?.map((p: Paragraph, index) => {
                    return (
                        <RecordContextProvider value={p} key={p.id}>
                            <ListItem disablePadding alignItems="flex-start">
                                <Box
                                    sx={{
                                        // marginLeft: '5px',
                                        // marginRight: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        mb: '2rem',
                                    }}
                                >
                                    {p.headings.length > 0 && (
                                        <Stack direction={'row'} alignItems={'center'} gap={[0, 2]} sx={{mb: 2.5}}>
                                            <ListHeadingsField
                                                current_paragraph={p}
                                                pre_paragraph={index > 0 ? data[index - 1] : props.paragraph}
                                            />

                                            <Box>
                                                <FavoriteButton content_id={p.content_id} />
                                            </Box>
                                            {props.version === 'editing' && (
                                                <Box>
                                                    <ParagraphEditButton paragraph={p} />
                                                </Box>
                                            )}
                                        </Stack>
                                    )}

                                    <PhaseField />

                                    <Stack direction="row" spacing={1}>
                                        <OrganizationsField type="responsible_organization" />
                                        <OrganizationsField type="co_responsible_organization" />
                                    </Stack>

                                    <IodMarkdownField source="body" className="font-size-changeable" />
                                </Box>
                            </ListItem>
                            {/*<Divider></Divider>*/}
                        </RecordContextProvider>
                    );
                })}
            </List>
        </>
    );
};
