import * as React from 'react';
import { FC } from 'react';
import { Breadcrumbs, Tooltip, Typography } from '@mui/material';
import { Heading, Paragraph } from '../types/paragraph';

interface Props {
    /**
     * 表示対象のパラグラフ
     */
    current_paragraph: Paragraph;
    /**
     * 一つ前のパラグラフ
     */
    pre_paragraph?: Paragraph;
    /**
     * 見出しについて、直接の親見出しのみを表示するならtrue。
     */
    show_parent_heading_only?: boolean;
}

export const ListHeadingsField: FC<Props> = (props) => {
    const pre_headings: Array<Heading> =
        props?.pre_paragraph && props?.pre_paragraph.headings ? props?.pre_paragraph.headings : [];
    const current_headings: Array<Heading> = props.current_paragraph.headings;

    // console.log("pre:" + pre_headings.map(h => h.body).join('/'));
    // console.log("current:" + current_headings.map(h => h.body).join('/'));

    if (!current_headings || current_headings.length == 0) {
        return null;
    }

    const pre_heading_ids = pre_headings.map((h) => h.content_node_id);
    const current_heading_ids = current_headings.map((h) => h.content_node_id);
    const diff_ids = current_heading_ids.filter((id) => !pre_heading_ids.includes(id));

    let headingsToShow = null;
    if (props.show_parent_heading_only) {
        // 直近の親のみ表示
        headingsToShow = current_headings.slice(-1);
    } else {
        // 差分を表示
        headingsToShow = current_headings.filter((heading) => {
            return diff_ids.includes(heading.content_node_id);
        });
    }

    if (!headingsToShow || headingsToShow.length <= 0) {
        return null;
    }

    return (
        <Tooltip placement="top-start" title={current_headings.map((h) => h.body).join(' / ')}>
            {/*<Divider></Divider>*/}
            <Breadcrumbs aria-label="breadcrumb" className="font-size-changeable large-font">
                {headingsToShow.map((heading) => {
                    return (
                        <Typography key={heading.content_node_id} variant="h6">
                            {heading.body}
                        </Typography>
                    );
                })}
            </Breadcrumbs>
            {/*<Divider></Divider>*/}
        </Tooltip>
    );
};
