import { FC } from 'react';
import { FunctionField, FunctionFieldProps } from 'react-admin';
import { ReviewStatusChip } from '../../../components/ReviewStatusChip';
import { MergeRequestInfo } from '../../../types/mergeRequest';

interface MergeStatusFieldProps extends Omit<FunctionFieldProps, 'render'> {}

export const MergeStatusField: FC<MergeStatusFieldProps> = (props) => {
    return (
        <FunctionField
            {...props}
            render={(record: MergeRequestInfo) => (
                <ReviewStatusChip isMergeRequested={true} reviewStats={record.review_stats} />
            )}
        />
    );
};
