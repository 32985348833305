import React, { FC } from 'react';
import { SimpleForm, usePermissions, useRecordContext } from 'react-admin';
import { EditButton } from '../../../components/ui/button/EditButton';
import IodMarkdownField from '../../../components/ui/fields/IodMarkdownField';
import { IodToolbar } from '../../../components/ui/layout/IodToolbar';
import { FormInformation } from '../types/information';

interface Props {
    onClickEdit: () => void;
}

export const InformationShowForm: FC<Props> = (props) => {
    const record = useRecordContext<FormInformation>();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) {
        return null;
        // return <CircularProgress/>;
    }

    const hasSavePolicy = permissions.information.includes('update');

    const handleClickEdit = (e: any) => {
        e.preventDefault(); // 本来のEditButtonの機能を止める。
        props.onClickEdit();
    };

    return (
        <SimpleForm
            toolbar={
                hasSavePolicy ? (
                    <IodToolbar>
                        <EditButton onClick={handleClickEdit} />
                    </IodToolbar>
                ) : (
                    <></>
                )
            }
        >
            {record.body ? (
                <IodMarkdownField
                    source="body"
                    sx={{
                        width: '100%',
                    }}
                />
            ) : (
                <>管理者からのお知らせはありません。</>
            )}
        </SimpleForm>
    );
};
