import { ParagraphFilterBuilder } from './ParagraphFilterBuilder';

export const editingParagraphFilters = new ParagraphFilterBuilder()
    .addDocumentsFilter()
    .addTooltipTextInput()
    .addNumberListInput()
    .addPhaseSelectInput()
    .addHierarchicalOrgDialogs()
    .addNoteTextInput()
    .addAffiliationTextInput()
    .addFavoriteListFilter()
    .build();

export const snapshotParagraphFilters = new ParagraphFilterBuilder()
    .addDocumentsFilter()
    .addTooltipTextInput()
    .addNumberListInput()
    .addPhaseSelectInput()
    .addHierarchicalOrgDialogs()
    .addFavoriteListFilter()
    .build();
