import React, { useEffect, useState } from 'react';
import {
    Identifier,
    useCreate,
    useGetIdentity,
    useGetOne,
    useNotify,
    usePermissions,
    useRecordContext,
    useRefresh,
} from 'react-admin';
import { Alert, Button, CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import ReplyIcon from '@mui/icons-material/Reply';
import SendIcon from '@mui/icons-material/Send';
import { SxProps } from '@mui/system';
import { ErrorResponse } from '../../../../../types/errorResponse';
import { UserInfo } from '../../../../users/types/userInfo';
import { CommentDialog } from '../../../components/CommentDialog';
import { EditInfo } from '../../../types/editInfo';
import { MergeRequestInfo } from '../../../types/mergeRequest';

type MergeRequestOperation = 'confirm' | 'approve' | 'reject' | 'withdraw' | 'reopen';

interface DialogState {
    type?: MergeRequestOperation;
    open: boolean;
}

interface Props {
    allowedOptions?: MergeRequestOperation[];
    sx?: SxProps<Theme>;
}

export const MergeRequestOperationButtons = (props: Props) => {
    const record = useRecordContext<MergeRequestInfo>();
    const { data: userData, isLoading: isLoadingUser } = useGetIdentity();
    const { permissions, isLoading: isLoadingPermissions } = usePermissions();

    const refresh = useRefresh();
    const notify = useNotify();

    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [dialogState, setDialogState] = useState<DialogState>({ open: false });
    const [updaterIds, setUpdaterIds] = useState<Identifier[]>([]);

    const [create, { isLoading: isPosting }] = useCreate();

    const editsResponse = useGetOne(
        `edits`,
        { id: record.content?.original_content_id },
        { enabled: !!record.content }, // Truthyな場合のみ実行
    );

    useEffect(() => {
        if (!editsResponse?.data) {
            return;
        }

        // 更新者（編集に関わった人）のidリストを用意する
        const editInfo: EditInfo = editsResponse.data;
        const ids: Identifier[] = editInfo.updated_by?.map((user: UserInfo) => user.id);
        setUpdaterIds(ids || []);
    }, [editsResponse?.data]);

    // 許可されたオプションが未指定のときは全て表示
    const allowedOptions = props.allowedOptions || ['confirm', 'approve', 'reject', 'withdraw', 'reopen'];

    if (isLoadingPermissions || isLoadingUser) {
        return <CircularProgress />;
    }

    const getText = (dialogType?: MergeRequestOperation) => {
        switch (dialogType) {
            case 'confirm':
                return '確認';
            case 'approve':
                return '承認';
            case 'reject':
                return '差戻';
            case 'withdraw':
                return '引戻';
            case 'reopen':
                return '再依頼';
            default:
                return '';
        }
    };

    const hasApprovePolicy = permissions.merge_requests.includes('approve');

    // 確認者に自分がいるかどうかを確認
    const isConfirmer = record.reviews.some((review) => {
        if (!review.reviewer_id) {
            return false;
        }
        return review.reviewer_id === userData?.id;
    });

    // 承認依頼のステータスが差戻または引戻
    const isRejectedOrWithdrawn =
        record.review_stats.status === 'rejected' || record.review_stats.status === 'withdrawn';

    const isFinalApproved = record.review_stats.status === 'secondary_approved';
    const isFixed = record.review_stats.status === 'fixed';
    const handleDialogClose = () => {
        setDialogState({
            ...dialogState,
            open: false,
        });
    };

    const handleDialogSubmit = async (comment: string) => {
        if (!dialogState.type) {
            throw new Error('Dialog type is not defined.');
        }

        await requestApi(dialogState.type, comment);
        handleDialogClose();
    };

    // ダイアログの送信リクエスト共通処理
    const requestApi = async (dialogType: MergeRequestOperation, comment: string = '') => {
        setButtonDisabled(true);
        await create(
            `merge_requests/${record.id}/${dialogType}`,
            {
                data: {
                    comment: comment,
                },
            },
            {
                onSuccess: () => {
                    // 送信成功時の処理
                    // https://marmelab.com/react-admin/useNotify.html#autohideduration
                    //承認したことがわかるように表示時間を追加
                    notify(
                        <Alert severity="success" sx={{ fontSize: 20 }}>
                            {getText(dialogType)}が完了しました。
                        </Alert>,
                        { autoHideDuration: 10000 },
                    );
                    refresh();
                    setButtonDisabled(false);
                },
                onError: (error: ErrorResponse | any) => {
                    notify(
                        <Alert severity="error" sx={{ fontSize: 20 }}>
                            {getText(dialogType)}に失敗しました。
                            <br />
                            {error.status && error.message}
                        </Alert>,
                        { autoHideDuration: 10000 },
                    );
                    refresh();
                    setButtonDisabled(false);
                },
            },
        );
    };

    // console.log('★★★★★★★★★★★★★★★★');
    // console.log(JSON.stringify(record));

    return (
        <>
            {allowedOptions.includes('confirm') && isConfirmer && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setDialogState({
                            type: 'confirm',
                            open: true,
                        });
                    }}
                    sx={props.sx || { m: 1 }}
                    startIcon={<CheckIcon />}
                    disabled={isButtonDisabled || isRejectedOrWithdrawn || isFixed}
                >
                    確認
                </Button>
            )}
            {allowedOptions.includes('approve') && hasApprovePolicy && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setDialogState({
                            type: 'approve',
                            open: true,
                        });
                    }}
                    sx={props.sx || { m: 1 }}
                    startIcon={<CheckIcon />}
                    disabled={isButtonDisabled || isRejectedOrWithdrawn || isFixed}
                >
                    承認
                </Button>
            )}
            {/* 自分がレビュワーにいるときにボタン表示 */}
            {allowedOptions.includes('reject') && (isConfirmer || hasApprovePolicy) && (
                <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                        setDialogState({
                            type: 'reject',
                            open: true,
                        });
                    }}
                    sx={props.sx || { m: 1 }}
                    startIcon={<ReplyIcon />}
                    disabled={isButtonDisabled || isRejectedOrWithdrawn || isFixed}
                >
                    差戻
                </Button>
            )}
            {allowedOptions.includes('withdraw') && userData && updaterIds.includes(userData.id) && (
                <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                        setDialogState({
                            type: 'withdraw',
                            open: true,
                        });
                    }}
                    sx={props.sx || { m: 1 }}
                    startIcon={<ReplyIcon />}
                    disabled={isButtonDisabled || isRejectedOrWithdrawn || isFixed}
                >
                    引戻
                </Button>
            )}

            {allowedOptions.includes('reopen') && isRejectedOrWithdrawn && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setDialogState({
                            type: 'reopen',
                            open: true,
                        });
                    }}
                    sx={props.sx || { m: 1 }}
                    startIcon={<SendIcon />}
                    disabled={isButtonDisabled || isFixed}
                >
                    再依頼
                </Button>
            )}
            <CommentDialog
                open={dialogState.open}
                dialogTitle={`「${getText(dialogState?.type)}」の理由やコメント`}
                handleDialogClose={handleDialogClose}
                handleDialogSubmit={handleDialogSubmit}
            />
        </>
    );
};
