import { FC, forwardRef, memo, useState } from 'react';
import { Button, Card, CardActions, CardProps, Collapse, List, ListItem, TextField } from '@mui/material';
import { Identifier } from 'ra-core';
import { Comment, CommentAdd, CommentUpdate } from '../../../types/comment';
import { LineDiscussionUpdate } from '../../../types/discussion/content-discussion/lineDiscussion';
import { DiscussionReplyContent } from '../DiscussionReplyContent';
import { LineDiscussionCommentContent } from './LineDiscussionCommentContent';

export interface LineDiscussionCardProps extends CardProps {
    discussionId: Identifier;
    classification: string;
    reason: string;
    comment: Comment;
    replies?: Comment[];
    onReply: (commentAdd: CommentAdd) => void;
    onEditReply: (commentId: Identifier, commentUpdate: CommentUpdate) => void;
    onDeleteReply: (commendId: Identifier) => void;
    onEditDiscussion: (discussionId: Identifier, discussionUpdate: LineDiscussionUpdate) => void;
    onDeleteDiscussion: (discussionId: Identifier) => void;
    onEditing?: (isEditing: boolean) => void; // 編集状態の変更を伝えるハンドラ
    // onHeightChange?: () => void;
    canReply?: boolean;
}

export const LineDiscussionCard: FC<LineDiscussionCardProps> = memo(
    forwardRef(
        (
            {
                discussionId,
                classification,
                reason,
                comment,
                replies,
                onEditDiscussion,
                onDeleteDiscussion,
                onReply,
                onEditReply,
                onDeleteReply,
                onEditing,
                canReply = true, // デフォルトはリプライ可能
                ...props
            },
            ref,
        ) => {
            const [replyText, setReplyText] = useState('');
            const [open, setOpen] = useState(true);

            const handleReplyClick = () => {
                onReply({
                    discussion_id: discussionId,
                    body: replyText,
                });
                setReplyText(''); // コメントが追加された後にフィールドをクリア
            };

            const handleCollapseChange = () => {
                setOpen(!open);
                // タイミング問題があるので一旦見送り
                // setTimeout(() => {
                //     if (onHeightChange) {
                //         onHeightChange();
                //     }
                // }, 1000);
            };

            return (
                <Card {...props} ref={ref} variant="outlined">
                    <LineDiscussionCommentContent
                        comment={comment}
                        classification={classification}
                        reason={reason}
                        onEditDiscussion={(data) => {
                            onEditDiscussion(discussionId, data);
                        }}
                        onDeleteDiscussion={() => onDeleteDiscussion(discussionId)}
                        onEditing={onEditing}
                    />

                    {replies && replies.length > 0 && (
                        <>
                            <CardActions sx={{ py: 1, px: 2, gap: 2 }}>
                                <Button size="small" onClick={handleCollapseChange}>
                                    {open ? 'リプライを折りたたむ' : `リプライを表示 (${replies.length})`}
                                </Button>
                            </CardActions>
                            <Collapse in={open}>
                                <List>
                                    {replies.map((reply, index) => (
                                        <ListItem key={index} style={{ paddingLeft: '20px' }}>
                                            <Card sx={{ width: '100%' }} variant="outlined">
                                                <DiscussionReplyContent
                                                    comment={reply}
                                                    onEditReply={(data) => {
                                                        onEditReply(reply.id, data);
                                                    }}
                                                    onDeleteReply={() => onDeleteReply(reply.id)}
                                                    onEditing={onEditing}
                                                />
                                            </Card>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </>
                    )}

                    {/*{replies?.map((reply) => <CommentContent key={reply.id} comment={reply} />)}*/}
                    {canReply && (
                        <CardActions sx={{ py: 1, px: 2, gap: 1 }}>
                            <TextField
                                variant="outlined"
                                sx={{ flexGrow: 1, my: 0 }}
                                value={replyText}
                                multiline
                                maxRows={6}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setReplyText(event.target.value);
                                }}
                            />
                            <Button variant="contained" disabled={!replyText} onClick={handleReplyClick}>
                                返信
                            </Button>
                        </CardActions>
                    )}

                </Card>
            );
        },
    ),
);
