import React, { useMemo } from 'react';
import { useSidebarState } from 'react-admin';
import { useTheme } from '@mui/material/styles';
import { IodThemeOptions } from '../components/functional/themes';

/**
 * パス文字列をReactAdmin Tree用の配列にする
 *
 * 利用例：
 * const path = "1 第３部　災害復興計画/第１章　復興の基本的考え方/２　東京都震災復興マニュアル/（１）復興プロセス編の主な内容";
 * const paths = usePathArray(path);
 */
export const usePathArray = (path: string): string[] => {
    return React.useMemo(() => {
        if (!path) {
            return [];
        }

        const splitPath = path.split('/');
        const paths: string[] = [];

        for (let i = 0; i < splitPath.length; i++) {
            const combinedPath = i === 0 ? splitPath[i] : paths[i - 1] + '/' + splitPath[i];
            paths.push(combinedPath);
        }

        return paths;
    }, [path]);
};
