import React, { useState } from 'react';
import {
    Create,
    Identifier,
    SaveButton,
    SimpleForm,
    TextInput,
    TopToolbar,
    useCreate,
    useGetIdentity,
    useGetList,
    useNotify,
    usePermissions,
} from 'react-admin';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, AlertTitle, Card, CardHeader, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { BackButton } from '../../../../../components/ui/button/BackButton';
import { IodToolbar } from '../../../../../components/ui/layout/IodToolbar';
import { useSidebarWidth } from '../../../../../hooks/useSidebarWidth';
import { DiffsRequestComponent } from '../../../components/DiffsRequestComponent';
import { ReviewersArrayInput } from '../../../components/ReviewersArrayInput';
import { DiffInfo } from '../../../types/diffInfo';
import { DESCRIPTION_MAX_LENGTH, MergeRequestCreatePayLoad, TITLE_MAX_LENGTH } from '../../../types/mergeRequest';

const scrollBarWidth = 20;

export const MergeRequestsCreate = () => {
    /**
     * 依頼を作成するコンポーネント（一括依頼に対応）
     */
    const { isLoading, permissions } = usePermissions();
    const location = useLocation();
    const sidebarWidth = useSidebarWidth();

    const notify = useNotify();
    const navigate = useNavigate();
    const [create] = useCreate<MergeRequestCreatePayLoad>();
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    // 遷移パラーメータからオリジナルのコンテンツIDを準備する
    const ids_str = new URLSearchParams(location.search).get('ids');
    if (!ids_str) {
        return null;
    }
    const original_content_ids = ids_str.split(',').map(Number);
    const requestClick = async (data: FieldValues) => {

        setButtonDisabled(true);
        // Request Parameter
        const sendData: MergeRequestCreatePayLoad = {
            title: data.title,
            description: data.description,
            original_content_ids: original_content_ids,
            reviewer_ids: data.reviewer_ids,
        };

        await create(
            'merge_requests',
            { data: sendData },
            {
                onSuccess: () => {
                    notify(<Alert severity="info">承認依頼が完了しました</Alert>);
                    navigate(`/edits`); // リダイレクト先
                    setButtonDisabled(false);
                },
                onError: (error: any) => {
                    notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>);
                    setButtonDisabled(false);
                },
            },
        );
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    // // 権限がある場合のみ各ボタンを有効化する
    const hasCreatePolicy = permissions.paragraphs.includes('create');
    const hasEditPolicy = permissions.paragraphs.includes('update');

    return (
        <>
            <TopToolbar sx={{justifyContent: 'flex-start'}}>
                <BackButton />
            </TopToolbar>

            <Card sx={{ mb: 2 }}>
                <CardHeader title="承認依頼" />
                <Create resource="merge_requests" title={<>承認依頼</>} >
                    <SimpleForm
                        toolbar={
                            <IodToolbar>
                                <SaveButton variant="contained" type="submit" label='承認依頼' icon={<SendIcon />} disabled={isButtonDisabled}/>
                            </IodToolbar>
                        }
                        onSubmit={requestClick}
                    >
                        <MergeRequestCreateAlert original_content_ids={original_content_ids} />

                        <TextInput
                            required
                            source="title"
                            label="依頼タイトル"
                            inputProps={{
                                maxLength: TITLE_MAX_LENGTH,
                                placeholder: `編集内容の簡潔な説明を${TITLE_MAX_LENGTH}文字以内で記入ください`,
                            }}
                            fullWidth
                            multiline
                            maxRows={5}
                        />
                        <TextInput
                            source="description"
                            label="補足事項"
                            inputProps={{
                                maxLength: DESCRIPTION_MAX_LENGTH,
                                placeholder:
                                    '確認者（同僚や上長）・承認者（総合防災部の担当）への申し送り事項を記入ください',
                            }}
                            fullWidth
                            multiline
                            maxRows={5}
                        />
                        <ReviewersArrayInput />
                    </SimpleForm>
                </Create>
            </Card>

            <Card style={{ margin: '1em 0' }}>
                <CardHeader title="編集内容" />

                <DiffsRequestComponent
                    original_content_ids={original_content_ids}
                    showHistory={true}
                    canComment={true}
                    sx={{
                        width: `calc(100vw - ${sidebarWidth + scrollBarWidth + 16}px)`,
                    }}
                />
            </Card>
        </>
    );
};


interface MergeRequestCreateAlertProps {
    original_content_ids: Array<Identifier>;
}
const MergeRequestCreateAlert = (props: MergeRequestCreateAlertProps) => {
    const {data: userData} = useGetIdentity();
    const {data: diffs, isLoading} = useGetList<DiffInfo>('documents/diffs', {
        filter: {
            ids: props.original_content_ids,
        },
    });

    if (isLoading || !diffs || !userData) {
        return null;
    }

    const alerts = [];
    for (const diff of diffs) {
        if (diff.new_updated_by.id !== userData.id) {
            alerts.push(`「${diff.new_path}」は、あなたの編集後に別の方が更新しています。「編集履歴を表示」から編集内容をご確認ください。`)
        }
    }

    if (!alerts.length) {
        return null;
    }

    return (
        <Alert severity="warning" sx={{mb: 2}}>
            <AlertTitle>以下{alerts.length}件のメッセージをご確認ください</AlertTitle>
            <ul>
                {alerts.map((alert, index) => {
                    return (
                        <li key={index}>{alert}</li>
                    )
                })}
            </ul>
        </Alert>
    )
}
