import { memo } from 'react';
import { FunctionField, FunctionFieldProps } from 'react-admin';
import { UserInfo } from '../../../features/users/types/userInfo';
import { UserChip } from '../elements/UserChip';

interface UsersFieldProps extends Omit<FunctionFieldProps, 'render'> {}

export const UsersField = memo((props: UsersFieldProps) => {
    const {source, ...restProps} = props;
    
    if (!source) {
        return null;
    }
    
    return (
        <FunctionField
            render={(record: any) => {
                const users: UserInfo[] = (record[source]) as UserInfo[];
                if (!users) {
                    return null;
                }
                return (
                    <>
                        {users.map((user: UserInfo) => (
                            <UserChip key={user.id} user={user} />
                        ))}
                    </>
                );
            }}
            {...restProps}
        />
    );
});
