import * as JsDiff from 'diff';
import { Document as DocxDocument, Paragraph as DocxParagraph, Table as DocxTable, PageOrientation } from 'docx';
import { LineDiscussion } from '../../types/discussion/content-discussion/lineDiscussion';
import { DiffCommentManager } from './diffCommentManager';
import { DiffToDocxRowsConverter, DocxInfo } from './diffToDocxRowsConverter';
import { DocxXmlFactory } from './docxXmlFactory';
import { ParagraphDiff, ParagraphDiffLine } from './paragraphDiffProcessor';
import { PAGE_HEIGHT, PAGE_WIDTH } from './utils/size';

interface DiffToDocxDocumentGeneratorParam {
    paragraphDiffList: ParagraphDiff[];
    discussionsList: LineDiscussion[][];
}

class DiffToDocxDocumentGenerator {
    private diffCommentManager: DiffCommentManager;
    private diffToDocxRowsConverter: DiffToDocxRowsConverter;
    private docxXmlFactory: DocxXmlFactory;

    constructor() {
        this.diffCommentManager = new DiffCommentManager();
        this.diffToDocxRowsConverter = new DiffToDocxRowsConverter();
        this.docxXmlFactory = new DocxXmlFactory();
    }

    public generate = async (param: DiffToDocxDocumentGeneratorParam): Promise<DocxDocument> => {
        const { paragraphDiffList, discussionsList } = param;

        // 差分情報に対して、行コメント（修正理由）を付与する
        const reasonAddedParagraphDiffList = this.diffCommentManager.addCommentsToDiffs(
            paragraphDiffList,
            discussionsList,
        );

        // 行ごとの差分オブジェクトを、Word出力用オブジェクトに対応付けする
        const docxInfoList: DocxInfo[] = this.diffToDocxRowsConverter.convert({
            paragraphDiffList: reasonAddedParagraphDiffList,
        });

        // docxライブラリを用いてWord出力用の形式（XML）に変換する
        // パラグラフ差分を三列テーブル［新｜旧｜修正理由］で表現
        const docxThreeColumnTables: DocxTable[] = await this.docxXmlFactory.convertDocxXml({
            docxInfoList: docxInfoList,
        });

        // Wordドキュメントを生成する
        const docxXmlObj: DocxDocument = new DocxDocument({
            sections: docxThreeColumnTables.map((table: DocxTable) => ({
                properties: {
                    page: {
                        size: {
                            width: PAGE_WIDTH,
                            height: PAGE_HEIGHT,
                            orientation: PageOrientation.LANDSCAPE, // 横向きページ指定
                        },
                    },
                },
                children: [table], // 三列テーブルを描画
            })),
        });

        return docxXmlObj;
    };
}
export { DiffToDocxDocumentGenerator };
