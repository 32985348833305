import React, { useCallback } from 'react';
import { useCreate, useEditContext, useGetList, useNotify, useRefresh, useTranslate } from 'react-admin';
import {
    Alert,
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { UserProfileIcon } from '../../../../../components/ui/elements/UserProfileIcon';
import { ErrorResponse } from '../../../../../types/errorResponse';
import { formatDate } from '../../../../../utils/dateUtil';
import { DiscussionCreateListItem } from '../../../components/discussion/DiscussionCreateListItem';
import { Discussion, DiscussionAdd } from '../../../types/discussion/discussion';
import { MergeRequestInfo } from '../../../types/mergeRequest';
import { ActivityType, MergeRequestActivity } from '../types/mergeRequestActivity';
import { MergeRequestActivityListItemContent } from './MergeRequestActivityListItemContent';

export const MergeRequestActivityCard = () => {
    const { record, isLoading } = useEditContext<MergeRequestInfo>();

    if (isLoading || !record) {
        return <CircularProgress />;
    }

    return (
        <Card sx={{ mb: 2 }}>
            <CardHeader title="アクティビティ" action={<></>} />
            {/* maxHeightは仮の値です。 UIのブラッシュアップは別途*/}
            {/*<CardContent sx={{ maxHeight: '395px',  overflowY: 'auto'}}>*/}
            <CardContent>
                <MergeRequestActivityList />
            </CardContent>
        </Card>
    );
};

const MergeRequestActivityList = () => {
    const { record } = useEditContext<MergeRequestInfo>();

    const merge_request_id = record?.id;
    const resource = `merge_requests/${merge_request_id}/activities`;

    const { data: activities, isLoading } = useGetList<MergeRequestActivity>(resource);
    const [create, { isLoading: isLoadingCreate }] = useCreate<DiscussionAdd | any>();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleCreateDiscussion = useCallback(
        (discussionAdd: DiscussionAdd) => {
            const apiPath = `merge_requests/${merge_request_id}/discussions`;
            create(
                apiPath,
                {
                    data: {
                        comment: discussionAdd.comment,
                    },
                },
                {
                    onSuccess: (res: Discussion) => {
                        notify(<Alert severity="info">コメントしました。</Alert>);
                        refresh();
                    },
                    onError: (error: ErrorResponse | any) => {
                        notify(<Alert severity="error">コメントに失敗しました。</Alert>);
                    },
                },
            );
        },
        [create, notify, refresh, merge_request_id],
    );

    if (isLoading || !activities || !merge_request_id) {
        return <CircularProgress />;
    }

    if (activities.length === 0) {
        return <Typography variant="body1">アクティビティがありません。</Typography>;
    }

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <DiscussionCreateListItem onCreateDiscussion={handleCreateDiscussion} />
            {activities?.map((activity: MergeRequestActivity) => {
                // const primary_node = ()`${who} は ${action_text} を行いました — ${when}`;
                return (
                    <ListItem key={activity.id} sx={{ alignItems: 'start' }}>
                        <ListItemAvatar sx={{ minWidth: '32px', mt: '6px' }}>
                            <UserProfileIcon userId={activity.created_by.id} />
                        </ListItemAvatar>

                       <MergeRequestActivityListItemContent merge_request_id={merge_request_id} activity={activity}/>
                    </ListItem>
                    // {/*{index !== activities.length - 1 && (*/}
                    // {/*    <Divider variant="inset" component="li"/>*/}
                    // {/*)}*/}
                );
            })}
        </List>
    );
};
