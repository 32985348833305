import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

enum LineCommentClassification {
    RevisionBasedOnLaws = '関係法令、計画の改正等を踏まえた修正',
    RevisionBasedOnSocialBusinessChanges = '社会情勢、事業・施策等の新設・変更を踏まえた修正',
    TimePointUpdate = '時点更新',
    OrganizationalReform = '組織改正',
    FormalModification = '形式的修正',
    Other = 'その他所要の修正',
}

export interface LineDiscussionFormValue {
    classification: string;
    reason: string;
    comment: string;
}

interface LineDiscussionFormProps {
    formValue: LineDiscussionFormValue;
    handleChange: (formValue: LineDiscussionFormValue) => void;
}

export const LineDiscussionForm = (props: LineDiscussionFormProps) => {
    const { formValue, handleChange } = props;

    const handleSelectChange = (event: SelectChangeEvent<any>) => {
        handleChange({
            ...formValue,
            classification: event.target.value as string,
        });
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        handleChange({
            ...formValue,
            [name]: value,
        });
    };

    return (
        <form>
            <FormControl fullWidth>
                <InputLabel id="classification-label" sx={{ zIndex: 0 }}>
                    修正理由の分類
                </InputLabel>
                <Select
                    labelId="classification-label"
                    name="classification"
                    value={formValue.classification}
                    label="修正理由の分類"
                    onChange={handleSelectChange}
                >
                    {Object.entries(LineCommentClassification).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
                {/*<FormHelperText>ヒントとなるような文言</FormHelperText>*/}
            </FormControl>
            <TextField
                name="reason"
                label="修正理由"
                InputLabelProps={{
                    sx: { zIndex: 0 }, // labelのz-indexを0に設定
                }}
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                maxRows={6}
                value={formValue.reason}
                onChange={handleInputChange}
                placeholder="新旧対照表などの公式文書作成時に、この文言を利用します。"
                // helperText="新旧対照表に表示される文言" //どちらが良いか
                required
            />
            <TextField
                name="comment"
                label="補足事項"
                InputLabelProps={{
                    sx: { zIndex: 0 }, // labelのz-indexを0に設定
                }}
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                maxRows={6}
                value={formValue.comment}
                onChange={handleInputChange}
                placeholder="これは文書作成には利用しません。確認者・承認者向けの連絡・補足事項を記載ください。"
            />
        </form>
    );
};
