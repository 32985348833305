import * as React from 'react';
import { FC } from 'react';
import { Labeled, useRecordContext } from 'react-admin';
import { Chip, List, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrganizationComponentType } from '../../organizations/types/organizationComponentType';
import { Paragraph } from '../types/paragraph';

type Props = {
    type: OrganizationComponentType;
};

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const OrganizationsField: FC<Props> = (props) => {
    const record = useRecordContext<Paragraph>();
    const organizations =
        props.type == 'responsible_organization'
            ? record.responsible_organizations
            : record.co_responsible_organizations;
    const label = props.type == 'responsible_organization' ? '主管組織' : '共管組織';

    return (
        <Labeled label={label}>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    display: 'flex',
                    minWidth: '100px',
                }}
                // component="ul"
            >
                <List
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        padding: 0,
                    }}
                    className="font-size-changeable small-font"
                >
                    {organizations &&
                        organizations.map((organization) => {
                            return (
                                <ListItem
                                    sx={{
                                        p: 0.5,
                                        m: 0,
                                    }}
                                    key={organization.id}
                                >
                                    <Chip
                                        label={organization.name}
                                        // color="secondary"
                                        variant={props.type == 'responsible_organization' ? 'filled' : 'outlined'}
                                    />
                                </ListItem>
                            );
                        })}
                </List>
            </Stack>
        </Labeled>
    );
};
export default OrganizationsField;
