import { FC, ReactNode, useState } from 'react';
import { Box } from '@mui/material';
import {
    Authenticator,
    Radio,
    RadioGroupField,
    TextField,
    translations,
    useAuthenticator,
} from '@aws-amplify/ui-react';
import { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents';
import '@aws-amplify/ui-react/styles.css';
import { Amplify, Auth, I18n } from 'aws-amplify';
import { cognitoConstants } from '../../config';
import './CognitoAuthenticator.scss';

/*
 * @aws-amplify/ui-react Authenticator公式ドキュメント：
 * https://ui.docs.amplify.aws/react/connected-components/authenticator
 */

Amplify.configure({ Auth: cognitoConstants });

I18n.putVocabularies(translations);
I18n.setLanguage('ja');
I18n.putVocabularies({
    ja: {
        // Sign-In, Sign-Up入力フォーム
        Affiliation: '所属',
        'Enter your Affiliation': '例：東京都総務局総合防災部防災計画課',
        Name: '氏名',
        'Enter your Name': '例：防災 太郎',
        Username: 'メールアドレス',
        'Enter your Username': '例：Taro_Bousai@member.metro.tokyo.jp',
        'Enter your Password': '大文字 / 小文字 / 数字 / 記号すべて必須',
        'Please confirm your Password': '大文字 / 小文字 / 数字 / 記号すべて必須',
        // パスワードを忘れたとき用入力フォーム
        'Enter your username': '例：Taro_Bousai@member.metro.tokyo.jp',
        'Confirmation Code': '認証コード',
        'Enter your Confirmation Code': 'メールの認証コードを入力',
        'New Password': '新しいパスワード',
        'Confirm New Password': '新しいパスワードの確認',
        'Resend Code': '認証コードを再送信',
        // バリデーション
        'Incorrect username or password.': 'メールアドレスもしくはパスワードが間違っています。',
        'User does not exist.': 'ユーザが見つかりません。',
        'User is not confirmed.': 'ユーザが認証されていません。',
        'User already exists': 'ユーザが既に存在しています。',
        'Invalid verification code provided, please try again.': '認証コードに誤りがあります。',
        'Password did not conform with policy: Password not long enough': 'パスワードは8文字以上を入力して下さい。',
        'Password did not conform with policy: Password must have uppercase characters':
            'パスワードには大文字を含めて下さい。',
        'Password did not conform with policy: Password must have lowercase characters':
            'パスワードには小文字を含めて下さい。',
        'Password did not conform with policy: Password must have numeric characters':
            'パスワードには数字を含めて下さい。',
        'Password did not conform with policy: Password must have symbol characters':
            'パスワードには記号を含めて下さい。',
        'Attempt limit exceeded, please try after some time.': 'パスワードの試行回数を超えました。',
        'Your passwords must match': 'パスワードを一致させて下さい。',
        'Username should be an email.': 'メールアドレスの形式が間違っています。',
        'PreSignUp failed with error InvalidParameterException: Invalid email.': '無効なメールアドレスです。',
        'PostConfirmation invocation failed due to error CodeArtifactUserPendingException.':
            '現在パスワードリセットシステムの再起動中です。30秒ほど待ってから再度お試しください。',
        'PreSignUp invocation failed due to error CodeArtifactUserPendingException.':
            '現在アカウント作成システムの再起動中です。30秒ほど待ってから再度お試しください。',
    },
});

// https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
// を参考にロゴなどを追加
const components: DefaultComponents = {
    Header() {
        return (
            <Box textAlign="center" paddingTop={5}>
                <img alt="Tokyo BPDB logo" src="bpdb_logo_text_with_icon.png" width="100%" />
            </Box>
        );
    },
    SignUp: {
        /**
         * Sets up form fields for a user registration form.
         * Updates the local state and the form state based on field changes.
         * Returns the form fields JSX to be rendered.
         */
        FormFields() {
            const [internalUser, setInternalUser] = useState(true);
            const { validationErrors, updateForm } = useAuthenticator();
            const [section, setSection] = useState<string>('');
            const [department, setDepartment] = useState<string>('');
            const [division, setDivision] = useState<string>('');
            const [team, setTeam] = useState<string>('');

            const handleAffiliationTypeChange = (value: string) => {
                const isInternal = value === 'internal';
                setInternalUser(isInternal);

                // ラジオボタンを切り替えた場合、所属関連ののフィールドをクリア
                setSection('');
                setDepartment('');
                setDivision('');
                setTeam('');
            };

            // Handle custom form field changes
            const handleCustomFieldChange = (value: string, fieldName: string) => {
                // Update the local state based on field name
                const newState = {
                    section: section.trim(),
                    department: department.trim(),
                    division: division.trim(),
                    team: team.trim(),
                    [fieldName]:  value.trim(),
                };
                setSection(newState.section);
                setDepartment(newState.department);
                setDivision(newState.division);
                setTeam(newState.team);

                const affiliation = `${newState.section}${newState.department}${newState.division}${newState.team}`

                // Combine the fields and update the form state for submission
                updateForm({
                    name: 'custom:affiliation',
                    value: affiliation,
                });

            };

            return (
                <>
                    <RadioGroupField
                        name="affiliationType"
                        label=""
                        direction="row"
                        defaultValue="internal"
                        onChange={(e) => handleAffiliationTypeChange(e.target.value)}
                    >
                        <Radio value="internal">東京都</Radio>
                        <Radio value="external">関係機関</Radio>
                    </RadioGroupField>
                    {internalUser ? (
                        <>
                            <TextField
                                name="section"
                                label="所属局等"
                                placeholder="例：総務局"
                                maxLength={32} // 一旦長めにとる
                                isRequired={true}
                                value={section}
                                onChange={(e) => handleCustomFieldChange(e.target.value, 'section')}
                            />
                            <TextField
                                name="department"
                                label="所属部"
                                placeholder="例：総合防災部"
                                maxLength={32} // 一旦長めにとる
                                isRequired={true}
                                value={department}
                                onChange={(e) => handleCustomFieldChange(e.target.value, 'department')}
                            />
                            <TextField
                                name="division"
                                label="所属課"
                                placeholder="例：防災計画課"
                                maxLength={32} // 一旦長めにとる
                                isRequired={true}
                                value={division}
                                onChange={(e) => handleCustomFieldChange(e.target.value, 'division')}
                            />
                            <TextField
                                name="team"
                                label="担当（任意）"
                                placeholder="例：計画調整担当"
                                maxLength={32} // 一旦長めにとる
                                value={team}
                                onChange={(e) => handleCustomFieldChange(e.target.value, 'team')}
                            />
                        </>
                    ) : (
                        <>
                            <TextField
                                name="section"
                                label="所属組織"
                                placeholder="例：〇〇株式会社、□□協会等"
                                maxLength={64} // 一旦長めにとる
                                isRequired={true}
                                value={section}
                                onChange={(e) => handleCustomFieldChange(e.target.value, 'section')}
                            />
                            <TextField
                                name="department"
                                label="部署（任意）"
                                placeholder="例：△△部"
                                maxLength={64} // 一旦長めにとる
                                value={department}
                                onChange={(e) => handleCustomFieldChange(e.target.value, 'department')}
                            />
                        </>
                    )}
                    {/* 共通のフィールド */}
                    <Authenticator.SignUp.FormFields />
                </>
            );
        },
    },
};

const formFields = {
    signUp: {
        name: {
            order: 1,
            isRequired: true,
            maxLength: 64, // 一旦長めにとる
        },
        username: {
            // メールアドレス
            order: 2,
            isRequired: true,
        },
        password: {
            order: 3,
            isRequired: true,
        },
        confirm_password: {
            order: 4,
            isRequired: true,
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            order: 1,
            placeholder: 'Enter your Confirmation Code',
            label: 'Confirmation Code',
            isRequired: true,
        },
        password: {
            order: 2,
            label: 'New Password',
            placeholder: 'Enter your Password',
            isRequired: true,
        },
        confirm_password: {
            order: 3,
            label: 'Confirm New Password',
            placeholder: 'Please confirm your Password',
            isRequired: true,
        },
    },
};

export interface CognitoAuthenticatorProps {
    children: ReactNode;
    hideSignUp?: boolean;
}

const CognitoAuthenticator: FC<CognitoAuthenticatorProps> = ({ children, ...rest }) => {
    const services = {
        async handleForgotPasswordSubmit(formData: { username: string; code: string; password: string }) {
            const { username, code, password } = formData;
            try {
                const result = await Auth.forgotPasswordSubmit(username, code, password);
                window.alert('パスワードリセットに成功しました');
                return result;
            } catch (error) {
                window.alert('パスワードリセットに失敗しました');
                throw error;
            }
        },
    };
    return (
        <Authenticator
            // ログインやログアウト時の挙動をカスタマイズするとき用
            services={services}
            hideSignUp={rest?.hideSignUp}
            formFields={formFields}
            components={components}
        >
            {children}
        </Authenticator>
    );
};

export default CognitoAuthenticator;
