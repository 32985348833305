import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface Props extends Omit<ButtonProps, 'color'> {}

export const BackButton = (props: Props) => {
    const handleReturn = (e: any) => {
        if (props.onClick) {
            // onClickイベントが設定されていればそちらを優先
            props.onClick(e);
            return;
        }

        window.history.back();
    };

    return (
        <Button color="primary" onClick={handleReturn} sx={{ pl: 2, pr: 2, columnGap: 0.5 }}>
            <ArrowBackIcon fontSize="small" />
            戻る
        </Button>
    );
};
