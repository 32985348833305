import { FC } from 'react';
import { Button, ButtonProps } from 'react-admin';

export interface CancelButtonProps extends Omit<ButtonProps, 'label'> {
    // Omitで指定したプロパティ以外を継承
}

export const CancelButton: FC<CancelButtonProps> = (props) => {
    return <Button label="キャンセル" {...props} />;
};
