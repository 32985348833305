import Encoding from 'encoding-japanese';

// @see { app/node_modules/ra-core/src/export/downloadCSV.ts }
export default (csv: string, filename: string): void => {
    const fakeLink = document.createElement('a');
    fakeLink.style.display = 'none';
    document.body.appendChild(fakeLink);

    // https://www.npmjs.com/package/encoding-japanese#supported-encodings
    const sjisArray = Encoding.convert(csv, {
        to: 'SJIS', // 文字コード指定
        from: 'UNICODE',
        type: 'array',
    });

    const blob = new Blob([new Uint8Array(sjisArray)]);

    // @ts-ignore
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // @ts-ignore
        window.navigator.msSaveOrOpenBlob(blob, `${filename}.csv`);
    } else {
        fakeLink.setAttribute('href', URL.createObjectURL(blob));
        fakeLink.setAttribute('download', `${filename}.csv`);
        fakeLink.click();
    }
};
