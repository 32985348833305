import { FC } from 'react';
import { ReferenceInput, SelectInput } from 'react-admin';

type DocumentIdListFilterProps = {
    source: string;
    alwaysOn: boolean;
};

export const DocumentsFilter: FC<DocumentIdListFilterProps> = ({ source, alwaysOn: _ }) => {
    // reference: GETリクエスト対象のエンドポイント
    // see: https://marmelab.com/react-admin/ReferenceInput.html#reference

    return (
        <ReferenceInput source={source} reference="documents" sort={{ field: 'position', order: 'ASC' }}>
            <SelectInput optionText="title" label="ドキュメント" fullWidth sx={{ minWidth: '350px' }} resettable />
        </ReferenceInput>
    );
};
