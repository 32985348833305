import {
    AppBar,
    AppBarProps,
    LoadingIndicator,
    LocalesMenuButton,
    TitlePortal,
    ToggleThemeButton,
    useThemesContext,
} from 'react-admin';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocales } from 'ra-core';
import { FeedbackButton } from '../../../features/feedbacks/components/FeedbackButton';
import { useSelectedMenu } from '../../../hooks/useSelectedMenu';
import { FontSizeSelector } from '../inputs/FontSizeSelector';
import { IodUserMenu } from './IodUserMenu';

interface ToolbarProps {}


/**
 * ヘッダ部分のツールバー
 *
 * {@link AppBar} 中の DefaultToolbarを参考にした。
 */
const Toolbar = (props: ToolbarProps) => {
    const locales = useLocales();
    const { darkTheme } = useThemesContext();
    return (
        <>
            <FeedbackButton />
            {locales && locales.length > 1 ? <LocalesMenuButton /> : null}
            {darkTheme && <ToggleThemeButton />}
            <LoadingIndicator />
            <FontSizeSelector />
        </>
    );
};

export const IodAppBar = (props: AppBarProps) => {
    const { selectedMenu } = useSelectedMenu();
    const theme = useTheme();

    // warning回避のためにsxで色指定する
    const sx = selectedMenu === 'edit' ? {
        backgroundColor: theme.palette.edit_secondary.main,
        color:  theme.palette.edit_secondary.contrastText,
    } : null;

    return (
        <AppBar
            {...props}
            color="secondary"
            sx={sx}
            elevation={1}
            userMenu={<IodUserMenu />}
            toolbar={<Toolbar />}
            alwaysOn
        >
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flex: '0 0 auto',
                    margin: '0 1rem 0 .5rem',
                }}
            >
                {selectedMenu !== 'edit' ? (
                    <img src="bpdb_logo_text_with_icon_white.png" alt="東京都地域防災計画データベース" height={36} />
                ) : (
                    <img src="edit_logo.png" alt="【編集】東京都地域防災計画DB" height={48} />
                )}
            </Box>
            <TitlePortal />
        </AppBar>
    );
};
