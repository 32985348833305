import * as React from 'react';
import { FC, useState } from 'react';
import { InputProps, TextInput, usePermissions } from 'react-admin';
import { MarkdownInput, MarkdownInputProps } from '@react-admin/ra-markdown';
import { EditorProps } from '@react-admin/ra-markdown/src/toast-ui';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import {Alert, AlertColor, Grid, Snackbar, Typography} from '@mui/material';
import '@toast-ui/editor/dist/i18n/ja-jp';
import {EditorType, HookCallback, HookMap, PreviewStyle} from '@toast-ui/editor/types/editor';
import { API_URL } from '../../../config';
import { httpClient } from '../../functional/dataProvider';
import './IodMarkdownInput.scss';


export interface IodMarkdownInputProps
    extends Partial<Omit<EditorProps, 'onChange' | 'onBlur' | 'onFocus'>>,
        InputProps {
    helperText?: string;
    label?: string;
    fullWidth?: boolean;
    previewStyle?: PreviewStyle;
    initialEditType?: EditorType;
    hideModeSwitch?: boolean;

    hooks?: HookMap;
}

const IodMarkdownInput: FC<IodMarkdownInputProps> = (props) => {
    const { hooks } = props;

    //  プレビューの表示方法
    const previewStyle = props.previewStyle ?? 'vertical';

    const showToolbarItems = () => {
        // @see https://nhn.github.io/tui.editor/latest/tutorial-example15-customizing-toolbar-buttons
        return [
            [
                'heading', // 見出し
                'bold', // 太字
                'italic', // 斜体
                'strike', // 取り消し線
            ],
            [
                'hr', // 水平線
                'quote', // 引用
            ],
            [
                'ul', // 箇条書きリスト
                'ol', // 順序付きリスト
                // 'task', // タスクリスト
                'indent', // インデント
                'outdent', // アウトデント
            ],
            [
                'table', // テーブル
                'image', // 画像
                'link', // リンク
            ],
            // [
            //     'code', // インラインコード
            //     'codeblock' // コードブロック
            // ]
        ];
    };


    const options: Partial<MarkdownInputProps> = {
        // height: "300px",
        previewStyle: previewStyle,　// markdownモードのときのプレビュー表示
        initialEditType: props.initialEditType ?? 'markdown',
        // hideModeSwitch: !hasApprovePolicy, // 202401 第3回ユーザテスト向けに、編集者はwysiwygのみに修正
        hideModeSwitch: props.hideModeSwitch ?? false,
        language: 'ja',
        // useCommandShortcut: false,
        toolbarItems: showToolbarItems(),
    };

    if (hooks) {
        options.hooks = hooks;
    }

    return (
        <ErrorBoundary
            fallback={
                <>
                    <p>描画中に問題が発生したため通常のtextareaで表示します。</p>
                    <TextInput multiline fullWidth {...props} />
                </>
            }
        >
            <MarkdownInput
                {...props}
                {...options}
                label={
                    <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <Typography sx={{
                                color: 'rgba(0, 0, 0, 0.6)',
                                fontSize: '13px',
                            }}>
                                {props.label ? (
                                    props.label
                                ):(
                                    <>編集エリア</>
                                )}
                            </Typography>
                        </Grid>
                        {previewStyle === 'vertical' && (
                            <Grid item xs={6} >
                                <Typography sx={{
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    fontSize: '13px',
                                }}>
                                    プレビュー表示
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                }
            />
        </ErrorBoundary>
    );
};

export default IodMarkdownInput;
