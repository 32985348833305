import { TextField } from '@mui/material';

export interface CommentFormValue {
    comment: string;
}

interface Props {
    formValue: CommentFormValue;
    handleChange: (formValue: CommentFormValue) => void;
}

export const CommentEditForm = (props: Props) => {
    const { formValue, handleChange } = props;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        handleChange({
            ...formValue,
            [name]: value,
        });
    };

    return (
        <form>
            <TextField
                name="comment"
                label="リプライの内容"
                InputLabelProps={{
                    sx: { zIndex: 0 }, // labelのz-indexを0に設定
                }}
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                maxRows={6}
                value={formValue.comment}
                onChange={handleInputChange}
                // placeholder=""
            />
        </form>
    );
};
