import { useState } from 'react';
import { DeleteButton, usePermissions, useRecordContext, useRefresh } from 'react-admin';
import { NodeActions } from '@react-admin/ra-tree';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItemIcon,
    MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { CancelButton } from '../../../components/ui/button/CancelButton';
import { useContentPermissions } from '../../../hooks/useContentPermissions';
import { ContentNode } from '../types/contentNode';

export const ContentNodeActions = (props: any) => {
    const { permissions } = usePermissions();
    const record = useRecordContext<ContentNode>();
    // const dataNode = props.data; // dataNode?.content_typeでも判定可能

    if (!permissions) {
        return null;
    }

    // コンテンツの削除権限
    const hasHeadingDeletePolicy = permissions.headings.includes('delete');
    const hasParagraphDeletePolicy = permissions.paragraphs.includes('delete');

    // ノードの属性
    const isHeading = record.content_type === 'heading';
    const isParagraph = record.content_type === 'paragraph';

    return (hasHeadingDeletePolicy && isHeading) || (hasParagraphDeletePolicy && isParagraph) ? (
        <NodeActions {...props}>
            <DeleteMenuItem documentId={record.document_id} contentPath={record.content_path} />
        </NodeActions>
    ) : null;
};

interface DeleteMenuItemProps {
    documentId: number;
    contentPath: string;
}

const DeleteMenuItem = (props: DeleteMenuItemProps) => {
    const refresh = useRefresh();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(true);
    const { deletable } = useContentPermissions({ documentId: props.documentId, contentPath: props.contentPath });

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleCancel = () => {
        setDialogOpen(false);
    };

    const handleDelete = () => {
        setDialogOpen(false);
        setMenuOpen(false);

        // FIXME: 削除後、ノードが残って表示されてしまうので要調整
        // setTimeout(() => {
        //     // 削除処理を待ってからリフレッシュ
        //     refresh();
        // }, 500);
    };

    return (
        <>
            {menuOpen && (
                <MenuItem onClick={handleClickOpen} sx={{ display: 'block' }} disabled={!deletable}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" sx={{ opacity: 0.5 }} />
                        削除
                    </ListItemIcon>
                </MenuItem>
            )}
            <Dialog open={dialogOpen} onClose={handleCancel}>
                <DialogTitle>{'削除確認'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>本当に削除しますか？この操作は取り消せません。</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CancelButton onClick={handleCancel} />
                    <DeleteButton onClick={handleDelete} />
                </DialogActions>
            </Dialog>
        </>
    );
};
