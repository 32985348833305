import { AutocompleteArrayInput, useGetList } from 'react-admin';
import { CircularProgress, Tooltip } from '@mui/material';
import { UserInfo } from '../../users/types/userInfo';
import { useDefaultReviewers } from './useDefaultReviewers';

export const ReviewersArrayInput = () => {
    const { data: allReviewers } = useGetList<UserInfo>('users/me/reviewers');

    // カスタムフックを使用してデフォルト確認者を取得
    const defaultReviewers = useDefaultReviewers(allReviewers);

    if (!allReviewers || !defaultReviewers) {
        return <CircularProgress />;
    }

    // デフォルト確認者をdefaultValueが期待している形式{idリスト}に組み替える
    const defaultReviewerIds = defaultReviewers.map((reviewer: UserInfo) => reviewer.id);

    return (
        <Tooltip
            title="メールが同一ドメインの方のみ指定可能で、キーワード検索ができます。総合防災部への承認依頼は自動設定されます"
            placement="top-start"
        >
            {/* Tooltipの直下にReactAdminのコンポーネントは相性が悪いためワンクッション */}
            <div style={{ width: '100%' }}>
                {/* 確認者候補 && デフォルト確認者の準備を待つ */}
                {allReviewers && defaultReviewerIds && (
                    <AutocompleteArrayInput
                        source="reviewer_ids"
                        choices={
                            // オブジェクトの配列を期待しており、選択肢の基本となるデータ
                            // https://marmelab.com/react-admin/AutocompleteArrayInput.html#choices
                            allReviewers
                        }
                        defaultValue={
                            // 指定したidのデータを初期設定する
                            // 指定するidはchoicesの選択肢に存在している必要がある
                            defaultReviewerIds
                        }
                        optionText={
                            // 選択肢の表示をカスタマイズ可能
                            // choices,defaultValueの両方に影響を及ぼす
                            (record: UserInfo) => `【${record.affiliation}】${record.name}`
                        }
                        label="確認者を追加（任意）"
                        onKeyDown={(event) => {
                            // エンターキーでsubmitされるのを防ぐ
                            if (event.key === 'Enter') {
                                event.preventDefault();
                            }
                        }}
                        fullWidth
                    />
                )}
            </div>
        </Tooltip>
    );
};
