import { FC, useEffect, useState } from 'react';
import { useGetIdentity, useGetList, useNotify, usePermissions } from 'react-admin';
import {
    Alert,
    Box,
    Card,
    CardActionArea,
    CardHeader,
    List,
    ListItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { httpClient } from '../../../components/functional/dataProvider';
import { UserProfileIcon } from '../../../components/ui/elements/UserProfileIcon';
import { NowLoading } from '../../../components/ui/layout/NowLoading';
import { API_URL } from '../../../config';
import { Commit } from '../../paragraphs/types/commit';
import { DiffInfo } from '../types/diffInfo';
import { CommitHistoryItemAction } from './CommitHistoryItemAction';
import { IodDiffContainer, IodDiffContainerProps } from './IodDiffContainer';

export interface CommitAndDiffContainerProps {
    documentId: number;
    contentPath: string;
    canHistoryOperation?: boolean;
}

export const CommitAndDiffContainer: FC<CommitAndDiffContainerProps> = (props) => {
    const { documentId, contentPath, canHistoryOperation } = props;
    const { data, isLoading } = useGetList<Commit>(
        `documents/${documentId}/${encodeURIComponent(contentPath)}/commits`,
    );
    const { permissions } = usePermissions();
    const [currentContentId, setCurrentContentId] = useState<number | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredData, setFilteredData] = useState<Commit[] | undefined>(data);
    const { data: userIdentity } = useGetIdentity();

    useEffect(() => {
        if (!isLoading && data && data.length > 0) {
            setCurrentContentId(data[0].id as number);
        } else {
            setCurrentContentId(null);
        }
    }, [data, isLoading]);

    useEffect(() => {
        if (!searchTerm || !data) {
            // 検索されていないとき、もしくは、データがないとき
            setFilteredData(data);
        } else {
            // 検索されているとき
            const filtered = data?.filter((item) => {
                if (!item.editor) {
                    // editorが存在しない場合はフィルターから外す
                    return false;
                }

                if (
                    item.editor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.editor.affiliation.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                    // nameまたはaffiliationにsearchTermが含まれている場合にフィルターを通す
                    return true;
                }

                // 上記の条件に一致しない場合はフィルターを通さない
                return false;
            });
            setFilteredData(filtered);
        }
    }, [searchTerm, data]);

    // ユーザ情報を更新する権限で管理者であることを判定する
    const hasDiscardCommitPermission = permissions.users?.includes('update') || false;

    return (
        <Stack direction="row">
            {/* 差分を表示するエリア */}
            <Box sx={{ width: '75%' }}>
                <Typography variant="h6" sx={{ ml: 2 }}>
                    変更内容
                </Typography>
                {currentContentId ? (
                    <CommitDiffContainer
                        documentId={documentId}
                        contentPath={contentPath}
                        contentId={currentContentId}
                        showHistory={false}
                    />
                ) : (
                    <Typography variant="body1" sx={{ m: 2 }}>
                        変更内容がありません
                    </Typography>
                )}
            </Box>

            {/* 履歴一覧を表示するエリア */}
            <Box sx={{ width: '25%' }}>
                <Typography variant="h6">履歴一覧</Typography>

                {data && data.length >= 1 && (
                    <Tooltip title="所属もしくは名前で絞り込みができます" placement="left">
                        <TextField
                            value={searchTerm}
                            type="search"
                            variant="outlined"
                            onChange={(event) => setSearchTerm(event.target.value)}
                            placeholder="絞り込み"
                            fullWidth
                        />
                    </Tooltip>
                )}
                <List sx={{ maxHeight: 'calc(100vh - 327px)', overflow: 'auto' }}>
                    {filteredData?.map((item, index) => (
                        <ListItem key={item.id} sx={{ paddingInline: 0 }}>
                            <Card
                                sx={{
                                    width: '100%',
                                    // 選択中の履歴を暗めにする
                                    backgroundColor: item.id === currentContentId ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
                                }}
                                variant="outlined"
                            >
                                {/* カードクリックに反応させる */}
                                <CardActionArea>
                                    <CardHeader
                                        avatar={<UserProfileIcon userId={item.editor?.id} />}
                                        title={item.editor ? `【${item.editor.affiliation}】${item.editor.name}` : ''}
                                        subheader={new Date(item.created_at).toLocaleString()}
                                        onClick={() => {
                                            setCurrentContentId(item.id as number);
                                        }}
                                        action={
                                            canHistoryOperation && (
                                                <CommitHistoryItemAction
                                                    documentId={documentId}
                                                    contentPath={contentPath}
                                                    commit={item}
                                                    // canRestore={index > 0}
                                                    canDiscard={
                                                        index === 0 &&
                                                        (hasDiscardCommitPermission ||
                                                            item.editor?.id === userIdentity?.id)
                                                    }
                                                />
                                            )
                                        }
                                    />
                                </CardActionArea>
                            </Card>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Stack>
    );
};

interface CommitDiffContainerProps extends Omit<IodDiffContainerProps, 'diff'> {
    documentId: number;
    contentPath: string;
    contentId: number;
}

const CommitDiffContainer: FC<CommitDiffContainerProps> = ({ documentId, contentPath, contentId, ...props }) => {
    const [data, setData] = useState<DiffInfo | null>(null);
    const [isLoading, setIsLoading] = useState(false); // 読み込み状態を追跡するための状態
    const notify = useNotify();

    useEffect(() => {
        setIsLoading(true);
        httpClient(`${API_URL}/documents/${documentId}/${encodeURIComponent(contentPath)}/commits/${contentId}/diff`)
            .then((res) => {
                setData(res.json);
            })
            .catch((err) => {
                console.error(err);
                notify(<Alert severity="error">差分の取得に失敗しました</Alert>);
            })
            .finally(() => {
                setIsLoading(false); // リクエスト終了時に読み込み状態をfalseに設定
            });
    }, [contentId, contentPath, documentId, notify]);

    if (isLoading) {
        // 読み込み中の場合、読み込み中アイコン（例: CircularProgress）を表示
        return <NowLoading />;
    }

    if (!data) {
        return <></>;
    }

    return <IodDiffContainer diff={data} {...props} />;
};
