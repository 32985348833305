import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { RecordContextProvider, useInfiniteGetList, useRedirect, useTranslate } from 'react-admin';
import { Box, CircularProgress, List, ListItem, Stack } from '@mui/material';
import IodMarkdownField from '../../../components/ui/fields/IodMarkdownField';
import { FavoriteButton } from '../../favorites/components/FavoriteButton';
import { Paragraph } from '../types/paragraph';
import { ListHeadingsField } from './ListHeadingsField';
import OrganizationsField from './OrganizationsField';
import { PhaseField } from './PhaseField';

// import Basket from '../orders/Basket';
// import { Customer, Invoice } from '../types';

interface Props {
    paragraph: Paragraph;
}

const ParagraphInfiniteListAfter = (props: Props) => {
    const sequence = props?.paragraph?.sequence;
    const perPage = 10;

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteGetList<Paragraph>(
        'paragraphs',
        {
            pagination: { page: 1, perPage },
            sort: {
                field: 'sequence',
                order: 'ASC',
            },
            filter: {
                document_id: props.paragraph.document_id,
                sequence_from: sequence + 1,
            },
        },
        { enabled: !!sequence },
    );

    const observerElem = useRef(null);

    const translate = useTranslate();
    const redirect = useRedirect();

    const handleObserver = useCallback(
        (entries: any) => {
            const [target] = entries;
            if (target.isIntersecting && hasNextPage) {
                fetchNextPage();
            }
        },
        [fetchNextPage, hasNextPage],
    );

    useEffect(() => {
        const element = observerElem.current;
        if (!element) return;
        const option = { threshold: 0 };
        const observer = new IntersectionObserver(handleObserver, option);
        observer.observe(element);
        return () => observer.unobserve(element);
    }, [fetchNextPage, hasNextPage, handleObserver]);

    // const record = useRecordContext<Invoice>();
    if (!sequence) return null;

    return (
        <
            // sx={{
            // height: '300px',
            // overflowY: 'auto',
            // }}
        >
            <List
                sx={
                    {
                        // margin: '1em',
                        // width: '98%'
                        // flexFlow: 'column',
                    }
                }
                dense
            >
                {data?.pages.map((page) => {
                    return page.data.map((p, index) => {
                        return (
                            <RecordContextProvider value={p} key={p.id}>
                                <ListItem disablePadding alignItems="flex-start">
                                    <Box
                                        sx={{
                                            // marginLeft: '5px',
                                            // marginRight: '5px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            mb: 2,
                                        }}
                                    >
                                        {p.headings.length > 0 && (
                                            <Stack direction={'row'} alignItems={'center'} gap={[0, 2]}>
                                                <ListHeadingsField
                                                    current_paragraph={p}
                                                    pre_paragraph={index > 0 ? page.data[index - 1] : props.paragraph}
                                                />
                                                <FavoriteButton content_id={p.content_id} />
                                            </Stack>
                                        )}

                                        <PhaseField />

                                        <Stack direction="row" spacing={1}>
                                            <OrganizationsField type="responsible_organization" />
                                            <OrganizationsField type="co_responsible_organization" />
                                        </Stack>
                                        <IodMarkdownField source="body" className="font-size-changeable" />
                                    </Box>
                                </ListItem>
                            </RecordContextProvider>
                        );
                    });
                })}
            </List>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                ref={observerElem}
            >
                {isFetchingNextPage && hasNextPage && <CircularProgress />}
            </Box>
        </>
    );
};

export default ParagraphInfiniteListAfter;
