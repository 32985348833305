import * as React from 'react';
import { FC } from 'react';
import { Identifier, NumberField, RecordContextProvider, SimpleForm, useGetOne } from 'react-admin';
import { Box, Divider, Grid, Stack } from '@mui/material';
import IodMarkdownField from '../../../components/ui/fields/IodMarkdownField';
import { FavoriteButton } from '../../favorites/components/FavoriteButton';
import { ListHeadingsField } from '../../paragraphs/components/ListHeadingsField';
import OrganizationsField from '../../paragraphs/components/OrganizationsField';
import ParagraphInfiniteListAfter from '../../paragraphs/components/ParagraphInfiniteListAfter';
import { ParagraphListAfter } from '../../paragraphs/components/ParagraphListAfter';
import { PhaseField } from '../../paragraphs/components/PhaseField';
import { Paragraph } from '../../paragraphs/types/paragraph';

// a Create view for a tree uses <CreateNode> instead of the standard <Create>

interface Props {
    document_id: number;
    paragraph_content_node_id: Identifier;
}

export const ParagraphsShow: FC<Props> = (props) => {
    const { data: baseParagraph, error } = useGetOne<Paragraph>(`paragraphs/${props.document_id}`, {
        id: props.paragraph_content_node_id,
    });

    if (error || !baseParagraph) {
        return null;
    }

    return (
        <Grid
            container
            sx={{
                flexFlow: 'column',
            }}
            // rowClick="edit"
        >
            <SimpleForm toolbar={<></>}>
                {/*{*/}
                {/*    baseParagraph && (*/}
                {/*        <ParagraphListBefore*/}
                {/*            onAfterLoad={scrollCurrent}*/}
                {/*            paragraph={baseParagraph}*/}
                {/*        />*/}
                {/*    )*/}
                {/*}*/}

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // backgroundColor: '#ddfaff',
                        width: '100%',
                        mb: 2,
                    }}
                >
                    <RecordContextProvider value={baseParagraph}>
                        {/*<Divider ></Divider>*/}
                        {/*<span ref={targetRef}></span>*/}
                        <Stack direction={'row'} alignItems={'center'} gap={[0, 2]}>
                            <ListHeadingsField current_paragraph={baseParagraph} />
                            <FavoriteButton content_id={baseParagraph.content_id} />
                        </Stack>

                        <NumberField source="id" label="コンテンツノードID" sx={{ display: 'none' }} />
                        <NumberField source="document_id" label="ドキュメントID" sx={{ display: 'none' }} />
                        <NumberField source="content_id" label="コンテンツID" sx={{ display: 'none' }} />

                        <PhaseField />

                        <Stack direction="row" spacing={1}>
                            <OrganizationsField type="responsible_organization" />
                            <OrganizationsField type="co_responsible_organization" />
                        </Stack>

                        <IodMarkdownField
                            source="body"
                            // label="内容"
                            className="font-size-changeable"
                        />
                        {/*<Divider></Divider>*/}
                    </RecordContextProvider>

                    <Divider></Divider>
                </Box>

                {baseParagraph && <ParagraphInfiniteListAfter paragraph={baseParagraph} />}
            </SimpleForm>
        </Grid>
    );
};
