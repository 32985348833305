import { FC } from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';
import {DocumentEditMode} from '../types/documentEditMode';

type Props = {} & Omit<SelectInputProps, 'choices' | 'required' >;
export const DocumentEditModeSelectInput: FC<Props> = (props) => {
    const modes: Array<{ id: DocumentEditMode; name: string; disabled?: boolean}> = [
        { id: 'read_only', name: '編集不可' },
        { id: 'approvers_only_edit', name: '総合防災部防災計画課（承認者以上）のみ編集可' },
        { id: 'internal_only_edit', name: '東京都のみ編集可' },
        { id: 'external_only_edit', name: '関係機関のみ編集可' },
        { id: 'all_but_conditional_edit', name: '制限付き全員編集可（東京都が編集したものは関係機関は編集できない）', disabled: true },
        { id: 'all_edit', name: '全員編集可' },
    ];

    return (
        <SelectInput
            {...props}
            required
            choices={modes}
        />
    );
};

