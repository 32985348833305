import { FC, MouseEvent } from 'react';
import { FunctionField, FunctionFieldProps } from 'react-admin';
import { Paragraph } from '../../paragraphs/types/paragraph';
import { FavoriteButton } from './FavoriteButton';

interface FavoriteButtonFieldProps extends Omit<FunctionFieldProps, 'render'> {}

export const FavoriteButtonField: FC<FavoriteButtonFieldProps> = (props) => {
    const handleClick = (e: MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <FunctionField
            render={(record: Paragraph) => (
                <div onClick={handleClick} className="favorite-button-wrapper">
                    <FavoriteButton content_id={record.content_id} {...props} />
                </div>
            )}
        />
    );
};
