import * as React from 'react';
import { Datagrid, DateField, FunctionField, Pagination, ReferenceField, TextField, TopToolbar } from 'react-admin';
import { Check } from '@mui/icons-material';
import TooltipTextInput from '../../../components/ui/inputs/TooltipTextInput';
import { IodList } from '../../../components/ui/layout/IodList';

export const UserList = () => {
    const userListFilters = [<TooltipTextInput source="id" title="IDで検索ができます" label="ID検索" alwaysOn />];

    const UserPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

    return (
        <IodList
            filters={userListFilters}
            exporter={false}
            pagination={<UserPagination />}
            actions={<TopToolbar /> /* 保存ボタンを非表示に */}
        >
            <Datagrid bulkActionButtons={false} rowClick="edit">
                <TextField source="id" label="ID" sortable={false} />
                <TextField source="email" label="メールアドレス" sortable={false} />
                <TextField source="affiliation" label="所属" sortable={false} />
                <TextField source="name" label="氏名" sortable={false} />
                <FunctionField render={renderStatus} label="ステータス" />
                <ReferenceField source="role_id" reference="roles" label="ロール" sortable={false}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="created_at" label="作成日時" sortable={false} showTime={true} />
                <DateField source="updated_at" label="更新日時" sortable={false} showTime={true} />
            </Datagrid>
        </IodList>
    );
};

const renderStatus = (record: any) => {
    return record.status === 'CONFIRMED' ? <Check /> : '';
};
