import { FC, useState } from 'react';
import { useCreate, useNotify } from 'react-admin';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SendIcon from '@mui/icons-material/Send';
import { SxProps } from '@mui/system';
import { useSelectedMenu } from '../../../hooks/useSelectedMenu';
import { Feedback } from '../types/Feedback';

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        edit_secondary: true;
    }
}

interface FeedbackButtonProps {}

export const FeedbackButton = (props: FeedbackButtonProps) => {
    const { selectedMenu } = useSelectedMenu();
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    let restSx: Partial<SxProps<Theme>> = {}
    if (selectedMenu === 'edit') {
        restSx = {
            ...restSx,
            color: theme.palette.edit_secondary.contrastText,
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderColor:  theme.palette.edit_secondary.contrastText,
            },
        }
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <Button
                variant="outlined"
                color={selectedMenu === 'edit' ? 'edit_secondary' : 'secondary'}
                sx={{
                    backgroundColor: '#fff',
                    fontWeight: 'bold',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    },
                    mr: 1,
                    ...restSx,
                }}
                onClick={handleOpen}
            >
                <FeedbackIcon fontSize="small" sx={{ mr: 0.5 }} />
                ご意見フォーム
            </Button>
            <FeedbackDialog open={open} handleClose={handleClose} />
        </>
    );
};

interface FeedbackDialogProps extends Omit<DialogProps, 'onClose' | 'maxWidth' | 'fullWidth'> {
    handleClose: () => void;
}

type FeedbackCategory = 'bug' | 'uiuximprovement' | 'incompletedata' | 'other';
const categoryToText = (category: FeedbackCategory): string => {
    switch (category) {
        case 'bug':
            return '機能不具合・エラー';
        case 'uiuximprovement':
            return '見た目・操作性改善';
        case 'incompletedata':
            return 'データの不備';
        case 'other':
            return 'その他';
    }
};

interface FeedbackFormData {
    title: string;
    description: string;
    category: FeedbackCategory;
}

const initialFormData: FeedbackFormData = {
    title: '',
    description: '',
    category: 'bug',
};

const FeedbackDialog: FC<FeedbackDialogProps> = ({ handleClose, ...props }) => {
    const [formData, setFormData] = useState<FeedbackFormData>(initialFormData);
    const notify = useNotify();
    const [create, { isLoading }] = useCreate<Feedback>();

    const canSend = (): boolean => formData.title !== '' && formData.description !== '' && !isLoading;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSelect = (event: SelectChangeEvent) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleDialogClose = () => {
        !isLoading && handleClose();
    };

    const handleSubmit = async () => {
        if (!canSend()) {
            return;
        }

        const sendData = {
            title: `【${categoryToText(formData.category)}】${formData.title}`,
            description: formData.description,
        };

        await create(
            'feedbacks',
            { data: sendData },
            {
                onError: (error) => {
                    console.error(error);
                    notify(<Alert severity="error">ご意見の送信に失敗しました。</Alert>);
                },
                onSuccess: () => {
                    notify(<Alert severity="info">ご意見を受け付けました。ありがとうございます。</Alert>);
                    setFormData(initialFormData);
                    handleClose();
                },
            },
        );
    };

    return (
        <Dialog onClose={handleDialogClose} maxWidth="lg" fullWidth={true} {...props}>
            <DialogTitle>ご意見フォーム</DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText sx={{ mb: 1.5, fontSize: '.95rem' }}>
                    <p style={{ marginBottom: `.5rem` }}>
                        いただいたご意見・ご報告は、システムの改善に役立てさせていただきます。
                        <br />
                        追加の情報が必要な場合には、担当者よりご連絡させていただくことがございます。
                    </p>
                    <p style={{ marginBottom: `.5rem` }}>
                        ご意見フォームの送信に際しては、ユーザー識別のためのIDのみが自動的に記録されます。
                        <br />
                        このIDは管理者以外には個人を特定できない形式で保管され、個人情報の安全を確保しています。
                    </p>
                    <Alert severity="warning">
                        セキュリティとプライバシーを保護するために、
                        <span style={{ color: 'red' }}>個人情報（氏名、電話番号など）はご記入いただかないよう</span>
                        お願いいたします。
                    </Alert>
                </DialogContentText>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel required>分類</InputLabel>
                        <Select name="category" value={formData.category} onChange={handleSelect}>
                            <MenuItem value="bug">{categoryToText('bug')}</MenuItem>
                            <MenuItem value="uiuximprovement">{categoryToText('uiuximprovement')}</MenuItem>
                            <MenuItem value="incompletedata">{categoryToText('incompletedata')}</MenuItem>
                            <MenuItem value="other">{categoryToText('other')}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label="件名"
                            name="title"
                            required
                            value={formData.title}
                            onChange={handleChange}
                            inputProps={{ maxLength: 100 }}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label="本文"
                            multiline
                            rows={8}
                            name="description"
                            required
                            value={formData.description}
                            onChange={handleChange}
                            inputProps={{ maxLength: 2000 }}
                        />
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions sx={{ columnGap: 2, paddingInline: 3, paddingBottom: 3 }}>
                <Button variant="contained" color="primary" size="large" onClick={handleSubmit} disabled={!canSend()}>
                    <SendIcon sx={{ mr: 1.5 }} fontSize="small" />
                    送信
                </Button>
                <Button onClick={handleClose} size="large" disabled={isLoading}>
                    キャンセル
                </Button>
            </DialogActions>
        </Dialog>
    );
};
