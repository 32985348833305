import * as React from 'react';
import { useState } from 'react';
import { FileField, FileInput, Identifier, NumberInput, RadioButtonGroupInput, SimpleForm, TextInput, usePermissions } from 'react-admin';
import { AccordionSection } from '@react-admin/ra-form-layout';
import { CreateNode } from '@react-admin/ra-tree';
import { matchPath, useLocation } from 'react-router-dom';
import { ContentType } from '../types/contentType';
import OrganizationArrayInput from './OrganizationArrayInput';
import { ParagraphMarkdownInput } from './ParagraphMarkdownInput';
import PhaseSelectInput from './PhaseSelectInput';
import {S3FilesInput} from './S3FilesInput';
import {Tooltip} from '@mui/material';
import {Help} from '@mui/icons-material';


// a Create view for a tree uses <CreateNode> instead of the standard <Create>
type ContentCreateLocationState = {
    parentId?: Identifier;
};

export const ContentCreate = () => {
    const [contentType, setContentType] = useState<ContentType>('heading');
    const location = useLocation();
    const { permissions } = usePermissions();

    const state = location.state as ContentCreateLocationState;
    const match = matchPath('edit/documents/:document_id/contents/create', location.pathname);

    const radioValues: Array<{ id: ContentType; name: string }> = [];
    let radioDefaultValue = 'heading';
    if (permissions?.paragraphs?.includes('create')) {
        radioValues.push({ id: 'paragraph', name: 'パラグラフ' });
    }
    if (permissions?.headings?.includes('create')) {
        radioValues.push({ id: 'heading', name: '見出し' });
    } else {
        radioDefaultValue = 'paragraph';
    }

    const documentId = parseInt((match as any).params.document_id);
    const tmpContentPath = `${state.parentId || ''}/create` // 新規作成時はコンテンツパスが未確定

    const handleContentTypeChange = (e: any) => {
        setContentType(e.target.value);
    };

    return (
        <CreateNode title="目次（最新修正案）の作成">
            <SimpleForm>
                <NumberInput
                    source="document_id"
                    defaultValue={documentId}
                    name="document_id"
                    label="ドキュメントID"
                    disabled
                    sx={{ display: 'none' }}
                />

                {state.parentId ? (
                    <TextInput
                        source="parent_path"
                        defaultValue={state.parentId}
                        name="parent_path"
                        label="親コンテンツ"
                        fullWidth
                        disabled
                    />
                ) : (
                    <>
                        <TextInput
                            source="parent_path"
                            value={null}
                            name="parent_path"
                            disabled
                            sx={{ display: 'none' }}
                        />
                        <p>ルートコンテンツ</p>
                    </>
                )}
                <RadioButtonGroupInput
                    source="content_type"
                    label="コンテンツタイプ"
                    choices={radioValues}
                    defaultValue={radioDefaultValue}
                    onChange={handleContentTypeChange}
                    name="content_type"
                />
                {contentType === 'heading' ? (
                    <TextInput
                        name="body"
                        source="body"
                        label="内容"
                        maxRows={3}
                        multiline
                        fullWidth
                        inputProps={{ maxLength: 100 }}
                    />
                ) : (
                    <>
                        <PhaseSelectInput source="phase" />
                        <OrganizationArrayInput type="responsible_organization" />

                        <OrganizationArrayInput type="co_responsible_organization" />

                        <ParagraphMarkdownInput />
                        {/*<IodMarkdownInput*/}
                        {/*    name="body" source="body"*/}
                        {/*    label="編集エリア"*/}
                        {/*    hideModeSwitch={true} // 202401 第3回ユーザテスト向けに、markdownのみに修正*/}
                        {/*/>*/}
                        <AccordionSection label="画像中の文言" fullWidth>
                            <TextInput
                                name="embedded_text"
                                source="embedded_text"
                                label="画像中の文言"
                                maxRows={10}
                                multiline
                                fullWidth
                                inputProps={{ maxLength: 1000 }}
                            />
                        </AccordionSection>
                    </>
                )}

                <TextInput
                    name="note"
                    source="note"
                    label="備考"
                    maxRows={3}
                    multiline
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                />

                {contentType === 'paragraph' && (
                    <S3FilesInput
                        documentId={documentId} contentPath={tmpContentPath}
                        source="files"
                        label={
                            <>
                                関連ファイル
                                <Tooltip
                                    placement="top"
                                    title="本文中に含まれる表の元データ（xlsxやdocxファイル等）を添付してください"
                                >
                                    <Help sx={{position: 'relative', top: '5px'}} />
                                </Tooltip>
                            </>
                        }
                    />
                )}
            </SimpleForm>
        </CreateNode>
    );
};
