import React, { FC } from 'react';
import { EditButton as RaEditButton, EditButtonProps as RaEditButtonProps } from 'react-admin';

export interface EditButtonProps extends Omit<RaEditButtonProps, 'label'> {
    // Omitで指定したプロパティ以外を継承
}

export const EditButton: FC<EditButtonProps> = (props) => {
    return <RaEditButton label="編集" {...props} />;
};
