import React, { useEffect, useRef, useState } from 'react';
import { FileInput, FileInputProps, useInput, useNotify, useRecordContext } from 'react-admin';
import { Box, Button, CircularProgress } from '@mui/material';
import { httpClient } from '../../../components/functional/dataProvider';
import { API_URL } from '../../../config';

interface Props extends Omit<FileInputProps, 'onChange' | 'multiple'> {
    documentId: number;
    contentPath: string;
}

export interface RaFile {
    rawFile: File;
    src: string;
    title: string;
    [key: string]: any; // インデックスシグネチャを追加
}

export const S3FilesInput = (props: Props) => {
    const { documentId, contentPath, ...fileInputProps } = props;
    const { field } = useInput(props);
    const notify = useNotify();
    const [uploading, setUploading] = useState(false);
    const endRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (endRef.current) {
            endRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [field.value]);

    const handleFilesChange = async (files: Array<RaFile | File>) => {
        if (!files || files.length === 0) {
            return;
        }

        if (field.value && files.length < field.value.length) {
            return;
        }

        setUploading(true);
        const updatedFiles = [...(field.value || [])]; // 現在のファイルリストを取得

        try {
            for (const file of files) {
                if (file instanceof File) {
                    const raFile = await uploadToS3(file);
                    // console.log(raFile);
                    updatedFiles.push(raFile); // 新しいファイルを追加
                }
            }

            // 成功時は通知いらなそう
            // notify('resources.paragraphs.custom.success_to_file_upload', { type: 'info' });
        } catch (error) {
            console.error(error);
            notify('resources.paragraphs.custom.failed_to_file_upload', { type: 'error' });
        } finally {
            // ファイルのアップロードに失敗した場合も、失敗を反映するためにフィールドの更新が必要
            field.onChange(updatedFiles); // フィールドを更新
            setUploading(false);
        }
    };

    const uploadToS3 = async (file: File): Promise<RaFile> => {
        const filename = file.name;

        const get_upload_url_api = `${API_URL}/documents/${documentId}/paragraphs/${encodeURIComponent(
            contentPath,
        )}/files`;
        const { json } = await httpClient(get_upload_url_api, {
            method: 'POST',
            body: JSON.stringify({ filename: filename }),
        });

        const formData = new FormData();
        Object.keys(json.fields).forEach((key) => formData.append(key, json.fields[key]));
        formData.append('file', file);

        // プリサインドURLでs3にファイルをアップロード
        const response = await fetch(json.url, {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'multipart/form-data'  // 注意: fetch APIでは、このヘッダーを設定しなくても、自動的に適切なバウンドリを設定します。
            // },
            body: formData,
        });

        if (!response.ok) {
            throw Error('Failed to upload file to S3.');
        }

        return {
            rawFile: file,
            title: file.name,
            // src: `${json.url}${json.fields.key}`,
            src: `${json.fields.key}`,
            // src: `${json.filename}`,
        };
    };

    return (
        <Box>
            <FileInput
                {...fileInputProps}
                onChange={handleFilesChange}
                maxSize={500 * 1000 * 1000} // 最大500MBまで（仮）
                multiple
                helperText={
                    // helperTextの親要素がpタグなので内側でpタグは使えない
                    <>
                        ※ここにアップロードしたファイルは一般には公開されませんが、編集者（都各局等・関係機関）であればダウンロードできます。
                        <br />
                        ※個人情報を含むファイルや機密文書をアップロードしないようにしてください。
                    </>
                }
            >
                {/* 独自のファイル */}
                <CustomFileField source="src" documentId={documentId} contentPath={contentPath} />
                {/*<FileField source="src" title="title" />*/}
            </FileInput>
            {uploading && <CircularProgress />}
            {/* ファイル追加時に追加したファイルを見失わないように画面をスクロールする */}
            <span ref={endRef} />
        </Box>
    );
};

interface CustomFileFieldProps {
    source: string;
    documentId: number;
    contentPath: string;
}

const CustomFileField = ({ source, documentId, contentPath }: CustomFileFieldProps) => {
    const recordContext = useRecordContext<RaFile>();
    const notify = useNotify();

    const handleClick = async () => {
        try {
            const downloadUrl = await getDownloadUrl(documentId, contentPath, recordContext[source]);
            window.open(downloadUrl, '_blank');
        } catch (error) {
            console.error(error);
            notify('resources.paragraphs.custom.failed_to_file_download', { type: 'error' });
        }
    };

    return <Button onClick={handleClick}>{recordContext.title}</Button>;
};

const getDownloadUrl = async (documentId: number, contentPath: string, filePath: string) => {
    const url = `${API_URL}/documents/${documentId}/paragraphs/${encodeURIComponent(
        contentPath,
    )}/files/${encodeURIComponent(filePath)}`;
    const { json } = await httpClient(url, {
        method: 'GET',
    });
    return json.url;
};
