import {FC, MouseEvent} from 'react';
import {Button, useRecordContext} from 'react-admin';
import {EditInfo} from '../../../types/editInfo';

interface MergeRequestButtonProps {
    label?: string;
    onClick: (e: MouseEvent) => void;
}

export const MergeRequestButton: FC<MergeRequestButtonProps> = ({label, onClick}) => {
    const handleClick = (e: MouseEvent) => {
        e.stopPropagation(); // クリックイベントを行に伝搬させない様にする
        onClick(e); // 渡されたイベントを実行
    };

    return (
        <Button
            label={label ?? '承認依頼'}
            variant="text"
            onClick={handleClick}
            // sx={{width: '85px', height: '35px'}}
        />
    )
};
