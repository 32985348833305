import * as React from 'react';
import { FC } from 'react';
import { FunctionField, Labeled } from 'react-admin';
import { TextField } from '@mui/material';

export const PhaseField: FC = () => {
    return (
        <FunctionField
            // render={(record: Paragraph) => { // npm update後、型エラーになるようになったため一旦anyで逃げる
            render={(record: any) => {
                let label = '';
                switch (record.phase) {
                    case 'yobou':
                        label = '予防';
                        break;
                    case 'oukyu':
                        label = '応急';
                        break;
                    case 'fukkyu':
                        label = '復旧';
                        break;
                    default:
                        return '';
                }
                return (
                    <TextField
                        label="フェーズ"
                        value={label}
                        size="small"
                        variant="standard"
                        inputProps={{ readOnly: true }}
                        className="font-size-changeable"
                        sx={{
                            pointerEvents: 'none', // マウスオーバーやクリックイベントを無効化
                            '&:hover': {
                                backgroundColor: 'transparent', // ホバー時の背景色変化を防止
                            },
                        }}
                    />
                );
            }}
        />
    );
};
