import * as JsDiff from 'diff';
import {
    Document as DocxDocument,
    Paragraph as DocxParagraph,
    Table as DocxTable,
    TableCell,
    TableRow,
    TextRun,
} from 'docx';
import { DocxCell, DocxInfo, DocxRow } from './diffToDocxRowsConverter';
import { ADDED_METADATA, DELETED_METADATA } from './docxXmlFactory';
import { ADDED_PLACEHOLDER, DELETED_PLACEHOLDER } from './paragraphDiffProcessor';
import { FONT_SIZE_DOCX_TABLE } from './utils/size';

class DocxTableConverter {
    // constructor( property: type, ) {} // 必要ならコンストラクタを定義

    /**
     * 1.マークダウン表があればdocxライブラリ形式の表に変換
     *   1-1.Word上の行（に紐づく範囲）ごとにマークダウン表の存在を確認
     *   1-2.存在するのであればマークダウン表を、docxライブラリ形式の表に変換
     * 2.変換した表をプロパティに持たせる
     */
    public convertMdTbl2DocxTbl = (docxInfoList: DocxInfo[]): DocxInfo[] => {
        // マークダウン表をdocxライブラリ形式のテーブルに変換してプロパティに持たせる
        const tableConvertedDocxTableInfoList: DocxInfo[] = docxInfoList.map((originalDocxInfo: DocxInfo): DocxInfo => {
            // Word上の行（に紐づく範囲）の単位に処理する
            const processedDocxRow: DocxRow[] = originalDocxInfo.docxRows.map((originalDocxRow: DocxRow): DocxRow => {
                const docxRow: DocxRow = JSON.parse(JSON.stringify(originalDocxRow)); // DeepCopyを作成して副作用を回避

                // マークダウン表がある行（に紐づく範囲）のみ処置する
                if (docxRow.beforeBody.bodyType === 'MdTable') {
                    // 編集前がマークダウンテーブルなら
                    const docxTable = this.convertBeforeMdTableToDocxTable(docxRow.beforeBody);
                    docxRow.beforeBody.docxTable = docxTable;
                }
                if (docxRow.afterBody.bodyType === 'MdTable') {
                    // 編集後がマークダウンテーブルなら
                    const docxTable = this.convertAfterMdTableToDocxTable(docxRow.afterBody);
                    docxRow.afterBody.docxTable = docxTable;
                }

                return docxRow;
            });

            // 新しいオブジェクトを作成して、副作用を回避
            const docxInfo = { ...originalDocxInfo };
            docxInfo.docxRows = processedDocxRow;
            return docxInfo;
        });

        // 変換後のテーブルを追加したオブジェクトを返却
        return tableConvertedDocxTableInfoList;
    };

    /**
     * 編集前のマークダウン表を変換する
     *
     * 差分情報を用いて、最小の要素から順に、表（XML）を構築する
     * テキスト -> 段落 -> 表のセル -> 表の行 -> 表の全体
     *
     * FIXME: 近似した処理がある、理想は共通化したい｛convertAfterMdTableToDocxTable｝
     */
    private convertBeforeMdTableToDocxTable = (docxCell: DocxCell): DocxTable => {
        const tableRows: TableRow[] = [];
        let textRuns: TextRun[] = [];

        docxCell.bodies.forEach((body, index: number) => {
            // 差分：マークダウン表の行単位

            if (this.isHeaderSeparator(body.body)) {
                // ヘッダ行とデータ行の境目の場合は何もしない
                // console.log(body.body)
                return;
            }

            const tableCells: TableCell[] = [];

            body.changes.forEach((change: JsDiff.Change) => {
                // 差分：変更された文字の単位

                if ((!change.removed && !change.added) || change.removed === true) {
                    // 変更なし文字列(context) または 削除された文字列

                    // プレースホルダがある場合は置き換える
                    if (change.value === ADDED_PLACEHOLDER) {
                        change.value = ADDED_METADATA;
                    }

                    const values: string[] = Array.from(change.value);
                    values.forEach((value, index) => {
                        // 一文字ずつの配列に分割して解析する

                        if (value === '|') {
                            // セルの区切りと見みなしてセルを作成する

                            // 段落とセルを同時に作成
                            const tableCell = new TableCell({
                                children: [
                                    new DocxParagraph({
                                        children: textRuns, // コンストラクタに累積テキストを渡す
                                    }),
                                ],
                            });

                            // 累積セルに反映
                            tableCells.push(tableCell);

                            textRuns = [];
                        } else {
                            // テキストの累積のみ行う

                            const textRun = new TextRun({
                                text: value,
                                size: FONT_SIZE_DOCX_TABLE,
                                color: change.removed ? '#FF0000' : undefined, //red
                            });

                            // 累積テキストに反映
                            textRuns.push(textRun);
                        }
                    });
                }
            });

            // 累積行に反映
            const tableRow = new TableRow({
                children: tableCells,
            });

            tableRows.push(tableRow);
        });

        const docxTable: DocxTable = new DocxTable({
            rows: tableRows, // コンストラクタに累積行を渡す
        });

        return docxTable;
    };

    private convertAfterMdTableToDocxTable = (docxCell: DocxCell): DocxTable => {
        const tableRows: TableRow[] = [];
        let textRuns: TextRun[] = [];

        docxCell.bodies.forEach((body, index: number) => {
            // 差分：マークダウン表の行単位

            if (this.isHeaderSeparator(body.body)) {
                return;
            }

            const tableCells: TableCell[] = [];

            body.changes.forEach((change: JsDiff.Change) => {
                // 差分：変更された文字の単位

                if ((!change.removed && !change.added) || change.added === true) {
                    // 変更なし文字列(context) または 追加された文字列

                    // プレースホルダがある場合は置き換える
                    if (change.value === DELETED_PLACEHOLDER) {
                        change.value = DELETED_METADATA;
                    }

                    const values: string[] = Array.from(change.value);
                    values.forEach((value, index) => {
                        // 一文字ずつの配列に分割して解析する

                        if (value === '|') {
                            // セルの区切りと見みなしてセルを作成する

                            // 段落とセルを同時に作成
                            const tableCell = new TableCell({
                                children: [
                                    new DocxParagraph({
                                        children: textRuns, // コンストラクタに累積テキストを渡す
                                    }),
                                ],
                            });

                            // 累積セルに反映
                            tableCells.push(tableCell);

                            // 累積テキストをリセット
                            textRuns = [];
                        } else {
                            // テキストの累積のみ行う

                            const textRun = new TextRun({
                                text: value,
                                size: FONT_SIZE_DOCX_TABLE,
                                color: change.added ? '#FF0000' : undefined, //red
                                // color: change.added ? '#97F295' : undefined, //green
                            });

                            // 累積テキストに反映
                            textRuns.push(textRun);
                        }
                    });
                }
            });

            // 累積行に反映
            const tableRow = new TableRow({
                children: tableCells,
            });
            tableRows.push(tableRow);
        });

        // docxライブラリ形式の表を作成する
        const docxTable: DocxTable = new DocxTable({
            rows: tableRows, // コンストラクタに累積行を渡す
        });

        return docxTable;
    };

    /**
     * テーブルのヘッダ行とデータ行の境目の行であれば true
     */
    public isHeaderSeparator = (line: string): boolean => {
        // 正規表現を用いて、ハイフンとコロン、空白のみを含む行をマッチさせる
        // 各セルは少なくとも1つのハイフンを含み、オプショナルでコロンを端に持つ
        const separatorPattern = /^\s*\|(\s*:?-+:?\s*\|)+\s*$/;

        // 正規表現で行がマッチするかチェック
        return separatorPattern.test(line);
    };
}
export { DocxTableConverter };
