import { useMemo } from 'react';
import { useSidebarState } from 'react-admin';
import { useTheme } from '@mui/material/styles';
import { IodThemeOptions } from '../components/functional/themes';

/**
 * 環境情報を取得するカスタムフック
 *
 * 環境依存のコードは本番リリースまでにテストが困難なので乱用しないでください。
 *
 * 利用例：
 * const { isLocal, isDevelopment, isProduction } = useEnv();
 * return (
 *     <div>
 *       {isLocal && <p>We are in Local environment</p>}
 *       {isDevelopment && <p>We are in Development(or Local) environment</p>}
 *       {isProduction && <p>We are in Production(or Staging) environment</p>}
 *     </div>
 *   );
 */
export const useEnv = () => {
    const env = process.env.REACT_APP_ENV;

    const isLocal = useMemo(() => env === 'local', [env]);
    const isDevelopment = useMemo(() => env === 'local' || env === 'development', [env]);
    const isProduction = useMemo(() => env === 'staging' || env === 'production', [env]);

    return {
        isLocal,
        isDevelopment,
        isProduction,
    };
};
