import { FC } from 'react';
import { Labeled, useListContext } from 'react-admin';
import { Button, IconButton, Tooltip } from '@mui/material';
import CancelOutlined from '@mui/icons-material/CancelOutlined';

type FilterClearProps = {
    source: string; // これがないとエラーが表示される
    alwaysOn: any;
    /**
     * クリアボタンクリック時のfilter状態を設定する場合に指定
     */
    defaultFilters?: any;
};

/**
 *  検索条件クリアボタン
 */
export const FilterClearButton: FC<FilterClearProps> = (props) => {
    // フィルタリング操作用
    const { setFilters } = useListContext();

    const handleClearFilters = () => {
        let filters = {}
        if (props.defaultFilters) {
            filters = props.defaultFilters;
        }
        setFilters(filters, {}, false); // フィルターをクリア
    };

    return (
        <Tooltip title={'検索条件をクリア'}>
            <IconButton
                size="large"
                onClick={handleClearFilters}
                sx={{
                    marginInline: '1px',
                }}
            >
                <CancelOutlined />
            </IconButton>
        </Tooltip>
    );
};
