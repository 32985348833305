import { ReviewStatsInfo } from '../types/reviewStatsInfo';

export enum ReviewStatusCode {
    editing = 0,
    confirming = 1,
    approving = 2,
    fixed = 3,
    rejected = 4,
    withdrawn = 5,
}

/**
 * 画面表示向けのステータスを判定する関数
 */
export const determineReviewStatus = (isRequested: boolean, reviewStats?: ReviewStatsInfo): ReviewStatusCode => {
    if (!isRequested) {
        return ReviewStatusCode.editing;
    }

    if (!reviewStats) {
        throw new Error('Review stats cannot be empty when a merge request is pending.');
    }

    if (reviewStats.status === 'fixed') {
        return ReviewStatusCode.fixed;
    } else if (reviewStats.status === 'rejected') {
        return ReviewStatusCode.rejected;
    } else if (reviewStats.status === 'withdrawn') {
        return ReviewStatusCode.withdrawn;
    } else if (
        reviewStats.approver_approve_count > 0 &&
        reviewStats.approver_approve_count === reviewStats.approver_total_count
    ) {
        // 承認がすべて完了した状態
        return ReviewStatusCode.approving;
    } else if (reviewStats.approver_approve_count > 0) {
        // 承認が一部完了した状態;
        return ReviewStatusCode.approving;
    } else if (
        reviewStats.approver_total_count > 0 &&
        reviewStats.confirmer_approve_count === reviewStats.confirmer_total_count
    ) {
        // 確認がすべて完了した状態
        return ReviewStatusCode.approving;
    } else if (reviewStats.confirmer_approve_count > 0) {
        // 確認が一部完了した状態
        return ReviewStatusCode.confirming;
    } else {
        // 依頼済み
        return ReviewStatusCode.confirming;
    }
};
