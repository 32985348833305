import { FC, useEffect } from 'react';
import { ResourceContextProvider, usePermissions } from 'react-admin';
import { useExpandedKeys } from '@react-admin/ra-tree';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { usePathArray } from '../../../hooks/usePathArray';
import { useSidebarWidth } from '../../../hooks/useSidebarWidth';
import { ContentCreate } from './ContentCreate';
import { ContentEditShow } from './ContentEditShow';
import { ContentNodeActions } from './ContentNodeActions';
import { IodTreeWithDetails } from './IodTreeWithDetails';

// a List view for a tree uses <TreeWithDetails>

export const ContentTree: FC = () => {
    const [_, setExpandedKeys] = useExpandedKeys();
    const { document_id } = useParams();
    const location = useLocation();
    const match = matchPath('/edit/documents/:document_id/contents/*', location.pathname);
    const content_path = match?.params['*'] || '';
    const paths = usePathArray(content_path);

    useEffect(() => {
        setExpandedKeys(paths);
    }, [setExpandedKeys]);

    const sidebarWidth = useSidebarWidth();

    const { isLoading, permissions } = usePermissions();
    if (isLoading) {
        return <CircularProgress />;
    }
    // 権限がある場合のみ各ボタンを有効化する
    const hasCreatePolicy = permissions.headings.includes('create');

    return (
        <Box
        // sx={{
        //     display: 'flex',
        // }}
        >
            <ResourceContextProvider value={`edit/documents/${document_id}/contents`}>
                <IodTreeWithDetails
                    title="目次（最新修正案）"
                    titleField="body"
                    addRootButton={hasCreatePolicy ? undefined : false} // undefinedは未指定と同様なのでボタン表示される
                    create={ContentCreate}
                    linkTo="show"
                    show={ContentEditShow}
                    nodeActions={<ContentNodeActions />}
                    // draggable
                    // autoExpandParent={false}
                    allowMultipleRoots
                    // lazy={true}
                    // expandedKeys={[]}
                    defaultExpandedKeys={[]}
                    // addRootButton={false}
                    showLine
                    motion={false} // ツリーが固まる問題を解消するのに役立つ
                    sx={{
                        position: 'fixed', //スクロールを無効化
                        // ツリーコンポーネント全体の幅 = 画面の幅 - (サイドバーの幅 + 画面全体のスクロールバーの幅？)
                        width: `calc(100vw - ${sidebarWidth + 16}px)`,
                        '& .RaTreeWithDetails-cardTree': {
                            width: '40%', // %が効かない
                            height: 'calc(100vh - 70px)',
                            overflowY: 'auto', //以下参照https://developer.mozilla.org/en-US/docs/Web/CSS/overflow
                            overflowX: 'hidden',
                            position: 'relative',
                            '& .MuiTypography-root': {
                                width: '400px', // 仮の値で、これがないとtextOverflow: ellipsisが効かない
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                        },
                        '& .RaTreeWithDetails-actionCard': {
                            width: '60%',
                            height: 'calc(100vh - 70px)',
                            overflowY: 'auto',
                            overflowX: 'auto',
                            '& .edit-page': {
                                // position: 'fixed',
                                '& .RaEdit-main': {
                                    width: '99%',
                                },
                            },
                        },
                        '& .iod-tree-gutter': {
                            backgroundColor: 'none',
                            width: '8px',
                            marginTop: '1em',
                            '&:hover': {
                                backgroundColor: '#ccc',
                            },
                        },
                    }}
                    // hideRootNodes
                />
            </ResourceContextProvider>
        </Box>
    );
};

export const ContentsEdit = () => {
    const { document_id } = useParams();

    if (document_id === null) {
        return null;
    }

    return (
        <ResourceContextProvider value={`documents/${document_id}/contents`}>
            <ContentTree />
        </ResourceContextProvider>
    );
};
