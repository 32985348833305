import { Document as DocxDocument, Packer } from 'docx';
import { saveAs } from 'file-saver';
import { generateNowJstDate } from '../../../../utils/dateUtil';
import { DiffInfo as ResourceDiffInfo } from '../../types/diffInfo';
import { LineDiscussion } from '../../types/discussion/content-discussion/lineDiscussion';
import { DiffToDocxDocumentGenerator } from './diffToDocxDocumentGenerator';
import { ParagraphDiff, ParagraphDiffProcessor } from './paragraphDiffProcessor';

interface DocxExporterParam {
    diffs: ResourceDiffInfo[];
    discussionsList: LineDiscussion[][]; // 差分:ディスカッション:修正理由 1:1:N
}

class DocxExporter {
    // constructor( property: type, ) {} // 必要ならコンストラクタを定義

    export = async (param: DocxExporterParam) => {
        const { diffs, discussionsList } = param;

        // console.log(JSON.parse(JSON.stringify(diffs))); // DeepCopy
        // console.log(JSON.parse(JSON.stringify(discussionsList))); // DeepCopy

        // パラグラフ文字列から差分を検出して、行ごとの差分オブジェクトへの対応付けを行う
        // diffs: diffsAPIのレスポンス diffInfoという型定義名称だが差分ではなく、新旧パラグラフの文字列情報
        const processor = new ParagraphDiffProcessor();
        const paragraphDiffList: ParagraphDiff[] = processor.compare(diffs);

        // この時点では、オブジェクトはあくまで差分の情報であってWordは関係ない

        // 行ごとの差分オブジェクトをWord出力用の形式に変換する
        const generator = new DiffToDocxDocumentGenerator();
        const docxXmlObj: DocxDocument = await generator.generate({
            paragraphDiffList: paragraphDiffList,
            discussionsList: discussionsList,
        });

        // Word出力を行う
        const docxName = `new_old_sheet_${generateNowJstDate()}.docx`;
        const blob = await Packer.toBlob(docxXmlObj);
        saveAs(blob, docxName);
    };
}
export { DocxExporter };
