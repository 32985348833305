import {FC, useEffect, useState} from 'react';
import { useListContext } from 'react-admin';
import { TextField, TextFieldProps } from '@mui/material';

export interface IodSearchInputProps extends Omit<TextFieldProps, ''> {
    source: string;
    alwaysOn?: boolean;
}

export const IodSearchInput: FC<IodSearchInputProps> = ({
    source,
    alwaysOn: _,
    onBlur,
    onChange,
    onKeyDown,
    ...props
}) => {
    const { filterValues, setFilters, displayedFilters } = useListContext();
    const [value, setValue] = useState<string>('');
    // クリアボタンなどでフィルターの値が変わった場合に対応
    useEffect(() => {
        const filterValue = filterValues[source] ?? '';
        const value = filterValue;
        setValue(value);
    }, [source, filterValues]);

    const setFilter = (newValue: string) => {
        if (filterValues[source] !== newValue) {
            setFilters(
                {
                    ...filterValues,
                    [source]: newValue,
                },
                displayedFilters,
            );
        }
    };

    // イベントハンドラの設定
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setFilter(value);
        onBlur && onBlur(e);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        onChange && onChange(e);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (document.activeElement instanceof HTMLInputElement) {
                // フォーカスを外す際にブラーイベントが発生するので、検索される
                document.activeElement.blur();
            }
        }
        onKeyDown && onKeyDown(e);
    };


    return <TextField onBlur={handleBlur} onChange={handleChange} onKeyDown={handleKeyDown} value={value} {...props} />;
};
