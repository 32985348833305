import { Card, CardHeader } from '@mui/material';
import { DashboardComponent } from 'ra-core/dist/cjs/types';
import { InformationComponent } from '../../features/information/components/InformationComponent';

export const Dashboard: DashboardComponent = () => {
    return (
        <>
            <Card style={{ margin: '1em 0' }}>
                <CardHeader title="お知らせ" />
                <InformationComponent />
            </Card>
        </>
    );
};
