import { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import { CommitAndDiffContainer } from '../../workflow/components/CommitsAndDiffContainer';

export interface ParagraphCommit {
    id: number;
    document_id: number;
    content_path: string;
    content_id: number;
    updated_at: string;
    updated_by: string;
}

export interface IodParagraphHistoryDialogProps extends Omit<DialogProps, 'maxWidth' | 'fullWidth'> {
    documentId: number;
    contentPath: string;
}

export const IodParagraphHistoryDialog: FC<IodParagraphHistoryDialogProps> = ({
    documentId,
    contentPath,
    ...props
}) => {
    return (
        <Dialog {...props} maxWidth="xl" fullWidth={true}>
            <DialogTitle>履歴</DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
                <CommitAndDiffContainer documentId={documentId} contentPath={contentPath} />
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                <Button variant="contained" onClick={() => props.onClose?.({}, 'backdropClick')}>
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    );
};
