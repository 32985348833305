import { FC, memo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CancelButton } from '../../../../../components/ui/button/CancelButton';
import { LineDiscussionForm, LineDiscussionFormValue } from './LineDiscussionForm';

export interface LineCommentDialogProps {
    // commentDialogState: {
    //     open: boolean;
    //     lineNum?: number;
    // };
    open: boolean;
    dialogTitle: string;
    handleDialogClose: () => void;
    handleDialogCancel: () => void;
    handleDialogAdd: (commentEvent: LineDiscussionFormValue) => void;
}

export const LineDiscussionCreateDialog: FC<LineCommentDialogProps> = memo((props) => {
    const { open, dialogTitle, handleDialogClose, handleDialogCancel, handleDialogAdd } = props;

    const [formValue, setFormValue] = useState<LineDiscussionFormValue>({
        classification: '',
        comment: '',
        reason: '',
    });

    const handleFormChange = (formValue: LineDiscussionFormValue) => {
        setFormValue(formValue);
    };

    const isFormValid = () => {
        return formValue.classification && formValue.reason;
    };

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        console.log('フォームの値:', formValue);
        // ここでAPI呼び出し等の処理を行う
        handleDialogAdd({
            classification: formValue.classification,
            reason: formValue.reason,
            comment: formValue.comment,
        });
        // コメントが追加された後にフィールドをクリア
        setFormValue({
            classification: '',
            comment: '',
            reason: '',
        });
    };

    return (
        <Dialog open={open} maxWidth="sm" fullWidth onClose={handleDialogClose}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <LineDiscussionForm formValue={formValue} handleChange={handleFormChange} />
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                {/*<CancelButton onClick={handleDialogCancel}/>*/}
                <Button variant="outlined" onClick={handleDialogCancel}>
                    キャンセル
                </Button>
                <Button variant="contained" disabled={!isFormValid()} onClick={handleSubmit}>
                    追加
                </Button>
            </DialogActions>
        </Dialog>
    );
});
