import * as React from 'react';
import {
    DateField,
    DeleteButton,
    Edit,
    Labeled,
    NumberField,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    usePermissions,
} from 'react-admin';
import { CircularProgress } from '@mui/material';
import { IodToolbar } from '../../../components/ui/layout/IodToolbar';
import OrganizationArrayInput from '../../contents/components/OrganizationArrayInput';
import { ORGANIZATION_ITEM_NAME_KANA_MAX_LENGTH, ORGANIZATION_ITEM_NAME_MAX_LENGTH } from '../types/organization';

export const OrganizationEdit = () => {
    // 権限の取得
    const { isLoading, permissions } = usePermissions();
    if (isLoading) {
        return <CircularProgress />;
    }

    // 権限がある場合のみ各ボタンを有効化する
    const hasSavePolicy = permissions.organizations.includes('update');
    const hasDeletePolicy = permissions.organizations.includes('delete');

    return (
        <Edit>
            <SimpleForm
                toolbar={
                    <IodToolbar>
                        <SaveButton disabled={!hasSavePolicy} />
                        <DeleteButton disabled={!hasDeletePolicy} />
                    </IodToolbar>
                }
            >
                <Labeled label="組織ID">
                    <NumberField source="id" />
                </Labeled>
                <TextInput
                    source="name"
                    fullWidth
                    inputProps={{
                        maxLength: ORGANIZATION_ITEM_NAME_MAX_LENGTH,
                    }}
                />
                <TextInput
                    source="name_kana"
                    fullWidth
                    inputProps={{
                        maxLength: ORGANIZATION_ITEM_NAME_KANA_MAX_LENGTH,
                    }}
                />
                <NumberInput source="position" fullWidth></NumberInput>
                <OrganizationArrayInput />

                <Labeled label="作成日時">
                    <DateField source="created_at" showTime={true} fullWidth />
                </Labeled>
                <Labeled label="更新日時">
                    <DateField source="updated_at" showTime={true} fullWidth />
                </Labeled>
            </SimpleForm>
        </Edit>
    );
};
