import React, { FC, Fragment, SyntheticEvent, useEffect, useState } from 'react';
import { Box, Button, Stack, Tab, Tooltip } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CompressIcon from '@mui/icons-material/Compress';
import ExpandIcon from '@mui/icons-material/Expand';
import HistoryIcon from '@mui/icons-material/History';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { OutputFormatType } from 'diff2html/lib/types';
import { Identifier } from 'ra-core';
import { IodParagraphHistoryDialog } from '../../paragraphs/components/IodParagraphHistoryDialog';
import { CommentAdd, CommentUpdate } from '../types/comment';
import { ContentMetadata } from '../types/diffInfo';
import { LineDiscussion, LineDiscussionUpdate } from '../types/discussion/content-discussion/lineDiscussion';
import { DiffViewer, LineComment } from './DiffViewer';
import { IodDiffPreview } from './IodDiffPreview';
import { IodMetadataDiffViewer } from './IodMetadataDiffViewer';

type TabStateType = 'viewer' | 'preview';

interface IodDiffViewerProps {
    newContent: string;
    oldContent: string;
    newPath: string;
    oldPath: string;
    newMetadata: ContentMetadata | null;
    oldMetadata: ContentMetadata | null;
    oldUpdatedAt?: string;
    newUpdatedAt?: string;
    oldSnapshotName?: string;
    newSnapshotName?: string;
    document_id: number;
    discussions: LineDiscussion[];
    // onComment?: (commentData: CommentData) => void;
    // onReply?: (commentData: CommentData) => void;
    // onDelete?: (commentData: CommentData) => void;
    tools?: React.ReactNode[];
    canComment?: boolean;
    canReply?: boolean;
    showHistory?: boolean;
    showMetadataDiff?: boolean;
    onComment: (discussionAdd: LineComment) => void;
    onEditDiscussion?: (discussionId: Identifier, discussionUpdate: LineDiscussionUpdate) => void;
    onDeleteDiscussion?: (discussionId: Identifier) => void;
    onReply: (commentAdd: CommentAdd) => void;
    onEditReply?: (commentId: Identifier, commentUpdate: CommentUpdate) => void;
    onDeleteReply?: (commentId: Identifier) => void;
}

/**
 * 2つのコンテンツの差分を表示するためのコンポーネント。
 *
 * 特定のAPIなどに依存しないようにすること。
 */
export const IodDiffViewer: FC<IodDiffViewerProps> = ({
    newContent,
    oldContent,
    newPath,
    oldPath,
    newMetadata,
    oldMetadata,
    oldUpdatedAt,
    newUpdatedAt,
    oldSnapshotName,
    newSnapshotName,
    document_id,
    discussions,
    // onComment,
    // onReply,
    // onDelete,
    tools,
    canComment,
    canReply,
    showHistory,
    showMetadataDiff,
    onComment,
    onEditDiscussion,
    onDeleteDiscussion,
    onReply,
    onEditReply,
    onDeleteReply,
}) => {
    const [fullContext, setFullContext] = useState<boolean>();
    const [outputFormat, setOutputFormat] = useState<OutputFormatType>('side-by-side');
    const [showDiscussion, setShowDiscussion] = useState<boolean>(true);
    const [limitLineNumber, setLimitLineNumber] = useState<number>(0);

    useEffect(() => {
        // 「全文表示に変更」クリック時の差分表示上限行数。あまりに長くなると非常に重くなるためリミットをつける。
        const LIMIT_LINE_NUM = 1000;
        const getLineBreakCount = (str: string): number => {
            return (str.match(/\n/g) || []).length;
        };

        const maxLineNum = Math.max(getLineBreakCount(oldContent), getLineBreakCount(newContent));
        setLimitLineNumber(Math.min(maxLineNum, LIMIT_LINE_NUM));
    }, [oldContent, newContent]);

    const [tabState, setTabState] = useState<TabStateType>('viewer');
    const handleTabChange = (e: SyntheticEvent, newValue: string) => {
        setTabState(newValue as TabStateType);
    };

    // 各パラグラフの履歴状態管理
    const [historyDialogState, setHistoryDialogState] = useState<{
        documentId: number;
        contentPath: string;
        open: boolean;
    }>({
        documentId: 0,
        contentPath: '',
        open: false,
    });
    const handleHistoryDialogOpen = (open: boolean, documentId?: number, contentPath?: string) => () => {
        setHistoryDialogState({
            documentId: documentId ?? historyDialogState.documentId,
            contentPath: contentPath ?? historyDialogState.contentPath,
            open: open,
        });
    };

    const handleContextChange = () => {
        setFullContext(!fullContext);
    };

    const handleOutputFormatChange = () => {
        if (outputFormat === 'line-by-line') {
            setOutputFormat('side-by-side');
        } else {
            setOutputFormat('line-by-line');
        }
    };

    const handleShowDiscussionChange = () => {
        setShowDiscussion(!showDiscussion);
    };

    return (
        <>
            <Box sx={{ px: 2, width: '100%' }}>
                <TabContext value={tabState}>
                    <TabList onChange={handleTabChange} sx={{ borderBottom: '1px solid rgba(0, 0, 0, .12)' }}>
                        <Tab label={'差分（テキスト）'} value={'viewer'} sx={{ px: 4 }} />
                        <Tab label={'差分（プレビュー）'} value={'preview'} sx={{ px: 4 }} />
                        {showMetadataDiff && <Tab label={'差分（付属情報）'} value={'metadata'} sx={{ px: 4 }} />}{' '}
                    </TabList>
                    <TabPanel value={'viewer'} sx={{ padding: 0 }}>
                        <Box
                            id={outputFormat === 'line-by-line' ? 'wordwrap' : 'nowordwrap'}
                            sx={{ margin: 0, width: '100%' }}
                        >
                            {/* line-by-line表示の時のみ長い差分は折り返して表示されるようにindex.scssで設定するために、上記idを設定 */}
                            <Stack sx={{ margin: 0, mb: 1 }} direction="row" gap={[0, 1]}>
                                {tools?.map((tool, index) => <Fragment key={index}>{tool}</Fragment>)}
                                {showHistory && (
                                    <Button
                                        onClick={handleHistoryDialogOpen(true, document_id, newPath)}
                                        sx={{ columnGap: 0.5, pl: 1.25 }}
                                    >
                                        <HistoryIcon />
                                        編集履歴を表示
                                    </Button>
                                )}
                                <Button onClick={handleOutputFormatChange} sx={{ columnGap: 0.5, pl: 1.25 }}>
                                    <SplitscreenIcon
                                        sx={{
                                            transform: `rotateZ(${outputFormat === 'line-by-line' ? 90 : 0}deg)`,
                                        }}
                                    />
                                    {outputFormat === 'line-by-line' ? '左右表示' : '上下表示'}
                                    に変更
                                </Button>
                                <Tooltip title="全文表示時でも、非常に行数が多い場合省略します" placement="top">
                                    <Button onClick={handleContextChange} sx={{ columnGap: 0.5, pl: 1.25 }}>
                                        {fullContext ? <CompressIcon /> : <ExpandIcon />}
                                        {fullContext ? '縮小表示' : '全文表示'}
                                        に変更
                                    </Button>
                                </Tooltip>
                                {outputFormat === 'side-by-side' && (
                                    <Button onClick={handleShowDiscussionChange} sx={{ columnGap: 0.5, pl: 1.25 }}>
                                        <ChatBubbleOutlineIcon />
                                        修正理由等を
                                        {showDiscussion ? '非表示' : '表示'}
                                    </Button>
                                )}
                            </Stack>
                            <DiffViewer
                                newContent={newContent}
                                oldContent={oldContent}
                                newTitle={newPath}
                                oldTitle={oldPath}
                                oldUpdatedAt={oldUpdatedAt}
                                newUpdatedAt={newUpdatedAt}
                                oldSnapshotName={oldSnapshotName}
                                newSnapshotName={newSnapshotName}
                                // oldHeader="変更前の追加情報"
                                // newHeader="変更後の追加情報"
                                outputFormat={outputFormat}
                                context={fullContext ? limitLineNumber : 2}
                                diffMaxLineLength={50000}
                                // diffMaxLineLength={2000}
                                // diffMaxChanges={300}
                                sx={{
                                    width: '100%',
                                    margin: 0,
                                    '& .d2h-file-wrapper': {
                                        margin: 0,
                                    },
                                    // 差分表示のグレー部分を非表示にしたい場合（※行番号に沿った表示にする）
                                    // '& .d2h-code-side-linenumber.d2h-code-side-emptyplaceholder': {
                                    //     display: 'none',
                                    // },
                                    // '& .d2h-code-side-line.d2h-code-side-emptyplaceholder': {
                                    //     display: 'none',
                                    // },
                                }}
                                discussions={discussions}
                                showDiscussion={showDiscussion}
                                onComment={onComment}
                                onReply={onReply}
                                onEditReply={onEditReply}
                                onDeleteReply={onDeleteReply}
                                onEditDiscussion={onEditDiscussion}
                                onDeleteDiscussion={onDeleteDiscussion}
                                canComment={canComment}
                                canReply={canReply}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value={'preview'} sx={{ p: 1 }}>
                        {/* 差分（プレビュー）*/}
                        <IodDiffPreview
                            oldContent={oldContent ?? ''}
                            newContent={newContent ?? ''}
                            oldUpdatedAt={oldUpdatedAt ?? ''}
                            newUpdatedAt={newUpdatedAt ?? ''}
                            oldSnapshotName={oldSnapshotName ?? ''}
                            newSnapshotName={newSnapshotName ?? ''}
                        />
                    </TabPanel>
                    <TabPanel value={'metadata'} sx={{ p: 0 }}>
                        {/* 差分（付属情報）*/}
                        {showMetadataDiff && (
                            <IodMetadataDiffViewer
                                newMetadata={newMetadata}
                                oldMetadata={oldMetadata}
                                oldUpdatedAt={oldUpdatedAt ?? ''}
                                newUpdatedAt={newUpdatedAt ?? ''}
                                oldSnapshotName={oldSnapshotName ?? ''}
                                newSnapshotName={newSnapshotName ?? ''}
                            />
                        )}
                    </TabPanel>
                </TabContext>
            </Box>
            {showHistory && (
                <IodParagraphHistoryDialog
                    documentId={historyDialogState.documentId}
                    contentPath={historyDialogState.contentPath}
                    open={historyDialogState.open}
                    onClose={handleHistoryDialogOpen(false)}
                    hideBackdrop={true}
                />
            )}
        </>
    );
};
