import { FC } from 'react';
import { ChipField, Labeled, ReferenceArrayField, SingleFieldList, useRecordContext } from 'react-admin';
import { OrganizationComponentType } from '../../organizations/types/organizationComponentType';
import { ContentNode } from '../types/contentNode';

type Props = {
    type: OrganizationComponentType;
};

export const OrganizationArrayField: FC<Props> = (props) => {
    // const record = useRecordContext<ContentNode>();
    const source_str = `${props.type}_ids`;
    //
    // if (!(source_str in record)) {
    //     return null
    // }

    return (
        <Labeled label={props.type == 'responsible_organization' ? '主管組織' : '共管組織'} onClick={() => false}>
            <ReferenceArrayField source={source_str} reference="organizations" perPage={200}>
                <SingleFieldList sx={{ minHeight: '1.5em' }}>
                    <ChipField
                        sx={{
                            '&.RaChipField-chip': {
                                margin: 1,
                            },
                        }}
                        label={props.type == 'responsible_organization' ? '主管組織' : '共管組織'}
                        source="name"
                        clickable={false}
                    />
                </SingleFieldList>
            </ReferenceArrayField>
        </Labeled>
    );
};
