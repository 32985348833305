import { RecordContextProvider } from 'react-admin';
import { Box, Stack, Typography } from '@mui/material';
import IodMarkdownField from '../../../components/ui/fields/IodMarkdownField';
import { formatDate } from '../../../utils/dateUtil';

interface IodDiffPreviewProps {
    oldContent: string;
    newContent: string;
    oldUpdatedAt: string;
    newUpdatedAt: string;
    oldSnapshotName: string;
    newSnapshotName: string;
}

export const IodDiffPreview = (props: IodDiffPreviewProps) => {
    const { oldContent, newContent, oldUpdatedAt, newUpdatedAt, oldSnapshotName, newSnapshotName } = props;

    // NOTE: 旧新テキストの差分によって装飾を施したい場合は以下のライブラリが有効
    // 差分検出: JsDiff.diffChars / HTML変換: marked

    return (
        <Stack direction={'row'} sx={{ width: '100%' }}>
            {/* old */}
            <Box sx={{ width: '50%' }}>
                <Box sx={{ mb: '0', backgroundColor: '#FEE8E9' }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                            variant="h6"
                            color="red"
                            sx={{
                                fontWeight: 'bold',
                                pl: 1,
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}
                        >
                            編集前　
                            <Typography>
                                {oldSnapshotName ? oldSnapshotName : oldUpdatedAt && formatDate(oldUpdatedAt)}
                            </Typography>
                        </Typography>
                    </Stack>
                </Box>
                <Box
                    sx={{
                        border: '1px solid #ccc',
                        borderRight: 'none',
                        borderTopLeftRadius: 6,
                        borderBottomLeftRadius: 6,
                        padding: 2,
                        mt: 1,
                    }}
                >
                    <IodDiffPreviewInner body={oldContent} />
                </Box>
            </Box>

            {/* new */}
            <Box sx={{ width: '50%', mb: 0.5 }}>
                <Box sx={{ mb: '0', backgroundColor: '#DDFFDD' }}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography
                            variant="h6"
                            color="green"
                            sx={{
                                fontWeight: 'bold',
                                pl: 1,
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}
                        >
                            編集後　
                            <Typography>
                                {newSnapshotName ? newSnapshotName : newUpdatedAt && formatDate(newUpdatedAt)}
                            </Typography>
                        </Typography>
                    </Stack>
                </Box>
                <Box
                    sx={{
                        border: '1px solid #ccc',
                        borderTopRightRadius: 6,
                        borderBottomRightRadius: 6,
                        padding: 2,
                        mt: 1,
                    }}
                >
                    <IodDiffPreviewInner body={newContent} />
                </Box>
            </Box>
        </Stack>
    );
};

const IodDiffPreviewInner = ({ body }: { body: string }) => {
    return (
        <RecordContextProvider value={{ body }}>
            <IodMarkdownField source="body" />
        </RecordContextProvider>
    );
};
