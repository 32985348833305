import { useEffect, useState } from 'react';
import { useGetIdentity, useGetList } from 'react-admin';
import { UserInfo } from '../../users/types/userInfo';
import { MergeRequestInfo } from '../types/mergeRequest';
import { ReviewInfo } from '../types/reviewInfo';

export const useDefaultReviewers = (allReviewers: UserInfo[] | undefined) => {
    const { data: userIdentity } = useGetIdentity();
    const [defaultReviewers, setDefaultReviewers] = useState<UserInfo[]>();

    const { data: latestMyMergeRequest } = useGetList<MergeRequestInfo>(
        // 自身の作成した承認依頼から、最新の依頼だけを取得する
        'merge_requests',
        {
            filter: {
                requestor: userIdentity?.id,
            },
            pagination: {
                page: 1,
                perPage: 1, // レスポンスの最大件数を1にして、疑似的なLimit:1とする
            },
            sort: {
                field: 'created_at',
                order: 'DESC',
            }, // 作成日時の降順
        },
        { enabled: !!userIdentity?.id },
    );

    useEffect(() => {
        if (!allReviewers || !latestMyMergeRequest) {
            return;
        }

        // 最新の承認依頼を取得しているため必ず1件
        const latestRequest = latestMyMergeRequest[0];

        // 承認依頼から、確認者の情報だけを抽出
        const extractedReviewers: Partial<UserInfo>[] = extractReviewers(latestRequest);

        // デフォルト確認者を決定する
        const defaultReviewers = filteredDefaultReviewers(allReviewers, extractedReviewers);
        setDefaultReviewers(defaultReviewers);
    }, [allReviewers, latestMyMergeRequest]);

    return defaultReviewers;
};

const extractReviewers = (latestMyMergeRequest: MergeRequestInfo): Partial<UserInfo>[] => {
    if (!latestMyMergeRequest) {
        return []; // 最新の承認依頼がない（過去に承認依頼を出していない）
    }

    // 確認者{is_approval_required:false}を抽出する
    const extractedReviewers: ReviewInfo[] = latestMyMergeRequest.reviews.filter(
        (review: ReviewInfo) => !review.is_approval_required,
    );

    return extractedReviewers.map((review) => ({
        id: review.reviewer_id,
        name: review.reviewer_name,
        status: review.status,
    }));
};

const filteredDefaultReviewers = (allReviewers: UserInfo[], extractedReviewers: Partial<UserInfo>[]): UserInfo[] => {
    const defaultReviewers: UserInfo[] = allReviewers.filter((allReviewer: UserInfo) => {
        // 承認依頼から抽出した確認者の中で、確認者候補に存在するユーザのみをデフォルト確認者として定める
        const existsReviewer = extractedReviewers.some(
            (extractedReviewer: Partial<UserInfo>) => extractedReviewer.id === allReviewer.id,
        );

        // 存在するユーザのみ新たなリストに含める
        return existsReviewer;
    });

    return defaultReviewers;
};
