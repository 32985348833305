import * as React from 'react';
import { useEffect, useState } from 'react';
import { RecordContextProvider, useGetList, useRedirect, useTranslate } from 'react-admin';
import { Box, CircularProgress, List, ListItem, Stack } from '@mui/material';
import IodMarkdownField from '../../../components/ui/fields/IodMarkdownField';
import { FavoriteButton } from '../../favorites/components/FavoriteButton';
import { Paragraph } from '../types/paragraph';
import { ListHeadingsField } from './ListHeadingsField';
import OrganizationsField from './OrganizationsField';
import { PhaseField } from './PhaseField';
import {ParagraphEditButton} from './ParagraphEditButton';

// import Basket from '../orders/Basket';
// import { Customer, Invoice } from '../types';

interface Props {
    onAfterLoad: () => void;
    paragraph: Paragraph;
    version?: string;
}

export const ParagraphListBefore = (props: Props) => {
    const [paragraphs, setParagraphs] = useState<Array<Paragraph>>([]);

    const sequence = props?.paragraph?.sequence;
    const perPage = 10;
    const startPage = 1;

    const { data, total, isLoading, error, isFetchedAfterMount } = useGetList(
        'paragraphs',
        {
            pagination: { page: startPage, perPage },
            sort: {
                field: 'sequence',
                order: 'DESC',
            },
            filter: {
                document_id: props.paragraph.document_id,
                sequence_to: sequence - 1,
                version: props.version ? props.version : undefined
            },
        },
        { enabled: !!sequence && sequence > 1 },
    );

    useEffect(() => {
        const list = data;
        if (list) {
            setParagraphs([...list].reverse());
        }
    }, [data]);

    useEffect(() => {
        if (isFetchedAfterMount) {
            // 要素を表示しきったタイミングで実行させたいが、正確なタイミングを検知する方法がわかるまで一旦タイムアウトで制御
            setTimeout(() => {
                // @ts-ignore
                props.onAfterLoad();
            }, 500);
        }
    }, [isFetchedAfterMount]);

    const translate = useTranslate();
    const redirect = useRedirect();

    // const record = useRecordContext<Invoice>();
    if (!sequence) {
        return null;
    }
    if (isLoading) {
        return <CircularProgress />;
        // return <Loading/>;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    return (
        <
            // sx={{
            // height: '300px',
            // overflowY: 'auto',
            // }}
        >
            <List
                sx={
                    {
                        // margin: '0',
                        // width: '50%'
                        // flexFlow: 'column',
                    }
                }
                disablePadding
                dense
            >
                {paragraphs.map((p, index) => {
                    return (
                        <RecordContextProvider value={p} key={p.id}>
                            <ListItem disablePadding alignItems="flex-start">
                                <Box
                                    sx={{
                                        // marginLeft: '5px',
                                        // marginRight: '5px',
                                        marginBottom: '1em',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        mb: 2,
                                    }}
                                >
                                    {p.headings.length > 0 && (
                                        <Stack direction={'row'} alignItems={'center'} gap={[0, 2]} sx={{mb: 2.5}}>
                                            <ListHeadingsField
                                                current_paragraph={p}
                                                pre_paragraph={paragraphs[index - 1]}
                                            />
                                            <Box>
                                                <FavoriteButton content_id={p.content_id} />
                                            </Box>
                                            {props.version === 'editing' && (
                                                <Box>
                                                    <ParagraphEditButton paragraph={p} />
                                                </Box>
                                            )}
                                        </Stack>
                                    )}

                                    <PhaseField />

                                    <Stack direction="row" spacing={1}>
                                        <OrganizationsField type="responsible_organization" />
                                        <OrganizationsField type="co_responsible_organization" />
                                    </Stack>
                                    <IodMarkdownField source="body" className="font-size-changeable" />
                                </Box>
                            </ListItem>
                            {/*<Divider></Divider>*/}
                        </RecordContextProvider>
                    );
                })}
            </List>
        </>
    );
};
