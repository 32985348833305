import * as React from 'react';
import { useRef } from 'react';
import { SimpleForm, useRecordContext, useRedirect, useTranslate } from 'react-admin';
import { Box, Divider, Grid, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IodMarkdownField from '../../components/ui/fields/IodMarkdownField';
import { useSidebarWidth } from '../../hooks/useSidebarWidth';
import { ParagraphDetail } from './ParagraphDetail';
import HeadingsField from './components/HeadingsField';
import OrganizationsField from './components/OrganizationsField';
import { ParagraphListAfter } from './components/ParagraphListAfter';
import { ParagraphListBefore } from './components/ParagraphListBefore';
import { PhaseField } from './components/PhaseField';
import { Paragraph } from './types/paragraph';

// import Basket from '../orders/Basket';
// import { Customer, Invoice } from '../types';

interface Props {
    refElem: any;
    onClose: () => void;
    onHeightChange: (newHeight: number) => void;
    version?: string;
}

export const ParagraphShow = (props: Props) => {
    const record = useRecordContext<Paragraph>();

    // const ref = React.createRef<HTMLDivElement>();
    const targetRef = useRef(null);

    const translate = useTranslate();
    const redirect = useRedirect();

    const scrollCurrent = () => {
        // @ts-ignore
        targetRef.current.scrollIntoView();
    };

    const sidebarWidth = useSidebarWidth();

    const childRef = useRef<HTMLDivElement>(null);
    const [childHeight, setChildHeight] = React.useState<number>(0);

    React.useEffect(() => {
        if (childRef.current) {
            const newHeight = childRef.current.scrollHeight;
            setChildHeight(newHeight);
            props.onHeightChange(newHeight); // 親コンポーネントに高さを伝える
        }
    }, [props.onHeightChange]);

    if (!record) return null;
    return (
        <Box
            pt={5}
            // sx={{
            //     backgroundColor: 'white',
            //     overflow: 'auto'
            // }}
        >
            <Box
                sx={{
                    // pt: {xs: 2, sm: 1},
                    top: '0',
                    pt: '48px',
                    position: 'fixed',
                    backgroundColor: 'white',
                    zIndex: 101,
                    minHeight: '70px',
                    width: `calc(100vw - ${sidebarWidth + 26}px)`,
                }}
                ref={childRef}
            >
                <Stack direction="row" p={2} overflow="auto" justifyContent="space-between">
                    {/*<Typography variant="h6" flex="1">*/}
                    {/*    {translate('resources.paragraphs.detail')}*/}
                    {/*</Typography>*/}
                    <HeadingsField></HeadingsField>

                    <IconButton onClick={props.onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Stack>
                {/*<ArrayField source="responsible_organizations">*/}
                {/*    <SingleFieldList>*/}
                {/*        <ChipField source="name"/>*/}
                {/*    </SingleFieldList>*/}
                {/*</ArrayField>*/}
                {/*<ArrayField source="co_responsible_organizations">*/}
                {/*    <SingleFieldList>*/}
                {/*        <ChipField source="name"/>*/}
                {/*    </SingleFieldList>*/}
                {/*</ArrayField>*/}
            </Box>

            <Box
                sx={
                    {
                        // marginTop: '148px',
                        // flexGrow: 1,
                        // width: '98%',
                        // maxWidth: 400,
                    }
                }
            >
                <SimpleForm
                    sx={{
                        pt: 0,
                        pb: 0,
                    }}
                    toolbar={false}
                >
                    <Grid
                        container
                        sx={{
                            flexFlow: 'column',
                        }}
                        // rowClick="edit"
                    >
                        <ParagraphListBefore onAfterLoad={scrollCurrent} paragraph={record} version={props.version} />
                        {/*<ParagraphInfiniteListBefore*/}
                        {/*    refElem={props.refElem}*/}
                        {/*    paragraph={record}*/}
                        {/*/>*/}

                        <span ref={targetRef}></span>

                        <Box
                            sx={{
                                // backgroundColor: "#dfe0e6",
                                borderLeft: '5px solid #399c4a',
                                marginLeft: '-7px',
                                paddingLeft: '17px',
                                // borderLeft: "5px solid #002063",
                                '::before': {
                                    width: '5px',
                                    position: 'absolute',
                                    left: '20px',
                                },
                            }}
                        >
                            <ParagraphDetail version={props.version}></ParagraphDetail>
                        </Box>

                        {/*<Divider></Divider>*/}

                        <ParagraphListAfter paragraph={record} version={props.version} />
                    </Grid>
                </SimpleForm>
            </Box>
        </Box>

        // <Card sx={{ width: 600, margin: 'auto' }}>
        //     <CardContent>
        //         <Grid container spacing={2}>
        //             <TextField source="id"/>
        //             <MarkdownField source="content"/>
        //
        //             <Grid item xs={6}>
        //                 <Typography variant="h6" gutterBottom>
        //                     Posters Galore
        //                 </Typography>
        //             </Grid>
        //             <Grid item xs={6}>
        //                 <Typography variant="h6" gutterBottom align="right">
        //                     Invoice {record.id}
        //                 </Typography>
        //             </Grid>
        //         </Grid>
        //         {/*<Grid container spacing={2}>*/}
        //         {/*    <Grid item xs={12} container alignContent="flex-end">*/}
        //         {/*        <ReferenceField*/}
        //         {/*            reference="customers"*/}
        //         {/*            source="customer_id"*/}
        //         {/*            link={false}*/}
        //         {/*        >*/}
        //         {/*            <CustomerField />*/}
        //         {/*        </ReferenceField>*/}
        //         {/*    </Grid>*/}
        //         {/*</Grid>*/}
        //         <Box height={20}>&nbsp;</Box>
        //         <Grid container spacing={2}>
        //             <Grid item xs={6}>
        //                 <Typography variant="h6" gutterBottom align="center">
        //                     Date{' '}
        //                 </Typography>
        //                 <Typography gutterBottom align="center">
        //                     {new Date(record.date).toLocaleDateString()}
        //                 </Typography>
        //             </Grid>
        //
        //             <Grid item xs={5}>
        //                 <Typography variant="h6" gutterBottom align="center">
        //                     Order
        //                 </Typography>
        //                 <ReferenceField
        //                     reference="commands"
        //                     source="command_id"
        //                     link={false}
        //                 >
        //                     <TextField
        //                         source="reference"
        //                         align="center"
        //                         component="p"
        //                         gutterBottom
        //                     />
        //                 </ReferenceField>
        //             </Grid>
        //         </Grid>
        //         {/*<Box margin="10px 0">*/}
        //         {/*    <ReferenceField*/}
        //         {/*        reference="commands"*/}
        //         {/*        source="command_id"*/}
        //         {/*        link={false}*/}
        //         {/*    >*/}
        //         {/*        <Basket />*/}
    );
};

// const CustomerField = () => {
//     const record = useRecordContext<Customer>();
//     return record ? (
//         <Typography>
//             {record.first_name} {record.last_name}
//             <br />
//             {record.address}
//             <br />
//             {record.city}, {record.zipcode}
//         </Typography>
//     ) : null;
// };
