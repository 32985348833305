import React, { FC, MouseEvent, useState } from 'react';
import { useDelete, useNotify, useRefresh } from 'react-admin';
import { Alert, IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { ErrorResponse } from '../../../types/errorResponse';
import { Commit } from '../../paragraphs/types/commit';

interface CommitHistoryItemActionProps {
    documentId: number;
    contentPath: string;
    commit: Commit;
    canRestore?: boolean;
    canDiscard?: boolean;
}

export const CommitHistoryItemAction: FC<CommitHistoryItemActionProps> = (props) => {
    const { canRestore, canDiscard, documentId, contentPath, commit } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const notify = useNotify();
    const refresh = useRefresh();
    // const theme = useTheme();
    const [deleteOne, { isLoading: isDeleting }] = useDelete();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        // メニューの表示位置を適切に制御するためにanchorElを利用する
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDiscard = (e: MouseEvent) => {
        e.stopPropagation();

        if (window.confirm('編集内容を取消してよろしいですか？')) {
            const apiPath = `documents/${documentId}/${encodeURIComponent(contentPath)}/commits`;
            deleteOne(
                apiPath,
                {
                    id: commit.id,
                },
                {
                    onSuccess: () => {
                        notify(<Alert severity="info">編集内容を取消しました。</Alert>, { autoHideDuration: 10000 });
                        refresh();
                    },
                    onError: (error: ErrorResponse | any) => {
                        if (error && error.status === 403) {
                            notify(<Alert severity="error">本人以外は編集内容を取消できません</Alert>, {
                                autoHideDuration: 10000,
                            });
                        } else {
                            console.log(error);
                            notify(
                                <Alert severity="error">
                                    編集内容の取消に失敗しました
                                    <br />
                                    {error.status && error.message}
                                </Alert>,
                                { autoHideDuration: 10000 },
                            );
                        }
                    },
                },
            );
        }
        handleClose();
    };

    const handleRestore = (e: MouseEvent) => {
        e.stopPropagation();

        if (window.confirm('編集内容を復元してよろしいですか？')) {
            window.alert('開発中です。');
        }
        handleClose();
    };

    if (!canDiscard && !canRestore) {
        return null;
    }

    return (
        <>
            <IconButton aria-label="settings" onClick={handleClick}>
                <MoreVert />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {canRestore && <MenuItem onClick={handleRestore}>この編集内容を復元</MenuItem>}
                {canDiscard && <MenuItem onClick={handleDiscard}>この編集内容を取消</MenuItem>}
            </Menu>
        </>
    );
};
