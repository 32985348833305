import React, { FC, useState } from 'react';
import { useGetIdentity } from 'react-admin';
import {
    Box,
    Button,
    CardProps,
    CircularProgress,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';
import { UserProfileIcon } from '../../../../components/ui/elements/UserProfileIcon';
import { DiscussionAdd } from '../../types/discussion/discussion';

interface Props extends CardProps {
    onCreateDiscussion: (discussionAdd: DiscussionAdd) => void;
}

export const DiscussionCreateListItem: FC<Props> = (props: Props) => {
    const { data: accountInfo } = useGetIdentity();
    const [discussionText, setDiscussionText] = useState('');
    const [open, setOpen] = useState(true);

    const handleCreateClick = () => {
        props.onCreateDiscussion({comment: discussionText});
        setDiscussionText(''); // フィールドをクリア
    };

    if (!accountInfo) {
        return <CircularProgress />;
    }

    return (
        <ListItem sx={{ alignItems: 'start' }}>
            <ListItemAvatar sx={{ minWidth: '32px', mt: '6px' }}>
                <UserProfileIcon userId={accountInfo.id} />
            </ListItemAvatar>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <ListItemText
                    primary={
                        <>
                            <Typography
                                sx={{ display: 'inline', ml: 0.5, mr: 0.5 }}
                                component="span"
                                variant="body2"
                                color="text.secondary"
                            >
                                コメントを追加する
                            </Typography>
                        </>
                    }
                />
                <Box display="flex" alignItems="center" gap={1}>
                    <TextField
                        variant="outlined"
                        sx={{ flexGrow: 1, my: 0 }}
                        value={discussionText}
                        multiline
                        maxRows={6}
                        fullWidth
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setDiscussionText(event.target.value);
                        }}
                    />
                    <Button variant="contained" disabled={!discussionText} onClick={handleCreateClick}>
                        送信
                    </Button>
                </Box>
            </Box>
        </ListItem>
    );
};
