// 差分プレフィックス
export const RE_DELETE_PREFIX = /^-/;
export const RE_INSERT_PREFIX = /^\+/;

// パイプ記号
export const RE_PIPE = /\|/g;

// マークダウン表
export const RE_MD_TABLE = /\|(.+\|)*/;

// マークダウン表のヘッダー識別子
// 行の先頭から始まり「|」と「-」1回以上のパターンが続く
export const RE_MD_HEADER_IDENTIFY = /^\|(-+\|)+$/;

// マークダウン画像
export const RE_MD_IMAGE = /!\[.*\]/;
export const RE_MD_IMAGE_URI = /!\[.*?\]\((.*?)\)/;

// マークダウンリンク
export const RE_MD_LINK = /\[.+\]/;
export const RE_MD_LINK_NAME = /\[(.*?)\]\(.*?\)/;
