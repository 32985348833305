import React from 'react';
import {
    Button,
    Datagrid,
    DateField,
    FunctionField,
    ShowButton,
    TextField, useGetIdentity,
    useListContext,
    usePermissions,
    useRedirect,
    useUnselectAll,
} from 'react-admin';
import { Box, CircularProgress } from '@mui/material';
import { UserField } from '../../../../../components/ui/fields/UserField';
import { IodList } from '../../../../../components/ui/layout/IodList';
import { ComparisonTableDownloadButton } from '../../../components/ComparisonTableDownloadButton';
import { EditInfo } from '../../../types/editInfo';
import { EditStatusField } from './EditStatusField';
import { MergeRequestButton } from './MergeRequestButton';

const CustomDownloadButton = () => {
    // チェックされているID
    const { selectedIds } = useListContext();

    return <ComparisonTableDownloadButton originalContentIds={selectedIds} />;
};

const CustomResetViewsButton = () => {
    const { selectedIds } = useListContext<EditInfo>();
    const unselectAll = useUnselectAll('edits');
    const { permissions, isLoading } = usePermissions();

    const redirect = useRedirect();

    const postRequestClick = () => {
        const param = selectedIds.map((value) => `${value}`).join(',');

        // チェックボックスのクリア
        unselectAll();

        redirect(`/diffs?ids=${param}`);
    };

    const canDownloadDiffDoc = permissions.paragraphs.includes('update');

    if (isLoading) {
        return null;
    }

    return (
        <>
            {canDownloadDiffDoc && <CustomDownloadButton />}
            <Button label="承認依頼" onClick={postRequestClick}></Button>
        </>
    );
};

export const EditList = () => {
    const unselectAll = useUnselectAll('edits');
    const redirect = useRedirect();
    const { data: userData } = useGetIdentity();
    // 権限の取得
    const { permissions, isLoading } = usePermissions();

    const clickMergeRequest = (data: EditInfo) => {
        const diffId = data.id;

        // チェックボックスのクリア
        unselectAll();

        redirect(`/diffs?ids=${diffId}`);
    };

    if (isLoading || !userData) {
        return <CircularProgress />;
    }

    return (
        <IodList
            exporter={false}
            // actions={} // 出力ボタン
            filter={{editor: userData.id}}
        >
            <Datagrid
                bulkActionButtons={<CustomResetViewsButton />}
                // rowClick={postRowClick}
                rowClick={false}
                isRowSelectable={(record: EditInfo) => !record.is_requested} // 承認依頼済みの場合はチェック不可
                sx={{
                    // チェックボックスが4%ほどの幅を使っているため全体で96%になるようにする
                    '& .document_title': {
                        width: '12%',
                    },
                    '& .heading_path': {
                        width: '30%',
                    },
                    '& .last_updated_by': {
                        width: '12%',
                    },
                    '& .updated_at': {
                        width: '10%',
                    },
                    '& .status': {
                        width: '8%',
                    },
                    // '& .request_description': {
                    //     width: '12%',
                    // },
                    '& .request_title': {
                        width: '12%',
                        minWidth: 100,
                    },
                    '& .buttons': {
                        width: '12%',
                        minWidth: 150,
                    },
                }}
            >
                <TextField source="document_title" headerClassName="document_title" sortable={false} />
                <TextField source="heading_path" headerClassName="heading_path" sortable={false} />

                <UserField source="last_updated_by" headerClassName="last_updated_by" sortable={false} />
                {/* <DateField source="created_at" label="作成日時" sortable={false} showTime={true} /> */}
                <DateField source="updated_at" headerClassName="updated_at" sortable={false} showTime={true} />
                <EditStatusField headerClassName="status" label="ステータス" />
                <TextField source="request_title" headerClassName="request_title" sortable={false} />

                {/*<EditButton label="詳細" icon={}/>*/}
                <Box className="buttons">
                    <ShowButton sx={{ mr: 2 }}></ShowButton>
                    <FunctionField
                        render={(record: EditInfo) => {
                            // 承認依頼済みの場合はボタンを表示しない
                            return record.is_requested ? null : (
                                <MergeRequestButton onClick={() => clickMergeRequest(record)} />
                            );
                        }}
                    />
                </Box>

                {/* {hasEditPolicy && <EditButton />} */}
                {/* 編集ボタンは暫定では無し */}
            </Datagrid>
        </IodList>
    );
};
