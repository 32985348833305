import { RaRecord } from 'react-admin';
import { Identifier } from 'ra-core';

export interface Organization extends RaRecord {
    id: Identifier;
    name: string;
    name_kana: string;
    position: number;
    created_at: string;
    updated_at: string;
    children: number[];
}

export const ORGANIZATION_ITEM_NAME_MAX_LENGTH: number = 100;
export const ORGANIZATION_ITEM_NAME_KANA_MAX_LENGTH: number = 100;

export const PARENT_PREFIX = '★';
