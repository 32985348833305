import * as JsDiff from 'diff';
import { Table as DocxTable } from 'docx';
import { ParagraphDiffWithReasons } from './diffCommentManager';
import { DocxTableConverter } from './docxTableConverter';
import { ParagraphDiff, ParagraphDiffLine } from './paragraphDiffProcessor';

/**
 * Wordの各行に着目した出力用のパラグラフ単位のオブジェクト
 */
export interface DocxInfo {
    original_content_id: number;
    beforeHeading: string;
    afterHeading: string;
    docxRows: DocxRow[];
}

export interface DocxRow {
    /**
     * Word上の行番号
     */
    rowNumber: number;

    /**
     * 編集前
     */
    beforeBody: DocxCell;

    /**
     * 編集後
     */
    afterBody: DocxCell;

    /**
     * 行範囲に紐づく修正理由（厳密には、修正理由は編集後の行にだけ紐づいている）
     *
     * 例）プレーンテキストなど 1:1｜テーブル 1:N 全ての修正理由
     */
    reasons?: ReasonAndClassification[];
}

export interface DocxCell {
    /**
     * 差分オブジェクトの行範囲
     *
     * 例）プレーンテキストなど [2,2]｜テーブル [3,8]
     */
    diffRowRange: RowRange;

    /**
     * 差分オブジェクトの行範囲に紐づくデータ
     *
     * 行ごとの文字列：プレーンテキスト・画像・リンク・マークダウン表など
     * 行ごとの差分：行ごとを比較した差分検出の結果
     */
    bodies: BodyWithChange[];

    /**
     * 内容を示すメタ情報
     */
    bodyType?: 'MdTable' | string;

    /**
     * Docxライブラリ形式に変換後のテーブル
     */
    docxTable?: DocxTable;
}

export interface RowRange {
    start: number;
    end: number;
}

export interface BodyWithChange {
    body: string;
    changes: JsDiff.Change[];
}

export interface ReasonAndClassification {
    reason?: string;
    classification?: string;
}

interface DiffDocxRowsMapperParam {
    paragraphDiffList: ParagraphDiffWithReasons[];
}

/**
 * Word行における範囲のオブジェクトを作成する関数のparam
 */
interface createDocxRowParam {
    startRange: number;
    endRange: number;
    rowRangeBodies: BodyWithChange[];
    isBefore: boolean;
    isBodyTypeMdTable?: boolean;
    reasons?: ReasonAndClassification[];
}

class DiffToDocxRowsConverter {
    private docxTableConverter: DocxTableConverter;

    constructor() {
        this.docxTableConverter = new DocxTableConverter();
    }

    public convert = (param: DiffDocxRowsMapperParam): DocxInfo[] => {
        const { paragraphDiffList } = param;

        // パラグラフごとの差分情報を、Word出力用オブジェクトに対応付けする
        const preprocessedList: DocxInfo[] = this.mapToDocxInfo(paragraphDiffList);

        // Word出力用オブジェクト内のマークダウン表を対象として処理する
        // Docxライブラリ形式（XML）のテーブルに変換して、追加でプロパティに持たせる
        const docxTableAddedList = this.docxTableConverter.convertMdTbl2DocxTbl(preprocessedList);

        return docxTableAddedList;
    };

    /**
     * 行ごとのオブジェクトを、Wordの各行に着目したオブジェクトに対応付けする
     *
     * パラグラフ複数のデータを受け取り順次処理
     */
    private mapToDocxInfo = (paragraphDiffList: ParagraphDiff[]): DocxInfo[] => {
        // パラグラフ単位で、対応付けを実施する
        const docxInfoList: DocxInfo[] = paragraphDiffList.map((paragraphDiff: ParagraphDiff) => {
            // console.log(paragraphDiff);
            const docxInfo: DocxInfo = this.mapToDiffDocxRows(paragraphDiff);
            // console.log(docxInfo);

            // パラグラフ単位で対応付け後のオブジェクトを返す
            return docxInfo;
        });

        // // Word上の行番号を元に昇順でソートする （不要なはず
        // docxInfoList.forEach((docxInfo: DocxInfo) => {
        //     docxInfo.docxRows.sort((a, b) => a.rowNumber - b.rowNumber);
        // });

        return docxInfoList;
    };

    /**
     * パラグラフ単体の行オブジェクトを、Wordの各行に着目したオブジェクトに対応付けする
     *
     * FIXME: 現行：行ごとのループ内で編集前のオブジェクトを構築 -> 行ごとのループ内で編集後のオブジェクトを構築
     * 理想は、1回の行ごとのループ内で編集前と編集後のオブジェクトを一緒くたに構築する
     */
    public mapToDiffDocxRows = (paragraphDiff: ParagraphDiffWithReasons): DocxInfo => {
        // マッピング後のオブジェクト
        const editedDocxRows: DocxRow[] = [];

        // テーブル範囲の開始行番号
        let mdTableRangeStartNumberBefore: number | null = null;
        let mdTableRangeStartNumberAfter: number | null = null;

        // 行範囲に紐づく文字列と行ごとの差分を保持する配列
        let rowRangeBodies: BodyWithChange[] = [];

        // 編集前（beforeBodyを元にマッピング）
        // ※処理内容の細かいコメントについては編集後を参照
        paragraphDiff.diffLines.forEach((diffLine) => {
            if (diffLine.beforeBody?.bodyType === 'MdTableLineStart') {
                mdTableRangeStartNumberBefore = diffLine.line_number;

                rowRangeBodies.push({
                    body: diffLine.beforeBody.body,
                    changes: diffLine.changes || [],
                });
            } else if (mdTableRangeStartNumberBefore && diffLine.beforeBody?.bodyType === 'MdTableLine') {
                rowRangeBodies.push({
                    body: diffLine.beforeBody.body,
                    changes: diffLine.changes || [],
                });
            } else if (mdTableRangeStartNumberBefore && diffLine.beforeBody?.bodyType === 'MdTableLineEnd') {
                rowRangeBodies.push({
                    body: diffLine.beforeBody.body,
                    changes: diffLine.changes || [],
                });

                editedDocxRows.push(
                    this.createDocxRow({
                        startRange: mdTableRangeStartNumberBefore,
                        endRange: diffLine.line_number,
                        rowRangeBodies: rowRangeBodies,
                        isBefore: true,
                        isBodyTypeMdTable: true,
                    }),
                );

                mdTableRangeStartNumberBefore = null;

                rowRangeBodies = [];
            } else if (!mdTableRangeStartNumberBefore && diffLine.beforeBody?.body) {
                rowRangeBodies.push({
                    body: diffLine.beforeBody.body,
                    changes: diffLine.changes || [],
                });

                editedDocxRows.push(
                    this.createDocxRow({
                        startRange: diffLine.line_number,
                        endRange: diffLine.line_number,
                        rowRangeBodies: rowRangeBodies,
                        isBefore: true,
                    }),
                );

                rowRangeBodies = [];
            }
            if (diffLine.beforeBody?.body === '') {
                rowRangeBodies.push({
                    body: diffLine.beforeBody.body,
                    changes: diffLine.changes || [],
                });

                editedDocxRows.push(
                    this.createDocxRow({
                        startRange: diffLine.line_number,
                        endRange: diffLine.line_number,
                        rowRangeBodies: rowRangeBodies,
                        isBefore: true,
                    }),
                );

                rowRangeBodies = [];
            }
        });

        // 修正理由を保持する配列
        let rowRangeReasons: ReasonAndClassification[] = [];

        // 編集後（afterBodyを元にマッピング）
        // 編集前と違って、修正理由の情報を考慮する必要がある
        paragraphDiff.diffLines.forEach((diffLine) => {
            if (diffLine.afterBody?.bodyType === 'MdTableLineStart') {
                // テーブル開始行

                // テーブルの開始行番号を保持
                mdTableRangeStartNumberAfter = diffLine.line_number;

                // 行範囲に紐づく文字列と行ごとの差分を保持
                rowRangeBodies.push({
                    body: diffLine.afterBody.body,
                    changes: diffLine.changes || [],
                });

                // 修正理由があれば保持
                diffLine.reason &&
                    rowRangeReasons.push({
                        classification: diffLine.classification,
                        reason: diffLine.reason,
                    });
            } else if (mdTableRangeStartNumberAfter && diffLine.afterBody?.bodyType === 'MdTableLine') {
                // テーブル中間行

                // 行範囲に紐づく文字列と行ごとの差分を保持
                rowRangeBodies.push({
                    body: diffLine.afterBody.body,
                    changes: diffLine.changes || [],
                });

                // 修正理由があれば保持する
                diffLine.reason &&
                    rowRangeReasons.push({
                        classification: diffLine.classification,
                        reason: diffLine.reason,
                    });
            } else if (mdTableRangeStartNumberAfter && diffLine.afterBody?.bodyType === 'MdTableLineEnd') {
                // テーブル終了行

                // 行範囲に紐づく文字列と行ごとの差分を保持
                rowRangeBodies.push({
                    body: diffLine.afterBody.body,
                    changes: diffLine.changes || [],
                });

                // 修正理由があれば保持
                diffLine.reason &&
                    rowRangeReasons.push({
                        classification: diffLine.classification,
                        reason: diffLine.reason,
                    });

                // テーブル情報を格納するべきWord上の行を特定する
                // 行情報がまだ無いのであれば作成、既にある場合（編集前で既に作成している場合）は情報を追加する
                const existRow = editedDocxRows.find((row: DocxRow) => row.rowNumber === mdTableRangeStartNumberAfter);
                if (!existRow) {
                    // 行オブジェクトを作成して反映する
                    editedDocxRows.push(
                        this.createDocxRow({
                            startRange: mdTableRangeStartNumberAfter,
                            endRange: diffLine.line_number,
                            rowRangeBodies: rowRangeBodies,
                            isBefore: false,
                            isBodyTypeMdTable: true,
                            reasons: rowRangeReasons,
                        }),
                    );
                } else {
                    // 編集前で既に作成している場合
                    existRow.afterBody = {
                        diffRowRange: {
                            start: mdTableRangeStartNumberAfter,
                            end: diffLine.line_number, // 終了行の番号が更新される
                        },
                        bodies: rowRangeBodies,
                        bodyType: 'MdTable',
                    };
                    existRow.reasons = rowRangeReasons;
                }

                // 1つのテーブル範囲が終了したのでリセット
                mdTableRangeStartNumberAfter = null;
                rowRangeBodies = [];
                rowRangeReasons = [];
            } else if (!mdTableRangeStartNumberAfter && diffLine.afterBody?.body) {
                // テーブル以外の行（つまりテーブル範囲がリセットされていて、bodyが存在する）
                // ifとして独立させるとテーブル範囲が終わった行で、追加でこの処理も実行されてしまうので注意

                // 行範囲に紐づく文字列と行ごとの差分を保持
                rowRangeBodies.push({
                    body: diffLine.afterBody.body,
                    changes: diffLine.changes || [],
                });

                // 修正理由があれば保持
                diffLine.reason &&
                    rowRangeReasons.push({
                        classification: diffLine.classification,
                        reason: diffLine.reason,
                    });

                // テーブル情報を格納するべきWord上の行を特定する
                // 行情報がまだ無いのであれば作成、既にある場合（編集前で既に作成している場合）は情報を追加する
                const existRow = editedDocxRows.find((row: DocxRow) => row.rowNumber === diffLine.line_number);
                if (!existRow) {
                    // 行オブジェクトを作成して反映する
                    editedDocxRows.push(
                        this.createDocxRow({
                            startRange: diffLine.line_number,
                            endRange: diffLine.line_number,
                            rowRangeBodies: rowRangeBodies,
                            isBefore: false,
                            reasons: rowRangeReasons,
                        }),
                    );
                } else {
                    // 編集前で既に作成している場合
                    existRow.afterBody = {
                        diffRowRange: {
                            start: diffLine.line_number,
                            end: diffLine.line_number,
                        },
                        bodies: rowRangeBodies,
                    };
                    existRow.reasons = rowRangeReasons;
                }

                // リセット
                rowRangeBodies = [];
                rowRangeReasons = [];
            }

            if (diffLine.afterBody?.body === '') {
                // 行番号落ちへの対策
                // 編集前の行自体が存在していない＆編集後が空白行 -> rowNumberが未作成のはずなので行を作成する
                // 編集後の行自体が存在していない＆編集前が空白行 -> beforeBody判定の時点でrowNumberは作成されているので問題ない想定

                // 行範囲に紐づく文字列と行ごとの差分を保持
                rowRangeBodies.push({
                    body: diffLine.afterBody.body,
                    changes: diffLine.changes || [],
                });

                // 修正理由があれば保持する
                diffLine.reason &&
                    rowRangeReasons.push({
                        classification: diffLine.classification,
                        reason: diffLine.reason,
                    });

                // Word上の行を存在確認
                const existRow = editedDocxRows.find((row: DocxRow) => row.rowNumber === diffLine.line_number);
                if (!existRow) {
                    // 空白行の行オブジェクトを作成して反映する
                    editedDocxRows.push(
                        this.createDocxRow({
                            startRange: diffLine.line_number,
                            endRange: diffLine.line_number,
                            rowRangeBodies: rowRangeBodies,
                            isBefore: false,
                            reasons: rowRangeReasons,
                        }),
                    );
                }

                // リセット
                rowRangeReasons = [];
                rowRangeBodies = [];
            }
        });

        return {
            original_content_id: paragraphDiff.original_content_id,
            beforeHeading: paragraphDiff.beforeHeading,
            afterHeading: paragraphDiff.afterHeading,
            docxRows: editedDocxRows,
        };
    };

    /**
     * 各情報を受け取り、Word行における範囲のオブジェクトを作成する関数
     */
    private createDocxRow = (param: createDocxRowParam): DocxRow => {
        const { startRange, endRange, rowRangeBodies, isBefore, isBodyTypeMdTable = false, reasons = [] } = param;

        // 元の範囲と、範囲に紐づくテキスト
        const rowRange: RowRange = {
            start: startRange,
            end: endRange,
        };
        const docxCell: DocxCell = {
            diffRowRange: rowRange,
            bodies: rowRangeBodies,
        };

        // 行オブジェクト
        const blankBodies: BodyWithChange[] = [{ body: '', changes: [] }];
        const docxRow: DocxRow = {
            rowNumber: startRange,
            beforeBody: isBefore ? docxCell : { diffRowRange: rowRange, bodies: blankBodies },
            afterBody: isBefore ? { diffRowRange: rowRange, bodies: blankBodies } : docxCell,
        };

        // 修正理由がある場合のみ追加する
        if (reasons.length > 0) {
            docxRow.reasons = reasons;
        }

        // マークダウン表であることを示すメタ情報を追加する
        if (isBodyTypeMdTable) {
            docxCell.bodyType = 'MdTable';
        }

        return docxRow;
    };
}
export { DiffToDocxRowsConverter };
