import * as React from 'react';
import { CheckForApplicationUpdate } from 'react-admin';
import { SolarLayout, SolarLayoutProps } from '@react-admin/ra-navigation';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useEnv } from '../../../hooks/useEnv';
import { IodAppBar } from './IodAppBar';
import { IodSolarSideMenu } from './IodSolarSideMenu';

const CHECK_FOR_UPDATE_INTERVAL = 3 * 60 * 1000; // 3分ごとに確認
export const IodSolarLayout = (props: SolarLayoutProps) => {
    const { isLocal } = useEnv();

    return (
        <>
            <SolarLayout
                {...props}
                sx={{
                    paddingTop: '48px', // ヘッダーは48px
                    '.RaSolarLayout-content': {
                        // padding: '0 !important',
                    },
                }}
                appBar={IodAppBar}
                menu={IodSolarSideMenu}

                // ロゴが上手く表示できないので一旦諦め
                // logo={
                //     <>
                //         <Box
                //             sx={{
                //                 position: 'relative',
                //                 display: 'flex',
                //                 flex: '0 0 auto',
                //                 margin: '0 1rem 0 .5rem',
                //             }}
                //         >
                //             <img src="bpdb_logo_text_with_icon_white.png" alt="東京都地域防災計画データベース"
                //                  height={36}/>
                //             {/* <Typography variant="h6">東京都地域防災計画データベース</Typography> */}
                //         </Box>
                //         <TitlePortal/>
                //     </>
                // }
            >
                {props.children}
                {!isLocal && (
                    <CheckForApplicationUpdate interval={CHECK_FOR_UPDATE_INTERVAL}/>
                )}
            </SolarLayout>
            {isLocal && <ReactQueryDevtools initialIsOpen={false} />}

        </>
    );
};
