import {FC, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';

export interface CommentDialogProps {
    // commentDialogState: {
    //     open: boolean;
    //     lineNum?: number;
    // };
    open: boolean;
    dialogTitle: string;
    handleDialogClose: () => void;
    handleDialogSubmit: (comment: string) => void;
}

export const CommentDialog: FC<CommentDialogProps> = (props) => {
    const { open, dialogTitle, handleDialogClose, handleDialogSubmit } = props;

    const [comment, setComment] = useState("");

    const handleSubmit = () => {
        handleDialogSubmit(comment);
        setComment(""); // コメントが追加された後にフィールドをクリア
    };

    return (
        <Dialog open={open} maxWidth="sm" fullWidth onClose={handleDialogClose}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <TextField
                    name="body"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={6}
                    value={comment}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setComment(event.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                <Button variant="outlined" onClick={handleDialogClose}>
                    キャンセル
                </Button>
                <Button variant="contained" onClick={handleSubmit}>
                    送信
                </Button>
            </DialogActions>
        </Dialog>
    );
};
