import React from 'react';
import { Avatar } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Identicon from '@polkadot/react-identicon';
import { Identifier } from 'ra-core';

interface Props {
    userId?: Identifier;
    size?: number;
}
export const UserProfileIcon = (props: Props) => {

    const size = props.size ?? 24;

    if (!props.userId) {
        return <AccountCircleIcon />
    }

    return (
        <Avatar
            sx={{
                width: size,
                height: size,
            }}
        >
            <Identicon
                value={props.userId as string}
                size={size}
                theme='ethereum'
            />
        </Avatar>
    );
};
