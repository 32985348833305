import React, { useEffect, useState } from 'react';
import { useGetList, useTranslate } from 'react-admin';
import {
    Alert,
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { IodToolbar } from '../../components/ui/layout/IodToolbar';
import { Snapshot } from '../snapshots/types/snapshot';
import { ComparisonTableDownloadButton } from '../workflow/components/ComparisonTableDownloadButton';
import { EditInfo } from '../workflow/types/editInfo';

type Target = 'editing' | string;

interface Props {
    documentId: number;
    snapshots: Snapshot[];
}

export const ComparisonTableExportCard = (props: Props) => {
    const { documentId, snapshots } = props;

    const defaultBeforeTarget = snapshots.length === 0 ? '' : String(snapshots[0].id);
    const [fromTarget, setFromTarget] = useState<Target>(defaultBeforeTarget);
    const [toTarget, setToTarget] = useState<Target>('editing');
    const translate = useTranslate();
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    // ここのAPIをいずれ GET documents/{document_id}/compare?from={commit_id}&to={commit_id}に置き換える想定
    const { data, total, isLoading, error, isFetchedAfterMount } = useGetList<EditInfo>('edits', {
        pagination: { page: 1, perPage: 9999 },
        filter: {
            document_id: documentId,
        },
    });
    useEffect(() => {
        if (!data) {
            setSelectedIds([]);
            return;
        }
        const ids = data.map((edit) => {
            return edit.id as number;
        });

        setSelectedIds(ids);
    }, [data]);

    const selectable = false; // TODO 別途版を選択可能にする

    return (
        <Card sx={{ mb: 2 }}>
            <CardHeader title="新旧対照表出力" />

            <CardContent>
                {snapshots.length > 0 && selectable && (
                    <Alert severity="info">新旧対照表としてエクスポートする版を新旧それぞれ選んでください。</Alert>
                )}
                {snapshots.length > 0 ? (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box>
                                <FormControl fullWidth>
                                    <InputLabel id="from-select-label">旧</InputLabel>
                                    <Select
                                        readOnly={!selectable}
                                        labelId="from-select-label"
                                        value={fromTarget}
                                        onChange={(e) => setFromTarget(e.target.value)}
                                    >
                                        {snapshots.map((snapshot) => (
                                            <MenuItem key={snapshot.id} value={snapshot.id}>
                                                {snapshot.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box>
                                <FormControl fullWidth>
                                    <InputLabel id="to-select-label">新</InputLabel>
                                    <Select
                                        readOnly={!selectable}
                                        labelId="to-select-label"
                                        value={toTarget}
                                        onChange={(e) => setToTarget(e.target.value)}
                                    >
                                        <MenuItem value={'editing'}>最新修正案</MenuItem>
                                        {snapshots.map((snapshot) => (
                                            <MenuItem key={snapshot.id} value={snapshot.id}>
                                                {snapshot.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Typography variant="body1">{translate('ra.notification.item_doesnt_exist')}</Typography>
                )}
                {/*<Button label="差分表示" onClick={handleDiffView} />*/}

                {/*<SelectInput source={}*/}
            </CardContent>

            <IodToolbar>
                <CardActions>
                    <ComparisonTableDownloadButton originalContentIds={selectedIds} />
                </CardActions>
            </IodToolbar>
        </Card>
    );
};
