import { ChangeEvent, FC, useState } from 'react';
import { useCreate, useNotify } from 'react-admin';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    TextField,
    Tooltip,
} from '@mui/material';
import { FAVORITE_ITEM_NAME_MAX_LENGTH, FavoriteItem } from '../types/favoriteItem';

export interface FavoriteListCreateDialogProps extends DialogProps {
    onSuccess?: (item: FavoriteItem) => void;
}

interface FavoriteListEditData {
    name: string;
}

const initialEditData: FavoriteListEditData = {
    name: '',
};

export const FavoriteListCreateDialog: FC<FavoriteListCreateDialogProps> = ({ onSuccess, ...props }) => {
    const [editData, setEditData] = useState<FavoriteListEditData>(initialEditData);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const notify = useNotify();
    const [create, { isLoading, error }] = useCreate<FavoriteItem>(undefined, undefined, {
        onSuccess: (res: FavoriteItem) => {
            onSuccess && onSuccess(res);
            setEditData(initialEditData);
            setIsChanged(false);
        },
        onError: (error: any) => {
            if (error.status === 409) {
                notify(<Alert severity="error">既に同名のお気に入りリストが存在します</Alert>);
            } else {
                notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>);
            }
        },
    });

    const validate = (): boolean => {
        return editData.name !== '';
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.value;
        setEditData({
            ...editData,
            [name]: value,
        });
        setIsChanged(true);
    };

    const handleCreateClick = async () => {
        const result = await create('favorites', { data: editData });
    };

    const handleCancelClick = (e: any) => {
        props.onClose && props.onClose(e, 'backdropClick');
    };

    return (
        <Dialog {...props}>
            <DialogTitle>お気に入りリストを作成</DialogTitle>
            <DialogContent>
                <Tooltip title={`${FAVORITE_ITEM_NAME_MAX_LENGTH}文字以内で入力してください`} placement="top">
                    <TextField
                        required
                        label="リスト名"
                        name="name"
                        value={editData.name}
                        inputProps={{
                            maxLength: FAVORITE_ITEM_NAME_MAX_LENGTH,
                        }}
                        onChange={handleChange}
                        error={isChanged && editData.name === ''}
                        helperText={editData.name === '' && 'このフィールドは空にできません。'}
                        sx={{
                            minWidth: 300,
                        }}
                        disabled={isLoading}
                    />
                </Tooltip>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelClick} color="secondary" disabled={isLoading}>
                    キャンセル
                </Button>
                <Button variant="contained" onClick={handleCreateClick} disabled={!validate() || isLoading}>
                    作成
                </Button>
            </DialogActions>
        </Dialog>
    );
};
