import * as React from 'react';
import { MouseEvent, useState } from 'react';
import {
    Button,
    Datagrid,
    DateField,
    EditButton,
    FunctionField,
    List,
    TextField,
    useGetOne,
    useNotify,
    usePermissions,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import {Alert, Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Tooltip} from '@mui/material';
import { FileDownload } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import { API_URL } from '../../../config';

export const SnapshotList = () => {
    const { documentId } = useParams();
    const translate = useTranslate();

    const { data, error, isLoading: isLoadingDoc } = useGetOne('documents', { id: documentId });

    // 権限の取得
    const { isLoading, permissions } = usePermissions();
    if (isLoading || isLoadingDoc || !data) {
        return <CircularProgress />;
    }

    // 権限がある場合のみ各ボタンを有効化する
    const hasCreatePolicy = permissions.snapshots.includes('create');

    return (
        <List
            resource={`admin/documents/${documentId}/snapshots`}
            exporter={false}
            pagination={false}
            hasCreate={hasCreatePolicy}
            title={`${data.title} / ${translate('routes.documents.snapshots.titles.list')}`}
        >
            <Datagrid
                bulkActionButtons={false}
                rowClick={false}
                sx={{
                    '.created_at': {
                        minWidth: '180px',
                    },
                    '.updated_at': {
                        minWidth: '180px',
                    },
                }}
            >
                <TextField source="id" label={translate('routes.documents.snapshots.fields.id')} sortable={false} />
                <TextField source="name" sortable={false} label={translate('routes.documents.snapshots.fields.name')} />
                <TextField
                    source="description"
                    sortable={false}
                    label={translate('routes.documents.snapshots.fields.description')}
                />
                <FunctionField
                    source="is_public"
                    sortable={false}
                    render={(record: any) => {
                        if (record.is_public) {
                            return '確定版';
                        } else {
                            return '素案';
                        }
                    }}
                    label={translate('routes.documents.snapshots.fields.is_public')}
                />
                <DateField
                    source="created_at"
                    label={translate('routes.documents.snapshots.fields.created_at')}
                    sortable={false}
                    showTime={true}
                    headerClassName="created_at"
                />
                <DateField
                    source="updated_at"
                    label={translate('routes.documents.snapshots.fields.updated_at')}
                    sortable={false}
                    showTime={true}
                    headerClassName="updated_at"
                />
                <EditButton />
                <ExportButton />
            </Datagrid>
        </List>
    );
};


interface ExportButtonProps {
}
const ExportButton = (props: ExportButtonProps) => {

    const record = useRecordContext();
    const translate = useTranslate();
    const notify = useNotify();
    // ダウンロードの進行状態を追跡
    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const downloadFile = async () => {
        setIsDownloading(true); // ダウンロード開始
        try {
            const session = await Auth.currentSession();
            const jwtToken = session.getAccessToken().getJwtToken();
            const headers = {
                'Authorization': `Bearer ${jwtToken}`
            }

            const apiUrl = `${API_URL}/documents/${record.document_id}/snapshots/${record.id}/download`;
            const response = await fetch(apiUrl, {
                method: 'GET',
                // 必要に応じて追加の設定（ヘッダーなど）
                headers: headers,
            });
            if (!response.ok) {
                throw new Error('レスポンスエラー');
            }

            // Content-Disposition ヘッダからエンコードされたファイル名を取得
            const contentDisposition = response.headers.get('Content-Disposition');
            if (!contentDisposition) {
                throw new Error('レスポンスエラー');
            }
            // @ts-ignore
            const encodedFileName = contentDisposition.match(/filename\*=utf-8''(.+)$/)[1];

            // URLデコードして実際のファイル名を取得
            const fileName = decodeURIComponent(encodedFileName);

            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName); // ここでダウンロードするファイルの名前を設定
            document.body.appendChild(link);
            link.click();
            link.remove(); // Clean up
        } catch (error) {
            console.error('ファイルのダウンロードに失敗しました', error);
            notify(
                <Alert severity="error">PDFファイルのダウンロードに失敗しました</Alert>
            )
        } finally {
            setIsDownloading(false); // ダウンロード終了
        }
    };


    const handleClick = (e: MouseEvent) => {
        e.stopPropagation(); // クリックイベントを行に伝搬させない様にする
        if (window.confirm('最大数分程度時間がかかりますがよろしいですか？')) {
            downloadFile();
        }
    };


    return (
        <>
            <Button
                startIcon={<FileDownload />}
                label={translate('routes.documents.snapshots.fields.export')}
                variant="text"
                onClick={handleClick}
            />
            <Dialog open={isDownloading}>
                <DialogTitle>
                    PDFファイルを出力中...
                </DialogTitle>
                <DialogContent sx={{textAlign: 'center'}}>
                    <Alert severity="warning" sx={{mb: 2}}>
                    PDFファイルの出力が完了するまで画面を操作しないでください。
                    </Alert>
                    <CircularProgress />
                </DialogContent>

            </Dialog>
        </>
    )
}
