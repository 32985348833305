import { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Box, SvgIcon } from '@mui/material';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import { Check, DoneAll, Edit, Verified } from '@mui/icons-material';
import ReplyIcon from '@mui/icons-material/Reply';
import { useSidebarWidth } from '../../../hooks/useSidebarWidth';
import { ReviewStatsInfo } from '../types/reviewStatsInfo';
import { ReviewStatusCode, determineReviewStatus } from './determineReviewStatus';

interface ReviewStatusStepperInnerProps {
    isRequested: boolean;
    reviewStats?: ReviewStatsInfo;
}

export const ReviewStatusStepper = (props: ReviewStatusStepperInnerProps) => {
    const { isRequested, reviewStats } = props;

    const sidebarWidth = useSidebarWidth();
    const translate = useTranslate();

    // 現在アクティブなステップの状態管理
    const [activeStep, setActiveStep] = useState<ReviewStatusCode>(ReviewStatusCode.editing);

    // ステータスが変更されたときにアクティブなステップを更新
    useEffect(() => {
        const reviewStatusCode: ReviewStatusCode = determineReviewStatus(isRequested, reviewStats);
        setActiveStep(reviewStatusCode);
    }, [isRequested, reviewStats]);

    const ColoredConnector = styled(StepConnector)(({ theme }) => ({
        // アクティブ・完了ステップのデザイン
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                ...(activeStep !== ReviewStatusCode.rejected &&
                    activeStep !== ReviewStatusCode.withdrawn && { borderColor: 'gray' }),
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                ...(activeStep !== ReviewStatusCode.rejected &&
                    activeStep !== ReviewStatusCode.withdrawn && { borderColor: 'gray' }),
            },
        },
        // コネクタの基本デザイン
        [`& .${stepConnectorClasses.line}`]: {
            borderRadius: 1,
            borderWidth: 1.5, // 線の太さ
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0', // モード別（light/dark）
        },
    }));

    return (
        <Box sx={{ width: `calc(100vw - ${sidebarWidth + 72}px)` }}>
            <Stepper
                sx={{ mt: 2, ml: 2.5, mr: 2.5, mb: 2 }}
                alternativeLabel
                activeStep={activeStep}
                connector={<ColoredConnector />}
            >
                <Step>
                    {/* 編集中/差戻/引戻はステータスによって表示分けする */}
                    <StepLabel
                        StepIconComponent={() => {
                            switch (activeStep) {
                                case ReviewStatusCode.editing:
                                    return (
                                        <SvgIcon color="info">
                                            <Edit />
                                        </SvgIcon>
                                    );
                                case ReviewStatusCode.rejected:
                                    return (
                                        <SvgIcon color="warning">
                                            <ReplyIcon />
                                        </SvgIcon>
                                    );
                                case ReviewStatusCode.withdrawn:
                                    return (
                                        <SvgIcon color="warning">
                                            <ReplyIcon />
                                        </SvgIcon>
                                    );
                                default:
                                    return (
                                        <SvgIcon color="info">
                                            <Edit />
                                        </SvgIcon>
                                    );
                            }
                        }}
                    >
                        {(() => {
                            switch (activeStep) {
                                case ReviewStatusCode.editing:
                                    return translate('resources.merge_requests.statusesName.editing');
                                case ReviewStatusCode.rejected:
                                    return translate('resources.merge_requests.statusesName.rejected');
                                case ReviewStatusCode.withdrawn:
                                    return translate('resources.merge_requests.statusesName.withdrawn');
                                default:
                                    return translate('resources.merge_requests.statusesName.editing');
                            }
                        })()}
                    </StepLabel>
                </Step>

                <Step>
                    <StepLabel
                        StepIconComponent={() => (
                            <SvgIcon
                                color={
                                    activeStep >= ReviewStatusCode.confirming && activeStep <= ReviewStatusCode.fixed
                                        ? 'secondary'
                                        : 'disabled'
                                }
                            >
                                <Check />
                            </SvgIcon>
                        )}
                    >
                        {translate('resources.merge_requests.statusesName.confirming')}
                        {reviewStats && ` ${reviewStats.confirmer_approve_count}/${reviewStats.confirmer_total_count}`}
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel
                        StepIconComponent={() => (
                            <SvgIcon
                                color={
                                    activeStep >= ReviewStatusCode.approving && activeStep <= ReviewStatusCode.fixed
                                        ? 'success'
                                        : 'disabled'
                                }
                            >
                                <DoneAll />
                            </SvgIcon>
                        )}
                    >
                        {translate('resources.merge_requests.statusesName.approving')}
                        {reviewStats && ` ${reviewStats.approver_approve_count}/${reviewStats.approver_total_count}`}
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel
                        StepIconComponent={() => (
                            <SvgIcon color={activeStep === ReviewStatusCode.fixed ? 'info' : 'disabled'}>
                                <Verified />
                            </SvgIcon>
                        )}
                    >
                        {translate('resources.merge_requests.statusesName.fixed')}
                    </StepLabel>
                </Step>
            </Stepper>
        </Box>
    );
};
