import { FC } from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

type FavoriteListFilterProps = {
    source: string;
    alwaysOn: any;
};

export const FavoriteListFilter: FC<FavoriteListFilterProps> = ({ source }) => {
    const matchSuggestion = (filter: string, choice: any) => {
        return choice.name.toLowerCase().includes(filter.toLowerCase());
    };

    return (
        <ReferenceInput source={source} reference="favorites">
            <AutocompleteInput
                optionText="name"
                label="お気に入りリスト"
                fullWidth
                sx={{ minWidth: '250px' }}
                matchSuggestion={matchSuggestion}
            />
        </ReferenceInput>
    );
};
