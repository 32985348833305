export const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };
    return new Intl.DateTimeFormat('ja-JP', options).format(new Date(dateString));
};

export const generateNowJstDate = (): string => {
    // ISO 8601形式［YYYY-MM-DDTHH:mm:ss.sssZ］の日付
    const now = new Date();

    // 協定世界時(UTC)から日本標準時(JST)に変換
    now.setHours(now.getHours() + 9);

    // YYMMDD形式の日付文字列を生成
    const formattedDate = now.toISOString().slice(2,10).replace(/-/g, '');

    // HHMMSS形式の時間文字列を生成
    const formattedTime = now.toISOString().slice(11,19).replace(/:/g, '');

    return `${formattedDate}_${formattedTime}`;
};
