import React, { FC, ReactNode, createContext, useState } from 'react';
import { Organization } from '../../features/organizations/types/organization';

// useContextの定義
export type HierarchicalOrgObjectType = {
    [key: string]: (Organization | null)[];
};

export type HierarchicalOrgContextType = {
    hierarchicalOrgObject: HierarchicalOrgObjectType;
    setHierarchicalOrgObject: React.Dispatch<React.SetStateAction<HierarchicalOrgObjectType>>;
};

// 組織を階層的に選択するためのContextとプロバイダ
export const HierarchicalOrgContext = createContext<HierarchicalOrgContextType>({} as HierarchicalOrgContextType);

export const HierarchicalOrgProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // Stateの定義
    const [hierarchicalOrgObject, setHierarchicalOrgObject] = useState<HierarchicalOrgObjectType>({});

    return (
        <HierarchicalOrgContext.Provider value={{ hierarchicalOrgObject, setHierarchicalOrgObject }}>
            {children}
        </HierarchicalOrgContext.Provider>
    );
};
