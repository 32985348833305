import { RaRecord } from 'react-admin';
import { Identifier } from 'ra-core';

export interface Document extends RaRecord {
    id: number;
    position: number;
    title: string;
    edit_mode: string;
    created_at?: string;
    updated_at?: string;
}

export const DOCUMENT_ITEM_TITLE_MAX_LENGTH: number = 100;
