import React from 'react';
import { Datagrid, DateField, FunctionField, List, TextField, usePermissions } from 'react-admin';
import { CircularProgress } from '@mui/material';
import { Organization, PARENT_PREFIX } from '../types/organization';

export const OrganizationList = () => {
    // 権限の取得
    const { isLoading, permissions } = usePermissions();
    if (isLoading) {
        return <CircularProgress />;
    }

    const hasCreatePolicy = permissions.organizations.includes('create');

    return (
        <List exporter={false} pagination={false} hasCreate={hasCreatePolicy} perPage={300}>
            <Datagrid
                bulkActionButtons={false}
                rowClick="edit"
                sx={{
                    '.created_at': {
                        minWidth: '180px',
                    },
                    '.updated_at': {
                        minWidth: '180px',
                    },
                }}
            >
                <TextField source="id" label="組織ID" sortable={false} />
                <FunctionField
                    source="name"
                    label="組織名"
                    sortable={false}
                    render={(record: Organization) =>
                        record.children.length > 0
                            ? PARENT_PREFIX + `${record.name}` // 表示名に親を表現するプレフィックスを付与
                            : record.name
                    }
                />
                <TextField source="name_kana" sortable={false} />
                <DateField
                    source="created_at"
                    label="作成日時"
                    sortable={false}
                    showTime={true}
                    headerClassName="created_at"
                />
                <DateField
                    source="updated_at"
                    label="更新日時"
                    sortable={false}
                    showTime={true}
                    headerClassName="updated_at"
                />
            </Datagrid>
        </List>
    );
};
