import * as React from 'react';
import { MouseEvent } from 'react';
import { Button, useRecordContext, useResourceContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import {Difference, Update} from '@mui/icons-material';
import { DocumentListComponent } from './DocumentListComponent';


/**
 * 管理者向けのドキュメントマスタ画面
 */
export const DocumentList = () => {
    return (
        <DocumentListComponent canEdit={true} rowClick={false}>
            <VersionEditButton />
            <ComparisonTableButton />
        </DocumentListComponent>
    );
};

const VersionEditButton = () => {

    const record = useRecordContext();
    const resource = useResourceContext();
    const navigate = useNavigate();
    const handleClick = (e: MouseEvent) => {
        e.stopPropagation(); // クリックイベントを行に伝搬させない様にする
        navigate(`/${resource}/${record.id}/snapshots`);
    };

    return (
        <Button
            startIcon={<Update />}
            label='版管理'
            variant="text"
            onClick={handleClick}
            // sx={{width: '85px', height: '35px'}}
        />
    )
}

const ComparisonTableButton = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const navigate = useNavigate();
    const handleClick = (e: MouseEvent) => {
        e.stopPropagation(); // クリックイベントを行に伝搬させない様にする
        navigate(`/${resource}/${record.id}/comparison-table`);
    };

    return (
        <Button
            startIcon={<Difference />}
            label='新旧対照表出力'
            variant="text"
            onClick={handleClick}
            // sx={{width: '85px', height: '35px'}}
        />
    )
}
