import * as React from 'react';
import { FC } from 'react';
import { useRecordContext, useRedirect } from 'react-admin';
import { Breadcrumbs, Link } from '@mui/material';
import { BreadcrumbsTypeMap } from '@mui/material/Breadcrumbs/Breadcrumbs';
import { Paragraph } from '../types/paragraph';

interface HeadingsFieldProps extends Omit<BreadcrumbsTypeMap['props'], 'aria-label'> {}

const HeadingsField: FC<HeadingsFieldProps> = (props) => {
    const record = useRecordContext<Paragraph>();
    const redirect = useRedirect();

    // const handleClick = (e: any) => {
    //     console.log(e.target)
    // };

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            className="font-size-changeable large-font"
            {...props}
            // sx={{
            //     width: '100%',
            //     bgcolor: 'background.paper',
            // }}
            // subheader={
            //     <ListSubheader component="div" id="nested-list-subheader">
            //         Nested List Items
            //     </ListSubheader>
            // }
        >
            {record.headings.map((item) => {
                return (
                    <Link
                        underline="hover"
                        color="inherit"
                        // href="/"
                        // clickable={false}
                        // sx={{
                        //     // margin: '0.5em',
                        //     // maxWidth: "98%",
                        //     // overflowWrap: "break-word",
                        //     // "&:hover": {
                        //     //     color: 'blue'
                        //     // }
                        // }}
                        key={item.content_node_id}
                        onClick={(e) => {
                            redirect(`/documents/${record.document_id}/contents_view/${item.content_node_id}`);
                        }}
                    >
                        {item.body}
                    </Link>
                );
            })}

            {/*<Link*/}
            {/*    underline="hover"*/}
            {/*    color="inherit"*/}
            {/*    // href="/"*/}
            {/*    // clickable={false}*/}
            {/*    // sx={{*/}
            {/*    //     // margin: '0.5em',*/}
            {/*    //     // maxWidth: "98%",*/}
            {/*    //     // overflowWrap: "break-word",*/}
            {/*    //     // "&:hover": {*/}
            {/*    //     //     color: 'blue'*/}
            {/*    //     // }*/}
            {/*    // }}*/}
            {/*    onClick={(aa) => {*/}
            {/*        redirect(`/documents/${record.document_id}/contents/${record.id}`)*/}
            {/*    }}*/}
            {/*>*/}
            {/*    ツリーへ*/}
            {/*</Link>*/}
        </Breadcrumbs>
    );
};
export default HeadingsField;
