import {useContext} from 'react';
import {SelectedMenuContext} from '../components/functional/SelectedMenuProvider';

export const useSelectedMenu = () => {
    const context = useContext(SelectedMenuContext);
    if (!context) {
        throw new Error('useSelectedMenu must be used within a SelectedMenuProvider');
    }
    return context;
};
