import { AdminContextProps, localStorageStore } from 'react-admin';
import './App.scss';
import { IodAdminUI } from './IodAdminUI';
import CognitoAuthenticator from './components/functional/CognitoAuthenticator';
import { IodAdminContextProvider } from './components/functional/IodAdminContextProvider';
import { cognitoAuthProvider as authProvider } from './components/functional/cognitoAuthProvider';
import { dataProvider } from './components/functional/dataProvider';
import { i18nProvider } from './components/functional/i18n/i18nProvider';
import { iodThemeOptions } from './components/functional/themes';


const adminContextProps: AdminContextProps = {
    dataProvider: dataProvider,
    authProvider: authProvider,
    i18nProvider: i18nProvider,
    theme: iodThemeOptions,
    store: localStorageStore(),
};
const App = () => {
    // dataProviderWithTree.getRootNodes('organizations').then((data: any) => {
    //     console.log(data);
    // });
    return (
        <CognitoAuthenticator
        // hideSignUp={true}
        >
            <IodAdminContextProvider {...adminContextProps}>
                <IodAdminUI />
            </IodAdminContextProvider>
        </CognitoAuthenticator>
    );
};

export default App;
