import { FC } from 'react';
import { TextInput } from 'react-admin';
import { Tooltip } from '@mui/material';
import { TextInputProps } from 'ra-ui-materialui';
import { IodSearchInput } from './IodSearchInput';

interface Props extends TextInputProps {
    title: string;
}

const TooltipTextInput: FC<Props> = (props) => {
    const { title, ...textInputProps } = props;

    return (
        <Tooltip title={title}>
            {/*Mui Tooltipはchildrenに直接refを渡すことが期待されているため、それが可能なコンポーネントでラップ*/}
            <span>
                <IodSearchInput {...textInputProps} />
            </span>
        </Tooltip>
    );
};

export default TooltipTextInput;
