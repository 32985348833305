import { FC, useCallback, useEffect } from 'react';
import { ResourceContextProvider, Title } from 'react-admin';
import { useExpandedKeys } from '@react-admin/ra-tree';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { usePathArray } from '../../../hooks/usePathArray';
import { useSidebarWidth } from '../../../hooks/useSidebarWidth';
import { ContentShow } from './ContentShow';
import { IodTreeWithDetails } from './IodTreeWithDetails';

// a List view for a tree uses <TreeWithDetails>
export const ContentReadOnlyTree: FC = () => {
    const [_, setExpandedKeys] = useExpandedKeys();
    const { document_id } = useParams();
    const location = useLocation();
    const match = matchPath('/documents/:document_id/contents_view/*', location.pathname);
    const content_path = match?.params['*'] || '';
    const paths = usePathArray(content_path);

    useEffect(() => {
        setExpandedKeys(paths);
    }, [setExpandedKeys]);
    // return (
    //     <IodTree
    //         titleField="body"
    //     />
    // )
    const sidebarWidth = useSidebarWidth();

    return (
        <Box
        // sx={{
        //     display: 'flex',
        // }}
        >
            <ResourceContextProvider value={`documents/${document_id}/contents_view`}>
                <IodTreeWithDetails
                    title="目次（現行版）"
                    titleField="body"
                    // linkTo="show"
                    linkTo="edit"
                    edit={ContentShow}
                    // autoExpandParent={false}
                    nodeActions={<></>}
                    // defaultExpandedKeys={[]}
                    allowMultipleRoots
                    // lazy
                    addRootButton={false}
                    motion={false} // ツリーが固まる問題を解消するのに役立つ
                    showLine
                    // 左右それぞれをスクロールできるようにhttps://trello.com/c/VdCsgCdM
                    sx={{
                        //sxにつぃて以下参照https://mui.com/system/getting-started/the-sx-prop/
                        position: 'fixed', //スクロールを無効化
                        // ツリーコンポーネント全体の幅 = 画面の幅 - (サイドバーの幅 + 画面全体のスクロールバーの幅？)
                        width: `calc(100vw - ${sidebarWidth + 16}px)`,
                        '& .RaTreeWithDetails-cardTree': {
                            width: '40%',
                            height: 'calc(100vh - 70px)',
                            overflowY: 'auto', //以下参照https://developer.mozilla.org/en-US/docs/Web/CSS/overflow
                            overflowX: 'hidden',
                            position: 'relative',
                            '& .MuiTypography-root': {
                                //   width: "200px", // 仮の値で、これがないとtextOverflow: ellipsisが効かない
                                overflow: 'hidden',
                                //   textOverflow: "ellipsis",
                            },
                        },
                        '& .RaTreeWithDetails-cardContentTree': {
                            overflow: 'auto',
                        },
                        '& .RaTreeWithDetails-actionCard': {
                            width: '60%',
                            height: 'calc(100vh - 70px)',
                            overflowY: 'auto',
                            overflowX: 'auto',
                            '& .edit-page': {
                                // position: 'fixed',
                                '& .RaEdit-main': {
                                    width: '99%',
                                },
                            },
                        },
                        '& .iod-tree-gutter': {
                            backgroundColor: 'none',
                            width: '8px',
                            marginTop: '1em',
                            '&:hover': {
                                backgroundColor: '#ccc',
                            },
                        },
                    }}
                    // hideRootNodes
                />
            </ResourceContextProvider>
        </Box>
    );
};

export const ContentsView = () => {
    const { document_id } = useParams();

    if (document_id === null) {
        return null;
    }

    return (
        <ResourceContextProvider value={`documents/${document_id}/contents_view`}>
            <ContentReadOnlyTree />
        </ResourceContextProvider>
    );
};
