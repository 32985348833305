import { RecordContextProvider, useGetOne, useRecordContext } from 'react-admin';
import { ApplyFontSize } from '../../../components/functional/FontSizeProvider';
import { ParagraphDetail } from '../ParagraphDetail';
import { Paragraph } from '../types/paragraph';

interface ParagraphExpandProps {
    version?: string;
}
export const ParagraphExpand = (props: ParagraphExpandProps) => {
    const record = useRecordContext<Paragraph>();
    const { data, error, isLoading } = useGetOne<Paragraph>('paragraphs', {
        id: record.id,
        meta: props.version
            ? {
                  version: props.version,
              }
            : null,
    });

    if (error || isLoading) {
        return null;
    }

    return (
        <RecordContextProvider value={data}>
            <ApplyFontSize>
                <ParagraphDetail version={props.version} />
            </ApplyFontSize>
        </RecordContextProvider>
    );
};
