import { Fragment } from 'react';
import { Labeled } from 'react-admin';
import { Paper, SxProps, Typography } from '@mui/material';
import * as JsDiff from 'diff';

interface DiffTextProps {
    oldText: string;
    newText: string;
    removedTextBackgroundColor: string;
    addedTextBackgroundColor: string;
    labelName: string;
    paperSx: SxProps;
}

export const DiffText = (props: DiffTextProps) => {
    const { oldText, newText, removedTextBackgroundColor, addedTextBackgroundColor, labelName, paperSx } = props;

    // 文字単位の差分を検出
    const diffChars = JsDiff.diffChars(oldText, newText);

    const oldDiffElement = diffChars
        .filter((change: JsDiff.Change) => !change.added) // 追加された文字は除外
        .map((change: JsDiff.Change) => (
            <Typography
                component="span"
                style={{ backgroundColor: change.removed ? removedTextBackgroundColor : 'transparent' }}
            >
                {change.value.split('\n').map((line, i) => (
                    <Fragment key={i}>
                        {/* i > 0: 最初の行ではない場合は改行を挿入 */}
                        {i > 0 && <br />}
                        {line}
                    </Fragment>
                ))}
            </Typography>
        ));

    const newDiffElement = diffChars
        .filter((change: JsDiff.Change) => !change.removed) // 削除された文字は除外
        .map((change: JsDiff.Change) => (
            <Typography
                component="span"
                style={{ backgroundColor: change.added ? addedTextBackgroundColor : 'transparent' }}
            >
                {change.value.split('\n').map((line, i) => (
                    <Fragment key={i}>
                        {i > 0 && <br />}
                        {line}
                    </Fragment>
                ))}
            </Typography>
        ));

    return (
        <>
            <Labeled label={labelName} fullWidth sx={{ mb: 2 }}>
                <Paper variant="outlined" sx={paperSx}>
                    {oldDiffElement}
                </Paper>
            </Labeled>
            <Labeled label={labelName} fullWidth sx={{ mb: 2 }}>
                <Paper variant="outlined" sx={paperSx}>
                    {newDiffElement}
                </Paper>
            </Labeled>
        </>
    );
};
