import EditIcon from '@mui/icons-material/Edit';
import {IconButton, Tooltip} from '@mui/material';
import * as React from 'react';
import {Paragraph} from '../types/paragraph';
import {useNavigate} from 'react-router-dom';
import {FC} from 'react';

interface Props {
    paragraph: Paragraph;
}
export const ParagraphEditButton: FC<Props> = ({paragraph}) => {

    const navigate = useNavigate();

    const handleEdit = (p: Paragraph) => {
        // 編集画面に遷移
        navigate(`/edit/documents/${p.document_id}/edit/contents/${encodeURIComponent(p.path)}`);
    };


    return (
        <Tooltip
            title="パラグラフの編集"
            placement="top"
        >
            <IconButton
                size="small"
                color="primary"
                onClick={(e) => {
                    e.stopPropagation(); // クリックイベントを行に伝搬させない様にする
                    handleEdit(paragraph)
                }}
            >
                <EditIcon />
            </IconButton>
        </Tooltip>
    )
}
