import React, { useEffect } from 'react';
import { Datagrid, RowClickFunction, useListContext, useNotify } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

interface DocumentDatagridComponentProps {
    canEdit: boolean;
    rowClick: string | RowClickFunction | false;
    children?: React.ReactNode;
}

export const DocumentDatagridComponent = (props: DocumentDatagridComponentProps) => {
    const { data } = useListContext();
    const location = useLocation();
    const navigate = useNavigate();
    const notify = useNotify();

    useEffect(() => {
        if (props.canEdit) {
            // 編集可能なら、更新系の操作を行う可能性があるためリダイレクトしない
            return;
        }

        if (data && data.length === 1) {
            // ドキュメントIDが一件のみ
            const documentId = data[0].id;

            // 現在パスに応じてリダイレクト先を決定する
            if (location.pathname.startsWith('/documents')) {
                navigate(`/documents/${documentId}/contents_view`);
            } else if (location.pathname.startsWith('/edit/documents')) {
                navigate(`/edit/documents/${documentId}/contents`);
            }
        }
    }, [data, location, navigate, notify, props]);

    if (!data) {
        return <CircularProgress />;
    }

    return (
        <Datagrid bulkActionButtons={false} rowClick={props.rowClick}>
            {props.children}
        </Datagrid>
    );
};
