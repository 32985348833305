// 基準となるISO A4サイズ［210mm*297mm］をtwip変換したサイズを定義
export const PAGE_HEIGHT = 21050; // レイアウトが横向きなので実施的には横幅（twip*1.25）
export const PAGE_WIDTH = 11906; // レイアウトが横向きなので実質的には高さ

// 応用的なサイズの定義
export const DEFAULT_MARGIN = 1440 * 2; // 両方向の余白（標準余白25.4mmをtwip変換）
export const TOTAL_TABLE_WIDTH = PAGE_HEIGHT - DEFAULT_MARGIN; // トンボからはみ出ないテーブルの横幅
export const COLUMN_WIDTH_BEFORE_AFTER = TOTAL_TABLE_WIDTH * 0.4; // 列:編集前の横幅
export const COLUMN_WIDTH_REASON = TOTAL_TABLE_WIDTH * 0.2;
export const TABLE_ROW_MARGIN = 50;

// フォントサイズ
export const FONT_SIZE = 21; // 実質的には10.5
export const FONT_SIZE_DOCX_TABLE = 18; // 実質的には9
