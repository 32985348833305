import {AdminContext, AdminContextProps} from 'react-admin';
import {FC, ReactNode} from 'react';
import {SelectedMenuProvider} from './SelectedMenuProvider';
import {FontSizeProvider} from './FontSizeProvider';
import {HierarchicalOrgProvider} from './HierarchicalOrgProvider';

interface IodAdminContextProviderProps extends AdminContextProps {
    children: ReactNode;
}

/**
 * ContextProviderをまとめたProvider。
 *
 * アプリケーションの外側でラップする。
 */
export const IodAdminContextProvider: FC<IodAdminContextProviderProps> = (props) => {
    const {children, ...restProps} = props;
    return (
        <AdminContext {...restProps}>
            <SelectedMenuProvider>
                <FontSizeProvider>
                    <HierarchicalOrgProvider>
                        {props.children}
                    </HierarchicalOrgProvider>
                </FontSizeProvider>
            </SelectedMenuProvider>
        </AdminContext>
    );
};
