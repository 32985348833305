import { FC } from 'react';
import { Toolbar, ToolbarProps } from 'react-admin';

interface Props extends Omit<ToolbarProps, 'sx'> {
    // sxプロパティ以外のToolbarPropsを継承
}

// 権限によるボタンの制御用
export const IodToolbar: FC<Props> = (props) => {
    const { children, ...restProps } = props;

    return (
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} {...restProps}>
            {children}
        </Toolbar>
    );
};
