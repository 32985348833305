import * as React from 'react';
import { useContext } from 'react';
import { FormControl, Input, InputAdornment, MenuItem, Select, Tooltip } from '@mui/material';
import { FormatSize } from '@mui/icons-material';
import { FontSizeContext } from '../../functional/FontSizeProvider';

interface Props {
    className?: string;
}

export const FontSizeSelector = (props: Props) => {
    const { fontSize, setFontSize } = useContext(FontSizeContext);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleFontSizeChange = (e: any) => {
        console.log(e);
        setFontSize(Number(e.target.value));
    };

    return (
        <FormControl
            variant="standard"
            sx={{
                m: 1,
            }}
            size="small"
        >
            <Tooltip title="フォントサイズ" placement="left">
                <Select
                    id="font-size"
                    value={fontSize}
                    onChange={handleFontSizeChange}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    sx={{
                        color: 'inherit',
                        '& .MuiSelect-icon': {
                            display: 'none', // 右側の▼アイコンを非表示
                        },
                        '& .MuiSelect-select': {
                            paddingRight: '8px!important', // 右側の▼アイコンを非表示分の余白を消す
                            paddingBottom: 'calc(4px - 1em)', // 下側のパディングを減少させる
                        },
                    }}
                    input={
                        <Input
                            id="icon-select"
                            startAdornment={
                                <InputAdornment position="start" sx={{ color: 'inherit' }} onClick={handleOpen}>
                                    <FormatSize
                                        sx={{
                                            color: 'inherit',
                                        }}
                                    />
                                </InputAdornment>
                            }
                        />
                    }
                >
                    <MenuItem value={0.6}>XS</MenuItem>
                    <MenuItem value={0.8}>S</MenuItem>
                    <MenuItem value={1}>M</MenuItem>
                    <MenuItem value={1.2}>L</MenuItem>
                    <MenuItem value={1.4}>XL</MenuItem>
                </Select>
            </Tooltip>
        </FormControl>
    );
};
