import { FC, MouseEvent, useState } from 'react';
import { useCreate, useDelete, useGetList, useNotify } from 'react-admin';
import {
    Alert,
    Badge,
    IconButton,
    IconButtonProps,
    Menu,
    MenuItem,
    PaletteColor,
    Stack,
    Tooltip,
    styled,
    useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { FAVORITE_ITEM_LIMIT, FavoriteItem } from '../types/favoriteItem';
import { FavoriteListCreateDialog } from './FavoriteListCreateDialog';
import { FavoriteListDeleteDialog } from './FavoriteListDeleteDialog';
import { FavoriteListEditDialog } from './FavoriteListEditDialog';
import {MoreVert} from '@mui/icons-material';

type FavoriteButtonProps = {
    content_id: number;
};

type DialogType = 'create' | 'edit' | 'delete';

type DialogStateType = {
    type?: DialogType;
    open: boolean;
};

export const FavoriteButton: FC<FavoriteButtonProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [listSettingMenuState, setListSettingMenuState] = useState<{
        list_id?: number;
        anchorEl: HTMLElement | null;
    }>({
        anchorEl: null,
    });
    const [dialogState, setDialogState] = useState<DialogStateType>({
        open: false,
    });
    const { data, isLoading, refetch } = useGetList<FavoriteItem>('favorites');
    const notify = useNotify();
    const theme = useTheme();
    const [create, { isLoading: isCreating }] = useCreate<FavoriteItem>(undefined, undefined, {
        onError: (error: any) => {
            if (error.body?.code === 'RESOURCE_CONFLICT') {
                notify(<Alert severity="error">既にお気に入りに登録済みです</Alert>);
            } else {
                notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>);
            }
            return error;
        },
        onSuccess: (res: FavoriteItem) => {
            notify(<Alert severity="info">{res.name}に追加しました。</Alert>);
            refetch();
        },
    });

    const [deleteOne, { isLoading: isDeleting }] = useDelete<FavoriteItem>(undefined, undefined, {
        onError: (error: any) => {
            notify(<Alert severity="error">{error.body?.detail || error.message}</Alert>);
            return error;
        },
        onSuccess: (res) => {
            notify(<Alert severity="info">お気に入りから削除しました。</Alert>);
            refetch();
        },
    });

    const content_id = props.content_id;

    const handleButtonClick = (e: MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (list_id: number) => async () => {
        const list = data && data.find((item) => item.id === list_id);
        if (!list) return;

        const listAlreadyHasParagraph = list.content_ids.includes(content_id);

        if (listAlreadyHasParagraph) {
            const res = await deleteOne(`favorites/${list_id}/paragraphs`, {
                id: content_id,
            });
        } else {
            const res = create(`favorites/${list_id}/paragraphs`, {
                data: {
                    content_ids: [content_id],
                },
            });
        }
        handleMenuClose();
    };

    const handleDialogOpen = (type: DialogType) => () => {
        setDialogState({
            type: type,
            open: true,
        });
    };

    const handleDialogClose = () => {
        setDialogState({
            open: false,
        });
    };

    const handleListSettingClick = (list_id: number) => (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setListSettingMenuState({
            list_id: list_id,
            anchorEl: e.currentTarget,
        });
    };

    const handleListSettingMenuClose = () => {
        setListSettingMenuState({
            anchorEl: null,
        });
    };

    const handleSettingMenuClick = (type: DialogType) => (e: MouseEvent) => {
        e.stopPropagation();
        handleDialogOpen(type)();
    };

    const handleEditSuccess = () => {
        console.log('success!!');
        handleDialogClose();
        handleListSettingMenuClose();
        refetch();
    };

    const favoriteItemLimitOver: boolean = (data?.length ?? 0) >= FAVORITE_ITEM_LIMIT;
    const registeredContentLimitOver = (item: FavoriteItem) => item.content_ids.length >= 1000;
    const includeCount: number = data?.filter((item) => item.content_ids.includes(content_id)).length ?? 0;

    return (
        <>
            <Tooltip
                title="お気に入りリスト"
                placement="top"
            >
                <Badge badgeContent={includeCount} color="success">
                    <OutlinedIconButton
                        color="success"
                        aria-label="お気に入りリストに追加"
                        size="small"
                        onClick={handleButtonClick}
                        disabled={isLoading}
                        contained={Boolean(data?.find((item) => item.content_ids.includes(content_id)))}
                    >
                        <BookmarkIcon fontSize="inherit" />
                    </OutlinedIconButton>
                </Badge>
            </Tooltip>
            <Menu open={anchorEl !== null} anchorEl={anchorEl} onClose={handleMenuClose}>
                <Tooltip
                    title={favoriteItemLimitOver ? 'お気に入りリストの数が上限に達しました。' : ''}
                    placement="top"
                >
                    <div>
                        <MenuItem onClick={handleDialogOpen('create')} disabled={favoriteItemLimitOver}>
                            <Stack direction="row" alignItems="center" gap={[0, 0.5]} sx={{ paddingBlock: 0.75 }}>
                                <AddIcon sx={{ opacity: 0.5 }} />
                                <div
                                    style={{
                                        lineHeight: '1em',
                                        height: '1em',
                                        marginTop: '.125em',
                                    }}
                                >
                                    リストを新規作成
                                </div>
                            </Stack>
                        </MenuItem>
                    </div>
                </Tooltip>
                {data?.map((item) => (
                    <Tooltip
                        title={registeredContentLimitOver(item) ? 'コンテンツの登録数が上限に達しました' : ''}
                        key={item.id}
                    >
                        <div>
                            <MenuItem
                                onClick={handleMenuClick(item.id)}
                                sx={{
                                    direction: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                                disabled={registeredContentLimitOver(item) && !item.content_ids.includes(content_id)}
                            >
                                <Stack
                                    direction="row"
                                    sx={{
                                        direction: 'row',
                                        alignItems: 'center',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                        columnGap: 4,
                                    }}
                                >
                                    {/*<div*/}
                                    {/*    style={{*/}
                                    {/*        lineHeight: "1em",*/}
                                    {/*        height: "1em",*/}
                                    {/*        marginTop: ".125em",*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    <Badge
                                        badgeContent={item.content_ids.length}
                                        max={9999}
                                        color="secondary"
                                        sx={{
                                            '& .MuiBadge-badge': {
                                                right: -30,
                                                top: '50%',
                                            },
                                        }}
                                    >
                                        {item.name}
                                        {item.content_ids.includes(content_id) ? 'から削除' : 'に追加'}
                                    </Badge>
                                    {/*</div>*/}
                                    <IconButton
                                        size="small"
                                        onClick={handleListSettingClick(item.id)}
                                        sx={{ marginLeft: 2 }}
                                    >
                                        <MoreVert />
                                    </IconButton>
                                </Stack>
                            </MenuItem>
                        </div>
                    </Tooltip>
                ))}
            </Menu>
            <Menu
                open={listSettingMenuState.anchorEl !== null}
                anchorEl={listSettingMenuState.anchorEl}
                onClose={handleListSettingMenuClose}
            >
                <MenuItem onClick={handleSettingMenuClick('edit')}>
                    <Stack direction="row" alignItems="center" gap={[0, 1]}>
                        <EditIcon fontSize="small" sx={{ opacity: 0.5 }} />
                        <div
                            style={{
                                lineHeight: '1em',
                                height: '1em',
                                marginTop: '.125em',
                                marginRight: '.5em',
                            }}
                        >
                            編集
                        </div>
                    </Stack>
                </MenuItem>
                <MenuItem
                    onClick={handleSettingMenuClick('delete')}
                    sx={{ ':hover': { color: theme.palette.error.main } }}
                >
                    <Stack direction="row" alignItems="center" gap={[0, 1]}>
                        <DeleteIcon fontSize="small" sx={{ opacity: 0.5 }} />
                        <div
                            style={{
                                lineHeight: '1em',
                                height: '1em',
                                marginTop: '.125em',
                                marginRight: '.5em',
                            }}
                        >
                            削除
                        </div>
                    </Stack>
                </MenuItem>
            </Menu>
            <FavoriteListCreateDialog
                open={dialogState.type === 'create' && dialogState.open}
                onClose={handleDialogClose}
                onSuccess={handleEditSuccess}
            />
            {listSettingMenuState.list_id && (
                <>
                    <FavoriteListEditDialog
                        list_id={listSettingMenuState.list_id}
                        open={dialogState.type === 'edit' && dialogState.open}
                        onClose={handleDialogClose}
                        onSuccess={handleEditSuccess}
                    />
                    <FavoriteListDeleteDialog
                        list_id={listSettingMenuState.list_id}
                        open={dialogState.type === 'delete' && dialogState.open}
                        onClose={handleDialogClose}
                        onSuccess={handleEditSuccess}
                    />
                </>
            )}
        </>
    );
};

interface OutlinedIconButtonProps extends IconButtonProps {
    contained: boolean;
}

const OutlinedIconButton = styled(({ contained, ...props }: OutlinedIconButtonProps) => (
    <IconButton {...props} />
))<OutlinedIconButtonProps>(({ theme, color, contained }) => {
    const palette: PaletteColor = theme.palette[color as keyof typeof theme.palette] as PaletteColor;
    return {
        border: `1px solid ${palette.main || 'transparent'}`,
        color: contained ? palette.contrastText : palette.main,
        backgroundColor: contained ? palette.main : 'transparent',
        '&:hover': {
            color: contained ? palette.contrastText : palette.main,
            backgroundColor: contained ? palette.main : 'transparent',
        },
    };
});
