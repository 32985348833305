import * as React from 'react';
import { Create, Labeled, NumberInput, SimpleForm, TextInput } from 'react-admin';
import OrganizationArrayInput from '../../contents/components/OrganizationArrayInput';
import { ORGANIZATION_ITEM_NAME_KANA_MAX_LENGTH, ORGANIZATION_ITEM_NAME_MAX_LENGTH } from '../types/organization';

export const OrganizationCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput
                source="name"
                label="名称を入力"
                fullWidth
                inputProps={{
                    maxLength: ORGANIZATION_ITEM_NAME_MAX_LENGTH,
                }}
            />
            <TextInput
                source="name_kana"
                label="組織名（カナ）を入力"
                fullWidth
                inputProps={{
                    maxLength: ORGANIZATION_ITEM_NAME_KANA_MAX_LENGTH,
                }}
            />
            <NumberInput source="position" fullWidth></NumberInput>
            <OrganizationArrayInput />
        </SimpleForm>
    </Create>
);
