import { SelectChangeEvent, TextField } from '@mui/material';

export interface DiscussionFormValue {
    comment: string;
}

interface Props {
    formValue: DiscussionFormValue;
    handleChange: (formValue: DiscussionFormValue) => void;
}

export const DiscussionForm = (props: Props) => {
    const { formValue, handleChange } = props;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        handleChange({
            ...formValue,
            [name]: value,
        });
    };

    return (
        <form>
            <TextField
                name="comment"
                label="コメント"
                InputLabelProps={{
                    sx: { zIndex: 0 }, // labelのz-indexを0に設定
                }}
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                maxRows={6}
                value={formValue.comment}
                onChange={handleInputChange}
                placeholder="こちらにコメントを記述してください..."
            />
        </form>
    );
};
